import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(3), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function CompanyStatisticsChart(data) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const teamSeries = { engagement: [], activity: [], sentiment: [], influence: [], connectedness: [] }
  const opacityArray = []
  const dashArray = []
  const categories = [...new Set(Object.values(data.company).reduce((a, c) => a.concat(c), []).map(i => i.date))].sort((a, b) => a > b ? 1 : -1)

  for (const [team, stats] of Object.entries(data.company)) {
    let seriesName = (team === "company" ? "Company Average" : "Team: " + team)
    if (team === "company") {
      seriesName = "Company Average"
      opacityArray.push(0.05)
      dashArray.push(3)
    }
    else if (team === "unassigned") {
      seriesName = "Unassigned"
      opacityArray.push(0.05)
      dashArray.push(3)
    }
    else {
      opacityArray.push(1)
      dashArray.push(0)
    }
    const teamData = { engagement: [], activity: [], sentiment: [], influence: [], connectedness: [] }
    for (const date of categories) {
      for (const key of Object.keys(teamData)) {
        const value = stats.find(i => i.date === date && i[key])
        teamData[key].push(value ? value[key] : null)
      }
    }
    teamSeries.engagement.push({ name: seriesName, data: teamData.engagement, type: (team === "company" ? "area" : "line") })
    teamSeries.activity.push({ name: seriesName, data: teamData.activity, type: (team === "company" ? "area" : "line") })
    teamSeries.sentiment.push({ name: seriesName, data: teamData.sentiment, type: (team === "company" ? "area" : "line") })
    teamSeries.influence.push({ name: seriesName, data: teamData.influence, type: (team === "company" ? "area" : "line") })
    teamSeries.connectedness.push({ name: seriesName, data: teamData.connectedness, type: (team === "company" ? "area" : "line") })
  }

  const graphChartOptionsCollaboration = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories },
    yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(0) : "" } } },
    legend: { position: teamSeries && teamSeries.engagement && teamSeries.engagement.length > 3 ? "right" : "top", show: true },
    stroke: { width: 2, dashArray: dashArray },
    markers: { size: 6, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 4, },
    fill: { type: 'solid', opacity: opacityArray, },
  };


  return (
    <Card className="card-box d-flex">
      <CardContent style={{width:"100%"}}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <div className={classes.themeColorTabs}>
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" variant="scrollable" scrollButtons="auto" >
                  <StyledTab label="Engagement" />
                  <StyledTab label="Activity" />
                  <StyledTab label="Sentiment" />
                  <StyledTab label="Connectedness" />
                  <StyledTab label="Influence" />
                </StyledTabs>
              </div>
              <TabPanel value={value} index={0}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className="mb-3 br-5" ><b className="font-size-lg " >Team Engagement Scores</b></div>
                    <span className="text-black-50 d-block">(This score is weighted and calculated based on Activity, Connectedness and Influece scores)</span>
                    <Chart options={graphChartOptionsCollaboration} series={teamSeries.engagement} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className="mb-3 br-5" ><b className="font-size-lg " >Team Activity Scores</b></div>
                    <span className="text-black-50 d-block">(This score is based on activity volume, active hours per day and mention metrics)</span>
                    <Chart options={graphChartOptionsCollaboration} series={teamSeries.activity} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className="mb-3 br-5" ><b className="font-size-lg " >Team Sentiment Scores</b></div>
                    <span className="text-black-50 d-block">(This score is calculated based on positive, negative and neutral collaboration data points)</span>
                    <Chart options={graphChartOptionsCollaboration} series={teamSeries.sentiment} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className="mb-3 br-5" ><b className="font-size-lg " >Team Connectedness Scores</b></div>
                    <span className="text-black-50 d-block">(This score is based on connections employees has, information broker index and degree centrality scores)</span>
                    <Chart options={graphChartOptionsCollaboration} series={teamSeries.connectedness} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className="mb-3 br-5" ><b className="font-size-lg " >Team Influence Scores</b></div>
                    <span className="text-black-50 d-block">(This score in based on employees betweenness, authority and pagerank indexes)</span>
                    <Chart options={graphChartOptionsCollaboration} series={teamSeries.influence} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
