import { Cache } from 'aws-amplify';
import pako from 'pako';
const cacheConfig = {itemMaxSize: (5 * 1024 * 1024), storage: window.sessionStorage, defaultTTL: 60 * 60 * 1000, capacityInBytes: 5 * 1024 * 1024};
const caches = [];

function getCache(service) {
    const cacheFound = caches.find(c => c.service === service);
    if(cacheFound) return cacheFound.cache;
    caches.push({service, cache: Cache.createInstance({...cacheConfig, keyPrefix: service})});
    return caches.find(c => c.service === service).cache;
}

function getItem(service, flowtraceUser, apiURL, qf) {
    try {
        const cache = getCache(service);
        const cacheKey = flowtraceUser.account.account_id + apiURL + JSON.stringify(qf);
        const cached = cache.getItem(cacheKey);
        if(cached) {
            return JSON.parse(pako.inflate(cached, { to: 'string' }));
        }
        return null;
    } catch(e){
        console.log(e);
    }
    return null;
}

function setItem(service, flowtraceUser, apiURL, qf, item) {
    try {
        const cache = getCache(service);
        const cacheKey = flowtraceUser.account.account_id + apiURL + JSON.stringify(qf);
        return cache.setItem(cacheKey, pako.deflate(JSON.stringify(item)));
    } catch (e) {
        console.log(e);
    }
    return;
}

function clear(service = null) {
    try {
        if(service){
            const cache = getCache(service);
            return cache.clear();
        }
        // clear all caches
        return Cache.clear();
    } catch (e) {
        console.log(e);
    }
    return Cache.clear();
}
const functions = { getItem, setItem, clear };
export default functions;
