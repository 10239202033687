import React, { Fragment } from 'react';
import { Select, FormControl, Switch, Button, Grid, Card, Divider } from '@material-ui/core';
import accountReportsAPI from '../../../flowtrace-api/Account/accountReportActions';
import Loading from '../../../flowtrace-components/Loading';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';

const savedSuccess =
  <MuiAlert className="mb-4" severity="info" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Success.</strong>
          <p>Report saved successfully.</p>
      </span>
    </div>
  </MuiAlert>;

const reportRunSuccess =
  <MuiAlert className="mb-4" severity="info" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Success.</strong>
          <p>Report run was a success. You can see the report on your selected Slack channel.</p>
      </span>
    </div>
  </MuiAlert>;

const reportRunFailed =
  <MuiAlert className="mb-4" severity="warning" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Running the report failed.</strong>
          <p>Please refresh the page and try again, or contact Flowtrace support for help.</p>
      </span>
    </div>
  </MuiAlert>;


function createTimeString(seconds) {
  // Make sure seconds are always 00:00 format (even if less than 10 hours, or 10 minutes)
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = seconds / 60 % 60
  let timeString = ""
  if (hours < 10)
    timeString = "0" + hours + ":"
  else
    timeString = hours + ":"

  if (minutes < 10)
    timeString = timeString + "0" + minutes
  else
    timeString = timeString + minutes

  return timeString
}

function parseTimeString(timeString) {
  const hoursInSeconds = timeString.split(':')[0] * 60 * 60;
  const minutesInSeconds = timeString.split(':')[1] * 60;
  let seconds = 0;
  // The component can fail in multiple ways, eg. user inputting text, or 75 minutes etc... Do some quick parsing to get the hours / minutes from the string:
  if (Number.isInteger(hoursInSeconds))
    seconds += hoursInSeconds;
  if (Number.isInteger(minutesInSeconds))
    seconds += minutesInSeconds;
  return seconds;
}

function createFrequencyString(report = {}) {
  // Combine frequence and frequency day for the value:
  let frequencyString = report.frequency ? report.frequency : "workday";
  if (report.frequency === "weekly")
    frequencyString = "weekly_" + report.frequencyDay;
  if (report.frequency === "monthly")
    frequencyString = "monthly_" + (report.frequencyDay === 1 ? "first" : "last");
  return frequencyString;
}

function parseFrequencyString(frequencyString) {
  // Get the daily, weekday, weekly, or monthly frequency and day parameter for weekly and monthly reports
  if (frequencyString.includes("weekly_"))
    return { frequency: "weekly", frequencyDay: parseInt(frequencyString.split("_")[1]) };
  else if (frequencyString === "monthly_first")
    return { frequency: "monthly", frequencyDay: 1 };
  else if (frequencyString === "monthly_last")
    return { frequency: "monthly", frequencyDay: -1 };
  else
    return { frequency: frequencyString };
}


export default function SlackReportConfiguration(props) {

  const [settingsChanged, setSettingsChanged] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const [state, setState] = React.useState({
    type: "slackReport",
    enabled: props.create ? false : props.report.enabled,
    title: props.create ? "" : props.report.title,
    weekday: props.create ? 1 : props.report.weekday,
    channel: props.create ? "" : props.report.channel,
    frequency: createFrequencyString(props.report),
    content: props.create ? 7 : props.report.content,
    time: props.create ? 0 : props.report.time,
    changed: props.create ? false : props.report.changed,

    // Contents of the report:
    benchmark: props.create ? true : props.report.benchmark,
    meeting: props.create ? true : props.report.meeting,
    productivity: props.create ? true : props.report.productivity,
    survey: props.create ? true : props.report.survey,
    breakdownChart: props.create ? false : props.report.breakdownChart,
    collaborationChart: props.create ? false : props.report.collaborationChart,
  });

  const handleTimeChange = (date) => {
    setSettingsChanged(true);
    setState({ ...state, time: (date.getHours() * 60 + date.getMinutes()) * 60 });
  };

  const handleChange = (event, button = null) => {
    setSettingsChanged(true);
    switch (event.target.name) {
      case "frequency":
        setState({ ...state, ...parseFrequencyString(event.target.value) });
        break;
      case "content":
        setState({ ...state, content: parseInt(event.target.value) });
        break;
      case "weekday":
        setState({ ...state, weekday: parseInt(event.target.value) });
        break;
      case "channel":
        setState({ ...state, channel: event.target.value });
        break;
      case "enabled":
        setState({ ...state, enabled: event.target.checked });
        break;
      case "time":
        setState({ ...state, time: parseTimeString(event.target.value) });
        break;
        // Contents of the slack alert:
      case "benchmark":
        setState({ ...state, benchmark: event.target.checked });
        break;
      case "survey":
        setState({ ...state, survey: event.target.checked });
        break;
      case "meeting":
        setState({ ...state, meeting: event.target.checked });
        break;
      case "productivity":
        setState({ ...state, productivity: event.target.checked });
        break;
      case "breakdownChart":
        setState({ ...state, breakdownChart: event.target.checked });
        break;
      case "collaborationChart":
        setState({ ...state, collaborationChart: event.target.checked });
        break;

      default:
        console.log("Event not recognized", event.target)
    }
  };

  const saveChanges = async (settings) => {
    setLoading(true);
    const success = await accountReportsAPI.updateReport(settings);
    setLoading(false);

    if (success) {
      setNotification(savedSuccess);
      setSettingsChanged(false);
      setTimeout(() => { setNotification(null); }, 3000);
    }
    else
      props.reload();
  };
  const runReport = async (channel) => {
    setLoading(true);
    const success = await accountReportsAPI.runSlackReport(channel);
    setLoading(false);
    if (success) {
      setNotification(reportRunSuccess);
      setTimeout(() => { setNotification(null); }, 5000);
    }
    else {
      setNotification(reportRunFailed);
      setTimeout(() => { setNotification(null); }, 10000);
    }
  };
  const deleteReport = async (settings) => {
    setLoading(true);
    await accountReportsAPI.deleteReport(settings);
    setLoading(false);
    props.reload();
  };

  const createReport = async (settings) => {
    setLoading(true);
    await accountReportsAPI.createReport(settings);
    setLoading(false);
    props.reload();
  };

  if (loading)
    return (
      <Fragment>
        <Card className="p-4 mb-4">
          <Loading/>
        </Card>
      </Fragment>
    );


  // If this is a new report, which have not been stored to DB yet, handle it differently:
  if (props.create)
    return (
      <Fragment>
        <Card className="p-4 mb-4">
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Grid container spacing={2}>
                <Grid item xs={8} md={4}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  Want to create new Slack report? Start by choosing a channel:
                  </div>
                </Grid>
                <Grid item xs={4} md={2}>
                  <FormControl >
                    <Select native value={state.channel} onChange={handleChange} name="channel" >
                      <option disabled value=""> Select Channel </option>
                      {props.channels.map(c => <option key={c.id} value={c.id}>#{c.name}</option> ) }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button disabled={!settingsChanged} onClick={async() => await createReport(state)} size="medium" variant="contained" color="primary" className="ml-1 mr-3">Create new Report</Button>
                </Grid>
              </Grid>
            </Grid >
          </Grid>
        </Card>
      </Fragment>
    );

  const channelName = props.channels.find(c => c.id === props.report.channel) ? props.channels.find(c => c.id === props.report.channel).name : "Unknown Channel";
  // Otherwise render the component with report data:
  return (
    <Fragment>
      <ExpansionPanel className="pr-3 pb-3 pl-3 mb-4" expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <h5 className="pt-4 font-weight-bold font-size-lg"> #{channelName } report</h5>
          <span className="pt-2 font-weight-bold font-size-xxl">
            {state.enabled?
              <span className={"badge m-3 badge-dark-success"}>Active</span>
              :
              <span className={"badge m-3 badge-dark-danger "}>Inactive</span>
            }
          </span>

        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="font-size-md font-weight-bold mt-3 mb-2">Report Schedule:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Report Active:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.enabled} onChange={handleChange} color="primary" name="enabled" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  How often the report should go out?
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select native value={createFrequencyString(state)} onChange={handleChange} name="frequency">
                      <option value={"daily"}>Daily</option>
                      <option value={"workday"}>Daily - Mon to Fri</option>
                      <option value={"weekly_1"}>Weekly - Mon</option>
                      <option value={"weekly_2"}>Weekly - Tue</option>
                      <option value={"weekly_3"}>Weekly - Wed</option>
                      <option value={"weekly_4"}>Weekly - Thu</option>
                      <option value={"weekly_5"}>Weekly - Fri</option>
                      <option value={"weekly_6"}>Weekly - Sat</option>
                      <option value={"weekly_7"}>Weekly - Sun</option>
                      <option value={"monthly_first"}>Monthly - First day of month</option>
                      <option value={"monthly_last"}>Monthly - Last day of month</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  When the report is sent out? (GMT)
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                    autoOk
                    ampm={false}
                    value={Date.parse("2020-01-01T" + createTimeString(state.time) + ":00.000+00:00")}
                    onChange={handleTimeChange}
                    format={"HH:mm (+00:00)"}
                    name="time"
                    minutesStep={60}
                    views={["hours"]}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid >
            <Grid item xs={12} md={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div className="font-size-md font-weight-bold mb-3">Report Contents:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  How many days included in the report
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select className="mb-4" native value={state.content} onChange={handleChange} name="content">
                      <option value={7}>Last 7 days</option>
                      <option value={30}>Last 30 days</option>
                      <option value={90}>Last 90 days</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  Show metric benchmark when available:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.benchmark} onChange={handleChange} color="primary" name="benchmark" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-md font-weight-bold mt-3 mb-3">Report Metrics:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Meeting metrics:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.meeting} onChange={handleChange} color="primary" name="meeting" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Time breakdown metrics:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.productivity} onChange={handleChange} color="primary" name="productivity" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Survey Scores:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.survey} onChange={handleChange} color="primary" name="survey" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-md font-weight-bold mt-3 mb-3">Report Charts:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Activity time chart:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.breakdownChart} onChange={handleChange} color="primary" name="breakdownChart" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Collaboration sentiment chart:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.collaborationChart} onChange={handleChange} color="primary" name="collaborationChart" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
              </Grid >
            </Grid >
            {notification}

            <Divider className="my-4" />
            <Button disabled={!settingsChanged} onClick={async() => await saveChanges(state)} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Save Report Settings</Button>
            <Button disabled={settingsChanged} onClick={async() => await runReport(props.report.channel)} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Run Report Now</Button>
            <Button onClick={async() => await deleteReport(props.report)} size="medium" variant="contained" color="secondary" className="ml-1 mt-3 mr-3">Delete</Button>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
}
