import Chart from 'react-apexcharts';

import React, { Component, Fragment } from 'react';
import TotalCard from './totalCard';
import CostDetail from './costDetail';
import { Card, CardContent, Grid, Tooltip } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { HelpOutline, SettingsOverscan, Sort, SortByAlpha, List, PieChart } from '@material-ui/icons';
const info = <HelpOutline style={{ fontSize: 15 }}/>;

function convertMinutesToTimeString(minutes) {
  let timeString = "";
  if (!minutes) return "-";
  if (minutes >= 60)
    timeString = Math.floor(minutes / 60).toFixed(0) + "h " + (minutes % 60).toFixed(0) + "m";
  else
    timeString = (minutes % 60).toFixed(0) + " min" || "-";
  return timeString;
}

function convertCostToString(costProps, cost) {
  if (!cost) return "-";
  const currency = costProps ? costProps.currency : "GBP";
  const formatter = new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions().locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits:0
  });
  return formatter.format(cost); // "£1,000.00"
}

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, expand:false, sortTeamAlpha:true, sortOrganizerAlpha:true, listTeam:false, listOrganizer:false };
  }



  async componentDidMount() {
    const organizerData = [];
    const teamInvitedData = [];
    const teamData = [];
    const late = {late:null, cost:null};

    // Get accepted meeting time for teams
    for (const userTime of this.props.times.accepted) {
      // Add member accepted meeting time to team structure
      const member = this.props.members.find(m => m.id === userTime.id);
      const teamName = member ? member.team : "Unknown";
      const team = teamData.find(t => t.name === teamName);
      const time = userTime.time;
      if (!team)
        teamData.push({ name: teamName, value: time });
      else
        team.value += time;
    }

    // Get accepted meeting time for teams
    for (const userTime of this.props.times.invited) {
      // Add member accepted meeting time to team structure
      const member = this.props.members.find(m => m.id === userTime.id);
      const teamName = member ? member.team : "Unknown";
      const team = teamInvitedData.find(t => t.name === teamName);
      const time = userTime.time;
      if (!team)
        teamInvitedData.push({ name: teamName, value: time });
      else
        team.value += time;
    }

    // Calculate team costs with override settings if available:
    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;
    const defaultCost = costProps ? costProps.hours / (costProps.salary * 1000) : 1730 / 84217;
      //console.log("default cost to use:", 1/defaultCost)
    const teamConfiguration = costProps && costProps.teamConfiguration ? costProps.teamConfiguration : [];
    //if(teamConfiguration)
      //teamConfiguration.forEach(tc => { console.log("hourly configured", (tc.salary * 1000) / tc.hours, tc.team)});

    const findConfigToUse = (path) => {
      if(teamConfiguration.find(t => t.team === path)){
        return teamConfiguration.find(t => t.team === path);
      }
      else if(path.split("/").length > 1){
        const reducedPath = path.split("/");
        reducedPath.length = reducedPath.length - 1;
        return findConfigToUse(reducedPath.join("/"));
      }
      else
        return null;
      };

    // Let's apply default config, company wide config, or given org structure config to the data
    teamData.forEach(td => {
      td.cost = td.value / 60 / defaultCost;
      // Let's find the exact team configuration (or check for parent until found or not)
      const teamConfig = findConfigToUse(td.name);
      if(teamConfig) {
        const teamCost = teamConfig.hours / (teamConfig.salary * 1000);
        td.cost = td.value / 60 / teamCost;
      }
      //console.log("calculated estimate hourlies backward:", td.cost/(td.value/60), td.name)
    });
    const totalData = teamData.reduce((cur, acc) => { return {value:acc.value + cur.value, cost:acc.cost + cur.cost} },{value:0, cost:0});

    // Let's apply default config, company wide config, or given org structure config to the data
    teamInvitedData.forEach(td => {
      td.cost = td.value / 60 / defaultCost;
      // Let's find the exact team configuration (or check for parent until found or not)
      const teamConfig = findConfigToUse(td.name);
      if(teamConfig) {
        const teamCost = teamConfig.hours / (teamConfig.salary * 1000);
        td.cost = td.value / 60 / teamCost;
      }
      //console.log("calculated invited hourlies backward:", td.cost/(td.value/60), td.name)
    });
    const totalInvitedData = teamInvitedData.reduce((cur, acc) => { return {value:acc.value + cur.value, cost:acc.cost + cur.cost} },{value:0, cost:0});

    // Calculate meeting minutes per organizer into its own structure
    for (const userTime of this.props.times.organizer) {
      const member = this.props.members.find(m => m.id === userTime.id);
      const organizer = organizerData.find(t => t.id === userTime.id);
      const name = member ? member.name : "Unknown";
      const time = userTime.time;
      if (!organizer)
        organizerData.push({ id:userTime.id, name, value: time, cost:0 });
      else
        organizer.value += time;
    }
    // Let's apply default config, company wide config for organizer data
    organizerData.forEach(td => {
      td.cost = td.value / 60 / defaultCost;
      //console.log("calculated hourlies backward organizer:", td.cost/(td.value/60), td.name)

    });

    if(this.props.video) {
      late.late = this.props.video.late.sum / 60; // Average late into minutes
      late.cost = this.props.video.late.sum / 60 / 60 / defaultCost;
      // if(this.props.video.inviteCount){
      //   //const ratio = this.props.video.inviteCount
      //   late.extrapolateLate = this.props.video.inviteCount * this.props.video.late.avg / 60; // Average late into minutes
      //   late.extrapolateCost = this.props.video.inviteCount * this.props.video.late.avg / 60 / 60 / defaultCost;
      // }

    }

    this.setState({
      loading: false,
      total: totalData,
      totalInvited: totalInvitedData,
      late,
      team: teamData,
      teamInvited: teamInvitedData,
      organizer: organizerData,
    });
  }

  render() {
    if (this.state.loading)
      return null;
    const valueSort = (a,b) => a.value < b.value?1:-1;
    const alphaSort = (a,b) => a.name > b.name?1:-1;

    const team = this.state.sortTeamAlpha?this.state.team.sort(alphaSort):this.state.team.sort(valueSort);
    const teamSeries = team.map(d => d.value);
    const teamCosts = team.map(d => d.cost);
    const teamLabels = team.map(d => d.name);

    const organizer = this.state.sortOrganizerAlpha?this.state.organizer.sort(alphaSort):this.state.organizer.sort(valueSort);
    const organizerSeries = organizer.map(d => d.value);
    const organizerCosts = organizer.map(d => d.cost);
    const organizerLabels = organizer.map(d => d.name);
    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;

    const chartAxisFormatterTeam = (val, index) => {return `${convertCostToString(costProps, teamCosts[index.dataPointIndex])} (${convertMinutesToTimeString(val)})`};
    const chartAxisFormatterOrganizer = (val, index) => {return `${convertCostToString(costProps, organizerCosts[index.dataPointIndex])} (${convertMinutesToTimeString(val)})`};
    const chartTeams = {
      chart: { animations: { enabled: false }, type: 'pie', },
      labels: teamLabels,
      responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
      colors: ['#32a871', '#608bbd', '#60bd79', '#6071bd', '#e87694', '#7216b8', '#cf3a29'],
      yaxis: { labels: { formatter: function(val, index) { return val ? chartAxisFormatterTeam(val, index) : "" } } },
    };
    const chartOrganizer = {
      chart: { animations: { enabled: false }, type: 'pie', },
      labels: organizerLabels,
      responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
      colors: ['#32a871', '#608bbd', '#60bd79', '#6071bd', '#e87694', '#7216b8', '#cf3a29'],
      yaxis: { labels: { formatter: function(val, index) { return val ? chartAxisFormatterOrganizer(val, index) : "" } } },
    };
    const costExplanationString = costProps ?
      `Account cost configuration: currency ${costProps.currency}, yearly working hours ${costProps.hours}, and yearly average salary of ${costProps.salary}k. You also have ${costProps.teamConfiguration?costProps.teamConfiguration.length : 0} teams with salary configuration override.`
      :
      "We use an average 1'730 hour working year (2021), £74k average tech industry salary (2019), and UK employer costs to calculate esimated total monetary investment of a meeting.";

    const includesTeamConfig = "This estimate uses team cost estimate overrides you have configured in the account settings. " + costExplanationString;
    const excludesTeamConfig = "This estimate DOES NOT use team cost estimate overrides you have configured in account settings. " + costExplanationString;
    const invitedTitle = "Invited Meeting Investment";
    const totalTitle = "Accepted Meeting Investment";
    const lateTitle = "Meeting Delay Cost Estimate";
    const invitedTooltip = "This estimate is based on group and direct invites. " + includesTeamConfig;
    const totalTooltip = "This estimate is based on internal ACCEPTED group and direct invites. " + includesTeamConfig;
    const lateTooltip = "This estimate is based video call start times. Value in brackes is extrapolated against all meetings with average call delay. " + excludesTeamConfig;
    const totalTime = convertMinutesToTimeString(this.state.total.value);
    const totalCost = convertCostToString(costProps, this.state.total.cost);
    const invitedTime = convertMinutesToTimeString(this.state.totalInvited.value);
    const invitedCost = convertCostToString(costProps, this.state.totalInvited.cost);
    const lateTime = convertMinutesToTimeString(this.state.late.late) + (this.state.late.extrapolateLate ? ` (${convertMinutesToTimeString(this.state.late.extrapolateLate)})` : "");
    const lateCost = convertCostToString(costProps, this.state.late.cost) + (this.state.late.extrapolateCost ? ` (${convertCostToString(costProps, this.state.late.extrapolateCost)})` : "");

    return (
      <Fragment >
          <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
          <div className=" font-weight-bold font-size-xl text-uppercase" >Meeting Cost Estimates</div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4} >
              <TotalCard height="250" key={"invitedtotal"} color={"bg-info"} totalTime={invitedTime} title={invitedTitle} tooltip={invitedTooltip} totalCost={invitedCost} />
            </Grid>
            <Grid item xs={12} md={4} >
              <TotalCard height="250" key={"acceptedtotal"} icon={"calendar-plus"} totalTime={totalTime} title={totalTitle} tooltip={totalTooltip} totalCost={totalCost} />
            </Grid>
            <Grid item xs={12} md={4} >
              <TotalCard height="250" key={"delaytotal"} icon={"calendar-minus"} color={"bg-brand-google"} totalTime={lateTime} title={lateTitle} tooltip={lateTooltip} totalCost={lateTime === "-" ? "Delay estimate requires Zoom or Google Meets integration" : lateCost} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="d-flex align-items-start">
                <Tooltip title={"This pie chart estimates the costs associated with meetings based on ACCEPTED invites (direct, or group invites) per team. This estimate includes team specific cost estimates you might have configured in the account settings. Company cost estimation baseline: " + costExplanationString}>
                  <div className="font-weight-bold mr-3"> Accepted Meeting Investment by Teams {info}</div>
                </Tooltip>
                <Tooltip title="Click here to expand">
                  <div className="font-weight-bold mr-3"> <SettingsOverscan onClick={() => this.setState({expand:"team"})}/></div>
                </Tooltip>
                <Tooltip title={"Click here to sort by the " + (this.state.sortTeamAlpha?"value":"name")}>
                  <div className="font-weight-bold mr-3">
                    {this.state.sortTeamAlpha?
                      <Sort onClick={() => this.setState({sortTeamAlpha:false})}/>
                      :
                      <SortByAlpha onClick={() => this.setState({sortTeamAlpha:true})}/>
                    }
                    </div>
                </Tooltip>
                <Tooltip title={"Click here to show " + (this.state.listTeam?"pie chart":"list data")}>
                  <div className="font-weight-bold mr-3">
                    {this.state.listTeam?
                      <PieChart onClick={() => this.setState({listTeam:false})}/>
                      :
                      <List onClick={() => this.setState({listTeam:true})}/>
                    }
                    </div>
                </Tooltip>
              </div>
              {this.state.listTeam?
                <CostDetail data={team} costProps={costProps} />
              :
                <Chart options={chartTeams} series={teamSeries} type="pie" height="250"/>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="d-flex align-items-start">
                <Tooltip title={"Here we estimate the costs of the meetings based on ACCEPTED, PENDING, and TENTATIVE invites (direct, or group invites) by the organizer. This estimate does NOT use team specific cost estimates you might have configured in account settings. Company cost estimation baseline: " + costExplanationString}>
                  <div className="font-weight-bold mr-3"> Meeting Investment by Organizer {info}</div>
                </Tooltip>
                <Tooltip title="Click here to expand">
                  <div className="font-weight-bold mr-3"> <SettingsOverscan onClick={() => this.setState({expand:"organizer"})}/></div>
                </Tooltip>
                <Tooltip title={"Click here to sort by the " + (this.state.sortOrganizerAlpha?"value":"name")}>
                  <div className="font-weight-bold mr-3">
                    {this.state.sortOrganizerAlpha?
                      <Sort onClick={() => this.setState({sortOrganizerAlpha:false})}/>
                      :
                      <SortByAlpha onClick={() => this.setState({sortOrganizerAlpha:true})}/>
                    }
                    </div>
                </Tooltip>
                <Tooltip title={"Click here to show " + (this.state.listOrganizer?"pie chart":"list data")}>
                  <div className="font-weight-bold mr-3">
                    {this.state.listOrganizer?
                      <PieChart onClick={() => this.setState({listOrganizer:false})}/>
                      :
                      <List onClick={() => this.setState({listOrganizer:true})}/>
                    }
                    </div>
                </Tooltip>
              </div>
              {this.state.listOrganizer?
                <CostDetail data={organizer} costProps={costProps} />
              :
                <Chart options={chartOrganizer} series={organizerSeries} type="pie" height="250"/>
              }
            </Grid>
          </Grid>
          {this.state.expand?
          <Dialog scroll={"paper"} fullWidth={true} maxWidth="lg" open={this.state.expand !== false} onClose={() => this.setState({expand:false})} aria-labelledby="form-dialog-title">
              {this.state.expand === "team"?<DialogTitle id="form-dialog-title">Cost estimate based on accepted meetings broken down by the team</DialogTitle>:null}
              {this.state.expand === "organizer"?<DialogTitle id="form-dialog-title">Cost estimate based on meeting invites sent by organizer</DialogTitle>:null}
              <DialogContent>
                {this.state.expand === "team"?
                  <div>
                    {this.state.listTeam?
                      <CostDetail data={team} costProps={costProps}/>
                      :
                      <Chart options={chartTeams} series={teamSeries} type="pie" height="600"/>
                    }
                  </div>
                  : null }
                {this.state.expand === "organizer"?
                  <div>
                    {this.state.listOrganizer?
                      <CostDetail data={organizer} costProps={costProps}/>
                      :
                      <Chart options={chartOrganizer} series={organizerSeries} type="pie" height="600"/>
                    }
                  </div>
                  : null }
                </DialogContent>
              <DialogActions>
                  <Button onClick={() => this.setState({expand:false})} color="primary"> Close </Button>
              </DialogActions>
          </Dialog>
          :null}
          </CardContent>
         </Card>

        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
