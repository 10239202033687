import React, { Component } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { Done } from '@material-ui/icons';

import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
const GoogleIcon = require("./google_calendar_icon.png");
const OutlookIcon = require("./outlook_calendar_icon.png");
const SlackIcon = require("./slack_workspace_icon.png");


const maxWidth = { style: { maxWidth: "800px" } };
const wizardSteps = (type) => {
  if(!type) return null;
  const googleSteps =
    <ul>
      <li>Step 1: Review the Flowtrace Meeting Analytics scopes with Google Workspace</li>
      <li>Step 2: Verify the integration with Google Workspace administrator account</li>
      <li>Step 3: Install Meeting Analytics from Google Workspace Marketplace</li>
      <li>Step 4: Discover the first meeting insights by Flowtrace</li>
    </ul>;
  const slackSteps =
    <ul>
      <li>Step 1: Define what Flowtrace can do within your Slack workspace</li>
      <li>Step 2: Connect Flowtrace with your Slack workspace</li>
      <li>Step 3: Enable and configure your weekly surveys (optional)</li>
      <li>Step 4: Introduce Flowtrace to your team</li>
      <li>Step 5: Sit back and let Flowtrace do the analysis</li>
    </ul>;
  const outlookSteps =
    <ul>
      <li>Step 1: Book a call with Flowtrace for introduction to our Outlook integrations</li>
      <li>Step 2: Your IT and Flowtrace works together to establish a secure connection</li>
      <li>Step 3: You install Flowtrace Meeting Costs for Outlook Add-In (optional)</li>
      <li>Step 4: Sit back and let Flowtrace do the analysis</li>
    </ul>;

  //const alertHeader = (type==="google"?"Meeting Analytics":"Slack Analytics") + " configuration only takes a couple of minutes and covers few simple steps:";
  let alertHeader = "Meeting Analytics configuration only takes a couple of minutes and covers few simple steps:";
  let nextSteps = googleSteps;
  if(type === "slack"){
    alertHeader = "Slack Analytics configuration only takes a couple of minutes and covers few simple steps:";
    nextSteps = slackSteps;
  }
  if(type === "outlook") {
    alertHeader = "Outlook Analytics configuration includes only a couple of simple steps:";
    nextSteps = outlookSteps;
  }

  return (<MuiAlert className="mb-4" severity="info" {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">{alertHeader}</strong>
            <br/>
            {nextSteps}
          </span>
        </div>
      </MuiAlert>);
};

class Onboarding extends Component {

  selectType(type) {this.props.onUpdate({ wizardType: type });}
  scrollToBottom = () => window.scroll({ top: document.body.offsetHeight, bottom: 0, left: 0,  behavior: 'smooth' });

  redirectToBookingPage() {
    window.location.href = "https://www.flowtrace.co/meetings/flowtrace/introduction"
  }

  render() {
    if(!this.props.isActive) return null;
    const s = this.props.settings;
    const meetingImage = <img style={{maxWidth:"250px"}} alt="Google Calendar analytics" src={GoogleIcon} />;
    const slackImage = <img style={{maxWidth:"250px"}} alt="Slack Workspace analytics" src={SlackIcon} />;
    const outlookImage = <img style={{maxWidth:"250px"}} alt="Outlook Calendar analytics" src={OutlookIcon} />;



    const slackIcon =
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} style={{ minHeight: "60px" }}>
          {s.wizardType === "slack"?
            <h4 className="text-weight-bold"><Done fontSize="large"/> Slack Workspace</h4>
          :
            <h4>Slack Workspace</h4>
          }
        </Grid>
        <Grid item xs={12}>
          {slackImage}
        </Grid>
      </Grid>;
    const googleIcon =
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} style={{ minHeight: "60px" }}>
          {s.wizardType === "google"?
            <h4 className="text-weight-bold"><Done fontSize="large"/> Google Calendar</h4>
          :
            <h4>Google Calendar</h4>
          }
        </Grid>
        <Grid item xs={12}>
          {meetingImage}
        </Grid>
      </Grid>;
    const outlookIcon =
      <Grid container spacing={2} justifyContent="flex-start">
        <Grid item xs={12} style={{ minHeight: "60px" }}>
          {s.wizardType === "outlook"?
            <h4 className="text-weight-bold"><Done fontSize="large"/> Outlook Calendar</h4>
          :
            <h4>Outlook Calendar</h4>
          }
        </Grid>
        <Grid item xs={12}>
          {outlookImage}
        </Grid>
      </Grid>;
    const slackButton = <IconButton size="medium" color="secondary" onClick={() => {this.selectType("slack"); this.scrollToBottom()}}>{slackIcon}</IconButton>;
    const googleButton = <IconButton size="medium" color="secondary" onClick={() => {this.selectType("google"); this.scrollToBottom()}}>{googleIcon}</IconButton>;
    const outlookButton = <IconButton size="medium" color="secondary" onClick={() => {this.selectType("outlook"); this.scrollToBottom()}}>{outlookIcon}</IconButton>;


    let nextAction = s.wizardType === "outlook" ? () => this.redirectToBookingPage.bind(this) : null;
    let nextText = s.wizardType === "outlook" ? "Book a call" : "Start";

    return (
      <StepNavigation stepTitle="Welcome to Flowtrace - the world's first self-serve company analytics platform." {...this.props} nextAction={nextAction} nextDisabled={!s.wizardType} nextText={nextText}>
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch" {...maxWidth}>
          <Grid item xs={12}>
            <h6 className="text-weight-bold">
              We exist so you can increase productivity, benefit from better decision making, and improve inter-team collaboration.
            </h6>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} justifyContent="center" alignItems="center" >
              <Grid item>
                <h5 className="text-weight-bold mt-5 mb-3">
                  What type of insights you want to get started with?
                </h5>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} justifyContent="flex-start" >
              <Grid item xs={4}>
                {slackButton}
              </Grid>
              <Grid item xs={4}>
                {googleButton}
              </Grid>
              <Grid item xs={4}>
                {outlookButton}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {wizardSteps(s.wizardType)}
          </Grid>
          {s.wizardType && s.wizardType !== "outlook" ? <Grid item xs={12} {...maxWidth}> To get you started we need to understand a bit more about your organisation and collaboration requirements. In the next screens we will ask you a couple of questions to help you get started. </Grid> : null }
          {s.wizardType? <Grid item xs={12}> <p><br/>Click {nextText.toUpperCase()} to {s.wizardType === "outlook" ? "get started with" : "configure"} Flowtrace</p> </Grid> : null }
        </Grid>

      </StepNavigation>
    );

  }
}

export default Onboarding;
