import React, { Component, Fragment } from 'react';

import Loading from '../../../../flowtrace-components/Loading';
import { getLogo } from '../../../../flowtrace-components/Common/Logos';

import TeamFilterDropdown from './teamOverride';

import { forwardRef } from 'react';


import MaterialTable from '@material-table/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';

const tableIcons = {
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};


const userAccountTypeRender = (type) => {
  return <img style={{maxHeight:"25px", maxWidth:"25px"}} alt="Logo" src={getLogo(type.toLowerCase())} />;
}

const mergeTypeRender = (type) => {
    let badgeColor = "badge badge-darkest-success"; // Default to dark green (for master badge)
    if (type ==="email") badgeColor = "badge badge-dark-success"; // Automatic as a green
    if (type ==="manual") badgeColor = "badge badge-darkest-warning";
    return <span className={badgeColor + " mb-1 "} >{type?type:"Master Record"} </span>;
};


class NodeMembers extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, mergeMemberDialogState: this.props.mergeMemberDialogState };
  }

  async componentWillReceiveProps() {
    this.setState({mergeMemberDialogState: this.props.mergeMemberDialogState});
  }


  render() {
    if (this.props.loading) {
      return <Loading/>;
    }


    const subCols = [
      { title: "Type", width: 30, field:"type", editable: 'never', render: ( rowData => userAccountTypeRender(rowData.type))},
      { title: "Merge Type", field:"merge", editable: 'never', render: ( rowData => mergeTypeRender(rowData.merge))},
      { title: "User ID", field:"id", editable: 'never'},
      { title: "30 Day Activity", field:"last30DayActivity", editable: 'never'},
      { title: "Deleted", width: 30, field:"deleted", editable: 'never'},
    ];

    const teamColumn = { title: "Team", field:"orgUnitPath", width:300, render: (rowData => {
          return (
            <TeamFilterDropdown
              flowtraceUser={ this.props.flowtraceUser }
              currentMember={rowData}
              teamFilters={this.props.teamFilters}
              teamMembers={this.props.teamMembers}
              onChange={this.props.onChangeTeam.bind(this)}
            />
          );
      })};

    const mainColumns = [
      { title: "Name", field:"name", defaultSort:"asc", editable: 'never'},
      { title: "Email", field:"email", editable: 'never'},
      { title: "Users", field:"userCount", editable: 'never'},
    ];
    // Add time zone and team actions for current teams only
    if(this.props.teamName !== 'past-team') {
      mainColumns.push({ title: "Timezone", field:"timeZone"});
      mainColumns.push(teamColumn);
    }

    const memberData = this.props.currentMembers.map(m => { return {...m, userCount:m.user.length}});
    const teamName = this.props.teamName ? this.props.teamName : this.props.flowtraceUser.account.account_name;
    let teamHeading = "Members of team: " + teamName;
    if(this.props.teamName === 'no-team') teamHeading = "Members without a team";
    if(this.props.teamName === 'past-team') teamHeading = "Past team members";


    const detailOptions = { search: false, emptyRowsWhenPaging:false, tableLayout:"auto", toolbar:false, padding:"dense", pageSize:20, paging:false };
    const mainOptions = { search: false, emptyRowsWhenPaging:false, tableLayout:"auto", toolbar:false, padding:"dense", pageSize:10, pageSizeOptions:[10,25, 50] };

    return (
      <Fragment>
        <h5>{teamHeading}</h5>
          <MaterialTable
              columns={mainColumns}
              detailPanel={  r => <MaterialTable columns={subCols} data={r.rowData.user} options={detailOptions} /> }
              data={memberData}
              icons={tableIcons}
              options={mainOptions}
            />
      </Fragment>
    );
  }
}

export default NodeMembers;
