import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiAlert from '@material-ui/lab/Alert';
import { Tooltip } from '@material-ui/core';

class PremiumFeature extends Component {
    render() {
        if(this.props.iconOnly)
            return <FontAwesomeIcon className="p-0 m-0 mt-1 mr-3" icon={['fa', 'gem' ]}  />;

        if(this.props.tooltip)
            return (
            <Tooltip title={this.props.tooltip}>
                <MuiAlert key="premiumFeature" icon={false} severity="info" className="pt-0 mt-0 mb-0 pb-0 mr-2">
                    <FontAwesomeIcon className="p-0 m-0 mt-1 mr-3" icon={['fa', 'gem' ]}  />{this.props.text?this.props.text:"Please upgrade your plan"}
                </MuiAlert>
            </Tooltip>
        );

        return (
            <div >
                <MuiAlert key="premiumFeature" icon={false} severity="info" className="pt-0 mt-0 mb-0 pb-0 mr-2">
                    <FontAwesomeIcon className="p-0 m-0 mt-1 mr-3" icon={['fa', 'gem' ]}  />{this.props.text?this.props.text:"Please upgrade your plan"}
                </MuiAlert>
            </div>
        );
    }
}

export default PremiumFeature;
