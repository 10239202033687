import React, { Component, Fragment } from 'react';
import { ExampleWrapperSeamless } from '../../../layout-components';
import Loading from '../../../flowtrace-components/Loading'
import ClientAccountDialog from './ClientAccountDialog'
import ClientAccountPropertyDialog from './ClientAccountPropertyDialog'
import ClientAccountUserDialog from './ClientAccountUserDialog'
import { Link } from "react-router-dom";

import { API } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import AddCircle from '@material-ui/icons/AddCircleOutline';
import RemoveCircle from '@material-ui/icons/RemoveCircleOutline';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import PersonRemove from '@material-ui/icons/PersonAddDisabled';
import PersonAdd from '@material-ui/icons/PersonAdd';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  AddCircle: forwardRef((props, ref) => <AddCircle {...props} ref={ref} />),
  RemoveCircle: forwardRef((props, ref) => <RemoveCircle {...props} ref={ref} />),
  PersonRemove: forwardRef((props, ref) => <PersonRemove {...props} ref={ref} />),
  PersonAdd: forwardRef((props, ref) => <PersonAdd {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const logger = new Logger('admin', 'DEBUG');

class AdminClientAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = { accountDialogOpen: false, accountPropertyDialogOpen: false, accountUserDialogOpen: false, loading: true }
  }

  async loadClientAccounts() {
    this.setState({ loading: 'true' });

    const flowtraceAPI = 'slackintegration';
    const clientAccountListURL = '/members/clientAccounts';
    const callHeaders = { headers: {}, response: true };

    let clientAccounts = await API.get(flowtraceAPI, clientAccountListURL, callHeaders).then((response, data, body) => {
      logger.debug(clientAccountListURL, response)

      return response.data.result;
    }).catch(error => { logger.error(error); return [] });

    this.setState({ clientAccounts: clientAccounts, loading: 'false' });

    return;
  }

  handleAccountDialogClickOpen(event) {
    this.setState({ accountDialogOpen: true })
  };
  handleAccountPropertyDialogClickOpen(event, account, command) {
    this.setState({ accountPropertyDialogOpen: true, editAccount: account, editCommand: command })
  }
  handleAccountUserDialogClickOpen(event, account, command) {
    this.setState({ accountUserDialogOpen: true, editAccount: account, users: [], editCommand: command })
  }
  async handleClose() {
    this.setState({ accountDialogOpen: false, accountPropertyDialogOpen: false, accountUserDialogOpen: false })
    await this.loadClientAccounts()
  };

  async componentDidMount() {
    await this.loadClientAccounts()
  }


  render() {
    if (this.state.loading === 'initial') {
      return <h2>Intializing...</h2>;
    }
    if (this.state.loading === 'true') {
      return <Loading/>;
    }
    if (this.state.loading === 'false' && this.state.clientAccounts) {
      return (
        <Fragment>
          <ClientAccountDialog dialogState={this.state.accountDialogOpen} onClose={this.handleClose.bind(this)} />
          <ClientAccountPropertyDialog dialogState={this.state.accountPropertyDialogOpen} command={this.state.editCommand} account={this.state.editAccount} onClose={this.handleClose.bind(this)} />
          <ClientAccountUserDialog dialogState={this.state.accountUserDialogOpen} command={this.state.editCommand} account={this.state.editAccount} onClose={this.handleClose.bind(this)} />
          <ExampleWrapperSeamless hidden="true" sectionHeading="Flowtrace client accounts">
           <MaterialTable
              columns={[
                { title: "Account Name", field:"account_name", render: (
                  rowData => {
                    return(
                      <Link color="primary" className="m-2 text-primary" to={"/Admin/ClientAccountMembers/"+rowData.account_id}>
                        <span>{rowData.account_name}</span>
                      </Link>
                  )}
                ) },
                { title: "Workspaces", field:"workspaces", render: (
                  rowData => {
                    const list = []
                    for(const item of rowData.workspaces)
                      list.push(<div key={item.type + "|" + item.workspaceId} className="text-left">{item.type + " : " + item.workspaceId}</div>)
                    return (list)
                  }
                ) },
                { title: "Properties", field:"properties", render: (
                  rowData => {
                    const list = []
                    for(const propertyList of rowData.properties)
                      for(const [key, value] of Object.entries(propertyList)){
                        list.push(<div key={key + "|" +value} className="text-left">{key + " : " +value}</div>)
                      }
                    return (list)
                  }
                ) },
                { title: "Users", align:"right", field:"users", render: (
                  rowData => {
                    const list = []
                    for(const user of rowData.users)
                      list.push(<div key={user} className="text-left">{user}</div>)
                    return (list)
                  }
                ) },


              ]}
              data={this.state.clientAccounts}
              icons={tableIcons}
              actions={[
                  {
                    icon: tableIcons.Add,
                    tooltip: 'Add Client Account',
                    isFreeAction: true,
                    onClick: (event) => this.handleAccountDialogClickOpen(event)
                  },
          {
            icon: tableIcons.AddCircle,
            tooltip: 'Add Property',
            onClick: (event, rowData) => {
              this.handleAccountPropertyDialogClickOpen(event, rowData, "add")
            }                  },
          {
            icon: tableIcons.RemoveCircle,
            tooltip: 'Remove Property',
            onClick: (event, rowData) => {
              this.handleAccountPropertyDialogClickOpen(event, rowData, "delete")
            }},
          {
            icon: tableIcons.PersonAdd,
            tooltip: 'Add User to Account',
            onClick: (event, rowData) => {
              this.handleAccountUserDialogClickOpen(event, rowData, "add")
            }                  },
          {
            icon: tableIcons.PersonRemove,
            tooltip: 'Remove User from Account',
            disabled:true,
            onClick: (event, rowData) => {
              this.handleAccountUserDialogClickOpen(event, rowData, "delete")
            }}

                ]}
              title="Client Accounts"
            />
          </ExampleWrapperSeamless>
        </Fragment>
      );
    }

    return <Loading/>; // Just in case
  }
}

export default AdminClientAccounts;
