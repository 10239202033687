import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading'
//import KPICard from './genericKPICard'
import Chart from './genericChartKPI'
import FilterComponent from '../Common/FilterBar/injectedFilter';

class ToolKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: this.props.queryFilters, type: (props.metric?"metric":"cycle-time") };
  }

  onProjectFilterChange(data) { this.setState({ projectFilter: data.filter }); }
  onTypeFilterChange(data) { this.setState({ typeFilter: data.filter }); }
  onStatusFilterChange(data) { this.setState({ statusFilter: data.filter }); }

  async loadStatistics(queryFilters) {
    const dataAPI = 'slackintegration';
    const path = '/metrics/kpi/'+ this.state.type +'/' + this.props.tool + "/" + queryFilters.range_window;
    const params = { headers: {}, response: true, queryStringParameters: { ...queryFilters, range_type:"calendar" } };

    try {
      const current = await API.get(dataAPI, path, params);
      return this.setState({loading: false, series:current.data.series, total: current.data.total});
    }
    catch (e) { }

    return this.setState({ loading: false, series: null, total:null });
  }

  async componentWillReceiveProps(nextProps) {
    if(this.props.queryFilters !== nextProps.queryFilters){
      this.setState({loading: true});
      await this.loadStatistics(nextProps.queryFilters);
    }
  }

  async componentDidMount() {
    await this.loadStatistics(this.props.queryFilters);
  }

  componentWillUnmount() { this.setState = (state, callback) => { return; }; } // fix Warning: Can't perform a React state update on an unmounted component

  render() {

    const title = [];
    const createTitle = (title) => <div key={title} className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >{title}</div>;
    if(this.props.tool === "github") title.push(createTitle("GitHub Pull Request - Cycle Time"));
    if(this.props.tool === "gitlab") title.push(createTitle("GitLab Merge Request - Cycle Time"));
    if(this.props.tool === "jira") title.push(createTitle("Jira Issue - Cycle Time"));
    if(this.props.tool === "clickup") title.push(createTitle("ClickUp Task - Cycle Time"));
    if(this.props.tool === "hubspot") title.push(createTitle("HubSpot Deals - Cycle Time"));
    if(this.props.tool === "front") title.push(createTitle("Front Emails - Cycle Time"));
    if(this.props.tool === "slack") title.push(createTitle("Slack - Metrics"));
    if(this.props.tool === "meeting") title.push(createTitle("Meeting - Metrics"));

    if (this.state.loading) {
      return (
        <Grid item xs={4} md={4} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                <span className="font-weight-bold font-size-xl"><div className="ml-3">{title}</div></span>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );
    }

    if (!this.state.total || !this.state.total.length)
      return null; // Return nothing if there is no data available

    const projects = this.state.series ? this.state.series.map(i => i.projects).flat():[];
    const types = (this.state.projectFilter? projects.filter(p => p.id === this.state.projectFilter) : projects).map(i => i.types).flat();
    const statuses = (this.state.typeFilter? types.filter(t => t.id === this.state.typeFilter) : types).map(i => i.total).flat();


    const uniqueProjects = [...new Set(projects.map(p => p.id))]
    const uniqueTypes = [...new Set(types.map(p => p.id))]
    const uniqueStatuses = [...new Set(statuses.map(p => p.id))]

    const categories = this.state.series.map(i => i.id); // Date timestamps
    const filteredStatuses = uniqueStatuses.filter(s => s === this.state.statusFilter || !this.state.statusFilter); // All statuses

    // Init series for all statuses
    const series = {};
    for(const status of filteredStatuses)
      series[status] = [];

    for(const period of this.state.series){
      if(!this.state.projectFilter && !this.state.typeFilter && !this.state.statusFilter){                // NO filters being applied:
        for(const status of filteredStatuses)
          series[status].push(period.total.find(t => t.id ===status)?period.total.find(t => t.id ===status).avg:null);
      } else if(!this.state.projectFilter && !this.state.typeFilter && this.state.statusFilter){           // ONLY Status filter applied:
        for(const status of filteredStatuses)
          series[status].push(period.total.find(t => t.id ===status)?period.total.find(t => t.id ===status).avg:null);
      } else if(this.state.projectFilter && !this.state.typeFilter){                                      // ONLY Project filter applied:
        const found = period.projects.find(p => p.id === this.state.projectFilter);
        for(const status of filteredStatuses)
          series[status].push(found && found.total.find(t => t.id ===status)?found.total.find(t => t.id ===status).avg:null);
      } else if(!this.state.projectFilter && this.state.typeFilter){                                      // ONLY Type filter applied:
        const found = period.types.find(t => t.id === this.state.typeFilter);
        for(const status of filteredStatuses)
          series[status].push(found && found.total.find(t => t.id ===status)?found.total.find(t => t.id ===status).avg:null);
      } else if(this.state.projectFilter && this.state.typeFilter){                                       // Project and Type filter applied:
        const projectFound = period.projects.find(p => p.id === this.state.projectFilter);
        const found = projectFound?projectFound.types.find(t => t.id === this.state.typeFilter):null;
          for(const status of filteredStatuses)
            series[status].push(found && found.total.find(t => t.id ===status)?found.total.find(t => t.id ===status).avg:null);
      }

    }

    const chart = <Chart title={this.props.tool +"-cycle-time-chart"} metric={this.state.type} categories={categories} data={series} />;

    // Use Jira defaults:
    let projectText = "Projects";
    let typeText = "Issue Types";
    let statusText = "Statuses";
    switch(this.props.tool){
      case "jira":
      case "clickup":
        break;
      case "github":
      case "gitlab":
        projectText = "Repositories";
        typeText = "Event Types";
        statusText = "Actions";
        break;
      case "hubspot":
        projectText = "Accounts";
        typeText = "Event Types";
        statusText = "Deal Stages";
        break;
      case "slack":
      case "meeting":
        projectText = "Tool";
        typeText = "Category";
        statusText = "Metric";
        break;
      default:
    }

    const projectFilter = <FilterComponent name={projectText} options={uniqueProjects}  onChange={this.onProjectFilterChange.bind(this)} />;
    const typeFilter = <FilterComponent name={typeText} options={uniqueTypes}  onChange={this.onTypeFilterChange.bind(this)} />;
    const statusFilter = <FilterComponent name={statusText} options={uniqueStatuses}  onChange={this.onStatusFilterChange.bind(this)} />;

    return (
      <Grid item xs={12} md={12} xl={6}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
              <span className="font-weight-bold font-size-xl"><div className="ml-3">{title}</div></span>

                <Grid container spacing={0}>
                  <Grid item xs={12} sm={12}>
                    {projectFilter}{ typeFilter}{ statusFilter}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {chart}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    ); // end of return
  } // end of render()
} // end of class

export default ToolKPIComponent;
