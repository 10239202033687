import React, { Fragment } from 'react';
import EntityNetwork from '../../flowtrace-components/Collaboration/EntityNetwork';

export default function Dashboard() {
  return (
    <Fragment>
            <span>This is an overview how all work topics relate to each other.</span>

      <EntityNetwork />
    </Fragment>
  );
}
