import { Button, ButtonGroup } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import React, { Component } from 'react';

class LoadingButton extends Component {
    render() {
        return (
            <div style={{float:'left', marginBottom:"15px"}}>
                <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                    <Button disabled color="secondary">Loading...</Button>
                    <Button disabled color="secondary" size="small" aria-haspopup="true">
                        <ArrowDropDown />
                    </Button>
                </ButtonGroup>
            </div>
        );
    }
}

export default LoadingButton;
