import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import ChannelSentiment from '../../flowtrace-components/Sentiment/ChannelSentiment';

const explanation = []
explanation.push(<h4 className="heading-2 pb-1"> Channel Sentiment analysis</h4>)
explanation.push(<p> <a target="_blank" rel="noopener noreferrer" href='https://en.wikipedia.org/wiki/Sentiment_analysis'>Sentiment analysis</a> (also known as opinion mining or emotion AI) refers to the use of natural language processing, text analysis, and computational linguistics to systematically identify, extract, quantify, and study affective states and subjective information. Sentiment analysis is widely applied to voice of the customer materials such as reviews and survey responses, online and social media, and healthcare materials for applications that range from marketing to customer service to clinical medicine. </p>)
explanation.push(<p> Flowtrace can reveal how your team interacts with each other in different communication channels so you can investigate any culprits.</p>)

export default function Dashboard() {
  return (
    <Fragment>
      <PageTitle titleHeading="" titleDescription="Collaboration Channel Sentiment Overview" explanation={explanation}/>

      <ChannelSentiment />
    </Fragment>
  );
}
