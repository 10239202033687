import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import NoSurveyData from '../../Common/SurveyComponents/noSurveyData';
import Loading from '../../Loading';
import FilterBar from '../../Common/FilterBar';
import SurveyScore from '../../Common/SurveyComponents/effectivenessCard';
import MostRecent from '../../Common/SurveyComponents/mostRecentQuestions';
import NPSCard from '../../Common/SurveyComponents/enpsCard';

class SurveyOverview extends Component {
  constructor(props) {
    super(props);
    console.log(props.flowtraceUser)
    const tier = props.flowtraceUser && props.flowtraceUser.account ? props.flowtraceUser.account.props.type : "free";
    const rangeLimit = tier === "free" ? 4 : 12;
    const rangePeriod = tier === "free" ? "week" : "month";
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: rangePeriod, range_limit: rangeLimit } };
  }


  async loadSurveyStatistics(queryFilters) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/surveys';
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };
    try {
      const surveyData = await API.get(dataAPI, statisticsPath, initAPI);
      this.setState({ loading: false, teamStats: surveyData.data ? surveyData.data.teamStats : null, company: surveyData.data ? surveyData.data.company : null });
    }
    catch (e) {
      this.setState({ loading: false, teamStats: null, company: null });
    }
  }

  async componentDidMount() {
    this.loadSurveyStatistics(this.state.queryFilters);
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadSurveyStatistics(queryFilters);
  }

  render() {
    if (this.state.loading)
      return (
        <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Loading/>
        </Fragment>
      );

    const surveyComponents = [];

    if (this.state.company && this.state.company.effectiveness && this.state.company.effectiveness.length > 0)
      surveyComponents.push(<MostRecent key="mostrecent" surveyData={this.state.company?this.state.company:null}/>);

    if (this.state.company && this.state.company.NPS && this.state.company.NPS.length > 0)
      surveyComponents.push(<NPSCard key={"companychart"} company={this.state.company} />);

    if (this.state.company && this.state.company.effectiveness && this.state.company.effectiveness.length > 0) {
      surveyComponents.push(<SurveyScore key="spr" surveyData={this.state.company?this.state.company:null} series="productivity"/>);
      surveyComponents.push(<SurveyScore key="sme" surveyData={this.state.company?this.state.company:null} series="meeting"/>);
      surveyComponents.push(<SurveyScore key="stm" surveyData={this.state.company?this.state.company:null} series="teamwork"/>);
      surveyComponents.push(<SurveyScore key="stc" surveyData={this.state.company?this.state.company:null} series="team_composition"/>);
      surveyComponents.push(<SurveyScore key="smm" surveyData={this.state.company?this.state.company:null} series="management"/>);
      surveyComponents.push(<SurveyScore key="scmpny" surveyData={this.state.company?this.state.company:null} series="company"/>);
      surveyComponents.push(<SurveyScore key="seng" surveyData={this.state.company?this.state.company:null} series="engagement"/>);
      surveyComponents.push(<SurveyScore key="sctc" surveyData={this.state.company?this.state.company:null} series="cross_team_collaboration"/>);
    }

    if (surveyComponents.length === 0)
      return (
        <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} searchWarning="No survey scores found with these filters." />
          <Grid container spacing={4} style={{width:"100%"}}>
            <NoSurveyData />
          </Grid>
        </Fragment>
      );

    return (
      <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Grid container spacing={4} style={{width:"100%"}}>
            {surveyComponents}
          </Grid>
        </Fragment>
    );
  } // end of render()
} // end of class

export default SurveyOverview
