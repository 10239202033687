import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import MeetingStatistics from '../../../flowtrace-components/MeetingStatistics/Company';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Company Meeting Overview</h4>)
explanation.push(<p key="paragraph1">Flowtrace breaks down every every meeting to its core. Assess the cost, quality, and attendace of meetings with below data.</p>)
explanation.push(<p key="paragraph2">Delay cost estimate is based on video call records we hold from your account. Average time late from a recorded video call is used to estimate overall late costs if all meetings would start as if the recorded video calls.</p>)

export default function MeetingDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Company's Meeting Overview" titleDescription="Follow the attendance and time trends across the business" explanation={explanation}/>

      <MeetingStatistics flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
