import React, { useState } from 'react';
import auth0 from 'auth0-js';

import { Button } from '@material-ui/core';

import clickUpLogo from './clickupLogo';
import entraLogo from './entraLogo';
import zoomLogo from './zoomLogo';
import hubspotLogo from './hubspotLogo';
import gitlabLogo from './gitlabLogo';
import googleLogo from './googleLogo';
import jiraLogo from './jiraLogo';
import slackLogo from './slackLogo';
import githubLogo from './githubLogo';

import Loading from '../../../../flowtrace-components/Loading';
const config = require("../../../../config.json");

export default function Auth0Button(props) {
  const [loading, setLoading] = useState(false);

  const options = {
    redirectUri:window.location.href,
    connection: props.connection
  };
  const button = {};

  if(props.connection === "hubspot"){
    button.icon = hubspotLogo.src;
    button.label = "HubSpot";
  }
  else if(props.connection === "clickup"){
    button.icon = clickUpLogo.src;
    button.label = "ClickUp";
  }
  else if(props.connection === "zoom"){
    button.icon = zoomLogo.src;
    button.label = "Zoom";
  }
  else if(props.connection === "atlassian"){
    button.icon = jiraLogo.src;
    button.label = "Jira";
  }
  else if(props.connection === "microsoft"){
    options.connection = "microsoft";
    options.connection_scope = ".default";
    options.prompt = "consent"
    button.icon = entraLogo.src;
    button.label = "M365 (AD)";
  }
  else if(props.connection === "slack-bot"){
    options.connection_scope = props.scopes;
    options.popupOptions = { width: 800, height: 1000 };
    button.icon = slackLogo.src;
    button.properties = {style: {"backgroundColor":"#4A154B"}, variant:"contained", color:"primary"};
    button.label = "Add to Slack";
  }
  else if(props.connection === "google-oauth2"){
    button.icon = googleLogo.src;
    button.label = "Google Workspace";
  }
  else if(props.connection === "gitlab"){
    button.icon = gitlabLogo.src;
    button.label = "GitLab";
  }
  else if(props.connection === "github"){
    button.icon = githubLogo.src;
    button.label = "GitHub";
  }

  // https://auth0.com/docs/libraries/auth0js for configuration and options
  const auth = new auth0.WebAuth({
      domain:       config.AUTH0_LOGIN_DOMAIN,
      clientID:     config.AUTH0_CLIENT_ID,
    });

  const onClick = () => {
    setLoading(true);
    // Here's we init the right connection and send user directly to IdP login page without universal login screen:
    auth.popup.authorize({...options, responseType:"token", owp:true}, popupCallback);
  };

  const popupCallback = (err, authResult) => {
    if(err && props.cbError) {
      props.cbError(err);
      setLoading(false);
      return;
    }
    if(authResult) {
      // get the user information and pass it
      auth.client.userInfo(authResult.accessToken, function(error, profile) {
        if(error) {
          console.log("Error fetching freshly authenticated user's profile.");
          props.cbError(error);
        }
        props.cbProfile(profile);
        setLoading(false);
      });
      return;
    } else
      setLoading(false);
    console.log("popup closed without error or profile information:");
  };

  const key = "install-button-" + props.connection;
  const install = props.reinstall ? "Re-install ":"Install ";
  const text = props.buttonText ? props.buttonText : install + button.label;
  const icon = <img style={{minWidth:"20px", maxHeight:"25px", maxWidth:"25px"}} alt="Logo" src={button.icon} />;


  if (loading || props.loading)
    return <Button key={key} startIcon={<Loading tiny={true}/>} disabled={true} color="primary" variant="outlined">{text}</Button>;

  return <Button key={key} color="primary" variant="outlined" {...(button.properties ? button.properties : {})} startIcon={icon} onClick={onClick} disabled={props.disabled?true:false} >{text}</Button>;
}