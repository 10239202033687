import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import UserChart from './UserChart';
import TeamChart from './TeamChart';
import Loading from '../Loading'
import FilterBar from '../Common/FilterBar';
import { Card, CardContent } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const names = require('../../utils/anonymizedNames.js')
const localStore = require('store')


class UserStatisticsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_period: "week" } };
  }

  async loadUserStatisticsData(queryFilters) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/onboarding';
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };

    const response = await API.get(dataAPI, statisticsPath, initAPI)

    const users = response.data ? response.data.statistics.users.filter(user => !user.deleted && !user.is_bot) : []

    if (localStore.get("anonymize"))
      names.anonymizeSlackUsers(users)

    this.setState({ loading: false, userData: users })
    return
  }

  async componentDidMount() {
    this.loadUserStatisticsData(this.state.queryFilters)
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadUserStatisticsData(queryFilters)
  }

  render() {
    if(this.state.loading)
      return (
        <Fragment>
          <FilterBar queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Loading />
        </Fragment>
      );

    const userStatistics = [];
    for (const key in this.state.userData) {
      if (!this.state.userData[key].weightedScores) continue; // This is going to be company average "user" so skip it
      userStatistics.push(<UserChart key={"user"+key} timeSpan={this.state.queryFilters.range_period} user={this.state.userData[key]}  />);
    }

    return (
      <Fragment>
          { userStatistics.length ?
            <div>
              <FilterBar queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
              <TeamChart key={"teamchart"} timeSpan={this.state.queryFilters.range_period} user={this.state.userData}  />
              <div className={"mt-3"}>
                {userStatistics}
              </div>
            </div>
            :
            <Card className="card-box d-flex pt-2 mb-3">
              <CardContent style={{width:"100%"}}>
                <MuiAlert key="noDataAlert" className="mb-2 align-items-center align-content-center" severity="warning">
                  <div className="m-3 align-items-center align-content-center">
                    <strong >We could not find employees with 12 weeks (3 months) or less of collaboration activity!</strong><br/>
                    Please visit this page again when new people have joined your team(s).
                  </div>
                </MuiAlert>
              </CardContent>
            </Card>
          }
        </Fragment>
    );
  } // end of render()
} // end of class

export default UserStatisticsOverview;
