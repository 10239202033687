import React from 'react';
import { Grid } from '@material-ui/core';
import QuestionTable from './questionTable';

export default function SurveyScoreChart(props) {
  const questions = props.surveyData.questions;

  return (
    <Grid item xs={12}>
      <QuestionTable mostRecent={true} questions={questions} />
    </Grid>
  );
}
