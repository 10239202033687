import React, { Component, Fragment } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { API } from 'aws-amplify';
import TeamStatPanel from './TeamStatPanel';
import CompanyChart from './CompanyChart';
import Loading from '../Loading'
import FilterBar from '../Common/FilterBar';
import MuiAlert from '@material-ui/lab/Alert';


class UserStatisticsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: "day", range_limit:28 } };
  }


  async loadEngagementData(queryFilters) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/statistics';
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };

    const engagementData = await API.get(dataAPI, statisticsPath, initAPI).then((response, data, body) => {
      if (!response.data.stats) {
        console.error("No statistics data");
        return { company: [], teams: [] }
      }

      const teams = []
      for (const [team, value] of Object.entries(response.data.stats)) {
        if (team !== "company" && team !== "unassigned") {
          value.name = team
          teams.push(value)
        }
      }
      const company = {};
      for(const key of Object.keys(response.data.stats).sort())
        company[key] = response.data.stats[key];
      return { company, teams:teams.sort((a,b) => a.name>b.name?1:-1) };
    }).catch(error => {
      console.error(error);
      return { company: [], teams: [] }
    });

    this.setState({ loading: false, engagementData })
    return
  }


  async componentDidMount() {
    this.loadEngagementData(this.state.queryFilters)
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadEngagementData(queryFilters);
  }

  render() {

    if (this.state.loading)
      return (
        <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Loading/>
        </Fragment>
      );

    const teamStatistics = [];
    for (const team of this.state.engagementData ? this.state.engagementData.teams : []) {
      teamStatistics.push(<TeamStatPanel key={"team"+team.name} team={team}  />);
    }

    const companyData = this.state.engagementData && this.state.engagementData.company ? this.state.engagementData.company : null;
    const dataFound = companyData && companyData.company && (companyData.company.length > 0);

    if (!dataFound)
      return (
        <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} searchWarning="We didn't find any data with these filters." />
          <Card className="card-box d-flex pt-2 mb-3">
            <CardContent style={{width:"100%"}}>
              <MuiAlert key="noDataAlert" className="mb-2 align-items-center align-content-center" severity="warning">
                <div className="m-3 align-items-center align-content-center">
                  <strong >We are current collecting the collaboration statistics!</strong> We need some more collaboration data to show you the results. Please visit this page again tomorrow.
                </div>
              </MuiAlert>
            </CardContent>
          </Card>
        </Fragment>
      );

    return (
      <Fragment>
          <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <CompanyChart key={"companychart"} company={this.state.engagementData?this.state.engagementData.company:[]}  />
          <div className={"mt-3"}>
            {teamStatistics}
          </div>
      </Fragment>
    );

  } // end of render()
} // end of class

export default UserStatisticsOverview;
