import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StatsCard from '../../Common/Cards/productivityStatsCard';
import extract from '../dataPointExtractor';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(1), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function TeamStatisticsChart(data) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const series = {
    e: [], // emails
    s: [], // slacks
    mi: [], // meetings internal
    me: [], // meetings external
    mt: [], // multitasking
    f: [], // focus time
    a: [], // activity time
    ooh: [], // out of hours time
  };
  const categories = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date })

  for (const team of data.teamStats) {
    const teamData = { e: [], s: [], mi: [], me: [], mt: [], f: [], a: [], ooh: [], }
    for (const cat of categories) {
      // Loop every category through so the stats are aligned
      const dataPoint = team.stats.find(stat => stat.date === cat)
      const data = extract.extractData(dataPoint);
      teamData.e.push(data.email)
      teamData.s.push(data.slack)
      teamData.mi.push(data.internalMeeting)
      teamData.me.push(data.externalMeeting)
      teamData.mt.push(data.multitasking)
      teamData.f.push(data.focus)
      teamData.a.push(data.activeHours)
      teamData.ooh.push(data.outOfHoursCollaboration)
    }
    series.a.push({ name: team.team, type: "line", data: teamData.a })
    series.e.push({ name: team.team, type: "line", data: teamData.e })
    series.s.push({ name: team.team, type: "line", data: teamData.s })
    series.mi.push({ name: team.team, type: "line", data: teamData.mi })
    series.me.push({ name: team.team, type: "line", data: teamData.me })
    series.mt.push({ name: team.team, type: "line", data: teamData.mt })
    series.f.push({ name: team.team, type: "line", data: teamData.f })
    series.ooh.push({ name: team.team, type: "line", data: teamData.ooh })
  }


  const graphChartOptionsOverview = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val ? (Math.abs(val) / 60).toFixed(1) + "h" : 0 } } },
    legend: { position: "right", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };


  const last = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[1]);
  const comp = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[2]);

  const slackStat = { value: last.slack, previous: comp.slack };
  const emailStat = { value: last.email, previous: comp.email };
  const meetingStat = { value: last.meeting, previous: comp.meeting };
  const multitaskingStat = { value: last.multitasking, previous: comp.multitasking };
  const focusTimeStat = { value: last.focus, previous: comp.focus };
  const outOfHoursStats = { value: last.outOfHoursCollaboration, previous: comp.outOfHoursCollaboration };

  return (
    <Card className="card-box m-3">
      <CardContent className="p-3">
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="slack" componentColor="bg-brand-slack" data={slackStat} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="email" componentColor="bg-primary" data={emailStat}period={data.timeSpan} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="meeting" componentColor="bg-plum-plate" data={meetingStat} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="focus" componentColor="bg-dark" data={focusTimeStat} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="multitasking" componentColor="bg-warning" data={multitaskingStat} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="outOfHours" componentColor="bg-brand-facebook" data={outOfHoursStats} period={data.timeSpan}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
            <div className={classes.themeColorTabs}>
              <StyledTabs value={value} onChange={handleChange} aria-label="Team breakdown" variant="scrollable" scrollButtons="auto">
                <StyledTab label="Active Hours" />
                <StyledTab label="Emailing" />
                <StyledTab label="Chatting" />
                <StyledTab label="Meetings (Int)" />
                <StyledTab label="Meetings (Ext)" />
                <StyledTab label="Focus-time" />
                <StyledTab label="Multi-tasking" />
                <StyledTab label="Out-of-hours" />
              </StyledTabs>
            </div>
            <TabPanel value={value} index={0}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.a} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.e} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.s} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.mi} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.me} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.f} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.mt} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdwon how they spends their days (hours / person / {data.timeSpan}) </span>
                  <Chart options={graphChartOptionsOverview} series={series.ooh} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
        </div>
      </CardContent>
    </Card>
  );


}
