import { API } from 'aws-amplify';

const flowtraceAPI = 'slackintegration';
const settingsAPIURL = '/report/settings';
const callHeaders = { headers: {}, response: true };

async function updateReport(report) {
    try {
        console.log("update", report)
        console.log("api call", { ...callHeaders, body: { report } })
        const response = await API.put(flowtraceAPI, settingsAPIURL, { ...callHeaders, body: { report } });
        return response.data.success;
    }
    catch (e) {
        console.error(e.message, e);
        return false
    }
}
async function deleteReport(report) {
    try {
        console.log("delete", report)
        const response = await API.del(flowtraceAPI, settingsAPIURL, { ...callHeaders, body: { report } });
        return response.data.success;
    }
    catch (e) {
        console.error(e.message, e);
        return false
    }
}
async function createReport(report) {
    try {
        console.log("create", report)
        const response = await API.post(flowtraceAPI, settingsAPIURL, { ...callHeaders, body: { report } });
        return response.data.success;
    }
    catch (e) {
        console.error(e.message, e);
        return false
    }
}

async function runSlackReport(channel) {
    try {
        console.log("run", channel)
        const runPath = '/report/run/channel/' + channel;
        const response = await API.get(flowtraceAPI, runPath, callHeaders);
        return response.data.success;
    }
    catch (e) {
        console.error(e.message, e);
        return false;
    }
}

async function runFeedbackReport() {
    try {
        const runPath = '/report/run/meetingFeedback';
        const response = await API.get(flowtraceAPI, runPath, callHeaders);
        return response.data;
    }
    catch (e) {
        console.error(e.message, e);
        return false;
    }
}


async function getReports() {
    try {
        const response = await API.get(flowtraceAPI, settingsAPIURL, callHeaders);
        return response.data.reports;
    }
    catch (e) {
        console.error(e.message, e);
        return [];
    }
}
async function getChannels() {
    try {
        const path = '/slack/api/channels';
        const response = await API.get(flowtraceAPI, path, callHeaders);
        return response.data.channels;
    }
    catch (e) { console.error(e) }
    return [];
}

const account = { updateReport, deleteReport, createReport, getReports, getChannels, runSlackReport, runFeedbackReport };
export default account;
