import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import Link from '@material-ui/core/Link';
import { API } from 'aws-amplify';
import Loading from '../../../flowtrace-components/Loading'
import { Tooltip, TextField, ButtonGroup, Button } from '@material-ui/core';

const left = 3;
const right = 9;

const confidenceRender = (confidence) => {
    const value = Math.round(confidence*100);
    let badge = <span className={"badge badge-dark-success"}>High</span>;
    let tip = "We are confident this recommendation applies to your company and team(s) and you should action it.";
    if(value<30){
        badge = <span className={"badge badge-dark-info"}>Low</span>;
        tip = "This recommendation might consider only some of your teams and you should consider how to implement it.";
    }
    if(value<65){
        badge = <span className={"badge badge-dark-warning"}>Medium</span>;
        tip = "We believe this recommendation is applicable to your company and team(s), but could impact only parts of your organization.";
    }
    return(
      <Tooltip title={tip} arrow>
        {badge}
      </Tooltip>
      );
};

const urlRender = (url) => {
    return <Link target="_blank" href={url} aria-label="open"><LaunchIcon fontSize="small" /> </Link>;
};

const howRender = (r) => {

    const suggestions = r.immediate || r.short_term || r.long_term;
    if(!suggestions && !r.url)
      return (
        <Grid container spacing={2}>
          <Grid item xs={left}>
            <h6><b>RECOMMENDED ACTIONS:</b></h6>
          </Grid>
          <Grid item xs={right}>
            We are working hard to get you the suggestions how to action this recommendation.
          </Grid>
        </Grid>);

    const actions = [];
    if(r.immediate){
      actions.push(<Grid key="i_title" item xs={left}> <h6><b>DO RIGHT NOW:</b></h6> </Grid>);
      actions.push(<Grid key="i_desc" item xs={right}><strong>{r.immediate}</strong><br/>{r.immediate_description}</Grid>);
    }
    if(r.short_term){
      actions.push(<Grid key="s_title" item xs={left}> <h6><b>DO IN SHORT TERM:</b></h6> </Grid>);
      actions.push(<Grid key="s_desc" item xs={right}><strong>{r.short_term}</strong><br/>{r.short_term_description}</Grid>);
    }
    if(r.long_term){
      actions.push(<Grid key="l_title" item xs={left}> <h6><b>DO IN LONG TERM:</b></h6> </Grid>);
      actions.push(<Grid key="l_desc" item xs={right}><strong>{r.long_term}</strong><br/>{r.long_term_description}</Grid>);
    }
    if(r.url){
      const titleText = suggestions?"NEED MORE SUGGESTIONS?":"LOOKING FOR SUGGESTIONS?";
      const actionText = suggestions?"Read more from our article:":"Find out more from our article:";
      actions.push(<Grid key="u_title" item xs={left}> <h6><b>{titleText}</b></h6> </Grid>);
      actions.push(<Grid key="u_desc" item xs={right}> <div>{actionText} {urlRender(r.url)}</div></Grid>);
    }

    return <Grid container spacing={2}>{actions}</Grid>;
};

const recommendationRender = (r) => {
  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <h4><b>{r.summary.toUpperCase()}</b></h4>
      </Grid>
      <Grid item xs={left}>
        <h6><b>WHAT'S THE BENEFIT:</b></h6>
      </Grid>
      <Grid item xs={right}>
        {r.benefit}
      </Grid>
      <Grid item xs={left}>
        <h6><b>HOW TO IMPROVE THIS:</b></h6>
      </Grid>
      <Grid item xs={right}>
        {r.text}
      </Grid>
      <Grid item xs={left}>
        <h6><b>WHY WE RECOMMED THIS:</b></h6>
      </Grid>
      <Grid item xs={right}>
        {r.reason}
      </Grid>
      <Grid item xs={left}>
        <h6><b>OUR AI-CONFIDENCE:</b></h6>
      </Grid>
      <Grid item xs={right}>
        {confidenceRender(r.confidence)}
      </Grid>
    </Grid>
    );

};

class RecommendationDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, feedback:null, feedbackText:null};
  }

  async sendFeedback() {
    this.setState({loading:true});
    try{
      const flowtraceAPI = 'slackintegration';
      const params = { headers: {}, response: true, body: {
        id: this.props.recommendation.id,
        feedback: this.state.feedback,
        text:this.state.feedbackText
      } };
      await API.post(flowtraceAPI, '/slack/api/feedback', params);
      return this.setState({loading:false, feedback:null, feedbackText:null});
    }
    catch(e){
      console.log(e);
    }
    return this.setState({loading:false});
  }

  async setFeedback(feedback) {
    return this.setState({feedback});
  }
  async setFeedbackText(feedbackText) {
    return this.setState({feedbackText});
  }


  render() {
    const r = this.props.recommendation;


    const stars = (number) => {
      let stars = "";
      for(let i = 0 ; i<number;i++)
        stars += "⭐";
      return <span>{stars}</span>;
    };

    const anyGiven = this.state.feedback !== null || this.state.feedbackText !== null;
    const feedback =
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={left}>
          <h6><b>REVIEW THIS RECOMMENDATION:</b></h6>
        </Grid>
        <Grid item xs={right}>
          <Grid container spacing={2} >
            <Grid item xs={10}>
              <ButtonGroup color="primary" size="medium" variant="contained">
                <Button color={this.state.feedback===1?"primary":"default"} onClick={() => this.setFeedback(1)}>{stars(1)}</Button>
                <Button color={this.state.feedback===2?"primary":"default"} onClick={() => this.setFeedback(2)}>{stars(2)}</Button>
                <Button color={this.state.feedback===3?"primary":"default"} onClick={() => this.setFeedback(3)}>{stars(3)}</Button>
                <Button color={this.state.feedback===4?"primary":"default"} onClick={() => this.setFeedback(4)}>{stars(4)}</Button>
                <Button color={this.state.feedback===5?"primary":"default"} onClick={() => this.setFeedback(5)}>{stars(5)}</Button>
              </ButtonGroup>
            </Grid>
            {this.state.feedback?
              <Grid item xs={10}>
                <TextField onInput={e => this.setFeedbackText(e.target.value)} margin="dense"id="key"  multiline fullWidth rows={4} placeholder="Anything else you want to add?" />
              </Grid>
            :null}
            <Grid item xs={2}>
              <Button disabled={!anyGiven} color="primary" variant="contained" onClick={() => this.sendFeedback()}>Submit</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>;

    return (
      <div className="mt-3 p-3">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {recommendationRender(r)}
            <br/>
            {howRender(r)}
            {this.state.loading?<Loading minimize/>:feedback}
          </Grid>
        </Grid>
      </div>
    );

  }

}

export default RecommendationDetailView;
