import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading'
import KPICard from './genericKPICard'
import BarChart from './genericBarChartKPI'


function calculateProductivityKPI(stats, previousStats = null) {
  // Calculate the KPIs and deltas (attendance, average meeting length, agenda%):
  const productivityKPI = { focusTime: 0, meetings: 0, internal: 0, external: 0, slack: 0, email: 0, textual: 0, multitasking: 0, outOfOfficeCollaboration: 0, meetingMultitasking: 0, count: 0, dataFound: false };
  if (previousStats) {
    productivityKPI.previous = calculateProductivityKPI(previousStats);
  }
  const s = [];
  for (const stat of stats ? stats : []) {
    for(const [key, value] of Object.entries(stat.tool?stat.tool:{})){
      if(!s.find(i => i.id === key)) s.push({id:key, sum:0, count:0});
      s.find(i => i.id === key).sum += value.sum;
      s.find(i => i.id === key).count += value.count;
    }
    for(const [key, value] of Object.entries(stat.time?stat.time:{})){
      if(!s.find(i => i.id === key)) s.push({id:key, sum:0, count:0});
      s.find(i => i.id === key).sum += value.sum;
      s.find(i => i.id === key).count += value.count;
    }
    for(const [key, value] of Object.entries(stat.timeType?stat.timeType:{})){
      if(!s.find(i => i.id === key)) s.push({id:key, sum:0, count:0});
      s.find(i => i.id === key).sum += value.sum;
      s.find(i => i.id === key).count += value.count;
    }

  }
  const output = s.map(i => { return {...i, value:(i.count?i.sum/i.count:0)}});

  productivityKPI.outOfOfficeCollaboration = output.find(i => i.id === "outOfHoursCollaboration").value;
  productivityKPI.multitasking = output.find(i => i.id === "multitasking").value;
  productivityKPI.meetingMultitasking = output.find(i => i.id === "meetingMultitasking").value;
  productivityKPI.focusTime = output.find(i => i.id === "focus").value;
  productivityKPI.meetings = output.find(i => i.id === "externalMeeting").value + output.find(i => i.id === "internalMeeting").value;
  productivityKPI.external = output.find(i => i.id === "externalMeeting").value;
  productivityKPI.internal = output.find(i => i.id === "internalMeeting").value;
  productivityKPI.textual = output.find(i => i.id === "email").value + output.find(i => i.id === "slack").value;
  productivityKPI.email = output.find(i => i.id === "email").value;
  productivityKPI.slack = output.find(i => i.id === "slack").value;
  productivityKPI.dataFound = true;
  return productivityKPI;
}

class ProductivityKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }


  async loadProducitivityStatistics() {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/productivity/statistics';

    const currentPeriodAPIParams = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.current, } };
    const previousPeriodAPIParams = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.previous, } };

    try {
      const [current, previous] = await Promise.all([
        API.get(dataAPI, statisticsPath, currentPeriodAPIParams),
        API.get(dataAPI, statisticsPath, previousPeriodAPIParams)
      ])

      const productivityKPI = calculateProductivityKPI(current.data ? current.data.stats : [], previous.data ? previous.data.stats : [])
      this.setState({ loading: false, foundProductivity: true, productivityKPI });
      return
    }
    catch (e) {}
    this.setState({ loading: false, foundProductivity: false });
    return;
  }

  async componentDidMount() {
    // No need to load if we don't have an account installed for the user
    if (!this.props.flowtraceUser || !this.props.flowtraceUser.account)
      return this.setState({ loading: false });

    // Load meetings stats only in case we know GSuite is installed:
    if (this.props.flowtraceUser.account.workspaces.find(ws => ws.type === "GSUITE"))
      this.loadProducitivityStatistics();
    else
      this.setState({ loading: false, foundProductivity: false });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { return; };
  }

  render() {
    if (this.state.loading) {
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                <div className="p-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Productivity</div>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );
    }


    const leftHandColumn = [];
    const rightHandColumn = [];

    if (this.state.foundProductivity) {
      const productivityMetadata = {
        title: "Time Usage (per person per day)",
        unit: "minutes",
        categories: [
          { cat: "Emailing", data: this.state.productivityKPI.email, color: "#32a871", operator: ">", low: 90 }, // More than 90 mins is bad?
          { cat: "Chatting", data: this.state.productivityKPI.slack, color: "#60bd79", operator: ">", low: 90 }, // More than 90 mins is bad?
          { cat: "Meeting (int)", data: this.state.productivityKPI.internal, color: "#608bbd", operator: "<", low: 30 }, // Less than half an hour of internal meetings is bad?
          { cat: "Meeting (ext)", data: this.state.productivityKPI.external, color: "#6071bd" },
          { cat: "Focus-time", data: this.state.productivityKPI.focusTime, color: "#e87694", operator: "<", high: 150, low: 90 }, // 2.5h good, less than 1.5 bad
        ]
      };
      leftHandColumn.push(<BarChart key="time_kpi" chartdata={productivityMetadata} />);
      rightHandColumn.push(<KPICard key="mt" KPI="prod_mt" benchmark={this.props.benchmark} value={this.state.productivityKPI.multitasking} compareValue={this.state.productivityKPI.previous.multitasking}/>);
      rightHandColumn.push(<KPICard key="mmt" KPI="prod_mmt" benchmark={this.props.benchmark} value={this.state.productivityKPI.meetingMultitasking} compareValue={this.state.productivityKPI.previous.meetingMultitasking} />);
      rightHandColumn.push(<KPICard key="ot" KPI="prod_ot" benchmark={this.props.benchmark} value={this.state.productivityKPI.outOfOfficeCollaboration} compareValue={this.state.productivityKPI.previous.outOfOfficeCollaboration} />);
    }

    if (this.props.productivitySurveyScore && this.props.productivitySurveyScore.average)
      rightHandColumn.push(<KPICard key="pss" KPI="prod_survey" benchmark={this.props.benchmark} value={this.props.productivitySurveyScore.average} compareValue={this.props.productivitySurveyScore.previous?this.props.productivitySurveyScore.previous.average:null} />);

    if (leftHandColumn.length === 0 && rightHandColumn.length === 0)
      return null; // Return nothing if there is no productivity data available

    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Productivity</div>
                    {leftHandColumn.length>0?leftHandColumn:null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {rightHandColumn}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    );
  } // end of render()
} // end of class

export default ProductivityKPIComponent;
