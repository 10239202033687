import React, { Component, Fragment } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Loading from '../../../flowtrace-components/Loading';
import Chart from 'react-apexcharts';

class InboundFunnel extends Component {

  render() {
    if (this.props.loading)
      return <Loading minimize/>;

    if (!this.props.reportData)
      return <Loading error/>;

    const funnelData = this.props.reportData.filter(i => i.category === "Inbound Funnel");
    console.log("funnelData:",funnelData)

    const categories = funnelData.map(i => i.metric);
    const allUniqueDates = [...new Set(funnelData.reduce((acc, cur) => {
      return acc.concat(cur.data.map(i => i.date));
    }, []))].sort((a, b) => a.localeCompare(b));

    const uniqueDates = allUniqueDates.slice(-4).sort((a, b) => b.localeCompare(a));
    console.log("unique dates:",allUniqueDates, uniqueDates)

    const adjustedData = [];
    // Adjust accounts, users, and churn to reflect "new" for the period instead of total values
    const adjust = ["Accounts", "Self Service Users", "Churn Accounts"];
    for(const cat of funnelData){
      if(!adjust.includes(cat.metric))
        adjustedData.push(cat);
      else
      adjustedData.push({
        metric:cat.metric,
        data: cat.data.reduce((acc, cur, index, array) => {
        if(index > 0)
          acc.push({date:cur.date, value:cur.value - array[index - 1].value});
        else
          acc.push({date:cur.date, value:cur.value});
        return acc;
      },[])
      });
    }

    // Pivot the data from metric series to category series
    const categoryDataSeries = [];
    for (const metricRow of adjustedData) {
      for (const date of uniqueDates) {
        const found = metricRow.data ? metricRow.data.find(i => i.date === date) : null;
        const valueForSeries = found && found.value !== "NaN" ? found.value + 0.0001 : 0.00001;

        if(!categoryDataSeries.find(i => i.name === date))
          categoryDataSeries.push({name: date, data:[]});
        const series = categoryDataSeries.find(i => i.name === date);
        series.data.push(valueForSeries);
      }
    }

    // Create a conversion rate series from the pivoted data:
    const conversionSeries = [];
    for(const cat of categoryDataSeries){
      cat.conversion = cat.data.reduce((acc, cur, index, array) => {
        if(index > 0)
          acc.push(Math.min(Math.round(cur / array[index - 1] * 100),100));
        else
          acc.push(100);
        return acc;
      },[]);
      conversionSeries.push({name:cat.name, data:cat.conversion});
    }

    console.log("all categories", categoryDataSeries);
    const FunnelConfiguration = {
      chart: { animations: { enabled: false }, toolbar: { show: false } },
      dataLabels: { enabled: true,  formatter: function (val) { return val ? Math.round(val) : 0 } },
      xaxis: { forceNiceScale: true, categories },
      yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val && !isNaN(val) ? Math.round(val) : val } } },
      plotOptions: { bar: { horizontal: true, dataLabels: { position: 'top' }}},
      legend: { position: "bottom", show: true, height: "80" },
      colors: ["#0a3455", "#6ebdc4", "#416d90", "#b4ebf0", "#7cc5cc", "#61a6b5", "#548fa6", "#2e5f80", "#174366"]
    };

    const ConversionFunnelConfiguration = {
      chart: { animations: { enabled: false }, toolbar: { show: false } },
      dataLabels: { enabled: true, offsetY:-10, enabledOnSeries:[0], formatter: function(val, { seriesIndex, dataPointIndex, w }) {
            const previousValue = w.config.series[seriesIndex+1]?w.config.series[seriesIndex+1].data[dataPointIndex]:null;
            const change = val && previousValue && !isNaN(val)?Math.round(val - previousValue ): "-";
            return val && !isNaN(val) ? Math.round(val) + "% (" +change + "%)" : val
      }},
      markers: { size:[5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      xaxis: { categories},
      stroke: { width: [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], curve: 'straight', dashArray: [0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]},
      yaxis: { max:100, labels: { formatter: function(val) { return val && !isNaN(val) ? Math.round(val) + "%" : val } } },
      legend: { position: "bottom", show: true, height: "80" },
      colors: ["#0a3455", "#6ebdc4", "#416d90", "#b4ebf0", "#7cc5cc", "#61a6b5", "#548fa6", "#2e5f80", "#174366"]
    };

    const LineFunnelConfiguration = {
      chart: { animations: { enabled: false }, toolbar: { show: false } },
      dataLabels: { enabled: true, offsetY:-10, enabledOnSeries:[0], formatter: function(val, { seriesIndex, dataPointIndex, w }) {
            const previousValue = w.config.series[seriesIndex+1]?w.config.series[seriesIndex+1].data[dataPointIndex]:0;
            const change = val && previousValue && !isNaN(val)?Math.round((1 - val / previousValue )*-100): "-";
            return val && !isNaN(val) ? Math.round(val) + " (" +change + "%)" : val
      }},
      markers: { size:[5, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] },
      xaxis: { categories},
      stroke: { width: [3, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], curve: 'straight', dashArray: [0, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3]},
      yaxis : { logarithmic: false, decimalsInFloat: false, labels: { formatter: function(val) { return val ? Math.round(val) : 0 } } },
      // TODO: fix logarithmic=true when apexchart works again
      legend: { position: "bottom", show: true, height: "80" },
      colors: ["#0a3455", "#6ebdc4", "#416d90", "#b4ebf0", "#7cc5cc", "#61a6b5", "#548fa6", "#2e5f80", "#174366"]
    };


    return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-0">
          <div className="pl-3 mt-3 font-weight-bold font-size-lg text-uppercase" >{this.props.category}</div>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4} >
                <div className="pl-3 mt-3 font-weight-bold font-size-md" >Current stage value and change from previous</div>
                <Chart options={LineFunnelConfiguration} series={categoryDataSeries} type="line" height="500"/>
              </Grid>
              <Grid item xs={12} sm={4} >
                <div className="pl-3 mt-3 font-weight-bold font-size-md" >Funnel stage absolute values</div>
                <Chart options={FunnelConfiguration} series={categoryDataSeries} type="bar" height="500"/>
              </Grid>
              <Grid item xs={12} sm={4} >
                <div className="pl-3 mt-3 font-weight-bold font-size-md" >Funnel stage conversion rates</div>
                <Chart options={ConversionFunnelConfiguration} series={conversionSeries} type="line" height="500"/>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default InboundFunnel;
