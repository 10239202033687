import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import FilterBar from '../Common/FilterBar';
import moment from 'moment'

import MeetingKPIComponent from './meetingKPIComponent'
import ToolKPIComponent from './toolKPIComponent';
import SentimentKPIComponent from './sentimentKPIComponent'
import ProductivityKPIComponent from './productivityKPIComponent'
import CompanyKPIComponent from './companyKPIComponent'
import EngagementKPIComponent from './engagementKPIComponent'
import FlowtraceKPIComponent from './flowtraceKPIComponent'

function calculateSurveyKPIs(surveys, surveyType) {
  const surveyScore = { responses: 0, sum: 0, latest: 0, average: 0, latestMovingAverage: 0, detractors: 0, promoters: 0, neutral: 0 };

  // Nothing to calculate...
  if (!surveys) return surveyScore

  // Take the previous entity and run it through recursion
  if (surveys.previous)
    surveyScore.previous = { ...calculateSurveyKPIs(surveys.previous, surveyType) }
  else
    surveyScore.previous = { ...surveyScore }

  const objectType = surveyType === "nps" ? "NPS" : "effectiveness"
  if (surveys[objectType]) {
    for (const survey of surveys[objectType]) {
      // Handle eNPS differently:
      if (surveyType === "nps") {
        surveyScore.detractors += survey.detractors | 0;
        surveyScore.promoters += survey.promoters | 0;
        surveyScore.neutral += survey.neutral | 0;
        surveyScore.responses += (survey.neutral + survey.promoters + survey.detractors) | 0;
        if (survey.score) surveyScore.latest = survey.score; // use last valid value (if any)
        surveyScore.average = ((surveyScore.promoters / surveyScore.responses) - (surveyScore.detractors / surveyScore.responses)) * 100;
      }
      else if (survey[surveyType]) {
        surveyScore.responses += 1;
        surveyScore.sum += survey[surveyType].score | 0;
        surveyScore.latest = survey[surveyType].score;
        surveyScore.latestMovingAverage = survey[surveyType].movingAverage; // can be null?
        surveyScore.average = surveyScore.sum / surveyScore.responses;
      }
    }
  }
  // Do a sanity check with previous object to avoid current.previous.previous
  if (surveyScore.previous && surveyScore.previous.previous)
    delete surveyScore.previous.previous

  return surveyScore;
}


function processSurveyData(surveyData, queryFilters) {
  const surveys = { NPS: [], effectiveness: [] }

  // No data -> no survey data
  if (!surveyData || !surveyData.data || !surveyData.data.company) return surveys

  // Return whole company surveys, or find the selected team survey results:
  if (queryFilters && queryFilters.teamFilter !== "/All") {
    surveys.NPS = surveyData.data.company.NPS
    surveys.effectiveness = surveyData.data.company.effectiveness
  }
  else {
    const found = surveyData.data.teamStats.filter(ts => ts.team === queryFilters.teamFilter)
    if (found) {
      surveys.NPS = found.NPS
      surveys.effectiveness = found.effectiveness
    }
  }
  return surveys
}

// Create 2 set of simple queryFilters for current and previous period
function produceQueryFilters(queryFilters) {
  const rangeDays = queryFilters.range_period * queryFilters.range_limit
  const currentEnd = moment().format("YYYY-MM-DD")
  const currentStart = moment().subtract(rangeDays, "days").format("YYYY-MM-DD")
  const previousEnd = moment().subtract(rangeDays + 1, "days").format("YYYY-MM-DD")
  const previousStart = moment().subtract(rangeDays * 2, "days").format("YYYY-MM-DD")

  const currentPeriodQueryFilters = { ...queryFilters, range_start: currentStart, range_end: currentEnd }
  const previousPeriodQueryFilters = { ...queryFilters, range_start: previousStart, range_end: previousEnd }

  return { current: currentPeriodQueryFilters, previous: previousPeriodQueryFilters }
}

class CompanyKPIDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, benchmark:true, queryFilters: produceQueryFilters({ range_type: "rolling", range_period: "7", range_limit: 1 }) };
  }

  componentShouldLoad(user, component) {
    // No rendering if there is no user
    if (!user || !user.account) return false;
    if(component === "meeting" && (user.account.ws.GSUITEMEETING || user.account.ws.GSUITESERVICE_ACCOUNT)) return true;
    if(component === "productivity" && user.account.ws.GSUITESERVICE_ACCOUNT) return true;
    if(component === "github" && user.account.ws.GITHUB) return true;
    if(component === "gitlab" && user.account.ws.GITLAB) return true;
    if(component === "jira" && user.account.ws.ATLASSIAN) return true;
    if(component === "hubspot" && user.account.ws.HUBSPOT) return true;
    if(component === "slack" && user.account.ws.SLACK) return true;
    if(component === "survey" && user.account.properties.find(i => i.surveySettings && i.surveySettings.enabled)) return true;
    return false;
  }

  async loadSurveys(queryFilters) {
    const dataAPI = 'slackintegration';
    const surveyPath = '/slack/installed/surveys';
    const currentPeriodAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters.current } };
    const previousPeriodAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters.previous } };

    const [currentData, previousData] = await Promise.all([
      API.get(dataAPI, surveyPath, currentPeriodAPI),
      API.get(dataAPI, surveyPath, previousPeriodAPI)
    ])

    this.setState({ loading: false, surveys: { ...processSurveyData(currentData, queryFilters), previous: processSurveyData(previousData, queryFilters) } });
    return;
  }


  async componentDidMount() {
    this.setState({ loading: false })
    // Check if we load surveys or set just empty placeholders
    if(this.componentShouldLoad(this.props.flowtraceUser, "survey"))
      this.loadSurveys(this.state.queryFilters);
    else
      this.setState({surveys:processSurveyData(), previous:processSurveyData()});
  }

  componentWillUnmount() {
    this.setState = () => { return; };
  }

  async queryFiltersUpdated(queryFilters, benchmark, shouldReload = true) {
    const updatedQueryFilters = produceQueryFilters(queryFilters);

    this.setState({ queryFilters: updatedQueryFilters, benchmark: benchmark, loading: shouldReload });
    if (shouldReload)
      this.loadSurveys(updatedQueryFilters);
  }
  onLoading(loading) {this.setState({loading})}


  render() {

    const KPIcomponents = [];

    const meetingComponent = <MeetingKPIComponent key="kpi_1" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} meetingSurveyScore={calculateSurveyKPIs(this.state.surveys,"meeting")} />;
    const productivityComponent = <ProductivityKPIComponent key="kpi_2" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} productivitySurveyScore={calculateSurveyKPIs(this.state.surveys,"productivity")} />;
    const hubspotComponent = <ToolKPIComponent tool="hubspot" key="kpi_3" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} />;
    const jiraComponent = <ToolKPIComponent tool="jira" key="kpi_4" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} />;
    const githubComponent = <ToolKPIComponent tool="github" key="kpi_5" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} />;
    const gitlabComponent = <ToolKPIComponent tool="gitlab" key="kpi_6" flowtraceUser={this.props.flowtraceUser} benchmark={this.state.benchmark} queryFilters={this.state.queryFilters} />;
    const sentimentComponent = <SentimentKPIComponent key="kpi_7" flowtraceUser={this.props.flowtraceUser} queryFilters={this.state.queryFilters} benchmark={this.state.benchmark} />;
    const engagementComponent = <EngagementKPIComponent key="kpi_8" flowtraceUser={this.props.flowtraceUser} queryFilters={this.state.queryFilters} benchmark={this.state.benchmark} engagementSurveyScore={calculateSurveyKPIs(this.state.surveys,"engagement")} npsSurveyScore={calculateSurveyKPIs(this.state.surveys,"nps")} />;
    const companyComponent = <CompanyKPIComponent key="kpi_9" flowtraceUser={this.props.flowtraceUser} queryFilters={this.state.queryFilters.current} benchmark={this.state.benchmark} companySurveyScore = { calculateSurveyKPIs(this.state.surveys, "company") } managementSurveyScore = { calculateSurveyKPIs(this.state.surveys, "management") } crossTeamCollaborationSurveyScore = { calculateSurveyKPIs(this.state.surveys, "cross_team_collaboration") } />;
    const flowtraceComponent = <FlowtraceKPIComponent key="kpi_0" flowtraceUser={this.props.flowtraceUser} queryFilters={this.state.queryFilters.current} benchmark={this.state.benchmark} teamworkScore={calculateSurveyKPIs(this.state.surveys,"teamwork")} teamCompositionScore={calculateSurveyKPIs(this.state.surveys,"team_composition")} />;

    if(this.componentShouldLoad(this.props.flowtraceUser, "meeting")) KPIcomponents.push(meetingComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "productivity")) KPIcomponents.push(productivityComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "hubspot")) KPIcomponents.push(hubspotComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "jira")) KPIcomponents.push(jiraComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "github")) KPIcomponents.push(githubComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "gitlab")) KPIcomponents.push(gitlabComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "slack")) KPIcomponents.push(sentimentComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "slack")) KPIcomponents.push(engagementComponent);
    if(this.componentShouldLoad(this.props.flowtraceUser, "meeting")) KPIcomponents.push(companyComponent);

    // Always add usage component
    KPIcomponents.push(flowtraceComponent);

    return (
      <Fragment>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FilterBar benchmarking hidePeriod teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters.current} onChange={this.queryFiltersUpdated.bind(this)}/>
            </Grid>
          </Grid>
          {!this.state.loading ? <Grid container spacing={4}> {KPIcomponents}</Grid> : null}
          {this.state.loading ? <Loading/> : null }
        </Fragment>
    ); // end of return
  } // end of render()
} // end of class

export default CompanyKPIDashboard;
