import React, { Fragment, useEffect, useState } from 'react';
import { Button, DialogActions, FormControlLabel, FormGroup, Checkbox, List, ListItem, ListItemIcon, ListItemText, Grid } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/FolderOutlined';

import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';

import Loading from '../../../../flowtrace-components/Loading';
import Auth0Button from '../Auth0Button';
import Oauth2 from './oauth2Install';


const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-0 pb-0">
      {message}
    </MuiAlert>
  );
};

const validateWebhooks = async (account) => {
  if (!account) {
    return;
  }
  const response = await API.get('slackintegration', `/github/webhooks/validate/${account.userId}`,  { headers: {}, response: true });
  return response.data;
}

export default function GithubConfigureModal(props) {

  const urlParams = new URLSearchParams(window.location.search);
  const installationId = urlParams.get('installation_id');

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [webhooksChecked, setWebhooksChecked] = useState(false);
  const [installedWebhooksOrg, setInstalledWebhooksOrg] = useState([]);
  const [installedWebhooksRepos, setInstalledWebhooksRepos] = useState([]);

  const githubInstallUrl = 'https://github.com/apps/flowtrace/installations/new?redirect_uri=' + window.location.href;

  useEffect(() => {
      const finishGithubInstallation = async () => {
        setLoading(true);
        const path = `/github/installation/${accountSub.userId}/${installationId}`;
        const initAPI = { headers: {}, response: true };
        const result = await API.post('slackintegration', path, initAPI);
        return result.data;
      };
      
      const [accountSub] = props.flowtraceUser.account.workspaces.filter(w => w.type === 'GITHUB');

      if (!accountSub || !installationId) {
        setLoading(false);
        return;
      }

      if (!accountSub.workspaceId) {
        finishGithubInstallation()
          .then(res => {
            setLoading(false);
            const githubWorkspaceIndex = props.flowtraceUser.account.workspaces.findIndex(w => w.type === 'GITHUB' && w.userId && !w.workspaceId);
            if (githubWorkspaceIndex >=0 ) {
              props.flowtraceUser.account.workspaces[githubWorkspaceIndex].workspaceId = res.accountId;
            }
            
            setMessage(createAlert("success", "Integration successfully setup."));

          })
          .catch(err => {
            console.log(err);
            setMessage(createAlert("error","Something went wrong!"));
          });
      } else {
        console.log('workspaceId already here.');
        setLoading(false);
      }
        
    const getGithubWebhooks = async () => {
      try {
        const [accountSub] = props.flowtraceUser.account.workspaces.filter(w => w.type === 'GITHUB');
        const currentUserId = accountSub.userId || null;
        if (!currentUserId) {
          return [];
        }
        const response = await validateWebhooks(accountSub);
    
        if (response && response.success) {
          setInstalledWebhooksRepos(response.repositories);
          setInstalledWebhooksOrg([response.organisation]);
          setWebhooksChecked(true);

        } else{
          console.log('redirect to github');
          window.open(githubInstallUrl, '_self');
    
        }
      } catch(e){
        setLoading(false);
        setWebhooksChecked(true);
        setMessage(createAlert("error","We couldn't retrieve your current configration. Please try configuring your integration or re-install it to ensure you get the relevant permissions from Github."));
      }
    };
    getGithubWebhooks().then(res => {
      setWebhooksChecked(true);
      setLoading(false);  
    });
  

    }, [props.flowtraceUser, installationId, webhooksChecked, loading, githubInstallUrl]);
  
  const authError = (error) => {
    console.log("authError", error);
    setMessage(createAlert("error", "We couldn't complete the verification."));
  };
  
  const reloadUserAndWebhooks = async () => {
    setLoading(true);
    return props.flowtraceUser.reload().then(() => { 
      setReload(true) 
    });
  };

  const authSuccess = (profile) => {
    setLoading(true);
    props.openDialog();

    Oauth2(profile.sub).then(data => {
      if(data && data.success) {
        setMessage(createAlert("success", "Installation succeeded."));
        reloadUserAndWebhooks();
      } else {
        setMessage(createAlert("warning", "Something didn't go right."));
        props.onInstall(data.success, data.success);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const isGithubWorkspaceEnabled = (account) => {
    const [workspace] = account.workspaces.filter(w => w.type === 'GITHUB' && w.userId);
    if (workspace && workspace.workspaceId) {
      return true;
    }
    return false;
  };

  if (reload) {
    setReload(false);
    setLoading(true);
  }
  
  const handleRepositoryChange = (e, item, type) => {

    if (e.target.checked) {
      if (!item.flowtrace_webhook_id) {
        item.webhookAdd = true;  
      }
      
      item.webhookRemove = false;

    } else {
      if (item.flowtrace_webhook_id > 0) {
        item.webhookRemove = true;
      }
      item.webhookAdd = false;
    }
    if (type === 'repo') {
      setInstalledWebhooksRepos(p => p.map((p) => (p.id === item.id ? item : p)));
      // If webhook is added to a repo, we need to remove it from the org to avoid duplicate
        setInstalledWebhooksOrg(p => p.map((p) => {
          p.webhookAdd = false;
          p.webhookRemove = true;
          return p;
        })
      );  
    }
    
    
    if (type === 'org') {
    setInstalledWebhooksOrg(p => p.map((p) => (p.id === item.id ? item : p))); 
    // If webhook is added to the org, we need to remove it from all the projects to avoid duplicates
    if (item.webhookAdd) {
      setInstalledWebhooksRepos(p => p.map((p) => {
        p.webhookAdd = false;
        p.webhookRemove = true;
        return p;
      }));  
     }
    }
  };
  
  const handleSubmit = async (e) =>{
      const accountSub = props.flowtraceUser.account.workspaces.find(w => w.type === 'GITHUB');
      
      const repoWebhooksToRemove = installedWebhooksRepos.filter(p => p.webhookRemove);
      if (repoWebhooksToRemove.length > 0) {
          for (let current of repoWebhooksToRemove) {
            const path = `/github/webhooks/repos/${accountSub.userId}/${current.full_name}/${current.flowtrace_webhook_id}`;
            await API.del('slackintegration', path, { headers: {}, response: true });
            current.flowtrace_webhook_id = 0;
            setInstalledWebhooksRepos(projects => projects.map((p) => (p.id === current.id ? current : p)));
          }
      }
      
      const repoWebhooksToAdd = installedWebhooksRepos.filter(p => p.webhookAdd);
      if (repoWebhooksToAdd.length > 0) {
          for (let current of repoWebhooksToAdd) {
            
            const path = `/github/webhooks/repos/${accountSub.userId}/${current.full_name}`;
            await API.post('slackintegration', path, { headers: {}, response: true });
            current.flowtrace_webhook_id = 1;
            setInstalledWebhooksRepos(projects => projects.map((p) => (p.id === current.id ? current : p)));
          }
      }

      const orgWebhooksToRemove = installedWebhooksOrg.filter(p => p.webhookRemove);
      if (orgWebhooksToRemove.length > 0) {
          for (let org of orgWebhooksToRemove) {
            const path = `/github/webhooks/orgs/${accountSub.userId}/${org.id}/${org.flowtrace_webhook_id}`;
            await API.del('slackintegration', path, { headers: {}, response: true });
            org.flowtrace_webhook_id = 0;
            setInstalledWebhooksOrg(projects => projects.map((p) => (p.id === org.id ? org : p)));
          }
      }

      const orgWebhooksToAdd = installedWebhooksOrg.filter(p => p.webhookAdd);
      if (orgWebhooksToAdd.length > 0) {
          for (let org of orgWebhooksToAdd) {
            const path = `/github/webhooks/orgs/${accountSub.userId}/${org.id}`;
            await API.post('slackintegration', path, { headers: {}, response: true });
            org.flowtrace_webhook_id = 1;
            setInstalledWebhooksOrg(projects => projects.map((p) => (p.id === org.id ? org : p)));
          }
      }
      setMessage(createAlert("success","Webhooks successfully updated."));
      
      return true;

  };
  
  const generateProject = (item, index, type) => {
      const checkbox = <Checkbox
                  key={'project-checkbox-' + index}
                  checked={isWebhookEnabled(item)}
                  onClick={e => handleRepositoryChange(e, item, type)}
                />;
      return    <ListItem key={item.id} dense={true}>
                    <FormControlLabel
                      margin='none'
                      key={'projects-list-' + index}
                      control={checkbox}
                      label={item.full_name || item.name || item.login}
                      />
                  </ListItem>;
  }

  const isWebhookEnabled = (project) => {
    const webhookId = parseInt(project.flowtrace_webhook_id) || 0;
    const isEnabled = (webhookId > 0 && !project.webhookRemove) || (webhookId === 0 && project.webhookAdd);
    return isEnabled || false;;
  };

  if (!webhooksChecked) {
    const [accountSub] = props.flowtraceUser.account.workspaces.filter(w => w.type === 'GITHUB');
    validateWebhooks(accountSub).then(response => {
      if (response && response.success) {
        setInstalledWebhooksRepos(response.repositories);
        setInstalledWebhooksOrg([response.organisation]);
      }
    });
    setWebhooksChecked(true);

  }
  

  if (props.existing && !installationId && !isGithubWorkspaceEnabled(props.flowtraceUser.account)) {

    console.log('Redirecting to Github, app not installed on workspace');
    window.open(githubInstallUrl, '_self');

  }

  const isSubscribeButtonDisabled = () => {

    if (installedWebhooksRepos.filter(p => p.webhookAdd).length > 0 || installedWebhooksRepos.filter(p => p.webhookRemove).length > 0) {
      return false;
    }
    
    if (installedWebhooksOrg.filter(p => p.webhookRemove).length  > 0 || installedWebhooksOrg.filter(p => p.webhookAdd).length > 0) {
      return false;
    }
    
    return true;

  }

  const submit = <Button 
    onClick={() => handleSubmit()} 
    disabled={loading || isSubscribeButtonDisabled()} 
    variant="contained" color="primary" size="large"> Subscribe webhooks </Button>
  const uninstall = <Button color="primary" variant="outlined" onClick={() => window.open(githubInstallUrl)}>Uninstall</Button>;
  const install = <Auth0Button loading={loading} cbProfile={authSuccess} cbError={authError} reinstall={props.existing} connection={"github"} />;
  const dialogButtons = <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-2 pb-3">{submit} {uninstall} {install} {props.cancelButton}</DialogActions>
  if(!props.existing && !props.open)
    return install;

  if (props.flowtraceUser.reloading) {
    return <Fragment> <Loading text="Please wait while we update the account settings."/> {dialogButtons} </Fragment>;
  }
    
  if (loading) {
    return <Fragment> <Loading text="Loading webhook information from GitHub"/> {dialogButtons} </Fragment>;  
  }
  
  let emptyPlaceholder;
  if (!webhooksChecked) {
    emptyPlaceholder = <Loading text="Validating webhook information from GitHub"/>;  
  } else {
    emptyPlaceholder = <p className="pt-5">Select the items you want to remove webhook. You can also choose to re-install the integration, or uninstall it:</p>;
  }

  return (
    <Fragment>
        <div>
          <div className="pt-5">
            {installedWebhooksOrg.length > 0 ? (
              <div>
                <Grid container spacing={2}>
                  <Grid item md={6} >
                    <h6>Flowtrace is integrated with the following organisation.</h6>
                    <FormGroup>
                      <List dense={true}>
                        { generateProject(installedWebhooksOrg[0], 1, 'org') }
                      </List>
                    </FormGroup>
                  </Grid>
                  <Grid item md={6}>
                    <h6>Repositories included in the organisation:</h6>
                    {(installedWebhooksOrg[0].flowtrace_webhook_id > 0)?
                      (
                        installedWebhooksRepos.map(repo => (
                          <ListItem key={repo.id}>
                            <ListItemIcon>
                              <FolderIcon />
                            </ListItemIcon>
                            <ListItemText primary={repo.full_name} />
                          </ListItem>))
                      ) : (
                        installedWebhooksRepos.map((item, index) => generateProject(item, index, 'repo'))
                      )
                    }
                  </Grid>
                </Grid>
              </div>
            ) : ('')
            }
          {emptyPlaceholder}
          {message}
          
          </div>
          {dialogButtons}
        </div>
    </Fragment>
  )
}