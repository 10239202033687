import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import TodayIcon from '@material-ui/icons/Today';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PaymentIcon from '@material-ui/icons/Payment';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RestoreIcon from '@material-ui/icons/Restore';
import Speed from '@material-ui/icons/Speed';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import IsoIcon from '@material-ui/icons/Iso';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import AssistantIcon from '@material-ui/icons/Assistant';

var iconsMap = {
  AssistantIcon,
  TodayIcon,
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  TimelapseIcon: TimelapseIcon,
  QueuePlayNextIcon: QueuePlayNextIcon,
  MenuBookIcon: MenuBookIcon,
  TrendingUpIcon: TrendingUpIcon,
  PaymentIcon: PaymentIcon,
  LiveHelpIcon: LiveHelpIcon,
  SupervisedUserCircleIcon: SupervisedUserCircleIcon,
  GroupWorkIcon: GroupWorkIcon,
  AssignmentIndIcon: AssignmentIndIcon,
  PersonAddIcon: PersonAddIcon,
  RestoreIcon: RestoreIcon,
  QuestionAnswerIcon: QuestionAnswerIcon,
  GroupWorkOutlinedIcon: GroupWorkOutlinedIcon,
  Speed: Speed,
  TrackChangesIcon: TrackChangesIcon,
  LibraryBooksIcon: LibraryBooksIcon,
  ArtTrackIcon: ArtTrackIcon,
  IsoIcon: IsoIcon,
  SubscriptionsIcon,
  EventNoteIcon,
  DeviceHubIcon
};


const dashboardNavi = {
  "label": "Dashboard",
  "icon": "Speed",
  "description": "This is where you see your team's collaboration with quick glance.",
  "to": "/Dashboard"
};

const recommendationNavi = {
  "label": "Recommendations",
  "icon": "AssistantIcon",
  "description": "Recommendations",
  "to": "/Recommendations/Company"
};

const resourcesNavi = {
  "label": "Resource Library",
  "icon": "ArtTrackIcon",
  "description": "All our guides, policy templates, and other useful reading how to master the art of collaboration.",
  "to": "/Resources"
};


const collaborationNavi = {
  "label": "Slack Analytics",
  "defaultOpen": false,
  "content": [{
    "label": "Slack Overview",
    "icon": "RestoreIcon",
    "description": "This is where you see your team's collaboration with quick glance.",
    "to": "/Slack/Overview"
  }, {
    "label": "Relational Network",
    "icon": "PeopleIcon",
    "description": "This is where you see your team's tighly knit network.",
    "to": "/Slack/Network"
  }, {
    "label": "Company Sentiment",
    "icon": "IsoIcon",
    "description": "This is where you see your team's collaboration sentiment with quick glance.",
    "to": "/Slack/Sentiment/Company"
  }]
}

const productivityNavi = {
  "label": "Productivity Analytics",
  "defaultOpen": false,
  "content": [{
    "label": "Productivity Overview",
    "icon": "TrackChangesIcon",
    "description": "This is where you see your team's collaboration with quick glance.",
    "to": "/Productivity/Overview"
  }, {
    "label": "Productivity by Teams",
    "icon": "AssignmentIndIcon",
    "description": "This is where you see your team's collaboration sentiment with quick glance.",
    "to": "/Productivity/Team"
  },{
      "label": "SaaS Tool Insights",
      "icon": "ViewModuleIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Productivity/Company/Tools"
    }
]
};

const meetingNavi = {
  "label": "Meeting Analytics",
  "defaultOpen": false,
  "content": [{
    "label": "Calendar Overview",
    "icon": "CalendarTodayIcon",
    "description": "This is where you see your team's collaboration sentiment with quick glance.",
    "to": "/Meeting/Overview"
  }, {
    "label": "Meeting Details",
    "icon": "TodayIcon",
    "to": "/Meeting/Details"
  }, {
    "label": "Meeting by Teams",
    "icon": "AssignmentIndIcon",
    "description": "This is where you see your team's collaboration sentiment with quick glance.",
    "to": "/Meeting/Statistics"
  }, {
    "label": "Meeting Audit",
    "icon": "EventNoteIcon",
    "description": "This is where you see your team's collaboration sentiment with quick glance.",
    "to": "/Meeting/Insights"
  }]
}

const premiumEngagementNavi = {
  "label": "Team Analytics",
  "defaultOpen": false,
  "content": [{
      "label": "Team Engagement",
      "icon": "TimelapseIcon",
      "description": "This is where you see your team's collaboration and engagement with quick glance.",
      "to": "/Engagement/Overview"
    },
    {
      "label": "Survey Overview",
      "icon": "GroupWorkIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Surveys/Company"
    },
    {
      "label": "Team Surveys",
      "icon": "GroupWorkOutlinedIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Surveys/Team"
    },
    // {
    //   "label": "Employee Onboarding",
    //   "icon": "PersonAddIcon",
    //   "description": "This is where you see your team's collaboration and engagement with quick glance.",
    //   "to": "/Engagement/EmployeeOnboarding"
    // }
  ]
};
const freemiumEngagementNavi = {
  "label": "Team Analytics",
  "defaultOpen": false,
  "content": [{
      "label": "Team Engagement",
      "icon": "TimelapseIcon",
      "description": "This is where you see your team's collaboration and engagement with quick glance.",
      "to": "/Engagement/Overview"
    },
    {
      "label": "Survey Overview",
      "icon": "GroupWorkIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Surveys/Company"
    }
  ]
};

const extraNavi = {
  "label": "Experimental Tools",
  "defaultOpen": false,
  "content": [{
      "label": "Channel Network",
      "icon": "SupervisedUserCircleIcon",
      "description": "This is where you see your team's discussion forum network.",
      "to": "/Slack/Network/Channel"
    }, {
      "label": "Channel Sentiment",
      "icon": "ChatIcon",
      "description": "Here you can see channels and how people feel about collaborating on them.",
      "to": "/Slack/Sentiment/Channel"
    },
    {
      "label": "Topic Sentiment",
      "icon": "BarChartIcon",
      "description": "Here you can see topics and how people feel about collaborating on them.",
      "to": "/Slack/Sentiment/Topic"
    },
    {
      "label": "Information Network",
      "icon": "GroupWorkIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Slack/Network/Entity"
    }
    ,
    {
      "label": "KPI Dashboard",
      "icon": "DashboardTwoToneIcon",
      "description": "Ever wondered how does your team's topics tie together? Find out more from here.",
      "to": "/Metric"
    }
  ]
}

const settingsNavi = {
  "label": "Settings",
  "defaultOpen": false,
  "content": []
}

const accountItems = [
{
    "label": "User Management",
    "icon": "PersonAddIcon",
    "description": "Here you can control how survey's are sent to your employees.",
    "to": "/Account/UserManagement"
  }, {
    "label": "Surveys",
    "icon": "QuestionAnswerIcon",
    "description": "Here you can control how survey's are sent to your employees.",
    "to": "/Account/Survey"
  }, {
    "label": "Reporting",
    "icon": "SubscriptionsIcon",
    "to": "/Account/Reporting"
  }, {
    "label": "Account Settings",
    "icon": "SettingsIcon",
    "to": "/Account/Settings"
  }   ];
const billingItem = {
    "label": "Billing",
    "icon": "PaymentIcon",
    "description": "This is where all the detail in relation to your account billing and features reside.",
    "to": "/Account/Billing"
  };
const integrationItem = {
    "label": "Integrations",
    "icon": "DeviceHubIcon",
    "description": "Here you can control how data is ingested into your account and how its used.",
    "to": "/Configuration"
  };
const adminNavi = {
  "label": "Admin",
  "defaultOpen": false,
  "content": []
}


const navItems = (user) => {

  const navigation = [];
  const userType = user && user.flowtrace ? user.flowtrace : null;
  const paid = user && user.account.props.type!=="free" ? true : false;

  // Admin or global admin can manage client accounts (create new, update properties, users, etc...)
  if (userType === "global_admin" || userType === "admin") {
    if (!adminNavi.content.find(i => i.label === "Accounts"))
      adminNavi.content.push({
        "label": "Accounts",
        "icon": "FolderIcon",
        "description": "Here you can control how data is ingested into your account and how its used.",
        "to": "/Admin/ClientAccounts"
      });
  }
  // Who sees the company KPIs
  if (userType === "global_admin" || userType === "admin" || userType === "investor") {
    if (!adminNavi.content.find(i => i.label === "Flowtrace KPIs"))
      adminNavi.content.push({
        "label": "Flowtrace KPIs",
        "icon": "FolderIcon",
        "description": "Here you can control how data is ingested into your account and how its used.",
        "to": "/Admin/BusinessKPIs"
      });
  }


  // If this is paid plan and we have slack or gsuite installed:
  if(paid && (user.account.ws.GSUITE || user.account.ws.SLACK))
    navigation.push(recommendationNavi);

  // Sorting of analytics options happens here: -> GSUITE -> show meeting/productivity first
  const teamNavi = paid ? premiumEngagementNavi : freemiumEngagementNavi;

  // Let's close all the menu before we open them (as after an integration we need it to close)
  settingsNavi.defaultOpen = false;
  meetingNavi.defaultOpen = false;
  productivityNavi.defaultOpen = false;
  collaborationNavi.defaultOpen = false;
  teamNavi.defaultOpen = false;
  if(user && (user.account.ws.GSUITEMEETING || user.account.ws.GSUITESERVICE_ACCOUNT || user.account.ws.GSUITE || user.account.ws.MICROSOFT ))  meetingNavi.defaultOpen = true;
  if(user && (user.account.ws.GSUITESERVICE_ACCOUNT || user.account.ws.GSUITE ))  productivityNavi.defaultOpen = true;
  if(user && (user.account.ws.SLACK))  collaborationNavi.defaultOpen = true;
  if(user && (user.account.ws.SLACK && user.account.props.surveySettings)) teamNavi.defaultOpen = true;
  if(user && !user.account.workspaces.length) settingsNavi.defaultOpen = true;

  // Let's set the menu sequence here
  if(user.account.ws.GSUITEMEETING || user.account.ws.GSUITESERVICE_ACCOUNT || user.account.ws.GSUITE || user.account.ws.MICROSOFT ){
    navigation.push(meetingNavi);
    navigation.push(productivityNavi);
    navigation.push(collaborationNavi);
    navigation.push(teamNavi);
  } else {
    navigation.push(collaborationNavi);
    navigation.push(teamNavi);
    navigation.push(meetingNavi);
    navigation.push(productivityNavi);
  }

  if(user.user_role === "administrator")
    settingsNavi.content = accountItems;
  if((user.user_role === "administrator" || user.user_role === "integration") && !settingsNavi.content.includes(integrationItem))
    settingsNavi.content.push(integrationItem);
  if((user.user_role === "administrator" || user.user_role === "billing") && !settingsNavi.content.includes(billingItem))
    settingsNavi.content.push(billingItem);

  if(settingsNavi.content.length)
    navigation.push(settingsNavi);

  // If user has gsuite then they are paid user so provide the experimental navi:
  if (paid && (user.account.ws.GSUITE || user.account.ws.SLACK)) {
    navigation.push(extraNavi);
  }

  // Add resources to the bottom
  navigation.push(resourcesNavi);

  // Add Administrator/Investor navi in case we need it:
  if (userType) {
    navigation.push(adminNavi);
  }

  return [{
    label:" ",
    key:"dashboardmenu",
    content: JSON.parse(JSON.stringify([dashboardNavi]),
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        }
        else {
          return value;
        }
      }
    )
  },{
    key:"content-menu",
    content: JSON.parse(JSON.stringify(navigation),
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        }
        else {
          return value;
        }
      }
    )
  }]

}
export default navItems;
