import { Grid, Switch, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import surveyHeadline from './surveyScoreHeadline';
import QuestionTable from './questionTable';

export default function SurveyScoreChart(props) {
  const [state, setState] = React.useState({ chart: true });
  const effectiveness = props.surveyData.effectiveness;
  const questions = props.surveyData.questions.filter(q => q.category === props.series);

  const categories = effectiveness.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date });

  // Populate company NPS data series array:
  const datapoints = [];
  const movingAverage = [];
  const series = [];

  let previousMovingAverage = null;
  for (const cat of categories) {
    // Loop every category through so the stats are aligned
    const stat = effectiveness.find(stat => stat.date === cat);

    datapoints.push(stat[props.series] && stat[props.series].score !== undefined ? stat[props.series].score : null);
    movingAverage.push(stat[props.series] && stat[props.series].movingAverage !== undefined ? stat[props.series].movingAverage : previousMovingAverage);
    if (stat[props.series] && stat[props.series].movingAverage !== undefined)
      previousMovingAverage = stat[props.series].movingAverage;
  }

  series.push({ name: "Trend", type: "line", data: movingAverage });
  series.push({ name: "Score", type: "line", data: datapoints });

  // Choose color palette for this series (sort categories by name and use their index for consistency):
  const listOfSeries = [...new Set(effectiveness.map(i => { return Object.keys(i) }).reduce((a, c) => a.concat(c), []).filter(i => i !== "date").sort((a, b) => (a > b) ? 1 : -1))];
  const palette = "palette" + listOfSeries.indexOf(props.series);

  const SurveyConfig = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: -100, max: 100, forceNiceScale: true, labels: { formatter: function(val) { return val !== null ? val.toFixed(1) : "-" } } },
    legend: { position: "top", show: true },
    theme: { mode: 'light', palette: palette, },
    stroke: { curve: ['smooth', 'straight'], width: [4, 0], dashArray: [5, 0] },
    markers: { size: [2, 6], strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: [2, 6], }
  };

  // Don't return component in case there is no data to render
  if (datapoints.length > 0 && datapoints.filter(i => i !== null).length === 0)
    return null;

  const headline = surveyHeadline.getHeadLine(datapoints, movingAverage, props.series, props.minimize);

  // Use state to handle show chart / not
  const handleChange = () => { return setState({ ...state, chart: !state.chart })};

  const chartOrQuestions =
    <Grid item xs={12} md={12}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8} md={8}>
          <span className="text-black-50 d-block mt-2">Show trend and scores:</span>
        </Grid>
        <Grid item xs={2} md={2}>
          <Switch checked={state.chart} onChange={handleChange} color="primary" name="showChart"  />
        </Grid>
      </Grid >
    </Grid >;

  return (
    <Grid item xs={12} sm={state.chart?6:12} md={state.chart?4:6} lg={state.chart?3:6}>
      <Card className="card-box d-flex" >
        <CardContent className="p-3" style={{width:"100%"}}>
          <Grid item sm={12} lg={12}>
            {props.minimize?
              <div><b className="font-size-md" >{headline}</b></div>
            :
            <div>
              <div className="br-5 mb-1" ><b className="font-size-lg " >{headline}</b></div>
              {chartOrQuestions}
              {state.chart?
                <Chart options={SurveyConfig} series={series} type="line" height="300"/>
                :
                <QuestionTable questions={questions} />
              }
            </div>
            }
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );


}
