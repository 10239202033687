import React, { Component } from 'react';
import { Box, Button, Card, CardContent, Grid } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as SlackLogo } from "./slackLogo.svg";

function SlackIcon(props) {
  return (
    <SvgIcon {...props}>
      <SlackLogo/>
    </SvgIcon>
  );
}

class StepNavigation extends Component {
  render() {
    const stepSize = 100 / this.props.totalSteps;

    const barSlices = [];
    for (let i = 1; i <= this.props.totalSteps; i++) {
      if (i < this.props.currentStep)
        barSlices.push(<div key={"progress-"+i} className="progress-bar bg-success" aria-valuenow={stepSize} aria-valuemin="0" aria-valuemax="100" style={{ width: stepSize +'%' }} />);
      if (i === this.props.currentStep)
        barSlices.push(<div key={"progress-"+i} className="progress-bar bg-success progress-bar-striped" aria-valuenow={stepSize} aria-valuemin="0" aria-valuemax="100" style={{ width: stepSize +'%' }} > {parseInt(stepSize * this.props.currentStep)+"%"} </div>);
    }
    const progressBar = <div className="mb-4 mt-3 progress progress-sm"> {barSlices} </div>;
    const heading = <h4>{"Step " + this.props.currentStep + " - " + this.props.stepTitle}{progressBar} </h4>;

    if(!this.props.isActive) return null;
    return (
        <Card >
          <CardContent >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={12}>
                {heading}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {this.props.children}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Box className="d-flex align-items-center">
                  <Button disabled={this.props.currentStep===1 || this.props.backDisabled} onClick={() => {this.props.previousStep(); window.scrollTo(0, 0);}} rel="noopener" size="medium" variant="contained" className="mr-3">Back</Button>
                  {this.props.nextAddToSlack?
                    <Button disabled={this.props.nextDisabled} style={{"backgroundColor":"#4A154B"}} onClick={this.props.nextAction?this.props.nextAction():() => {this.props.nextStep(); window.scrollTo(0, 0);}} rel="noopener" size="medium" variant="contained" color="primary" className="mr-3" startIcon={<SlackIcon />} >Add to Slack</Button>
                  :
                    <Button disabled={this.props.nextDisabled} onClick={this.props.nextAction?this.props.nextAction():() => {this.props.nextStep(); window.scrollTo(0, 0);}} rel="noopener" size="medium" variant="contained" color="primary" className="mr-3">{this.props.nextText?this.props.nextText:"Next"}</Button>
                  }
                  {this.props.additionalAction}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
    );
  }
}


export default StepNavigation;
