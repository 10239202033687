import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Chart from 'react-apexcharts';

export default function HourlyChart(data) {

  let totalUsers = data.data.total.user
  let totalChannels = data.data.total.channel
  let totalTopics = data.data.total.topic

  let neutralData = []
  let negativeData = []
  let positiveData = []
  let categories = []

  for (const key in data.data.days) {
    const total = data.data.days[key].doc_count
    const positive = data.data.days[key].sentiment.POSITIVE || 0
    const negative = data.data.days[key].sentiment.NEGATIVE || 0
    neutralData.push(total - positive - negative)
    positiveData.push(positive)
    negativeData.push(negative)
    categories.push(key)
  }

  const chartOptions = {
    chart: { toolbar: { show: false }, stacked: true, stackType: '100%' },
    dataLabels: { enabled: false },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { show: false, min: 0 },
    legend: { show: true },
    tooltip: { y: { formatter: function(value, opts) { let percent = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return percent.toFixed(0) + '% (' + value + ")" } } },
    colors: ['#F44336', '#B4DDEE', '#37a141']
  };

  const series = [{
      name: 'Negative',
      data: negativeData
    },
    {
      name: 'Neutral',
      data: neutralData
    },
    {
      name: 'Positive',
      data: positiveData
    }
  ];

  return (
    <Fragment>
    <Card className="card-box ">
        <CardContent className="p-2 m-2">
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <div className="d-flex pl-3">
                  <FontAwesomeIcon icon={['far', 'user']} className="font-size-xxl text-success"/>
                <b className="pl-3 font-size-lg">Last 30 day Slack users: { totalUsers }</b>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="text-center">
                <div className="mt-3 line-height-sm">
                  <span className="pb-2 text-black-50 d-block">Active Topics</span>
                  <b className="font-size-md">{ totalTopics }</b>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="text-center">
                <div className="mt-3 line-height-sm">
                  <span className="pb-2 text-black-50 d-block">Active Channels</span>
                  <b className="font-size-md">{ totalChannels }</b>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Chart options={chartOptions} series={series} type="bar" height="200"/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
