import React, { Suspense } from 'react';

import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import { Cache } from 'aws-amplify';
import {ErrorBoundary} from 'react-error-boundary'
import MuiTheme from './theme';

// Layout Blueprints
import { LeftSidebar, PresentationLayout } from './layout-blueprints';
import Icons from './example-pages/Icons';

// Flowtrace pages and controls:
import SlackDashboard from './flowtrace-pages/Dashboard'
import CompanyKPI from './flowtrace-pages/CompanyKPI'
import Metric from './flowtrace-pages/Metric'
import Collaboration from './flowtrace-pages/Collaboration'
import EntityRelationship from './flowtrace-pages/EntityRelationship'
import ChannelRelationship from './flowtrace-pages/ChannelRelationship'
import Billing from './flowtrace-pages/Billing'
import Resources from './flowtrace-pages/Resources'
import ConfigurationIntegration from './flowtrace-pages/Configuration/Integration'
import ConfigurationSurvey from './flowtrace-pages/Configuration/Survey'
import AccountManagement from './flowtrace-pages/Configuration/AccountManagement'
import AccountSettings from './flowtrace-pages/Configuration/AccountSettings';
import Reporting from './flowtrace-pages/Configuration/Reporting';
import CompanySentiment from './flowtrace-pages/CompanySentiment'
import TopicSentiment from './flowtrace-pages/TopicSentiment'
import ChannelSentiment from './flowtrace-pages/ChannelSentiment'
import Install from './flowtrace-pages/Install'
import Profile from './flowtrace-pages/Configuration/Profile'
import Engagement from './flowtrace-pages/Engagement'
import EmployeeOnboarding from './flowtrace-pages/EmployeeOnboarding'
import FlightRisk from './flowtrace-pages/FlightRisk'
import ProductivityStatistics from './flowtrace-pages/ProductivityStatistics/Company'
import CompanyToolStatistics from './flowtrace-pages/ProductivityStatistics/CompanyTool'
import Paywall from './flowtrace-pages/Paywall'
import TeamProductivityStatistics from './flowtrace-pages/ProductivityStatistics/Team'
import MeetingOverview from './flowtrace-pages/MeetingStatistics/Company'
import MeetingDetails from './flowtrace-pages/MeetingStatistics/Details'
import MeetingInsights from './flowtrace-pages/MeetingStatistics/Insights'
import MeetingStatistics from './flowtrace-pages/MeetingStatistics/Team'
import AdminBusinessKPIs from './flowtrace-pages/Admin/BusinessKPIs'
import AdminClientAccounts from './flowtrace-pages/Admin/ClientAccounts'
import AdminClientAccountMembers from './flowtrace-pages/Admin/ClientAccountMembers'
import SurveyCompany from './flowtrace-pages/Surveys/Company'
import SurveyTeam from './flowtrace-pages/Surveys/Team'
import RecommendationsCompany from './flowtrace-pages/Recommendations/Company';
import commonAnalytics from "./commonAnalytics";

import Loading from './flowtrace-components/Loading';

// This is the Flowtrace app's "root" handler. It makes sure session is updated, user profile exists, and does necessery applicatin logic transfers
const RequireAuth = ({ children }) => {

  if (Cache.getItem("lastActive")) {
    const lastActive = parseInt((Date.now() - Cache.getItem("lastActive")) / 1000);
    // Reload the browser in case last activity was over 1 hour ago. This is to recheck session/authentication status, and also reload often enough to get new version of the app continuously.
    if (lastActive > 60 * 60) {
      Cache.removeItem("lastActive"); // Remove the key so it won't trigger never ending reload loop
      window.location.reload();
    }

  }
  Cache.setItem("lastActive", Date.now());

  return children;
};


const RequireSlackInstallation = ({ children }) => {
  // TODO: Add code to handle the missing user cache: Re-fetch it and carry on as it is preferred way to use it inside more complex install workflows insted of fetching the user profile in multiple components...

  return children;
};


const Routes = (props) => {
  const location = useLocation();
  const history = useHistory();
  commonAnalytics.page();

  const pageVariants = {
    initial: { opacity: 0, scale: 0.99 },
    in: { opacity: 1, scale: 1 },
    out: { opacity: 0, scale: 1.01 }
  };

  const pageTransition = { type: 'tween', ease: 'anticipate', duration: 0.4 };

  const routePaths = [
    '/',
    '/CompanyKPI', //Flowtrace
    '/Metric', //Flowtrace
    '/Dashboard', //Flowtrace
    '/Configuration', //Flowtrace
    '/Account/Reporting', //Flowtrace
    '/Account/Survey', //Flowtrace
    '/Account/Settings',
    '/Account/UserManagement',
    '/Account/Billing', //Flowtrace
    '/Profile', //Flowtrace
    '/Login', //Flowtrace
    '/Resources', //Flowtrace
    '/Onboarding', //Flowtrace (added to routes just for internal testing)
    '/Slack/Overview', //Flowtrace Slack Analytics
    '/Slack/Network', //Flowtrace
    '/Slack/Network/Entity', //Flowtrace
    '/Slack/Network/Channel', //Flowtrace
    '/Slack/Sentiment/Company', //Flowtrace
    '/Slack/Sentiment/Topic', //Flowtrace
    '/Slack/Sentiment/Channel', //Flowtrace
    '/Engagement/Overview', //Flowtrace
    '/Engagement/FlightRisk', //Flowtrace
    '/Engagement/EmployeeOnboarding', //Flowtrace
    '/Meeting/Overview', //Flowtrace
    '/Meeting/Detail', //Flowtrace
    '/Meeting/Insights', //Flowtrace
    '/Meeting/Statistics', //Flowtrace
    '/Productivity/Overview', //Flowtrace
    '/Productivity/Company/Tools', //Flowtrace
    '/Productivity/Team', //Flowtrace
    '/Surveys/Company', //Flowtrace
    '/Surveys/Team', //Flowtrace
    '/Recommendations/Company', //Flowtrace
    '/Icons',
  ]
  const navigationPaths = []
  navigationPaths.push(<Route key="CompanyKPI" path="/Dashboard" ><CompanyKPI flowtraceUser={ props.flowtraceUser }/></Route>)



  const microsoftUser = props.flowtraceUser && props.flowtraceUser.account && props.flowtraceUser.account.ws && props.flowtraceUser.account.ws.MICROSOFT && props.flowtraceUser.account.props.type !== "free"? true : false
  // Define the Dashboard depending on the type of installation
  if (props.flowtraceUser && (props.flowtraceUser.current_gsuite || microsoftUser)) {
    // Premium paths:
    if(props.flowtraceUser.account && props.flowtraceUser.account.props.type === "free") {
      navigationPaths.push(<Route key="gsuite_activity_route" path="/Productivity/Overview"><Paywall page="Productivity Overview"/></Route>);
      navigationPaths.push(<Route key="employee_onboarding_route" path="/Engagement/EmployeeOnboarding"><Paywall page="Employee Onboarding"/></Route>);
      navigationPaths.push(<Route key="meetinginsights_route" path="/Meeting/Insights" ><MeetingInsights flowtraceUser={ props.flowtraceUser } /></Route>);
      navigationPaths.push(<Route key="meetingoverview_route" path="/Meeting/Overview" ><MeetingOverview flowtraceUser={ props.flowtraceUser }/></Route>);
      navigationPaths.push(<Route key="meetingdetails_route" path="/Meeting/Details" ><Paywall page="Meeting Details"/></Route>);
      navigationPaths.push(<Route key="meetingstatistics_route" path="/Meeting/Statistics"><Paywall page="Meeting Statistics"/></Route>);
      navigationPaths.push(<Route key="teamproducitivity_route" path="/Productivity/Team"><Paywall page="Team Productivity"/></Route>);
    } else {
      navigationPaths.push(<Route key="gsuite_activity_route" path="/Productivity/Overview" ><ProductivityStatistics flowtraceUser={ props.flowtraceUser }/></Route>);
      navigationPaths.push(<Route key="employee_onboarding_route" path="/Engagement/EmployeeOnboarding"><EmployeeOnboarding flowtraceUser={ props.flowtraceUser }/></Route>);
      navigationPaths.push(<Route key="meetinginsights_route" path="/Meeting/Insights" ><MeetingInsights flowtraceUser={ props.flowtraceUser } /></Route>);
      navigationPaths.push(<Route key="meetingstatistics_route" path="/Meeting/Statistics" ><MeetingStatistics flowtraceUser={ props.flowtraceUser } /></Route>);
      navigationPaths.push(<Route key="meetingdetails_route" path="/Meeting/Details" ><MeetingDetails flowtraceUser={ props.flowtraceUser } /></Route>);
      navigationPaths.push(<Route key="meetingoverview_route" path="/Meeting/Overview" ><MeetingOverview flowtraceUser={ props.flowtraceUser }/></Route>);
      navigationPaths.push(<Route key="teamproducitivity_route" path="/Productivity/Team" ><TeamProductivityStatistics flowtraceUser={ props.flowtraceUser }/></Route>);
    }
  }
  else {
    // Paywall these paths unless there is GSuite/Outlook installation to indicate paid plan:
    navigationPaths.push(<Route key="gsuite_activity_route" path="/Productivity/Overview"><Paywall page="Productivity Overview"/></Route>)
    navigationPaths.push(<Route key="employee_onboarding_route" path="/Engagement/EmployeeOnboarding"><Paywall page="Employee Onboarding"/></Route>)
    navigationPaths.push(<Route key="meetinginsights_route" path="/Meeting/Insights"><Paywall page="Meeting Audit"/></Route>)
    navigationPaths.push(<Route key="meetingdetails_route" path="/Meeting/Details" ><Paywall page="Meeting Details"/></Route>);
    navigationPaths.push(<Route key="meetingstatistics_route" path="/Meeting/Statistics"><Paywall page="Meeting Statistics"/></Route>)
    navigationPaths.push(<Route key="meetingoverview_route" path="/Meeting/Overview"><Paywall page="Meeting Overview"/></Route>)
    navigationPaths.push(<Route key="teamproducitivity_route" path="/Productivity/Team"><Paywall page="Team Productivity"/></Route>)

  }
  // This page can be shown as it is (it's Slack only for freemium)
  navigationPaths.push(<Route key="company_tools_route" path="/Productivity/Company/Tools" ><CompanyToolStatistics flowtraceUser={ props.flowtraceUser }/></Route>);

  navigationPaths.push(<Route key="slack_activity" path="/Slack/Overview" ><SlackDashboard flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="cs" path="/Slack/Sentiment/Company" ><CompanySentiment flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="chs" path="/Slack/Sentiment/Channel" ><ChannelSentiment flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="ts" path="/Slack/Sentiment/Topic" ><TopicSentiment flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="NetworkOverview" path="/Slack/Network" exact ><Collaboration flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="ChannelNetwork" path="/Slack/Network/Channel" ><ChannelRelationship flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="MetricExplorer" path="/Metric" ><Metric flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="Entities" path="/Slack/Network/Entity" ><EntityRelationship flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="engagement" path="/Engagement/Overview" ><Engagement flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="fr" path="/Engagement/FlightRisk" component={FlightRisk} />);
  navigationPaths.push(<Route key="myprofile" path="/Profile" component={Profile} />);

  if(props.flowtraceUser && props.flowtraceUser.user_role === "administrator") {
    navigationPaths.push(<Route key="accountmanagement" path="/Account/UserManagement" ><AccountManagement flowtraceUser={ props.flowtraceUser }/></Route>);
    navigationPaths.push(<Route key="surveyconfig" path="/Account/Survey" ><ConfigurationSurvey flowtraceUser={ props.flowtraceUser }/></Route>);
    navigationPaths.push(<Route key="accountconfing" path="/Account/Settings" ><AccountSettings flowtraceUser={ props.flowtraceUser }/></Route>);
    navigationPaths.push(<Route key="slackReporting" path="/Account/Reporting" ><Reporting flowtraceUser={ props.flowtraceUser }/></Route>);
  }
  if(props.flowtraceUser && (props.flowtraceUser.user_role === "administrator" || props.flowtraceUser.user_role === "integration"))
    navigationPaths.push(<Route key="config" path="/Configuration" ><ConfigurationIntegration flowtraceUser={ props.flowtraceUser }/></Route>);
  if(props.flowtraceUser && (props.flowtraceUser.user_role === "administrator" || props.flowtraceUser.user_role === "billing"))
    navigationPaths.push(<Route key="billing" path="/Account/Billing" ><Billing flowtraceUser={ props.flowtraceUser }/></Route>);



  navigationPaths.push(<Route key="surveycompany" path="/Surveys/Company" ><SurveyCompany flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="surveyteam" path="/Surveys/Team" ><SurveyTeam flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="recommendationscompany" path="/Recommendations/Company" ><RecommendationsCompany flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="resources" path="/Resources" ><Resources flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="install" path="/Onboarding" ><Install flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="installProcessing" path="/Processing" ><Install flowtraceUser={ props.flowtraceUser }/></Route>);
  navigationPaths.push(<Route key="icons" path="/Icons" component={Icons} />);

  // Investor specific routes, and pages
  if (props.flowtraceUser && props.flowtraceUser.flowtrace === "investor") {
    routePaths.push("/Admin/BusinessKPIs")
    navigationPaths.push(<Route key="admin/businesskpis" path="/Admin/BusinessKPIs" component={AdminBusinessKPIs} />)
  }
  // Admin specific routes, and pages
  if (props.flowtraceUser && props.flowtraceUser.flowtrace === "global_admin") {
    routePaths.push("/Admin/BusinessKPIs")
    routePaths.push("/Admin/ClientAccounts")
    routePaths.push("/Admin/ClientAccountMembers")
    navigationPaths.push(<Route key="admin/businesskpis" path="/Admin/BusinessKPIs" ><AdminBusinessKPIs flowtraceUser={ props.flowtraceUser }/></Route>)
    navigationPaths.push(<Route key="admin/clientaccounts" path="/Admin/ClientAccounts" component={AdminClientAccounts} />)
    navigationPaths.push(<Route key="admin/clientaccountmembers" path="/Admin/ClientAccountMembers/:account_id?" component={AdminClientAccountMembers} />)
  }

  // Create the app navigation and routes for onboarding flow:
  const onboarding = () => {
    return (
      <Route key="install" ><Install flowtraceUser={ props.flowtraceUser }/></Route>
    );
  };


const ErrorFallback = ({error, resetErrorBoundary}) => {
  return ( <Loading error errorText="Please go back to, contact us, or try again later." onClick={resetErrorBoundary}/> )
}

  // Main app navigation and underlying routes
  const flowtrace = () => {
    return (
      <RequireSlackInstallation key="sidebar">
        <Route path={routePaths}>
          <LeftSidebar flowtraceUser={props.flowtraceUser}>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => { history.goBack() }} >
                  {navigationPaths}
                 </ErrorBoundary>
              </motion.div>
            </Switch>
          </LeftSidebar>
        </Route>
      </RequireSlackInstallation>
    )
  }
  // Route to Login (only used without session/user profile details)
  const rootLogin = [
    <Route key="RootLogin" path={['/','/Login']}>
      <PresentationLayout>
        <Switch location={location} key={location.pathname}>
          <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
            <Route path="/Login">
              <Redirect to="/Dashboard" />
            </Route>
          </motion.div>
        </Switch>
      </PresentationLayout>
    </Route>
  ]


  if (props.flowtraceUser && (location.pathname.includes("/Onboarding") || location.pathname.includes("/Processing") || !props.flowtraceUser.account)) {
    rootLogin.push(onboarding())
  }
  else if (props.flowtraceUser) {
    rootLogin.push(flowtrace())
  }

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={
          <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
            <div className="w-50 mx-auto">
              Please wait while we diagnose your team collaboration
            </div>
          </div>
        }>
          <Switch>
            <RequireAuth flowtraceUser={props.flowtraceUser} >
              {rootLogin}
            </RequireAuth>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;
