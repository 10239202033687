import React, { Component } from 'react';
import { Grid, Button, Card, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import commonAnalytics from "../../commonAnalytics";

const priceDetail = [
  { id:2, feature: "Integrations", heading:true, text: "", free: "Slack and Google Workspace", team: "Slack and Google Workspace", scale: "Unlimited", tooltip:"We integrate with most common SaaS collaboration and productivity tools. See more from our Integrations page." },
  { id:14, feature: "Search History", heading:true, text: "", free: "4 weeks", team: "12 months", scale: "unlimited", tooltip:"This is how long back in time you can see the trends in your metrics, insights, and recommendations." },
  { id:3, feature: "Meeting Analytics", heading:true, text: "", free: true, team: true, scale: true, tooltip:"Our Meeting Analytics for Google Calendar and Google Meets." },
  { id:4, feature: "Calendar overview", text: "", free: true, team: true, scale: true, tooltip:"Calendar event and invite analytics." },
  { id:4, feature: "Meeting audit", text: "", free: "5 meetings", team: true, scale: true, tooltip:"Meeting audit tools." },
  { id:4, feature: "Group invites", text: "", free: false, team: false, scale: true, tooltip:"Calendar analytics support for group invites." },
  { id:4, feature: "Video call insights", text: "", free: false, team: false, scale: true, tooltip:"Calendar analytics support for group invites." },
  { id:5, feature: "Slack Analytics", heading:true, text: "", free: true, team: true, scale: true, tooltip:"Our Slack analytics is available in all our plans but limited to number of channels for analysis."},
  { id:1, feature: "Channel limit", text: " channels", free: 5, team: "unlimited", scale: "unlimited", tooltip:"This is how many channls you can include in your sentiment and Slack analytics." },
  { id:6, feature: "Workspace overview", text: "", free: true, team: true, scale: true, tooltip:"Overview of your Slack workspace activity, sentiment, and frequent topics." },
  { id:6, feature: "Company sentiment & topics", text: "", free: true, team: true, scale: true, tooltip:"Company sentiment analysis across the company, channels, and topics." },
  { id:11, feature: "Productivity Analytics", heading:true, text: "", free: false, team: true, scale: true, tooltip:"Focus time and time utilization metrics require minimum of Slack, Calendar, Meets, and Email integrations." },
  { id:12, feature: "Time utilization metrics", text: "", free: false, team: true, scale: true, tooltip:"Our propretiary workday algorithms reveal your team and company focus, shallow, and collaboration time trends and insights." },
  { id:11, feature: "Cycle time metrics", text: "", free: false, team: false, scale: true, tooltip:"Cycle time metrics are available from all our project management, engineering, and customer facing SaaS tool integrations." },
  { id:9, feature: "Team Analytics", heading:true, text: "", free: true, team: true, scale: true, tooltip:"Team engagement and survey metrics." },
  { id:13, feature: "Employee NPS survey", text: "", free: true, team: true, scale: true },
  { id:13, feature: "Team effectiveness survey", text: "", free: false, team: true, scale: true, tooltip: "8 category survey across collaboration, meeting, and productivity topics." },
  { id:9, feature: "Organizational network analysis", text: "", free: false, team: true, scale: true, tooltip:"Organizational network analysis with metrics like influece, connectedness, and sentiment." },
  { id:9, feature: "Employee Dashboard", heading:true, text: "", free: false, team: true, scale: true, tooltip:"Personalized metrics about employee's workday with built-in privacy guards delivered via our Slack app home page." },
  { id:10, feature: "Meeting toolbox", text: "", free: false, team: true, scale: true, tooltip:"Anonymous meeting feedback requests and reviews." },
  { id:10, feature: "Worklog", text: "", free: false, team: false, scale: true, tooltip:"Productivity tool worklog and work item status log." },
  { id:10, feature: "Consent and onboarding", text: "", free: false, team: false, scale: true, tooltip:"Consent management solution for your employees via Slack app. We support opt-in, and opt-out consent mechanisms." },
  { id:7, feature: "Organization Structure", heading:true, text: "", free: false, team: true, scale: true, tooltip:"Integrate or manage your organization structure in Flowtrace." },
  { id:7, feature: "Team filters", text: "", free: false, team: true, scale: true, tooltip:"You can filter all our analytics views using organizational filters." },
  { id:8, feature: "Relationship maps", text: "", free: false, team: true, scale: true },
  { id:7, feature: "Team benchmarking", text: "", free: false, team: true, scale: true, tooltip:"You can filter all our analytics views using organizational structure filters." },
  { id:12, feature: "Culture Champion", heading:true, text: "", free: "Free Guides", team: "Quarterly Insights Review Call", scale: "Dedicated Insights Manager", tooltip:"Our customer success team is available through the app chat and email." },
];


class Billing extends Component {
  constructor(props) {
    super(props);
    const currentTier = props.flowtraceUser.account.props.type?props.flowtraceUser.account.props.type:"free";
    this.state = { loading: true, subscription:null, account: props.flowtraceUser.account, currentTier, showConfirmDialog: false};
  }

  async getManageSubscriptionUrl() {
    this.setState({ loading: true });
    commonAnalytics.track('manage-subscription', {event:"view-plan"});
    try{
      const response = await API.get('slackintegration', '/stripe/create-portal-session', { headers: {}, response: true });
      if(response.data && response.data.url)
        window.location.href = response.data.url;
    } catch (e){
      if(e.response && e.response.data && e.response.data.message)
        this.setState({loading:false, errorMessage: e.response.data.message});
      else
        this.setState({loading:false, errorMessage: e.message});
    }
  }

  async currentSubscription() {
    this.setState({ loading: true, subscription:null });
    try{
      const subscription = await API.get('slackintegration', '/stripe/current');
      this.setState({loading:false, subscription});
    } catch (e){
      if(e.response && e.response.data && e.response.data.message)
        this.setState({loading:false, errorMessage: e.response.data.message});
      else
        this.setState({loading:false, errorMessage: e.message});
    }
  }


  async confirmNewPrice() {
    this.setState({ loading: true });
    try{
      await API.get('slackintegration', '/stripe/create-checkout-session/' + this.state.plan + "/confirm");
      this.setState({loading:false, price:null, plan:null});
    } catch (e){
      if(e.response && e.response.data && e.response.data.message)
        this.setState({loading:false, errorMessage: e.response.data.message});
      else
        this.setState({loading:false, errorMessage: e.message});
    }
  }

  closeDialog = (refresh) => {
    this.setState({showConfirmDialog:false, planUpdated:refresh});
    if(refresh)
      this.props.flowtraceUser.reload();
  };

  async getCheckoutUrl(plan) {
    this.setState({ loading: true });
    commonAnalytics.track('manage-subscription', {event:"select-plan"});
    try{
      const response = await API.get('slackintegration', '/stripe/create-checkout-session/' + plan);
      // Check if this is a portal session (for new subscriptions) or if it is a price update
      if(response && response.url)
        window.location.href = response.url;
      else if(response && Object.keys(response).includes("price") )
      {
        this.setState({loading:false, showConfirmDialog:true, plan, price:response.price});
      }
      else throw Error("Something went wrong with the plan upgrade. Please contact our support for help.");
    } catch (e){
      if(e.response && e.response.data && e.response.data.message)
        this.setState({loading:false, errorMessage: e.response.data.message});
      else
        this.setState({loading:false, errorMessage: e.message});
    }
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("type") === "stripe" && urlParams.get("success") && urlParams.get("session_id")){
      const currentTier = this.props.flowtraceUser.account.props.type?this.props.flowtraceUser.account.props.type:"free";
      // Trigger reload to make sure we have "final" state of the plan.
      if(currentTier === "free"){
        this.props.flowtraceUser.reload();
      }
      this.setState({planUpdated:true});
      this.props.history.push(window.location.path);
    }
    await this.currentSubscription();
  }

  getSubscriptionInfo() {
    const cancelAt = this.state.subscription && this.state.subscription.active ? this.state.subscription.active.cancel_at : null;
    const currentPeriod = this.state.subscription && this.state.subscription.active ? this.state.subscription.active.current_period_end : null;
    const isTrial = this.state.subscription && this.state.subscription.active && this.state.subscription.active.status === "trialing" ? true : false;

    if(cancelAt){
      const d = new Date(cancelAt * 1000);
      return "(Cancells at " + d.toLocaleDateString() + ")";
    }
    if(currentPeriod){
      const d = new Date(currentPeriod * 1000);
      if(isTrial)
        return "(Trial renews at " + d.toLocaleDateString() + ")";
      else
        return "(Renews at " + d.toLocaleDateString() + ")";
    }
    return "";
  }

  render() {
    if(this.state.loading && this.state.price !== null)
      return <Loading text="Connecting to our payment provider to update the new plan settings."/>;
    if(this.state.loading)
      return <Loading text="Creating a secure session and connecting to our payment provider."/>;
    if(this.props.flowtraceUser.reloading)
      return <Loading text="Reloading the current account plan information."/>;

    // Don't use router / history, to ensure that next page user goes to is reloaded with new account tier:
    const integrate = <Button onClick={() => window.location.href = "/Configuration"} rel="noopener" variant="contained" color="primary">Add new integrations</Button>;
    const survey = <Button onClick={() => window.location.href = "/Account/Survey"} rel="noopener" variant="contained" color="primary">Add survey categories</Button>;
    const reports = <Button onClick={() => window.location.href = "/Account/Reporting"} rel="noopener" variant="contained" color="primary">Add Slack reports</Button>;


    const planUpdatedGrid =
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={6}>
            <MuiAlert className="mb-4" severity={"success"}>
              <div className="d-flex align-items-center align-content-center">
                <span>
                  <Grid container spacing={4} >
                    <Grid item xs={12}>
                      <strong className="d-block font-size-lg">Great! We are now processing your plan update.</strong>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="d-block font-size-md">It usually takes a couple of seconds to get all settings enabled.</p>
                      <p className="d-block font-size-md">Organization structure, and user teams can take up to ~12 hours to be up to date.</p>
                      <p className="d-block font-size-md">While we get everything ready you can explore your new features:</p>
                    </Grid>
                    <Grid item xs={4}>
                      {integrate}
                    </Grid>
                    <Grid item xs={4}>
                      {survey}
                    </Grid>
                    <Grid item xs={4}>
                      {reports}
                    </Grid>
                  </Grid>
                </span>
              </div>
            </MuiAlert>
          </Grid>
        </Grid>

    const selectedColumnStyle = { backgroundColor: '#7cc5cc', color: "#0a3455", verticalAlign: "top" };
    const selectedCellStyle = { backgroundColor: '#7cc5cc', color: '#0a3455' };

    const cellStyle = { color: '#0a3455' };
    const headerStyle = { color: '#0a3455', verticalAlign: "top" };
    // Column decorations and settings
    const featureColumn = { title: <div className="pt-2 font-size-md"><span className="font-weight-bold font-size-lg">Features</span></div>, align: "left", field: "feature", cellStyle: { fontWeight: "bold", ...cellStyle }, headerStyle, render: (rowData => { return <div className="mt-2 mb-2"> {rowData.feature }</div> }) };
    const freeColumn = { title: <div className="pt-2 font-size-md"><span className="font-weight-bold font-size-lg">Start<br/></span>£0/mo</div>, align: "center", field: "free", cellStyle: { fontWeight: "bold", ...cellStyle }, headerStyle };
    const teamColumn = { title: <div className="pt-2 font-size-md"><span className="font-weight-bold font-size-lg">Team<br/></span>£1/mo/team member</div>, align: "center", field: "team", cellStyle: { fontWeight: "bold", ...cellStyle }, headerStyle };
    const scaleColumn = { title: <div className="pt-2 font-size-md"><span className="font-weight-bold font-size-lg">Scale<br/></span>£2/mo/team member</div>, align: "center", field: "scale", cellStyle: { fontWeight: "bold", ...cellStyle }, headerStyle };



    const manageSubscription = <Button onClick={async() => await this.getManageSubscriptionUrl()} rel="noopener" size="medium" variant="outlined" color="primary">Plan, billing information, and payment history</Button>;
    const checkoutStart = <Button onClick={async() => await this.getManageSubscriptionUrl()} rel="noopener" size="medium" variant="outlined" color="primary">Cancel your plan</Button>;
    const checkoutTeam = <Button onClick={async() => await this.getCheckoutUrl("team")} rel="noopener" size="medium" variant="contained" color="primary">Select Team Plan</Button>;
    const checkoutScale = <Button onClick={async() => await this.getCheckoutUrl("scale")} rel="noopener" size="medium" variant="contained" color="primary">Select Scale Plan</Button>;
    const currentPlan = <Button disabled rel="noopener" size="medium" variant="outlined" color="primary">Current Plan</Button>;

    if (this.state.currentTier === "scale") {
      scaleColumn.headerStyle = { ...selectedColumnStyle };
      scaleColumn.cellStyle = { ...selectedCellStyle };
      scaleColumn.title = <div>{scaleColumn.title}<br/>{currentPlan}<p/></div>;
      freeColumn.title = <div>{freeColumn.title}<br/>{checkoutStart}<p/></div>;
      teamColumn.title = <div>{teamColumn.title}<br/>{checkoutTeam}<p/></div>;
    }
    else if (this.state.currentTier === "team") {
      teamColumn.headerStyle = { ...selectedColumnStyle };
      teamColumn.cellStyle = { ...selectedCellStyle };
      teamColumn.title = <div>{teamColumn.title}<br/>{currentPlan}<p/></div>;
      freeColumn.title = <div>{freeColumn.title}<br/>{checkoutStart}<p/></div>;
      scaleColumn.title = <div>{scaleColumn.title}<br/>{checkoutScale}<p/></div>;
    }
    else { // This is free (and all other plans there might be in DB i.e. trial/internal/test etc.)
      freeColumn.headerStyle = { ...selectedColumnStyle };
      freeColumn.cellStyle = { ...selectedCellStyle };
      freeColumn.title = <div>{freeColumn.title}<br/>{currentPlan}<p/></div>;
      teamColumn.title = <div>{teamColumn.title}<br/>{checkoutTeam}<p/></div>;
      scaleColumn.title = <div>{scaleColumn.title}<br/>{checkoutScale}<p/></div>;
    }


    const rowRender = (value, text, tooltip) => {
      let component;
      if (typeof value === "boolean")
        component = <div ><span className="font-weight-bold font-size-md">{value ? <CheckCircle style={{color: "#007032"}} /> : <RadioButtonUnchecked />}</span></div>;
      if (typeof value === "string")
        component = <div className="text-center font-size-md"><span> { value } </span>{text}</div>;
      if (typeof value === "number")
        component = <div className="text-center font-size-md"><span className="font-weight-bold"> { value } </span>{text}</div>;
      if (typeof value === "object")
        component = value;

      const hideTooltip = typeof value === "boolean" && value?false:true;
      return tooltip && !hideTooltip ? <Tooltip title={tooltip} >{component}</Tooltip> : component;
    };

    const confirmNewPriceDialog =
      <Dialog open={this.state.showConfirmDialog} onClose={() => this.closeDialog(false)} >
        <DialogTitle>{this.state.price !== null ? "Confirm plan change" : "Plan updated successfully!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.price !== null ?
              "Your new monthly price will be £" + this.state.price / 100 + " per month based on current usage. Please click confirm to update the plan."
            :
              "Your plan has been updated succesfully! Click close to reload your current user profile with new plan information."
            }
          </DialogContentText>
        </DialogContent>
        {this.state.price !== null ?
          <DialogActions>
            <Button onClick={() => this.closeDialog(false)} variant="outlined" color="primary">Cancel</Button>
            <Button onClick={() => this.confirmNewPrice()} variant="contained" color="primary" autoFocus>Confirm</Button>
          </DialogActions>
          :
          <DialogActions>
            <Button onClick={() => this.closeDialog(true)} variant="contained" color="primary">Close</Button>
          </DialogActions>
        }
      </Dialog>;

    const featureRender = (title, tooltip = null, heading = null) => {
      return tooltip ? <Tooltip title={tooltip} ><span className={heading?"font-size-md":"pl-3"}>{title}</span></Tooltip> : <span className={heading?"":"pl-3"}>{title}</span>;
    };
    const tierName = this.state.currentTier[0].toUpperCase() + this.state.currentTier.substring(1);
    const errorMessage = <MuiAlert className="mb-4" severity={"error"}>
              <div className="d-flex align-items-center align-content-center">
                <span> <strong className="d-block">{this.state.errorMessage}</strong> </span>
              </div>
            </MuiAlert>;

    return (
      <Grid container spacing={0}>
        {confirmNewPriceDialog}
        { this.state.planUpdated ? planUpdatedGrid : null }
        { this.state.errorMessage ? errorMessage : null }
        <Grid item xs={12}>
          <Card className="p-4 mb-4">
            <Grid container spacing={2} justifyContent="space-between" >
              <Grid item xs={3} >
                <div className="font-size-md"><span className="font-weight-bold font-size-lg ">Current Plan: {tierName} </span><span className="font-size-md">{this.getSubscriptionInfo()}</span></div>
              </Grid>
              <Grid item >
                {manageSubscription}
              </Grid>
            </Grid>
          </Card >
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            columns={[
              { field: "feature",...featureColumn, sorting:false, render: row => featureRender(row.feature, row.tooltip, row.heading) },
              { field: "free",...freeColumn, sorting:false, render: row => rowRender(row.free, row.text, row.tooltip) },
              { field: "team",...teamColumn, sorting:false, render: row => rowRender(row.team, row.text, row.tooltip) },
              { field: "scale",...scaleColumn, sorting:false, render: row => rowRender(row.scale, row.text, row.tooltip) },
              ]}
            data={priceDetail} title="" options={ { paging:false, search:false, draggable:false, padding:"dense" } }
          />
        </Grid>
      </Grid>
    )
  }
}

export default withRouter(Billing);
