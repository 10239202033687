import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(3), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function UserStatisticsChart(data) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userArray = data.user; // Array of all users
  const series = { engagement: [], activity: [], sentiment: [], connectedness: [], influence: [] };
  const categories = []; // Week number / date (fill this outside of user loop so whole data range gets populated)

  // Prepopulate the categories from every single user, so they can be used to fill empty values for missing data later on
  for (const userKey in userArray) {
    const user = userArray[userKey]
    for (const key in user.timeStats) {
      if (!user.timeStats[key]) continue
      const axisLabel = data.timeSpan === "week" ? "Week " + (Number.parseInt(user.timeStats[key].timeSpan) + 1) : "Month " + (Number.parseInt(user.timeStats[key].timeSpan) + 1)
      if (!categories.includes(axisLabel)) categories.push(axisLabel); // X-Axis
    }
  }

  const opacityArray = []
  const dashArray = []
  for (const userKey in userArray) {
    const user = userArray[userKey]
    const engagement = [];
    const activity = [];
    const sentiment = [];
    const connectedness = [];
    const influence = [];
    const userName = user.name

    for (const key in user.timeStats) {
      const timeSlot = user.timeStats[key]
      if (user.timeStats.length === key - 1 && timeSlot.activeDays === 0) continue; // If this is last week/month of the user, and there are no active data, skip the last data point on the chart (could be looked on monday, before stats have been executed, or before there is activity)

      // Score metrics
      engagement.push(timeSlot ? timeSlot.score.engagement : 0);
      activity.push(timeSlot ? timeSlot.score.activity.score : 0);
      sentiment.push(timeSlot ? timeSlot.score.sentiment.score : 0);
      connectedness.push(timeSlot ? timeSlot.score.connectedness.score : 0);
      influence.push(timeSlot ? timeSlot.score.influence.score : 0);
    }
    opacityArray.push((userName === "Company Average" ? 0.05 : 1))
    dashArray.push((userName === "Company Average" ? 3 : 0))
    series.engagement.push({ name: userName, data: engagement, type: (userName === "Company Average" ? "area" : "line") })
    series.activity.push({ name: userName, data: activity, type: (userName === "Company Average" ? "area" : "line") })
    series.sentiment.push({ name: userName, data: sentiment, type: (userName === "Company Average" ? "area" : "line") })
    series.connectedness.push({ name: userName, data: connectedness, type: (userName === "Company Average" ? "area" : "line") })
    series.influence.push({ name: userName, data: influence, type: (userName === "Company Average" ? "area" : "line") })
  }
  // xaxis: { type: "date", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
  // yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : null } } },

  const graphChartOptionsCollaboration = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "category", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, max: 99, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : "" } } },
    legend: { position: "right", show: true },
    stroke: { width: 2, dashArray: dashArray },
    fill: { type: 'solid', opacity: opacityArray, },
    //fill: { opacity: [0.85, 0.25, 1], gradient: { inverseColors: false, shade: 'light', type: "vertical", opacityFrom: 0.85, opacityTo: 0.55, stops: [0, 100, 100, 100] } },
    //states: { normal: { filter: { type: "darken", value: 0.04 } } }
    //states: { normal: { filter: { type: "darken", value: 0.15 } }, hover: { filter: { type: "darken", value: 0.15 } } }
  };


  return (
    <Card className="card-box d-flex">
      <CardContent style={{width:"100%"}}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <div className={classes.themeColorTabs}>
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" variant="scrollable" scrollButtons="auto" >
                  <StyledTab label="Connectedness" />
                  <StyledTab label="Influence" />
                  <StyledTab label="Engagement" />
                  <StyledTab label="Activity" />
                  <StyledTab label="Sentiment" />
                </StyledTabs>
              </div>
              <TabPanel value={value} index={0}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className=" br-5" ><b className="font-size-lg " >New Employee Connectedness Compared to Historical Onboardings</b></div>
                    <span className="text-black-50 d-block">(This score is based on connections employees has, information broker index and degree centrality scores)</span>
                    <Chart options={graphChartOptionsCollaboration} series={series.connectedness} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className=" br-5" ><b className="font-size-lg " >New Employee Influence Compared to Historical Onboardings</b></div>
                    <span className="text-black-50 d-block">(This score in based on employees betweenness, authority and pagerank indexes)</span>
                    <Chart options={graphChartOptionsCollaboration} series={series.influence} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className=" br-5" ><b className="font-size-lg " >New Employee Engagement Compared to Historical Onboardings</b></div>
                    <span className="text-black-50 d-block">(This score is weighted and calculated based on Activity, Connectedness and Influece scores)</span>
                    <Chart options={graphChartOptionsCollaboration} series={series.engagement} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className=" br-5" ><b className="font-size-lg " >New Employee Activity Compared to Historical Onboardings</b></div>
                    <span className="text-black-50 d-block">(This score is based on activity volume, active hours per day and mention metrics)</span>
                    <Chart options={graphChartOptionsCollaboration} series={series.activity} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={4}>
                <Grid item xs={12} sm={12} >
                  <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                    <div className=" br-5" ><b className="font-size-lg " >New Employee Sentiment Compared to Historical Onboardings</b></div>
                    <span className="text-black-50 d-block">(This score is calculated based on positive, negative and neutral collaboration data points)</span>
                    <Chart options={graphChartOptionsCollaboration} series={series.sentiment} type="line" height="300"/>
                  </div></div>
                </Grid>
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
