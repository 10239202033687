import React, { Component } from 'react';
import { Menu, Grid, Button, ButtonGroup } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { CheckBox, CheckBoxOutlineBlank, Business, ArrowDropDown } from '@material-ui/icons';
import LoadingButton from './loadingButton';
import PremiumFeature from './premiumFeature';
import teamFilterAPI from '../../../flowtrace-api/dataAPI/teamFilters';

const createNodeTree = (paths) => {
    let result = [];
    let level = {result};

    paths.forEach(path => {
        path = path.replace("/", ""); // replace first sladh away
        path.split('/').reduce((r, name, i, a) => {
            if(!r[name]) {
              r[name] = {result: []};
              if(name.length)
                r.result.push({path:"/" + path, name, children: r[name].result});
            }
        return r[name];
      }, level);
    });
    return result;
};


class FilterBarTeamFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, teamFilter: [], currentlySelected: [], teamFilterAnchor: null, benchmark: false };
    }

    async loadTeamFilters() {
        this.props.onLoading(true);
        const teams = await teamFilterAPI.loadTeamFilters(this.props.flowtraceUser);
        this.props.onLoading(false);
        return this.setState({ loading: false, teamFilters: teams.teamFilters, teamMembers: teams.teamMembers });
    }

    handleNodeSelect(event, value) {
        if(this.props.tier === "free" || value === "/") return;
        const currentlySelected = this.state.currentlySelected;
        if(currentlySelected.includes(value[0]))
            currentlySelected.splice(currentlySelected.indexOf(value[0]), 1);
        else
            currentlySelected.push(value[0]);
        this.setState({currentlySelected});
    }

    applyNodeSelect() {
        this.setState({teamFilter: this.state.currentlySelected});
        if(this.state.currentlySelected.length){
            // "Team1,Team2,Team3" or "Team"
            const teamFilter = this.state.currentlySelected.length > 1 ? this.state.currentlySelected.join("|") : this.state.currentlySelected[0];
            this.props.onChange({ teamFilter });
        }
        else
            this.props.onChange({teamFilter:null});
        this.setState({teamFilterAnchor:null});
    }

    resetNodeSelect() {
        this.setState({currentlySelected: [], teamFilter:[]});
        this.props.onChange({teamFilter:null});
        this.setState({teamFilterAnchor:null});
    }

    renderTree(nodes) {
        const iconColor = this.props.tier === "free"?"disabled":"inherit";
        const tree = [];
        for(const node of nodes) {

            const icon = this.state.currentlySelected.includes(node.path) ? <CheckBox color={iconColor}/> : <CheckBoxOutlineBlank color={iconColor}/>;
            const count = this.state.teamMembers.find(m => m.team === node.path);
            const label = node.name + " (" + (count?count.members.filter(m => !m.is_deleted).length:"NA")+ ")"
            if(node.children.length)
                tree.push(<TreeItem className="m-2 pr-3" key={node.path} icon={icon} nodeId={node.path} label={label}> {this.renderTree(node.children, iconColor)} </TreeItem>);
            else
                tree.push(<TreeItem className="m-2 pr-3" color={iconColor} key={node.path} icon={icon} nodeId={node.path} label={label} />);
        }
        return tree;
    }

    componentDidMount() {
        this.loadTeamFilters();
    }


    render() {
        if (this.state.loading || this.props.loading) {
            return <LoadingButton />;
        }

        // console.log(this.state.currentlySelected.length )
        // console.log(this.state.teamFilter.length)
        const selectedText = this.state.teamFilter.length ? this.state.teamFilter.length + " teams selected" : "All teams";
        const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : "Company";
        const count = this.state.teamMembers.find(m => m.team === "/");
        const companyLabel = companyName + (count ? " (" + count.members.filter(m => !m.is_deleted).length + ")":"");
        return (
            <div style={{float:'left', marginBottom:"5px"}}>
                <ButtonGroup style={{marginTop:'7px', marginLeft: '15px'}} variant="contained" color="secondary" aria-label="split button">
                    <Button disabled={this.state.teamFilters && this.state.teamFilters.length<=1} color="secondary" onClick={(e) => {this.setState({teamFilterAnchor:e.currentTarget})}}>{selectedText}</Button>
                    <Button disabled={this.state.teamFilters && this.state.teamFilters.length<=1} color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({teamFilterAnchor:e.currentTarget})}>
                    <ArrowDropDown />
                    </Button>
                </ButtonGroup>
                <Menu id="simple-menu" width={500} anchorEl={this.state.teamFilterAnchor} keepMounted open={Boolean(this.state.teamFilterAnchor)} onClose={() => this.setState({teamFilterAnchor:null})}>
                    <TreeView multiSelect={true}

                        onNodeSelect={this.props.tier === "free"?null:this.handleNodeSelect.bind(this)}
                        expanded={["/"].concat(this.state.teamFilters)}
                        selected={this.state.currentlySelected} >
                        <TreeItem className="m-3 pr-3" key={"/"} icon={<Business  color={this.props.tier === "free"?"disabled":"inherit"}/>} nodeId={"/"} label={ companyLabel } >
                                {this.renderTree(createNodeTree(this.state.teamFilters))}
                        </TreeItem>
                    </TreeView>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                    { this.props.tier === "free" ?
                        <span style={{float:"center", marginLeft:"20px", marginRight:"25px"}} onClick={() => this.setState({teamFilterAnchor:null})} >
                            <PremiumFeature key="premiumnotify" />
                        </span>
                    :
                        [<Grid key="applyitem" item xs={6}>
                            <Button key="applybutton" size="small" variant="contained" onClick={this.applyNodeSelect.bind(this)} color="primary" className="m-2">Apply</Button>
                        </Grid>,
                        <Grid key="resetitem" item xs={6}>
                            <Button key="resetbutton" size="small" variant="contained" onClick={this.resetNodeSelect.bind(this)} className="m-2">Reset</Button>
                        </Grid>]
                    }
                    </Grid>
                </Menu>
            </div>
        );

    }
} // end of class

export default FilterBarTeamFilter;
