import { API } from 'aws-amplify';
import cache from '../apiCache';

const flowtraceAPI = 'slackintegration';
const statisticsAPIURL = '/productivity/statistics';
const initAPI = { };

async function statistics(qf, flowtraceUser) {
    try {
        const cached = cache.getItem("statistics", flowtraceUser, statisticsAPIURL, qf);
        if(cached) return cached;
        const response = await API.get(flowtraceAPI, statisticsAPIURL, {...initAPI, queryStringParameters: { ...qf }});
        cache.setItem("statistics", flowtraceUser, statisticsAPIURL, qf, response);
        return response;
    }
    catch (e) {
        console.error(e.message, e);
        return {exception:e, statistics:[]};
    }
}

const functions = { statistics };
export default functions;
