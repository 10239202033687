import { Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';

import Chart from 'react-apexcharts';

export default function TopicSentimentChart(data) {
  //console.log("Processing Team Chart ")

  let neutralData = []
  let negativeData = []
  let positiveData = []
  let totalData = []
  let categories = []

  for (const key in data.data) {
    const total = data.data[key].doc_count
    const positive = data.data[key].sentiment.POSITIVE || 0
    const negative = data.data[key].sentiment.NEGATIVE || 0
    totalData.push(total)
    neutralData.push(total - positive - negative)
    positiveData.push(positive)
    negativeData.push(negative)
    categories.push(key)
  }
  const totalOptions = {
    chart: { id: "total", parentHeightOffset: 0, offsetX: 0, offsetY: 0, animations: { enabled: false }, stacked: true, toolbar: { show: false }, group: 'combo', zoom: { enabled: false, } },
    dataLabels: { enabled: false },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, labels: { show: false, minWidth: 40 } },
    legend: { show: false },
    tooltip: { y: { formatter: function(value, opts) { return value } } },
    colors: ["#3244a8"],
    subtitle: {
      text: 'Collaboration Volume',
      //offsetX: 30,
      // style: {
      //   cssClass: 'font-size-sm'
      // }
    }
  };

  const chartOptions = {
    chart: { id: "sentiment", parentHeightOffset: 0, offsetX: 0, offsetY: 0, animations: { enabled: false }, stacked: true, toolbar: { show: false }, stackType: '100%', group: 'combo', zoom: { enabled: false, } },
    dataLabels: { enabled: false },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    xaxis: { type: "datetime", labels: { show: false }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, labels: { show: false, minWidth: 40 } },
    legend: { show: false },
    tooltip: { y: { formatter: function(value, opts) { let percent = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return percent.toFixed(0) + '% (' + value + ")" } } },
    colors: ['#F44336', '#B4DDEE', '#37a141'],
    subtitle: {
      text: 'Collaboration Sentiment',
      //offsetX: 30,
      // style: {
      //   cssClass: 'font-size-sm'
      // }
    }
  };

  const series = [{
      name: 'Negative',
      data: negativeData,
      type: "bar"
    },
    {
      name: 'Neutral',
      data: neutralData,
      type: "bar"
    },
    {
      name: 'Positive',
      data: positiveData,
      type: "bar"
    }
  ];
  //<Chart id="total" key="totalChart" options={totalOptions} series={[{name:"Total Activity", data: totalData, type:"bar"}]} type="bar" height="200"/>

  return (

    <Fragment>
      <Card className="card-box mb-4">
        <CardContent className="p-0">
          <div className="pt-4 pr-4 pl-4">
              <b className="font-size-lg">{data.title}</b><br/>
          </div>
              <div id="synced-charts">
                <Chart id="sentiment" key="sentimenChart" options={chartOptions} series={series} type="bar" height="200"/>
                <Chart id="total" key="totalChart" options={totalOptions} series={series} type="bar" height="200"/>
              </div>
        </CardContent>
      </Card>
    </Fragment>
  );
}
