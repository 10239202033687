import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Welcome from '../../flowtrace-components/Install/welcome';
import Trial from '../../flowtrace-components/Install/trial';
import ChromeCost from '../../flowtrace-components/Install/chrome1_CostConfigure';
import ChromeConfigure from '../../flowtrace-components/Install/chrome2_ExtensionConfigure';
import ChromePayment from '../../flowtrace-components/Install/chrome3_Payment';
import GoogleScope from '../../flowtrace-components/Install/google1_Scope';
import GoogleInstall from '../../flowtrace-components/Install/google3_Install';
import GoogleSettings from '../../flowtrace-components/Install/google2_Settings';
import SlackScope from '../../flowtrace-components/Install/slack1_Scope';
import SlackInstall from '../../flowtrace-components/Install/slack2_Install';
import SlackIntroduction from '../../flowtrace-components/Install/slack4_Introduction';
import SlackSurvey from '../../flowtrace-components/Install/slack3_Survey';
import SlackComplete from '../../flowtrace-components/Install/slack5_Complete';
import SlackProcessing from '../../flowtrace-components/Install/slack_Processing';
import StepWizard from "react-step-wizard";
import { OnboardingLeftSidebar } from '../../layout-blueprints';
import { Switch } from 'react-router-dom';
import queryString from 'query-string';
import { Cache } from 'aws-amplify';
import commonAnalytics from "../../commonAnalytics";

class Onboarding extends Component {
  constructor(props) {
    super(props);

    // Read the user's previous input values from cache (if they exist). They have installed the app on slack's website so wont have state stored when they come back as a redirect.
    const wizardState = Cache.getItem("installWizardState");
    const { pathname } = this.props.location;

    if (wizardState)
      this.state = { currentStep: { activeStep: 1 }, instance: null, ...wizardState, processing: pathname === "/Processing" ? true : false };
    else
      this.state = { currentStep: { activeStep: 1 }, instance: null };
  }

  getWizardState() {
    const { wizardType, channel, survey, introductionChannel, introduction, introductionValid, installationInfo, introductionSent, backfill, slackInstalled, selectedChannels, accountType, session, joinAll, autoJoin, stripe, ...rest } = this.state;
    rest.supressWarning = "supressWarning";
    return { wizardType, channel, survey, introductionChannel, introduction, introductionValid, installationInfo, introductionSent, backfill, slackInstalled, selectedChannels, accountType, session, joinAll, autoJoin, stripe };
  }

  // Stores (cache + state) the wizard state data across the flow:
  updateSettings(value) {

    // Remove the onboarding option cache and set the state to null
    if (value === "onboardingComplete") {
      Cache.removeItem("installWizardState");
      commonAnalytics.track('onboarding', {complete:true});
      return this.setState({ wizardState: null });
    }

    const wizardState = this.getWizardState();
    Cache.setItem("installWizardState", { ...wizardState, ...value });
    this.setState({ ...value });
  }

  stepChange(stats) {
    this.setState({ currentStep: stats });
  }

  wizardInstance(instance) {
    this.setState({ instance });
    const qp = queryString.parse(this.props.location.search);

    if(!qp) return;
    if (qp.success && qp.type === "slack") {
      // Store installation parameters to state
      this.updateSettings({ slackInstalled: qp });
      instance.goToNamedStep("install");
    }
    if (qp.type === "stripe") {
      // Store installation parameters to state
      this.updateSettings({ stripe:qp });
      if(this.state.wizardType === "chrome")
        instance.goToNamedStep("chrome-payment");
      else
        instance.goToNamedStep("trial");
    }
    if (qp.type === "chrome") {
      // Store installation parameters to state
      this.updateSettings({ wizardType: "chrome" });
      instance.goToNamedStep("chrome-cost");
    }
  }

  render() {
    const commonProps ={
      onUpdate:this.updateSettings.bind(this),
      flowtraceUser:this.props.flowtraceUser,
      settings: this.getWizardState()
    };

    let onboardingSteps = [
      { id: "welcome", label: "Welcome", component: <Welcome key="welcome-step" {...commonProps}/> },
      { id: "scopereview", type:"google", label: "Review Scopes", component: <GoogleScope key="google-scope-step" {...commonProps}/> },
      { id: "extensionCost", type:"chrome", label: "Configure Costs", component: <ChromeCost key="chrome-cost-step" stepName="chrome-cost" {...commonProps}/> },
      { id: "extensionConfig", type:"chrome", label: "Configure Appearance", component: <ChromeConfigure key="chrome-configure-step" {...commonProps}/> },
      { id: "extensionPayment", type:"chrome", label: "Team Subscription", component: <ChromePayment key="chrome-payment-step" stepName="chrome-payment" {...commonProps}/> },
      { id: "scope", type:"slack", label: "Configure Flowtrace", component: <SlackScope key="scope-step" {...commonProps}/> },
      { id: "googleinstall", type:"google", label: "Install Meeting Analytics", component: <GoogleInstall stepName="install" key="install-step" {...commonProps}/> },
      { id: "googlesettings", type:"google", label: "Customize Cost Estimates", component: <GoogleSettings stepName="settings" key="settings-step" {...commonProps}/> },
      { id: "install", type:"slack", label: "Install Flowtrace", component: <SlackInstall stepName="install" key="slack-install-step" {...commonProps}/> },
      { id: "survey", type:"slack", label: "Survey (Optional)", component: <SlackSurvey key="survey-step" {...commonProps}/> },
      { id: "intro", type:"slack", label: "Introduction", component: <SlackIntroduction key="introduction-step" {...commonProps}/> },
      { id: "trial", label: "Review your plan", component: <Trial stepName="trial" key="trial-step" {...commonProps}/> },
      { id: "complete", type:"slack", label: "Complete", component: <SlackComplete key="complete-step" {...commonProps} /> }
    ];

    // This is one processing page show now:
    if (this.state.processing)
      onboardingSteps = [{ id: "processing", label: "Preparing Account", component: <SlackProcessing key="processing-step" {...commonProps} /> }];


    // Filter out the survey in case its not needed
    if (!this.state.survey)
      onboardingSteps = onboardingSteps.filter(i => i.id !== "survey");

    // Filter out the steps based on wizard type
    if (!this.state.wizardType)
      onboardingSteps = onboardingSteps.filter(i => i.id === "welcome");
    if (this.state.wizardType === "outlook")
      onboardingSteps = onboardingSteps.filter(i => i.id === "welcome");
    if (this.state.wizardType === "slack")
      onboardingSteps = onboardingSteps.filter(i => !i.type || i.type === "slack");
    if (this.state.wizardType === "google")
      onboardingSteps = onboardingSteps.filter(i => !i.type || i.type === "google");
    if (this.state.wizardType === "chrome")
      onboardingSteps = onboardingSteps.filter(i => i.type === "chrome");

    // Add labels and step numbers for navigation tree "clickability":
    const navigationTree = {
      label: 'Flowtrace Install',
      content: onboardingSteps.map((i, index) => { return { label: i.label, step: index + 1 } })
    };

    if (this.state.processing) {
      return (
        <OnboardingLeftSidebar flowtraceUser={this.props.flowtraceUser} navi={navigationTree} wizard={this.state.instance} currentStep={this.state.currentStep} key="sidebar" >
        <Switch>
          {onboardingSteps.map(i => i.component)}
        </Switch>
      </OnboardingLeftSidebar>
      );
    }
    return (
      <OnboardingLeftSidebar flowtraceUser={this.props.flowtraceUser} navi={navigationTree} wizard={this.state.instance} currentStep={this.state.currentStep} key="sidebar" >
        <Switch>
          <StepWizard style={{height:"100%", width:"100%"}} instance={this.wizardInstance.bind(this)} onStepChange={this.stepChange.bind(this)} isHashEnabled={false}>
            {onboardingSteps.map(i => i.component)}
          </StepWizard>
        </Switch>
      </OnboardingLeftSidebar>
    );

  }
}

export default withRouter(Onboarding);
