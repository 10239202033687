import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "@auth0/auth0-react";
import { AnalyticsProvider } from 'use-analytics'
import commonAnalytics from './commonAnalytics.js'

const history = require("history").createBrowserHistory();
const config = require("./config.json");

const auth0 = {
  domain: config.AUTH0_LOGIN_DOMAIN,
  clientId: config.AUTH0_CLIENT_ID,
  redirectUri: window.location.origin,
  audience: `https://${config.AUTH0_DOMAIN}/api/v2/`,
  redirectComplete: false,
};

// This is required for cypress end to end tests to authenticate the user through backend and mimic a cookie.
if(window.location.hostname === "localhost") auth0.cacheLocation ="localstorage";

const onRedirectCallback = (appState) => {
    if(appState && appState.returnTo && appState.returnTo !== "/")
        history.push(appState.returnTo);
};

ReactDOM.render(
    <AnalyticsProvider instance={commonAnalytics}>
        <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} redirectUri={auth0.redirectUri} audience={auth0.audience} scope={auth0.scope} cacheLocation={auth0.cacheLocation} onRedirectCallback={ onRedirectCallback } >
            <App auth0Config={auth0}/>
        </Auth0Provider>
    </AnalyticsProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
