import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Loading from '../../../../flowtrace-components/Loading';
import { Grid } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DailyActivityChart from './dailyActivityChart';
import SlackPermissions from './installSlackSelector';
import slackBot from '../../../../flowtrace-api/Slack/slackBotActions';
import IncludedChannels from './includedChannels';
import ExcludedChannels from './excludedChannels';

function toast(success){
  if(success && success.success)
  return (
    <MuiAlert key="publicMessage" severity={"success"}>
      <div className="d-flex align-items-center align-content-center">
        <span> <p><strong className="d-block">Channel settings updated!</strong></p> </span>
      </div>
    </MuiAlert>
  );
  if(success && success.message && success.message.includes("missing_scope"))
  return (
    <MuiAlert key="publicMessage" severity={"warning"}>
      <div className="d-flex align-items-center align-content-center">
        <span>
          <p><strong className="d-block">We couldn't complete the process:</strong></p>
          <p>Please re-install your Slack integration with the latest permissions below to enable this feature.</p>
        </span>
      </div>
    </MuiAlert>
  );
  return (
    <MuiAlert key="publicMessage" severity={"error"}>
      <div className="d-flex align-items-center align-content-center">
        <span>
          <p><strong className="d-block">Something went wrong. We couldn't complete the process:</strong></p>
          {success && success.message ?
            <p>{success.message}</p>
            :
            null
          }
        </span>
      </div>
    </MuiAlert>
  );
}

const tooManyChannels = () => {
  return (
    <MuiAlert key="publicMessage" severity={"warning"}>
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block">You have reached your plan's channel limit (5)</strong>
          <p> Please remove Flowtrace bot from a channel before you try to add it to another channel.</p>
        </span>
      </div>
    </MuiAlert>
  );
};

const joinedChannelMessage = (count) => {
  return (
    <MuiAlert key="publicMessage" severity={"success"}>
      <div className="d-flex align-items-center align-content-center">
        <span> <strong className="d-block">You joined {count} channels!</strong> </span>
      </div>
    </MuiAlert>
  );
};

const getAccountType = (user) => {
    return user && user.account && user.account.props.type ? user.account.props.type : "free";
};


class SlackBotInstallation extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadRecentEvents() {
    const flowtraceAPI = 'slackintegration';
    const slackDashboardPath = '/slack/installed/dashboard';
    const callHeaders = { headers: {}, response: true };
    try{
    const recentEvents = await API.get(flowtraceAPI, slackDashboardPath, callHeaders);
    if(recentEvents.data)
      return recentEvents.data;
    } catch (e) {
      //No installation ?
    }
    return {};
  }
  async load() {
    this.setState({ loading: true });
    const [response, recentEvents] = await Promise.all([
      slackBot.listChannels(),
      this.loadRecentEvents()
      ]);
    if(recentEvents === {}){
      this.setState({ other: [], current: [], recentEvents:{}, loading: false });
    }
    const cur = response.current?response.current:[];
    const all = response.all?response.all:[];
    const privatisedChannels = recentEvents.channelMetadata?recentEvents.channelMetadata.filter(i => i.is_private):[];

    for(const privateChannel of privatisedChannels){
      const foundAll = all.find(i => i.id === privateChannel.id);
      const foundCur = cur.find(i => i.id === privateChannel.id);
      if(foundAll) foundAll.name = privateChannel.name;
      if(foundCur) foundCur.name = privateChannel.name;
    }

    const currentChannelIDs = cur.map(c => c.id);
    const otherChannels = all.filter(c => !currentChannelIDs.includes(c.id));
    this.setState({ other: otherChannels.sort((a,b) => a.name>b.name?1:-1), current: cur.sort((a,b) => a.name>b.name?1:-1), recentEvents:recentEvents.summary, loading: false });
  }

  async componentDidMount() {
    await this.load();
  }

  async leaveChannel(event) {
    this.setState({ loading: true });
    const response = await slackBot.leaveChannel(event.target.id);
    this.setState({userNotification:toast(response)});
    await this.load();
  }

  async joinChannel(event) {
    // Check if this account is limited account and has reached its limit
    const account = getAccountType(this.props.flowtraceUser);
    if(account === "free" && this.state.current.length >= 5)
      return this.setState({userNotification:tooManyChannels()});

    this.setState({ loading: true });
    const response = await slackBot.joinChannel(event.target.id);
    this.setState({userNotification:toast(response)});
    await this.load();
  }

  async joinAllChannels() {
    this.setState({ loading: true });
    let success = 0;
    for(const channel of this.state.other){
      const response = await slackBot.joinChannel(channel.id);
      if(response.success)
        success += 1;
    }
    this.setState({userNotification:joinedChannelMessage(success)});
    await this.load();
  }

  handleChange(event) {
    return this.setState({ [event.target.id]: !this.state[event.target.id] });
  }

  render() {
    if (this.state.loading)
      return  <Loading/>

    // No installation? show way to install SLACK
    if(!this.props.flowtraceUser.account.ws.SLACK)
      return (
        <Grid container spacing={4}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h5 className="card-title font-weight-bold font-size-md">Slack Integration Scopes:</h5>
                <SlackPermissions flowtraceUser={this.props.flowtraceUser} onClose={this.props.onClose} reload={this.load.bind(this)} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} lg={12}>
          { this.state.userNotification? <div className="mb-3"> { this.state.userNotification } </div> : null }

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <h5 className="card-title font-weight-bold font-size-md">Recent Slack activity:</h5>
              <DailyActivityChart data={this.state.recentEvents} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <IncludedChannels leaveChannel={this.leaveChannel.bind(this)} current={this.state.current} />
                </Grid>
                <Grid item xs={12}>
                  <ExcludedChannels accountType={getAccountType(this.props.flowtraceUser)} joinChannel={this.joinChannel.bind(this)} joinAllChannels={this.joinAllChannels.bind(this)} other={this.state.other} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <h5 className="card-title font-weight-bold font-size-md">Slack Integration Scopes:</h5>
              <SlackPermissions flowtraceUser={this.props.flowtraceUser} onClose={this.props.onClose} reload={this.load.bind(this)} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );

  }
}

export default SlackBotInstallation;
