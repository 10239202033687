import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, FormControl, Select, Grid, Link, Menu, Button, Divider } from '@material-ui/core';
import { LogoutButton } from '../../flowtrace-pages/Authentication/LogoutButton';
import Loading from '../../flowtrace-components/Loading';
import { API } from 'aws-amplify';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';

function HeaderUserbox(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(null);

  const user = props.flowtraceUser;
  const userType = user && user.flowtrace ? user.flowtrace : null;
  const handleAccountChange = async(event) => {
    // Implements the change of primary account for the user and forces relogin
    setLoading(true);
    const dataAPI = 'slackintegration';
    const statisticsPath = '/user/impersonate/' + event.target.value;
    const initAPI = { headers: {}, response: true };
    await API.patch(dataAPI, statisticsPath, initAPI);
    // Trigger reload to "login" to the new account:
    props.flowtraceUser.reload().then(() => {
      setLoading(false);
      setAnchorEl(null);
    });
  };

  // Admin or global admin can manage client accounts (create new, update properties, users, etc...)
  const changeAccounts = [];
  if (user && user.flowtraceAccounts && user.flowtraceAccounts.length)
    changeAccounts.push(<FormControl key="impersonate" className="mr-3" style={{minWidth: 200, marginLeft:"20px", marginRight:"25px", paddingTop:"10px"}}>
      <Select id="selectAccount" value={user.account ? user.account.account_id:"no-account"} onChange={handleAccountChange} >
        <MenuItem key={"key-id-no-account"} value={"no-account"}>-</MenuItem>;
        {user.flowtraceAccounts.map(a => {
          return <MenuItem key={"key-id-"+ a.account_id} value={a.account_id}>{a.account_name}</MenuItem>;
        })}
      </Select>
    </FormControl>);


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading || (props.flowtraceAccounts && props.flowtraceAccounts.reloading))
    return <div style={{paddingTop:"140px"}} ><Loading minimize/></div>;

  const userName = user ? (user.name ? user.name : user.full_name) : "";
  const accountName = user && user.account ? user.account.account_name : "";
  const accountType = user && user.account && user.account.props && user.account.props.type ? user.account.props.type : null;

  const billingLink = <Link component="button" underline="hover" variant="body2" onClick={() => props.history.push("/Account/Billing")}>Upgrade for complete analytics.</Link>;

  const profileLink = <Link component="button" underline="hover" variant="body2" onClick={() => {props.history.push("/Profile"); setAnchorEl(null);} }>View My Profile</Link>;

  return (
    <Fragment>
        <Grid container spacing={0} >
          {accountType === "free" && !window.location.pathname.includes("Onboarding") ?
            <Grid item >
              <MuiAlert key="publicMessage" severity={"info"} className="pt-0 pb-0 m-0 mt-1">
                <div className="font-size-sm"> <strong>You are on a limited plan!</strong> {billingLink} </div>
              </MuiAlert>
            </Grid>
            : null }
          <Grid item >
            <Button color="inherit" onClick={handleClick} className="px-3 text-left btn-inverse d-flex align-items-center">
              <div className="d-xl-block pl-3">
                <div className="text-capitalize font-weight-bold pr-2 line-height-1">{userName}</div>
                <div className="text-capitalize text-white-50 pt-1 pr-2 line-height-1">{accountName}</div>
              </div>
              <span className="pl-1 pl-xl-3">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </Grid>
        </Grid>

      <Menu anchorEl={anchorEl} keepMounted getContentAnchorEl={null} open={Boolean(anchorEl)} anchorOrigin={{ vertical: 'center', horizontal: 'center' }} transformOrigin={{ vertical: 'center', horizontal: 'center' }} onClose={handleClose} className="ml-2 mr-2">
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={0} >
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0} >
              <Grid item >
                <div className="font-weight-bold pt-2 pl-4 pr-4">
                    {userName}
                </div>
              </Grid>
              <Grid item >
                <div className="text-black-50 pl-4 pr-4 ">
                  {user?user.email:""}
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="w-100 mt-2" />
          {!window.location.pathname.includes("Onboarding") ?
            <Grid item xs={12}>
              <div className="text-black-50 p-4 ">
                {profileLink}
              </div>
            </Grid>
            : null }
          {(userType === "global_admin" || userType === "admin") ?<Grid item>{changeAccounts}</Grid>:null}
          <Divider className="w-100 mt-2" />

          <Grid item >
            <div className="pt-2 pl-4 pr-4">
              <LogoutButton />
            </div>
          </Grid>
        </Grid>
      </Menu>
    </Fragment>
  );
}

export default withRouter(HeaderUserbox)