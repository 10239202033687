import React, { Component } from 'react';
import { Menu, MenuItem, Button, ButtonGroup, Slider, Divider, TextField } from '@material-ui/core';
import {ArrowDropDown, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import LoadingButton from './loadingButton';
import PremiumFeature from './premiumFeature';

const weekdayOptions = {
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
    7: "Sunday",
};

const typeOptions = {
    1: "One off",
    2: "Recurring",
};

const audienceOptions = {
    1: "Internal",
    2: "External",
    3: "Group",
};

const defaultWeekdaySelected = [1, 2, 3, 4, 5, 6, 7];
const defaultTypeSelected = [1, 2];
const defaultAudienceSelected = [1, 2, 3];
const defaultLengthSelected = [0, 12*60];
class FilterBarCalendarOptions extends Component {
    constructor(props) {
        super(props);
        this.state = { textFilter:"", selected:false, lengthSelected:defaultLengthSelected, weekdaySelected:defaultWeekdaySelected, typeSelected:defaultTypeSelected, audienceSelected:defaultAudienceSelected, anchor:null };
    }

    sliderSelect(event, value) {
        this.setState({ selected:true, lengthSelected:value });
    }

    textFilterChange(event){
        this.setState({textFilter:event.target.value})
    }
    select(key, selectType) {
        if(selectType === "weekday"){
            const keyInt = parseInt(key, 10);
            const weekdaySelected = this.state.weekdaySelected.includes(keyInt) ? this.state.weekdaySelected.filter(i => i !== keyInt) : this.state.weekdaySelected.concat([keyInt]);
            this.setState({ selected:true, weekdaySelected });
        } else if(selectType === "type"){
            const keyInt = parseInt(key, 10);
            const typeSelected = this.state.typeSelected.includes(keyInt) ? this.state.typeSelected.filter(i => i !== keyInt) : this.state.typeSelected.concat([keyInt]);
            this.setState({ selected:true, typeSelected });
        } else if(selectType === "audience"){
            const keyInt = parseInt(key, 10);
            const audienceSelected = this.state.audienceSelected.includes(keyInt) ? this.state.audienceSelected.filter(i => i !== keyInt) : this.state.audienceSelected.concat([keyInt]);
            this.setState({ selected:true, audienceSelected });
        }
    }

    apply() {
        this.setState({ anchor: null });

        const stateUpdate = {};

        stateUpdate.weekdayFilter = this.state.weekdaySelected.length !== defaultWeekdaySelected.length ? this.state.weekdaySelected : null;
        stateUpdate.typeFilter = this.state.typeSelected.length !== defaultTypeSelected.length ? this.state.typeSelected : null;
        stateUpdate.audienceFilter = this.state.audienceSelected.length !== defaultAudienceSelected.length ? this.state.audienceSelected : null;

        const lengthStart = this.state.lengthSelected[0] === defaultLengthSelected[0];
        const lengthEnd = this.state.lengthSelected[1] === defaultLengthSelected[1];
        stateUpdate.lengthFilter = !lengthStart || !lengthEnd ? this.state.lengthSelected : null;
        stateUpdate.textFilter = this.state.textFilter.length ? this.state.textFilter : null;
        this.props.onChange({ ...stateUpdate });
    }
    close() {
        this.setState({ textFilter:"", selected:false, weekdaySelected:defaultWeekdaySelected, typeSelected:defaultTypeSelected, audienceSelected:defaultAudienceSelected, lengthSelected:defaultLengthSelected, anchor: null });
        this.props.onChange({ textFilter:null, weekdayFilter:null, typeFilter:null, audienceFilter: null, lengthFilter:null });
    }

    render() {

        if (this.props.loading) {
            return <LoadingButton />;
        }

        const selectedFilters = this.state.weekdaySelected.concat(this.state.typeSelected).concat(this.state.audienceSelected);
        const buttonGroup =
            <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                <Button color="secondary" onClick={(e) => this.setState({anchor:e.currentTarget})}>
                { !this.state.selected ? "More filters..." : selectedFilters.length + " filters selected"}
                </Button>
                <Button color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({anchor:e.currentTarget})}>
                    <ArrowDropDown />
                </Button>
            </ButtonGroup>;

        const selectedIcon = (key, selectType) => {
            if(selectType === "weekday")
                if(this.state.weekdaySelected.includes(parseInt(key, 10)))
                    return <CheckBox />;
                else
                    return <CheckBoxOutlineBlank />;
            else if(selectType === "type")
                if(this.state.typeSelected.includes(parseInt(key, 10)))
                    return <CheckBox />;
                else
                    return <CheckBoxOutlineBlank />;
            else if(selectType === "audience")
                if(this.state.audienceSelected.includes(parseInt(key, 10)))
                    return <CheckBox />;
                else
                    return <CheckBoxOutlineBlank />;
        };

        const freeTierButtons = [
            <Button key="close" size="small" variant="contained" onClick={() => this.setState({ weekdaySelected:defaultWeekdaySelected, typeSelected: defaultTypeSelected, audienceSelected:defaultAudienceSelected, anchor: null })} className="m-2">Close</Button>,
            <PremiumFeature key="premiumnotify" />
        ];

        const paidTierButtons = [
            <Button key="applyFilters" variant="contained" color="primary" onClick={() => { this.apply()}}>Apply</Button>,
            <Button key="resetFilters" className="ml-3" variant="outlined"  onClick={() => { this.close()}} color="secondary">Reset</Button>
            ];
        const free = this.props.tier === "free";

        const valueText = (val) => {
            if(val<60)
                return (val + "m");
            if(val%60 === 0)
                return ((val / 60 ) + "h");
            return (((val - val%60) / 60 ) + "h" + val%60 + "m");
        };
        const sliderMarks = [];
        sliderMarks.push({value:this.state.lengthSelected[0], label: valueText(this.state.lengthSelected[0])});
        if(this.state.lengthSelected[0] !== this.state.lengthSelected[1])
            sliderMarks.push({value:this.state.lengthSelected[1], label: valueText(this.state.lengthSelected[1])});


        const slider = <span style={{minWidth:"200px"}} className="pt-4 pr-1 pl-1"><Slider className="ml-" valueLabelFormat={valueText} aria-labelledby="discrete-slider-always" min={0} step={15} max={12*60} marks={sliderMarks} value={this.state.lengthSelected} valueLabelDisplay={"auto"} onChange={(e, v) => this.sliderSelect(e,v)} /></span>;
        const menu =
            <Menu id="option-menu" anchorEl={this.state.anchor} keepMounted open={Boolean(this.state.anchor)} onClose={() => this.setState({anchor:null})}>
                <MenuItem className="mt-3" key={"applyoptions"}>
                    { free ? freeTierButtons : paidTierButtons }
                </MenuItem>
                <Divider className="mt-2 mb-2"/>
                <span className="font-weight-bold pl-2" >Boolean search:</span><br />
                <TextField id="free-text" className="ml-2 mt-1" label="Query:" value={this.state.textFilter } variant="outlined" onKeyDown={(e) => e.stopPropagation()} onChange={this.textFilterChange.bind(this)}/>
                <Divider className="mt-2 mb-2"/>
                <span className="font-weight-bold pl-2" >Meeting type included:</span>
                {Object.entries(typeOptions).map(([key, value]) =>
                    <MenuItem key={"typeOptions"+key} disabled={free} onClick={() => { this.select(key, "type")}}>{selectedIcon(key, "type")} {value} </MenuItem>
                )}
                <Divider className="mt-2 mb-2"/>
                <span className="font-weight-bold pl-2 mr-5" >Meeting audience includes:</span>
                {Object.entries(audienceOptions).map(([key, value]) =>
                    <MenuItem key={"audienceOptions"+key} disabled={free} onClick={() => { this.select(key, "audience")}}>{selectedIcon(key, "audience")} {value} </MenuItem>
                )}
                <Divider className="mt-2 mb-2"/>
                <span className="font-weight-bold pl-2 mr-5" >Meeting duration filter:</span>
                <MenuItem >{slider} </MenuItem>
                <Divider className="mt-2 mb-2"/>
                <span className="font-weight-bold pl-2" >Weekdays included:</span>
                {Object.entries(weekdayOptions).map(([key, value]) =>
                    <MenuItem key={"calendarOptions"+key} disabled={free} onClick={() => { this.select(key, "weekday")}}>{selectedIcon(key, "weekday")} {value} </MenuItem>
                )}
            </Menu>;


        return (
            <div style={{float:'left', marginLeft:"5px", marginBottom:"15px", minWidth: '200px'}}>
                {buttonGroup}
                {menu}
            </div>
        );

    }
} // end of class

export default FilterBarCalendarOptions;
