import { API } from 'aws-amplify';
import cache from '../apiCache';

const flowtraceAPI = 'slackintegration';
const teamFilterAPIURL = '/slack/installed/teamFilters';

async function loadTeamFilters(flowtraceUser) {
    try {
        const cached = cache.getItem("team", flowtraceUser, teamFilterAPIURL, {});
        if(cached) return cached;
        const teams = await API.get(flowtraceAPI, teamFilterAPIURL);
        cache.setItem("team", flowtraceUser, teamFilterAPIURL, {}, teams);
        return teams;
    }
    catch (e) {
        console.error(e.message, e);
        return {teamFilters: [], teamMembers: []};
    }
}

const functions = { loadTeamFilters };
export default functions;
