import React, { Fragment } from 'react';
import { ReactComponent as FlowtraceLogo } from '../../assets/images/flowtrace_logo_only.svg';
import { Button } from '@material-ui/core';

export default function Loading(props) {
  if (props.error) {
    return (
      <Fragment>
        <div className="pt-5 pb-4">
          <div className="heading-5 heading-2-demo pb-1 mb-5 text-black-50">Oops, something did not go just right...</div>
          <div className="px-5">
            <div className="m-3 text-center font-size-lg">
              <FlowtraceLogo width="50px" height="50px" className="mr-3" />{props.errorText?props.errorText:"Please try again..."}
              <br />
              {props.onClick?
                <Button size="medium" variant="contained" color="primary" onClick={async() => props.onClick()}>Go Back</Button>
              : null }

            </div>
          </div>
        </div>
    </Fragment>
    );

  }
  if(props.tiny)
    return (
      <div className="text-center font-size-lg">
        <FlowtraceLogo width="25px" height="25px" className="rotate" />
        { props.text ? props.text : null }
      </div>);

  if(props.minimize)
    return (
      <Fragment>
        <div className="pt-5 pb-4">
          <div className="px-5">
            <div className="m-3 text-center font-size-lg">
              <FlowtraceLogo width="50px" height="50px" className="rotate mr-3" />
              { props.text ? props.text : null }
            </div>
          </div>
        </div>
    </Fragment>
  );

  return (
    <Fragment>
        <div className="pt-5 pb-4">
          <div className="px-5">
            <div className="m-3 text-center font-size-lg">
              <FlowtraceLogo width="50px" height="50px" className="rotate mr-3" />
              { props.text ? props.text : "Please wait while we crunch the numbers..." }
            </div>
          </div>
        </div>
    </Fragment>
  );
}
