import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


class CostDetail extends Component {

  render() {

    const currency = this.props.costProps ? this.props.costProps.currency : "GBP";
    if (!this.props.data)
      return;


    return (
      <Fragment >
        <MaterialTable
          title=""
          columns={[
            { title: "Name", field: 'name', sorting:false},
            { title: "Hours", field: 'hours', render: (row) => row.hours.toFixed(1) + " h", sorting:false},
            { title: "Minutes", field: 'value', sorting:false},
            { title: `Costs (${currency})`, field: 'cost', render: (row) => row.cost.toFixed(0), sorting:false},
          ]}
          data={this.props.data.map(d => {
            return {...d, hours:d.value/60, };
          })}
          icons={tableIcons}
          renderSummaryRow={ ({ data, index, column, columns }) => {
            if(column.field==="name")
              return "Total:";
            else if(column.field==="hours")
              return data.map(row => row.hours).reduce((a, b) => a + b).toFixed(1) + " h";
            else if(column.field==="value")
              return data.map(row => row.value).reduce((a, b) => a + b);
            else if(column.field==="cost")
              return data.map(row => row.cost).reduce((a, b) => a + b).toFixed(0);
          }}
          options={
            {
            showTitle:false,
            toolbar:false,
            padding:"dense",
            draggable:false,
            search:true,
            emptyRowsWhenPaging:false,
            paging:this.props.data.length>10?true:false,
            pageSize:10,
            pageSizeOptions:[10,25,50],
            }
          }
        />
        </Fragment>
    );

  } // end of render()

} // end of class

export default CostDetail;
