import React, { Fragment, useEffect } from 'react';
import { Select, FormControl, Switch, Button, Grid, Card, CardContent, Divider, Tooltip } from '@material-ui/core';
import accountReportsAPI from '../../../../flowtrace-api/Account/accountReportActions';
import Loading from '../../../../flowtrace-components/Loading';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MuiAlert from '@material-ui/lab/Alert';

const savedSuccess =
  <MuiAlert className="mb-4" severity="info" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Settings Saved.</strong>
          <p>The settings were saved successfully.</p>
      </span>
    </div>
  </MuiAlert>;

const loadFailed =
  <MuiAlert className="mb-4" severity="warning" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Failed to load the settings.</strong>
          <p>Please try again or contact our support for help.</p>
      </span>
    </div>
  </MuiAlert>;

const saveFailed =
  <MuiAlert className="mb-4" severity="warning" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Failed to save the settings.</strong>
          <p>Please try again or contact our support for help.</p>
      </span>
    </div>
  </MuiAlert>;

const reportRunSuccess =
  <MuiAlert className="mb-4" severity="info" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Meeting feedback request sent.</strong>
          <p>Example meeting feedback request was sent to you successfully. Please check your direct messages between Slack and Flowtrace bot.</p>
      </span>
    </div>
  </MuiAlert>;

const reportRunFailed = (text) => {
  return <MuiAlert className="mb-4" severity="warning" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Running the meeting feedback request failed.</strong>
          <p>Reason: {text}</p>
          <p>Please refresh the page and try again, or contact Flowtrace support for help.</p>
      </span>
    </div>
  </MuiAlert>;
};


function createTimeString(seconds) {
  // Make sure seconds are always 00:00 format (even if less than 10 hours, or 10 minutes)
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = seconds / 60 % 60
  let timeString = ""
  if (hours < 10)
    timeString = "0" + hours + ":"
  else
    timeString = hours + ":"

  if (minutes < 10)
    timeString = timeString + "0" + minutes
  else
    timeString = timeString + minutes

  return timeString
}

function parseTimeString(timeString) {
  const hoursInSeconds = timeString.split(':')[0] * 60 * 60;
  const minutesInSeconds = timeString.split(':')[1] * 60;
  let seconds = 0;
  // The component can fail in multiple ways, eg. user inputting text, or 75 minutes etc... Do some quick parsing to get the hours / minutes from the string:
  if (Number.isInteger(hoursInSeconds))
    seconds += hoursInSeconds;
  if (Number.isInteger(minutesInSeconds))
    seconds += minutesInSeconds;
  return seconds;
}

function createFrequencyString(report = {}) {
  // Combine frequence and frequency day for the value:
  let frequencyString = report.frequency ? report.frequency : "workday";
  if (report.frequency === "weekly")
    frequencyString = "weekly_" + report.frequencyDay;
  if (report.frequency === "monthly")
    frequencyString = "monthly_" + (report.frequencyDay === 1 ? "first" : "last");
  return frequencyString;
}

function parseFrequencyString(frequencyString) {
  // Get the daily, weekday, weekly, or monthly frequency and day parameter for weekly and monthly reports
  if (frequencyString.includes("weekly_"))
    return { frequency: "weekly", frequencyDay: parseInt(frequencyString.split("_")[1]) };
  else if (frequencyString === "monthly_first")
    return { frequency: "monthly", frequencyDay: 1 };
  else if (frequencyString === "monthly_last")
    return { frequency: "monthly", frequencyDay: -1 };
  else
    return { frequency: frequencyString };
}


export default function MeetingFeedbackConfiguration(props) {

  const [settingsChanged, setSettingsChanged] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [running, setRunning] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const [state, setState] = React.useState({
    type: "meetingFeedback",
    enabled: false,
    title: "",
    weekday: 1,
    frequency: "daily",
    content: 7,
    time: 0,
    changed: false,

    // Contents of the report:
    notification:true,
    duration:{min:0, max:720},
    participants:{min:0, max:100}
  });

  useEffect(() => {
    setLoading(true);
    accountReportsAPI.getReports().then(res => {
      if(res && res.length && res.find(s => s.type === "meetingFeedback"))
        setState(res.find(s => s.type === "meetingFeedback"));
      else
        console.log(res);
      setLoading(false);
    }).catch(err => {
      console.log(err);
      setNotification(loadFailed);
      setTimeout(() => { setNotification(null); }, 10000);
      setLoading(false);
    });
  }, [props.flowtraceUser]);

  const handleTimeChange = (date) => {
    setSettingsChanged(true);
    setState({ ...state, time: (date.getHours() * 60 + date.getMinutes()) * 60 });
  };

  const handleChange = (event) => {
    setSettingsChanged(true);
    switch (event.target.name) {
      case "frequency":
        setState({ ...state, ...parseFrequencyString(event.target.value) });
        break;
      case "content":
        setState({ ...state, content: parseInt(event.target.value) });
        break;
      case "weekday":
        setState({ ...state, weekday: parseInt(event.target.value) });
        break;
      case "enabled":
        setState({ ...state, enabled: event.target.checked });
        break;
      case "time":
        setState({ ...state, time: parseTimeString(event.target.value) });
        break;
        // Contents of the slack alert:
      case "notification":
        setState({ ...state, notification: event.target.checked });
        break;
      case "duration_min":
        setState({ ...state, duration: {...state.duration, min:parseInt(event.target.value, 10)} });
        break;
      case "duration_max":
        setState({ ...state, duration: {...state.duration, max:parseInt(event.target.value, 10)} });
        break;
      case "participants_min":
        setState({ ...state, participants: {...state.participants, min:parseInt(event.target.value, 10)} });
        break;
      case "participants_max":
        setState({ ...state, participants: {...state.participants, max:parseInt(event.target.value, 10)} });
        break;

      default:
        console.log("Event not recognized", event.target)
    }
  };

  const saveChanges = async (settings) => {
    setLoading(true);
    const success = await accountReportsAPI.updateReport(settings);
    setLoading(false);

    if (success) {
      setNotification(savedSuccess);
      setSettingsChanged(false);
      setTimeout(() => { setNotification(null); }, 5000);
    }
    else {
      setNotification(saveFailed);
      setTimeout(() => { setNotification(null); }, 10000);
    }

  };
  const runReport = async () => {
    setRunning(true);
    const response = await accountReportsAPI.runFeedbackReport();
    setRunning(false);
    if (response.success) {
      setNotification(reportRunSuccess);
      setTimeout(() => { setNotification(null); }, 5000);
    }
    else {
      setNotification(reportRunFailed(response && response.message ? response.message : ""));
      setTimeout(() => { setNotification(null); }, 30000);
    }
  };

  if (loading || running)
    return (
      <Fragment>
        <Card className="p-4 mb-4">
          {running ? <Loading text="Running the example request..." /> : <Loading />}
        </Card>
      </Fragment>
    );

  // Otherwise render the component with report data:
  return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-4">
            <Grid container spacing={4} direction="column" >
              <Grid item xs={12} sm={12} md={12}>
                <h5 className="mb-5">Configure automated meeting feedback collection through Flowtrace Slack app</h5>
                <p>You can set a policy to prompt meeting participants to provide feedback for meeting organizer. The feedback is either a rating or rating and writen feedback.</p>
                <p>With this feature Flowtrace will collect and show insights through meeting organizer leaderboard, meeting audit, and calendar overview pages.</p>
              </Grid>
            </Grid>
            {notification}
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <div className="font-size-md font-weight-bold mt-3 mb-2">Meeting Feedback Request Schedule:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                    Automation Enabled:
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Switch checked={state.enabled} onChange={handleChange} color="primary" name="enabled" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  How often the feedback should be requested?
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select native value={createFrequencyString(state)} onChange={handleChange} name="frequency">
                      <option value={"daily"}>Daily</option>
                      <option value={"weekly_1"}>Weekly - Mon</option>
                      <option value={"weekly_2"}>Weekly - Tue</option>
                      <option value={"weekly_3"}>Weekly - Wed</option>
                      <option value={"weekly_4"}>Weekly - Thu</option>
                      <option value={"weekly_5"}>Weekly - Fri</option>
                      <option value={"weekly_6"}>Weekly - Sat</option>
                      <option value={"weekly_7"}>Weekly - Sun</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                  <div className="font-size-md d-flex align-items-center justify-content-left pt-2">
                  What time we should collect the feedback? (GMT)
                  </div>
                </Grid>
                <Grid item xs={12} md={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                    autoOk
                    ampm={false}
                    value={Date.parse("2020-01-01T" + createTimeString(state.time) + ":00.000+00:00")}
                    onChange={handleTimeChange}
                    format={"HH:mm (+00:00)"}
                    name="time"
                    minutesStep={60}
                    views={["hours"]}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Grid >
            <Grid item xs={12} md={6}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div className="font-size-md font-weight-bold mb-3">Meeting Duration Rules:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Tooltip title="Don't ask feedback from meetings which are scheduled to run less than this (i.e. use to exclude short meetings like Daily Scrum)">
                    <div className="font-size-md d-flex align-items-center justify-content-left pt-2"> Minimum duration </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select className="mb-4" native value={state.duration.min} onChange={handleChange} name="duration_min">
                      <option value={0}>No minimum</option>
                      <option value={15}>{">"} 15 minutes</option>
                      <option value={30}>{">"} 30 minutes</option>
                      <option value={45}>{">"} 45 minutes</option>
                      <option value={60}>{">"} 1 hour</option>
                      <option value={120}>{">"} 2 hours</option>
                      <option value={180}>{">"} 3 hours</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Tooltip title="Don't ask feedback from meetings which are scheduled to run longer than this (i.e. use to exclude long events like 'Offsite meeting')">
                      <div className="font-size-md d-flex align-items-center justify-content-left pt-2">Maximum duration</div>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select className="mb-4" native value={state.duration.max} onChange={handleChange} name="duration_max">
                      <option value={720}>{"<"} 12 hours</option>
                      <option value={480}>{"<"} 8 hours</option>
                      <option value={360}>{"<"} 6 hours</option>
                      <option value={240}>{"<"} 4 hours</option>
                      <option value={120}>{"<"} 2 hours</option>
                      <option value={60}>{"<"} 1 hour</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div className="font-size-md font-weight-bold mb-3">Meeting Participation Rules:</div>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Tooltip title="Don't ask feedback from meetings which have less than this accepted internal participant (i.e. use to exclude 'Catch ups' between 2 people)">
                    <div className="font-size-md d-flex align-items-center justify-content-left pt-2"> Minimum internal attendance </div>
                  </Tooltip>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select className="mb-4" native value={state.participants.min} onChange={handleChange} name="participants_min">
                      <option value={2}>2 participants</option>
                      <option value={3}>3 participants</option>
                      <option value={4}>4 participants</option>
                      <option value={5}>5 participants</option>
                      <option value={7}>7 participants</option>
                      <option value={10}>10 participants</option>
                      <option value={20}>20 participants</option>
                      <option value={30}>30 participants</option>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={8}>
                    <Tooltip title="Don't ask feedback from meetings which have more than this accepted internal partcipants (i.e. use to exclude big events like 'Company all hands')">
                      <div className="font-size-md d-flex align-items-center justify-content-left pt-2"> Maximum internal attendance</div>
                    </Tooltip>
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl >
                    <Select className="mb-4" native value={state.participants.max} onChange={handleChange} name="participants_max">
                      <option value={0}>No maximum</option>
                      <option value={100}>100 participants</option>
                      <option value={75}>75 participants</option>
                      <option value={50}>50 participants</option>
                      <option value={40}>40 participants</option>
                      <option value={30}>30 participants</option>
                      <option value={20}>20 participants</option>
                      <option value={10}>10 participants</option>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid >
            </Grid >

            <Divider className="my-4" />
            <Button disabled={!settingsChanged} onClick={async() => await saveChanges(state)} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Save Settings</Button>
            <Tooltip title="Flowtrace bot will send you an example message based on your calendar and configuration as per the current meetings in your calendar. Make sure to test the request with time frame you have internal multi-participant meetings booked.">
              <div>
                <Button disabled={settingsChanged} onClick={async() => await runReport()} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Send Example Request Now</Button>
              </div>
            </Tooltip>
          </Grid>
          </CardContent>
        </Card>
      </Fragment>
  );
}
