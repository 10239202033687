import React, { Component, Fragment, forwardRef } from 'react';
import { Search, FirstPage, LastPage, Clear, ChevronRight, ChevronLeft, ArrowDownward } from '@material-ui/icons';
import MaterialTable from '@material-table/core';
import { ExampleWrapperSeamless } from '../../../layout-components';
import Loading from '../../../flowtrace-components/Loading';

const tableIcons = {
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
};

const tableOptions = {
  padding: "dense",
  draggable: false,
  headerStyle: { height: 100 },
  pageSize: 15,
  pageSizeOptions: [15, 50],
  doubleHorizontalScroll: true,
};

const rotateHeader = {
  WebkitTransform: 'rotate(-90deg)',
  width: 80,
  position: "absolute"
};

class KPITable extends Component {

  render() {
    if (this.props.loading)
      return <Loading minimize/>;

    if (!this.props.reportData)
      return <Loading error/>;

    // Process the data for row and column purposes:
    const metricRows = [];
    for (const metricRow of this.props.reportData) {
      const row = { category: metricRow.category, metric: metricRow.metric, columns: {} };
      for (const value of metricRow.data ? metricRow.data : []) {
        row.columns[value.date] = value.value;
      }
      metricRows.push(row);
    }

    // Create unique columns and dates for the material-table:
    const uniqueDates = [...new Set(this.props.reportData.reduce((acc, cur) => {
      return acc.concat(cur.data.map(i => i.date));
    }, []))].sort();

    const tableColumns = [
      { title: "Category", field: "category", defaultSort: "asc" },
      { title: "Metric", field: "metric", defaultSort: "asc" }
    ];

    for (const date of uniqueDates) {
      tableColumns.push({ title: <div style={rotateHeader}>{date}</div>, field: "columns." + date, emptyValue: "0" });
    }

    return (
      <Fragment>
          <ExampleWrapperSeamless hidden="true" sectionHeading="All Flowtrace KPIs">
           <MaterialTable
              columns={tableColumns}
              data={metricRows}
              icons={tableIcons}
              title="KPIs"
              options={tableOptions}
            />
          </ExampleWrapperSeamless>
        </Fragment>
    );
  }
}

export default KPITable;
