import { Menu, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LoadingButton from './loadingButton';

import React, { Component } from 'react';

const periodOptions = {
    "day": "Daily frequency",
    "week": "Weekly frequency",
    "month": "Monthly frequency",
    "quarter": "Quarterly frequency"
};

class FilterBarPeriodOptions extends Component {
    constructor(props) {
        super(props);
        // Find the component's desired default value from queryFilters, or set to component's default:
        let periodDefault = "month"
        if (props.defaults && props.defaults.range_period && Object.keys(periodOptions).includes(props.defaults.range_period))
            periodDefault = props.defaults.range_period
        this.state = { loading: true, periodOption: periodDefault, periodOptionAnchor: null };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    selectionChanged(key) {
        this.setState({ periodOption: key, periodOptionAnchor: null })
        this.props.onChange({ range_period: key })
    }

    render() {
        if (this.props.loading) {
            return <LoadingButton />;
        }

        return (
            <div style={{float:'left', marginBottom:"15px" }}>
                <ButtonGroup style={{marginTop:'7px', marginLeft: '15px'}} variant="contained" color="secondary" aria-label="split button">
                    <Button color="secondary" onClick={(e) => this.setState({periodOptionAnchor:e.currentTarget})}>{periodOptions[this.state.periodOption]}</Button>
                    <Button color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({periodOptionAnchor:e.currentTarget})}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Menu id="periodOptions-menu" anchorEl={this.state.periodOptionAnchor} keepMounted open={Boolean(this.state.periodOptionAnchor)} onClose={() => this.setState({periodOptionAnchor:null})}>
                    {
                        Object.entries(periodOptions).map(([key, value]) =>
                            <MenuItem key={"periodOptions"+key} onClick={() => { this.selectionChanged(key)   }}>{value}</MenuItem>
                            )
                    }
                </Menu>
            </div>
        );

    }
} // end of class

export default FilterBarPeriodOptions;
