/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:f47e7746-c602-47ae-b072-35aa815d4034",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_BsY6xJKc3",
    "aws_user_pools_web_client_id": "6vrnhqdg7la627jng8fb270lgr",
    "oauth": {
        "domain": "oauth-production.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.flowtrace.io/Login/,https://dev.flowtrace.io/Login/",
        "redirectSignOut": "https://app.flowtrace.io/,https://dev.flowtrace.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_mobile_analytics_app_id": "10c1a3a14c61419596eb80e1f795d261",
    "aws_mobile_analytics_app_region": "eu-west-1",
    "aws_user_files_s3_bucket": "client-slack-event-data72731-production",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "slack-connection-strings-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "eventData-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "slackMetadata-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "userStatistics-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "users-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "collaborationEvents-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "clientAccounts-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "clientMembers-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "securityTokens-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "surveyResponses-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "flowtraceBusinessAnalytics-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "webhookPayload-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "webhookCollaborationEvents-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "metrics-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "meetings-production",
            "region": "eu-west-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "slackintegration",
            "endpoint": "https://izsnleauh6.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "flowtrace-20200624123715-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "http://flowtrace-20200624123715-hostingbucket-production.s3-website-eu-west-1.amazonaws.com"
};


export default awsmobile;
