import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import UserChart from './UserChart';
import TeamChart from './TeamChart';
import Loading from '../Loading'


class UserStatisticsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, timeSpan: "week" };
  }


  async loadUserStatisticsData(timeSpan) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/flightRisk';
    const initAPI = { headers: {}, response: true, queryStringParameters: { timeSpan: timeSpan } };

    let slackSentimentData = await API.get(dataAPI, statisticsPath, initAPI).then((response, data, body) => {

      if (!response.data.statistics) {
        console.error("No statistics data");
        return null
      }
      return response.data.statistics;
    }).catch(error => {
      console.error(error);
      return null
    });

    return slackSentimentData; // return it for storing into state
  }

  async reloadData(timeSpan) {
    if (this.state.timeSpan === timeSpan) return
    this.setState({ loading: 'true', timeSpan: timeSpan });
    await this.loadUserStatisticsData(timeSpan).then((statisticsData) => {
      if (statisticsData) {
        this.setState({ userData: statisticsData.users })
      }
      this.setState({ loading: 'false' });
    });
  }

  async componentDidMount() {
    this.setState({ loading: 'true' });
    await this.loadUserStatisticsData(this.state.timeSpan).then((statisticsData) => {
      if (statisticsData) {
        this.setState({ userData: statisticsData.users })
      }
      this.setState({ loading: 'false' });
    });
  }

  render() {
    if (this.state.loading === 'true') {
      return <Loading/>;
    }
    //console.log(this.state.userData)
    if (this.state.loading === 'false' && this.state.userData === null) {
      return ("Ooops, something went wrong..")
    }
    if (this.state.loading === 'false') {
      const userStatistics = []
      //console.log(this.state.userData)
      for (const key in this.state.userData) {
        userStatistics.push(<UserChart key={"user"+key} timeSpan={this.state.timeSpan} user={this.state.userData[key]}  />)
      }

      return (
        <Fragment>
          <Grid container spacing={4}>
            <TeamChart key={"teamchart"} timeSpan={this.state.timeSpan} reload={this.reloadData.bind(this)} user={this.state.userData}  />
                        {userStatistics}

          </Grid>
        </Fragment>
      ); // end of return

    } // end or IF
    return <Loading/>; // Just in case return to render something
  } // end of render()

} // end of class

export default UserStatisticsOverview
