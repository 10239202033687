import React, { Fragment, useState, useEffect } from 'react';
import { Button, DialogActions, Container } from '@material-ui/core';

import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import { FormControlLabel, FormGroup, Checkbox, Grid } from '@material-ui/core';

import Loading from '../../../../flowtrace-components/Loading';
import Auth0Button from '../Auth0Button';
import Oauth2 from './oauth2Install';

const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-0 pb-0 mt-3">
      {message}
    </MuiAlert>
  );
};


export default function GitlabConfigureModal(props) {

    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reload, setReload] = useState(false);
    const [projectsFetched, setProjectsFetched] = useState(false);
    
    const [message, setMessage] = useState("");

    const handleSubmit = async (e) =>{
      const accountSub = props.flowtraceUser.account.workspaces.find(w => w.type === 'GITLAB');

      const webhooks = projects.filter(p => p.webhookAdd);
      if (webhooks.length > 0) {
          const path = `/gitlab/webhooks/${accountSub.userId}/${props.flowtraceUser.account.account_id}`;
          const body = {projectIds: webhooks.map(w => w.id)};
          const initAPI = { headers: {}, response: true, body };
          const webhookData = await API.post('slackintegration', path, initAPI).then(res => res.data);
  
          for (let currentProject of webhooks) {
            const [currentWebhook] = webhookData.filter(w => w.projectId === currentProject.id); 
            currentProject.flowtrace_webhook_id = currentWebhook.webhook.id;
            setProjects(projects => projects.map((p) => (p.id === currentProject.id ? currentProject : p)));
          }
      }
      
      const webhooksToRemove = projects.filter(p => p.webhookRemove);
      if (webhooksToRemove.length > 0) {
          for (let currentProject of webhooksToRemove) {
            const path = `/gitlab/webhooks/${accountSub.userId}/${currentProject.id}/${currentProject.flowtrace_webhook_id}`;
            await API.del('slackintegration', path, { headers: {}, response: true });
            currentProject.flowtrace_webhook_id = 0;
            setProjects(projects => projects.map((p) => (p.id === currentProject.id ? currentProject : p)));
          }
      }

      setMessage(createAlert("success","Webhooks successfully updated."));
      
      return true;

    };

    const generateProject = (item, index) => {
      const checkbox = <Checkbox
                  key={'project-checkbox-' + index}
                  checked={isWebhookEnabled(item)}
                  onClick={e => handleProjectChange(e, item)}
                />;
      return <FormControlLabel
                key={'projects-list-' + index}
                control={checkbox}
                label={item.name_with_namespace} />;
    };  

    const handleProjectChange = (e, project) => {
      if (e.target.checked) {
        if (!project.flowtrace_webhook_id) {
          project.webhookAdd = true;  
        }
        
        project.webhookRemove = false;

      } else {
        project.webhookRemove = true;
        project.webhookAdd = false;
      }
      setProjects(projects => projects.map((p) => (p.id === project.id ? project : p)));
    };

    const isWebhookEnabled = (project) => {
      const webhookId = parseInt(project.flowtrace_webhook_id) || 0;
      const isEnabled = (webhookId > 0 && !project.webhookRemove) || (webhookId === 0 && project.webhookAdd);
      return isEnabled || false;
    };
  
  
  useEffect(() => {

 
    const getGitlabProjects = async () => {
      const accountSub = props.flowtraceUser.account.workspaces.find(w => w.type === 'GITLAB');
      if (!accountSub) {
        return [];
      }
      
      const result = await API.get(
        'slackintegration', 
        `/gitlab/projects/${accountSub.userId}`, 
        { headers: {}, response: true }
      );
      return result.data;
    };
    setLoading(true);
    getGitlabProjects()
      .then(res => {
        setProjects(res);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setProjectsFetched(true);
        setMessage(createAlert("error","Something went wrong!"));
      });
  }, [props.flowtraceUser]);

  
  const accountSub = props.flowtraceUser.account.workspaces.find(w => w.type === 'GITLAB');
   if (loading) {

  }      

  const authError = (error) => {
    console.log("authError", error);
    setMessage(createAlert("error", "We couldn't complete the verification."));

    if(error.original === "User closed the popup window")
      props.onInstall(false, false, "You didn't complete the integration.");
    else
      props.onInstall(false, false, error.description ? error.description:null); // Tell the page we got a failure
    setLoading(false);

  };

  const authSuccess = (profile) => {
    setLoading(true);

    Oauth2(profile.sub).then(data => {
      if(data && data.success) {
        setMessage(createAlert("success", "Installation succeeded."));
        props.openDialog();
        props.flowtraceUser.reload().then(() => { 
          setReload(true);
        });       
      } else {
        setMessage(createAlert("warning", "Something didn't go right."));
      }
      props.onInstall(data.success);
    }).finally(() => {
      setLoading(false);
    });
  };
  
  const isSubscribeEnabled = () => {
  
    return projects.filter(p => p.webhookAdd).length === 0 && projects.filter(p => p.webhookRemove).length === 0;
  };
  
  if (reload) {
    setReload(false);
    setLoading(true);
  }
  
  const install = <Auth0Button loading={loading} cbProfile={authSuccess} cbError={authError} reinstall={props.existing} connection={"gitlab"} />;

  const dialogButtons =
    <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
      <Button 
        onClick={() => handleSubmit()} 
        disabled={loading || isSubscribeEnabled() } 
        variant="contained" color="primary" size="large"> Subscribe webhooks </Button>
      {install}
      {props.cancelButton}
    </DialogActions>;

  if(!props.existing && !accountSub)
    return install;

  if (loading)
    return <Fragment> <Loading text="Loading webhook information from Gitlab"/> {dialogButtons} </Fragment>;

  let emptyProjectsPlaceholder;
  if (projectsFetched) {
    emptyProjectsPlaceholder = <h6>You don't have any GitLab projects.</h6>;
  } else {
    emptyProjectsPlaceholder = <Loading text="Loading webhook information from Gitlab"/>;
  }
  return (
    <Fragment>
      <Container>
        <Grid container spacing={2}>
          <Grid item md={12} >
            { projects.length > 0 ? (
                <div>
                  <p>Select the projects to subscribe to the Flowtrace webhook:</p>
                  <FormGroup>
                    { projects.map((item, index)=>{
                        return generateProject(item, index)
                      })
                    }
                  </FormGroup>
                </div>
              ) : (emptyProjectsPlaceholder)
            }
          </Grid>
      </Grid>
        {message}

        {dialogButtons}
      </Container>
    </Fragment>
  )
}