import React, { Component } from 'react';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { CheckBox, CheckBoxOutlineBlank, Business } from '@material-ui/icons';
import { Menu, Grid, Select, MenuItem  } from '@material-ui/core';
import teamFilterAPI from '../../../../flowtrace-api/dataAPI/teamFilters';

const createNodeTree = (paths) => {
    let result = [];
    let level = {result};

    paths.forEach(path => {
        path = path.replace("/", ""); // replace first sladh away
        path.split('/').reduce((r, name, i, a) => {
            if(!r[name]) {
              r[name] = {result: []};
              if(name.length)
                r.result.push({path:"/" + path, name, children: r[name].result});
            }
        return r[name];
      }, level);
    });
    return result;
};


class FilterBarTeamFilter extends Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, teamFilter: [], currentlySelected: this.props.team?this.props.team:"", teamFilterAnchor: null, benchmark: false };
    }


    async loadTeamFilters() {
        const teams = await teamFilterAPI.loadTeamFilters(this.props.flowtraceUser);
        return this.setState({ loading: false, teamFilters: teams.teamFilters, teamMembers: teams.teamMembers });
    }

    handleNodeSelect(event, value) {
        if(this.props.tier === "free" || value === "/") return;
        this.setState({currentlySelected : value[0], teamFilterAnchor:null});
        this.props.onTeamChange(value[0], this.props.index);
    }


    renderTree(nodes) {
        const iconColor = this.props.tier === "free"?"disabled":"inherit";
        const tree = [];
        for(const node of nodes) {

            const icon = this.state.currentlySelected.includes(node.path) ? <CheckBox color={iconColor}/> : <CheckBoxOutlineBlank color={iconColor}/>;
            const count = this.state.teamMembers.find(m => m.team === node.path);
            const label = node.name + " (" + (count?count.members.filter(m => !m.is_deleted).length:"NA")+ ")"
            if(node.children.length)
                tree.push(<TreeItem className="m-2 pr-3" key={node.path} icon={icon} nodeId={node.path} label={label}> {this.renderTree(node.children, iconColor)} </TreeItem>);
            else
                tree.push(<TreeItem className="m-2 pr-3" color={iconColor} key={node.path} icon={icon} nodeId={node.path} label={label} />);
        }
        return tree;
    }

    componentDidMount() {
        this.loadTeamFilters();
    }


    render() {
        if (this.state.loading || this.props.loading) {
            return "Loading";
        }

        //console.log(this.state.currentlySelected )
        // console.log(this.state.teamFilter.length)
        const selectedText = this.state.currentlySelected.length ? this.state.currentlySelected : "Select team";
        const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : "Company";
        const count = this.state.teamMembers.find(m => m.team === "/");
        const companyLabel = companyName + (count ? " (" + count.members.filter(m => !m.is_deleted).length + ")":"");
        return (
            <div >
                <Select value={selectedText} open={false} displayEmpty variant="outlined" autoWidth={true} onOpen={(e) => { this.setState({teamFilterAnchor:e.currentTarget})}}>
                    <MenuItem value={selectedText} disabled>{selectedText}</MenuItem>
                </Select>

                <Menu id="simple-menu" width={500} anchorEl={this.state.teamFilterAnchor} keepMounted open={Boolean(this.state.teamFilterAnchor)} onClose={() => this.setState({teamFilterAnchor:null})}>
                    <TreeView multiSelect={true}

                        onNodeSelect={this.props.tier === "free"?null:this.handleNodeSelect.bind(this)}
                        expanded={["/"].concat(this.state.teamFilters)}
                        selected={this.state.currentlySelected} >
                        <TreeItem className="m-3 pr-3" key={"/"} icon={<Business  color={this.props.tier === "free"?"disabled":"inherit"}/>} nodeId={"/"} label={ companyLabel } >
                                {this.renderTree(createNodeTree(this.state.teamFilters))}
                        </TreeItem>
                    </TreeView>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                    { this.props.tier === "free" ?
                        <span style={{float:"center", marginLeft:"20px", marginRight:"25px"}} onClick={() => this.setState({teamFilterAnchor:null})} >
                            <div>Premium</div>
                        </span>
                    :
                        null
                    }
                    </Grid>
                </Menu>
            </div>

        );

    }
} // end of class

export default FilterBarTeamFilter;
