import React, { Fragment } from 'react';
import Chart from 'react-apexcharts';

export default function DailyActivityChart(data) {

  if (!data.data || !data.data.days) {
    return (<Fragment> <div className="pt-4 pr-4 pl-4">No Recent Collaboration Activity</div> </Fragment>);
  }

  let activityData = []
  let categories = []
  for (const key in data.data.days) {
    const total = data.data.days[key].doc_count
    activityData.push(total)
    categories.push(key)
  }

  const chartOptions = {
    chart: { toolbar: { show: false }, stacked: true },
    dataLabels: { enabled: false },
    stroke: { color: '#4191ff', curve: 'smooth', width: 4 },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { show: false, min: 0 },
    legend: { show: true },

  };

  const series = [{
    name: 'Activity',
    data: activityData
  }];

  return (
    <Fragment>
      <Chart options={chartOptions} series={series} type="area" height="200"/>
    </Fragment>
  );
}
