import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import SurveySettings from '../../../flowtrace-components/Configuration/Survey';
import Loading from '../../../flowtrace-components/Loading'
import { API } from 'aws-amplify';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from "react-router-dom";
import { Button } from '@material-ui/core';
import testSlackScope from '../slackSurveyScope';

class ConfigurationControls extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadSurveySettings() {
    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    if (!account)
      return null;
    const flowtraceAPI = 'slackintegration';
    const surveySettingsAPI = '/survey/account/' + account.account_id + "/" + new Date().toISOString().split('T')[0];
    const callHeaders = { headers: {}, response: true };

    const surveySettings = await API.get(flowtraceAPI, surveySettingsAPI, callHeaders).then((response, data, body) => {
      return response.data.survey;
    }).catch(error => { console.error(error); });
    return surveySettings;
  }

  async reloadSettings() {
    if (!this.state.loading)
      this.setState({ loading: true });

    const [surveySettings, surveyScope] = await Promise.all([
      this.loadSurveySettings(),
      testSlackScope()
    ]);
    this.setState({ survey: surveySettings, surveyScope, loading: false });
  }

  componentDidMount() {
    this.reloadSettings();
  }


  render() {
    if (this.state.loading) {
      return <Loading/>;
    }

    return (
      <Fragment>

          {this.props.installWizard?null:<PageTitle titleHeading="Employee Survey Configuration" titleDescription="Your employee surveys conducted through Slack" />}
          {
            this.props.flowtraceUser.account && (this.props.installWizard || this.state.surveyScope)?
              <SurveySettings flowtraceUser={this.props.flowtraceUser} reload={this.reloadSettings.bind(this)} survey={this.state.survey}/>
            :
              <div>
                <MuiAlert key="noScopeInstalled" className="mb-4" severity="warning">
                  <div className="d-flex align-items-center align-content-center">
                    <span>
                      <strong>Your current installation doesn't support employee surveys.</strong>
                      <p>You can install required survey scope to your Slack integration from Integrations page.</p>
                    </span>
                  </div>
                </MuiAlert>
                <Button onClick={() => {this.props.history.push("/Configuration");}  } rel="noopener" size="large" variant="contained" color="primary">Take me to Integrations</Button>
              </div>
            }
        </Fragment>
    );
  }
}

export default withRouter(ConfigurationControls);
