import { API } from 'aws-amplify';

const flowtraceAPI = 'slackintegration';
const slackAPIURL = '/slack/api/';
const callHeaders = { headers: {}, response: true };

async function listChannels() {
    try {
        const callURL = slackAPIURL + 'channels/list';
        const response = await API.get(flowtraceAPI, callURL, callHeaders);
        if(response.data.success)
            return {all: response.data.allChannels, current: response.data.currentChannels};
    }
    catch (e) {
        console.error(e.message, e);
    }
    return {all:[], current:[]};
}


async function joinChannel(channel) {
    try {
        const callURL = slackAPIURL + 'channels/join/' + channel;
        const response = await API.get(flowtraceAPI, callURL, callHeaders);
        return response.data;
    }
    catch (e) {
        console.error(e.message, e);
        return [];
    }
}
async function leaveChannel(channel) {
    try {
        const callURL = slackAPIURL + 'channels/leave/' + channel;
        const response = await API.get(flowtraceAPI, callURL, callHeaders);
        return response.data;
    }
    catch (e) {
        console.error(e.message, e);
        return [];
    }
}

async function uninstall() {
    try {
        const callURL = slackAPIURL + 'uninstall';
        const response = await API.get(flowtraceAPI, callURL, callHeaders);
        return response.data;
    }
    catch (e) {
        console.error(e.message, e);
        return [];
    }
}

const botActions = { listChannels, joinChannel, leaveChannel, uninstall };
export default botActions;
