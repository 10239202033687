import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import AccountOverview from '../../flowtrace-components/Dashboard/AccountOverview';

export default function Dashboard(props) {
  return (
    <Fragment>
      <PageTitle titleHeading="Slack Workspace Overview" titleDescription="" />

      <AccountOverview  flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
