import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import Metric from '../../flowtrace-components/Metric';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Company KPIs</h4>)
explanation.push(<p key="paragraph1">Flowtrace tracked KPIs.</p>)

export default function CompanyKPIDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Metrics Dashboard" titleDescription="Here you can see all Flowtrace connected analytics KPI's" explanation={explanation}/>
      <Metric flowtraceUser={props.flowtraceUser}/>
    </Fragment>
  );
}
