import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import { Grid, Tooltip, Card, CardContent } from '@material-ui/core';
import moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
const info = <HelpOutline style={{ fontSize: 15 }}/>;

const chartOptions = {
  chart: { toolbar: { show: false }, animations: { enabled: false } },
  dataLabels: {
    enabled: true,
    style: {
      fontSize: '12px',
    },
    formatter: function(text, op) {
      return [text];
    },
    offsetY: 0.5,
    offsetX: -0.5
  },
  plotOptions: {
    heatmap: {
      //distributed: true,
      shadeIntensity: 0.1,
      inverse: true
    }
  },
  tooltip: {
    y: {
      formatter: function(value) {
        if(value<1) return "";
        return Math.abs(value) + " invites";
      },
      title:{
        formatter: function() {return "Meeting Invites:"}
      }
    },
    z: {
      formatter: function([internal, external]) {
        const total = internal + external;
        const int = (Math.abs(internal/total) * 100).toFixed(0);
        const ext = (Math.abs(external/total) * 100).toFixed(0);

        if(total<1)
          return "";
        if(internal<1)
          return ext + "% external";
        if(external<1)
          return int + "% internal";
        return int + "% internal, " + ext + "% external";
      },
      title: "Type:"
    }
  },
};

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, dialogOpen:false, dialogContent:[] };
  }



  async componentDidMount() {
    const day = [];
    for(let i = 0 ; i <24 ; i++)
      day.push({id:i, x: (i<10?"0"+i:i) +":00", y:0, w:0, z:[0,0], meetings:[]});

    const series = [
      { id:0, name: "Monday", data:JSON.parse(JSON.stringify(day))},
      { id:1, name: "Tuesday", data:JSON.parse(JSON.stringify(day))},
      { id:2, name: "Wednesday", data:JSON.parse(JSON.stringify(day))},
      { id:3, name: "Thursday", data:JSON.parse(JSON.stringify(day))},
      { id:4, name: "Friday", data:JSON.parse(JSON.stringify(day))},
      { id:5, name: "Saturday", data:JSON.parse(JSON.stringify(day))},
      { id:6, name: "Sunday", data:JSON.parse(JSON.stringify(day))},
    ];

    const total = [
      {id:"total", x:"Total", y:0, w:0, z:[0,0], meetings:[]},
      //{id:"internal", x:"Int", y:0, meetings:[]},
      //{id:"external", x:"Ext", y:0, meetings:[]}
      ];
    const totalSeries = [
      { id:0, name: "Monday", data:JSON.parse(JSON.stringify(total))},
      { id:1, name: "Tuesday", data:JSON.parse(JSON.stringify(total))},
      { id:2, name: "Wednesday", data:JSON.parse(JSON.stringify(total))},
      { id:3, name: "Thursday", data:JSON.parse(JSON.stringify(total))},
      { id:4, name: "Friday", data:JSON.parse(JSON.stringify(total))},
      { id:5, name: "Saturday", data:JSON.parse(JSON.stringify(total))},
      { id:6, name: "Sunday", data:JSON.parse(JSON.stringify(total))},
    ];

    const localAwareMoment = (eventDate) => {
      // Make the time normal/summer time aware based on current user's timezone:
      const utcDate = moment(eventDate, "YYYY/MM/DD HH:mm:ss");
      const offset = utcDate.utcOffset();
      return utcDate.add(offset, "minutes");
    };

    for(const e of this.props.events){
      const groupInvites = e.invites.group.invites;
      const directInvites = e.invites.internal.invites;
      const externalInvites = e.invites.external.invites;
      const internalInvites = groupInvites + directInvites;
      const allInvites = internalInvites + externalInvites;

      const external = allInvites !== internalInvites;
      const event = localAwareMoment(e.eventDate);
      const start = localAwareMoment(e.eventDate).format("YYYY-MM-DD HH:mm");
      const end = localAwareMoment(e.eventDate).add(e.duration, 'minutes').format("YYYY-MM-DD HH:mm");
      const totalItem = totalSeries[event.isoWeekday() - 1].data[0];
      totalItem.y += internalInvites;
      totalItem.meetings.push({name: e.name, start, end, internalInvites, groupInvites, directInvites, externalInvites});
      totalItem.z = external?[totalItem.z[0], totalItem.z[1] + 1] : [totalItem.z[0] + 1, totalItem.z[1]];
      for(let duration = 0 ; duration < e.duration ; duration += 60){
        event.add(duration?60:0, "minutes"); // add 60 minutes for all the following loops to push the event to right slot
        const item = series[event.isoWeekday() - 1].data[event.hour()];
        item.y += internalInvites;
        item.z = external?[item.z[0], item.z[1] + 1] : [item.z[0] + 1, item.z[1]];
        item.meetings.push({name: e.name, start, end, internalInvites, groupInvites, directInvites, externalInvites});
      }
    }

    this.setState({ loading: false, heatmap:series, totalHeatmap:totalSeries });
    //this.props.timestamp("meetingHeatmap processing done")


  }


  closeDialog() {
    this.setState({dialogOpen:false});
  }
  openDialog(event, context, config) {
    const meetings = config.w.config.series[config.seriesIndex].data[config.dataPointIndex].meetings;
    const hour = config.dataPointIndex;
    const day = config.w.config.series[config.seriesIndex].name;
    const timeSlot = day + " " + (hour>9?hour:"0" + hour) + ":00";
    if(meetings.length)
      this.setState({dialogOpen:true, dialogContent:meetings, dialogTimeSlot:timeSlot});
  }

  render() {

    if (this.state.loading)
      return <div/>;

    const chartConfig = {
      ...chartOptions,
      chart:{...chartOptions.chart, events: {dataPointSelection: this.openDialog.bind(this)}}
    };

    return (
      <Fragment >
        <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
            <div className=" font-weight-bold font-size-xl text-uppercase" >When We Meet</div>
            <div className="font-weight-bold mr-3 mb-3"> All times in your local timezone: {Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={4}>
                  <Grid item xs={4} md={2}>
                    <div className="d-flex align-items-start">
                      <Tooltip title="Here you can see the meeting load at the given day across the calendars. These are total number of invites for during a give weekday and we don't use hour slot weighting on this heat map (total number will differ when you compare to all invididual hour slots on the time heat map). ">
                        <div className="font-weight-bold mr-3"> Meeting Events by Weekday  {info}</div>
                      </Tooltip>
                    </div>
                    <Chart key="totalHeatmap" options={chartConfig} series={this.state.totalHeatmap} type="heatmap" height="280"/>
                  </Grid>
                  <Grid item xs={8} md={10}>
                    <div className="d-flex align-items-start">
                      <Tooltip title="Here you can see the meeting load at the given hour across the calendars. Every hour slot is a weighted number of meeting participations for your timezone. You can click the heatmap for details about the meetings at the given time.">
                        <div className="font-weight-bold mr-3"> Internal and External Meeting Events by the Time and Weekday {info}</div>
                      </Tooltip>
                    </div>
                    <Chart key="timeheatmap" options={chartConfig} series={this.state.heatmap} type="heatmap" height="280"/>
                  </Grid>
              </Grid>
              </Grid>
            </Grid>
          </CardContent>
         </Card>
        <Dialog scroll={"paper"} fullWidth={true} maxWidth="lg" open={this.state.dialogOpen} onClose={this.closeDialog.bind(this)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Meetings @ {this.state.dialogTimeSlot} (date and time based on {Intl.DateTimeFormat().resolvedOptions().timeZone} timezone)</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                {this.state.dialogContent.sort((a, b) => a.internalInvites<b.internalInvites?1:-1).map((i, index) =>
                  <Grid key={"grid-" + index} item xs={12} sm={6} md={3}>
                    <Card className={"card-box border-0 m-0 "}>
                      <CardContent className="m-0">
                        <div className="font-weight-bold">
                          <span className=" d-block text-uppercase">{i.name}</span>
                          <small className=" d-block"><b>Start:</b> {i.start}</small>
                          <small className=" d-block"><b>End:</b> {i.end}</small>
                          <small className=" d-block"><b>Internal Invites: </b>{i.internalInvites}</small>
                          <Grid container spacing={0}>
                            <Grid key={"grida-" + index} item xs={4}>
                              <small className=" d-block"><b>Direct: </b>{i.directInvites}</small>
                            </Grid>
                            <Grid key={"gridb-" + index} item xs={4}>
                              <small className=" d-block"><b>Group: </b>{i.groupInvites}</small>
                            </Grid>
                            <Grid key={"gridc-" + index} item xs={4}>
                              <small className=" d-block"><b>External: </b>{i.externalInvites}</small>
                            </Grid>
                          </Grid>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
              </DialogContent>
            <DialogActions>
                <Button onClick={this.closeDialog.bind(this)} color="primary"> Close </Button>
            </DialogActions>
        </Dialog>

        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
