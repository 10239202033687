import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { Component, Fragment } from 'react';
import Loading from '../../Loading'
import ChannelDetail from './channelDetail'
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

function extractStats(histogram) {
    const stats = { total: 0, positive: 0, negative: 0, neutral: 0, trend: 0 }
    const array = Object.values(histogram)

    array.reduce((acc, cur) => {
        stats.total += cur.doc_count
        if (cur.sentiment.POSITIVE) stats.positive += cur.sentiment.POSITIVE
        if (cur.sentiment.NEGATIVE) stats.negative += cur.sentiment.NEGATIVE

        acc += 1
        return acc
    }, stats)
    stats.neutral = stats.total - stats.positive - stats.negative
    if (array.length > 6) {
        const latest = array[array.length - 6]
        const before = array[array.length - 7]
        const lratio = ((latest.sentiment.POSITIVE ? latest.sentiment.POSITIVE : 0) - (latest.sentiment.NEGATIVE ? latest.sentiment.NEGATIVE : 0)) / latest.doc_count
        const bratio = ((before.sentiment.POSITIVE ? before.sentiment.POSITIVE : 0) - (before.sentiment.NEGATIVE ? before.sentiment.NEGATIVE : 0)) / before.doc_count

        stats.trend = bratio - lratio
    }

    return { total: stats.total, positive: stats.positive / stats.total, negative: stats.negative / stats.total, neutral: stats.neutral / stats.total, ratio: (stats.positive - stats.negative) / stats.total, trend: stats.trend }
}

class ChannelTable extends Component {
    constructor(props) {
        super(props);
        const channels = Object.values(props.channelData).map(i => { return { id: i.key, name: i.metadata ? i.metadata.name : "unknown", private: i.metadata ? i.metadata.is_private : true, histogram: i.sentimentHistogram, stats: extractStats(i.sentimentHistogram) } })

        this.state = { channelData: channels }
    }



    async componentDidMount() {

    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => { return; };
    }

    render() {
        if (!this.state.channelData) {
            return <Loading/>;
        }

        return (
            <Fragment>


         <MaterialTable
          columns={[
            { title: "Name", align:"left", field: 'name', defaultSort:"asc", render: (rowData => {
            if(rowData.private)
                return (<div><FontAwesomeIcon icon={['fas', 'lock']} className="pt-2 font-size-xl " /> {rowData.name}</div> )
            return (<div><FontAwesomeIcon icon={['fas', 'hashtag']} className="pt-2 font-size-xl " /> { rowData.name}</div> )

            })},
            { title: 'Total Messages', align:"center", field: 'stats.total' },
            { title: 'Positive Messages', align:"center", field: 'stats.positive', render: (rowData => { return ((rowData.stats.positive*100).toFixed(0)+" %" )})},
            { title: 'Negative Messages', align:"center", field: 'stats.negative', render: (rowData => { return ((rowData.stats.negative*100).toFixed(0)+" %" )})},
            { title: 'Channel Sentiment', align:"center", field: 'stats.ratio', render: (rowData => {
                const value = (rowData.stats.ratio*100).toFixed(0)
                let trend
                if(rowData.stats.trend>0) trend =  <span className="text-success"><FontAwesomeIcon icon={['fas', 'caret-up']} className="pt-2 font-size-xl " /></span>
                if(rowData.stats.trend<0) trend =  <span className="text-danger"><FontAwesomeIcon icon={['fas', 'caret-down']} className="pt-2 font-size-xl " /></span>

                if(value>0)
                    return (<span className="text-success"> {value + " %" } {trend} </span>)
                if(value<0)
                    return (<span className="text-danger"> {value + " %"} { trend} </span>)
                return (value+" %")}
            )},

          ]}
          data={this.state.channelData}
          icons={tableIcons}
          title="Channel Sentiment"
          options={
            {
            pageSize:10,
            pageSizeOptions:[5,10,25],
            }
          }
          detailPanel={[
          {
            render: ({rowData}) => { return ( <ChannelDetail queryFilters={this.props.queryFilters} channelData={rowData} /> ) }

          }
          ]}
          onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />


        </Fragment>
        ); // end of return

    } // end of render()

} // end of class

export default ChannelTable
