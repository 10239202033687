import React, { Component, Fragment } from 'react';
import Loading from '../../Loading';
import MeetingDistribution from './meetingDistribution';
import FilterBar from '../../Common/FilterBar';
import meetingAPI from '../../../flowtrace-api/meetingAPI';
import { Switch } from '@material-ui/core';

class MeetingOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, compare:false, searchWarning:null, queryFilters: { range_type: "calendar"} };
  }


  async loadMeetingStatistics(queryFilters) {
    this.setState({ searchWarning:null});

    const setNetworkError = (e) => {
      console.error(e);
      const searchWarning = {text:"Unkonwn search error.", tooltip:"Please contact our support if this error persists. The error we encountered: " + e.message};
      this.setState({searchWarning});
    };


    const distribution = await meetingAPI.distribution(queryFilters, this.props.flowtraceUser)

    // Error handling
    if(distribution.exception) setNetworkError(distribution.exception);

    return this.setState({loading: false, distribution});
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadMeetingStatistics(queryFilters);
  }

  componentDidMount() {
    this.loadMeetingStatistics(this.state.queryFilters);
  }

  onCompareChange(e) { this.setState({ compare:e.target.checked }); }

  componentWillUnmount() { this.setState = () => { return; }; }

  render() {
    if(this.state.loading)
    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter hidePeriod searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} />
        <Loading text={"This feature requires Google or Outlook integration. Please inquire your account manager for more details."}/>
      </Fragment>);


    const compare =
            <div>
              <Switch checked={this.state.compare} onChange={this.onCompareChange.bind(this)} color="primary" name="compare" />
              <div className="font-weight-bold mr-3">Compare with previous date range</div>
            </div>;

    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter hidePeriod fixedCalendarDates searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} injectedComponent={compare}/>
        <MeetingDistribution compare={this.state.compare} distribution={this.state.distribution} />
      </Fragment>
    );
  } // end of render()
} // end of class

export default MeetingOverview;
