import React, { Component } from 'react';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

class QuestionTable extends Component {
    render() {
        return (
         <MaterialTable
            title={this.props.mostRecent?"Most recent questions for current filters:":""}
            columns={[
            { title: 'Surveyd', align:"left", type:"date", width: 100, defaultSort:"desc", field: 'surveyDate' },
            { title: 'Question', align:"left", width:1000, field: 'text' },
            { title: 'Score', align:"right", width: 100, field: 'score', render: (rowData => { return  Math.round(rowData.score) } ) }
          ]}
          data={this.props.questions}
          icons={tableIcons}
          options={
            {
            search: false,
            tableLayout:"auto",
            fixedColumns: this.props.mostRecent ? {} : {left:1, right:1},
            toolbar:Boolean(this.props.mostRecent),
            padding:"dense",
            pageSize: this.props.mostRecent?2:5,
            pageSizeOptions: this.props.mostRecent?[2,5,10,25]:[5,10,25],
            }
          }
          />
        ); // end of return

    } // end of render()

} // end of class

export default QuestionTable
