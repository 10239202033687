const names = [
    "Ammie Mauffray",
    "Hank Ernest",
    "Clora Simons",
    "Serita Byfield",
    "Barry Brown",
    "Echo Delahanty",
    "Kanesha Kindell",
    "Dorris Atchley",
    "Yoko Halm",
    "Remona Kelson",
    "Carter Westmoreland",
    "Jovan Olivera",
    "Ashley Michel",
    "Alessandra Moten",
    "Delcie Nissen",
    "Grayce Friley",
    "Shu Belair",
    "Torri Row",
    "Julie Fallin",
    "Kenyatta Clem",
    "Shannon Berns",
    "Tiny Flythe",
    "Agnus Starcher",
    "Hertha Knecht",
    "Michel Feldman",
    "Annalisa Lucien",
    "Terry Thornburg",
    "Aretha Veselka",
    "Kathyrn Pero",
    "Bertie Bridgeforth",
    "Keturah Surface",
    "Sam Wormley",
    "Demetra Bolt",
    "Darell Criner",
    "Harris Bolanos",
    "Sharie Rosebrook",
    "Danna Frary",
    "Noriko Ballweg",
    "Benito Sommerfield",
    "Minna Harkleroad",
    "Rhoda Drysdale",
    "Quiana Catoe",
    "Napoleon Coulson",
    "Georgeanna Risley",
    "Debera Lauritsen",
    "Pinkie Plumlee",
    "Bryant Cork",
    "Kenyetta Moronta",
    "Chloe Cozart",
    "Caroline Navarrette",
    "Sima Bosarge",
    "Emmett Archambault",
    "Gregory Letsinger",
    "Kyung Slaven",
    "Shawana Garside",
    "Daniela Gandy",
    "Britta Witherington",
    "Kiesha Haskett",
    "Charis Ta",
    "Brenton Joslin",
    "Jennefer Mangrum",
    "Dani Peppers",
    "Deadra Goheen",
    "Nicholas Tookes",
    "Lorri Nesbit",
    "Alisia Mera",
    "Maple Dedmon",
    "Benny Gisi",
    "Verdell Mulligan",
    "Cordie Lowry",
    "Willard Shipley",
    "Oda Pina",
    "Garrett Lavigne",
    "Isaiah Kiefer",
    "Madaline Harriott",
    "Ila Szczepanski",
    "Sook Duane",
    "Yelena Kilbane",
    "Shawn Preas",
    "Cyndy Palafox",
    "Danielle Prewett",
    "Benita Raynes",
    "Johanne Davenport",
    "Stephanie Jaime",
    "Pearlene Klopp",
    "Roscoe Culley",
    "Bart Losey",
    "Dyan Durand",
    "Danille Trexler",
    "Inge Rosse",
    "Charlott Gierlach",
    "Mana Goode",
    "Tressie Keeling",
    "Royal Allie",
    "Jama Duggins",
    "Raleigh Valenta",
    "Jacelyn Berkowitz",
    "Serena Halpin",
    "Rosalia Wasserman",
    "Elvera Dorner"
]

// function getName(index) {
//     return names[index]
// }

function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}

export function anonymizeNodes(nodes) {
    if (nodes) {
        let namesToUse = JSON.parse(JSON.stringify(names));
        nodes = Object.values(nodes).map(item => {
            const randomInt = getRandomInt(namesToUse.length)
            //console.log(namesToUse.length, randomInt)
            item.label = namesToUse[randomInt]
            namesToUse.splice(randomInt, 1)
            return item
        })
    }
}
export function anonymizeSlackUsers(users) {
    if (users) {
        //console.log(users)
        let namesToUse = JSON.parse(JSON.stringify(names));
        users = users.map(item => {
            if (item.name === "Company Average") return item
            const randomInt = getRandomInt(namesToUse.length)
            //console.log(namesToUse.length, randomInt)
            item.name = namesToUse[randomInt]
            namesToUse.splice(randomInt, 1)
            return item
        })
    }
}


export default anonymizeNodes()
