import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Select, MenuItem} from '@material-ui/core';
import Loading from '../../../../flowtrace-components/Loading'
import { API } from 'aws-amplify';

const regExEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regExName = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;

export default function InviteUser(props) {

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [role, setRole] = React.useState("insights");
  const [validating, setValidating] = React.useState(false);
  const [saveError, setSaveError] = React.useState(null);
  const [saveSuccess, setSaveSuccess] = React.useState(null);
  const [emailError, setEmailError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);

  const inviteUser = async(details) => {
    const flowtraceAPI = 'slackintegration';
    const callHeaders = { headers: {}, response: true, body: { ...details } };
    const response = await API.post(flowtraceAPI, '/user/invite', callHeaders)
    return (response.data && response.data.message ? response.data.message : true)
  }

  const handleSave = async() => {
    try {
      setValidating(true);
      setSaveSuccess(null);
      setEmailError(null);
      setNameError(null);
      const testEmail = (regExEmail.test(String(email).toLowerCase()))
      const testName = (regExName.test(name) && name.split(" ").length > 1)
      if (!testEmail)
        setEmailError("Hint: 'firstname.lastname@company.com'");
      if (!testName)
        setNameError("Hint: 'Firstname Lastname'");


      if (testEmail && testName) {
        const response = await inviteUser({ email, name, role })
        //console.log(response)
        if (response){
          setSaveSuccess("Your colleague has been invited to Flowtrace.")
          setSaveError(null);
        }
        else {
          setSaveError("Something went wrong. Please try again later or contact your account manager.")
        }
      }
    }
    catch (e) {
      console.error("Invite Failed:", e.message)
      setSaveError("Invite Unsuccesful: " + e.message)
    }
    setValidating(false)
  };


  return (
    <Fragment>
      <Dialog open={props.dialogState} onClose={() => { props.onClose(); }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite Your Colleague to Flowtrace!</DialogTitle>
        <DialogContent>
          <div style={{minWidth:'400px'}}>
            <TextField error={nameError!==null} disabled={validating} onInput={e => setName(e.target.value)} autoFocus margin="dense" id="name" label="First and Lastname" type="text" fullWidth />
            <DialogContentText className="text-warning"> {nameError}</DialogContentText>
            <TextField error={emailError!==null} disabled={validating} onInput={e => setEmail(e.target.value)} margin="dense" id="email" label="Email Address" type="text" fullWidth />
            <DialogContentText className="text-warning"> {emailError}</DialogContentText>

            <Select labelId="demo-controlled-open-select-label" disabled={validating} id="select-role" value={role} onChange={(e) => setRole(e.target.value)} >
              <MenuItem value={"administrator"}>Administrator</MenuItem>
              <MenuItem value={"insights"}>Insights User</MenuItem>
              <MenuItem value={"billing"}>Insights + Billing</MenuItem>
              <MenuItem value={"integration"}>Insights + Integrations</MenuItem>
            </Select>

          </div>
          <DialogContentText className="text-success"> {saveSuccess}</DialogContentText>
          <DialogContentText className="text-danger"> {saveError}</DialogContentText>
          <div>
            {validating? <Loading minimize/> : null }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} disabled={validating} color="primary"> Invite </Button>
          <Button onClick={() => { props.onClose(); }} disabled={validating} color="primary"> Close </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
