import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import NetworkChart from './networkChart';
import Loading from '../../Loading'

class AccountOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadSlackUserData() {
    const dataAPI = 'slackintegration';
    const networkDataPath = '/slack/installed/entityNetwork';
    const initAPI = { headers: {}, response: true, };

    let networkData = await API.get(dataAPI, networkDataPath, initAPI).then((response, data, body) => {
      if (!response.data.network) {
        console.error("No usage data");
        return { nodes: [], entityEdges: [], userEdges: [], channelEdges: [] }; // return empty so things don't crash and burn?
      }

      return response.data.network;
    }).catch(error => {
      console.error(error); // CORS hits quite often... Figure out why Lambda keeps on failing...
      return { nodes: [], entityEdges: [], userEdges: [], channelEdges: [] }; // return empty so things don't crash and burn?
    });

    return networkData; // return it for storing into state
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }
  async componentDidMount() {
    this.setState({ loading: 'true' });
    await this.loadSlackUserData().then((data) => {
      this.setState({ networkData: data });
      this.setState({ loading: 'false' });
    });
  }

  render() {
    if (this.state.loading === 'true') {
      return (
        <Loading/>
      );
    }

    if (this.state.loading === 'false') {
      let entityData = { nodes: this.state.networkData.nodes, edges: this.state.networkData.entityEdges }
      return (
        <Fragment>
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <NetworkChart data={entityData}/>
            </Grid>
          </Grid>
        </Fragment>
      ); // end of return

    } // end or IF
    return (<Loading/>); // Init stage render return
  } // end of render()

} // end of class

export default AccountOverview
