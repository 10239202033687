import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

const cardTypes = [
    { type: "hubspot_long", title: "Slowest stage", unit: "minutes", icon: ['fa', 'ban'], info: "." },
    { type: "hubspot_short", title: "Fastest stage", unit: "minutes", icon: ['fas', 'diagnoses'], info: "" },
    { type: "hubspot_total", title: "Average deal sales cycle", unit: "minutes", icon: ['fa', 'check'], info: "" },
    { type: "github_open", title: "Average time of first PR's action", unit: "minutes", icon: ['fa', 'stopwatch'], info: "When did the first pull request action take place since it was opened?" },
    { type: "github_total", title: "Average Time all PR's are open", unit: "minutes", icon: ['fa', 'hourglass-end'], info: "How long in total is the pull request open on average?" },
    { type: "gitlab_open", title: "Average time of first MR's action", unit: "minutes", icon: ['fa', 'stopwatch'], info: "When did the first pull request action take place since it was opened?" },
    { type: "gitlab_total", title: "Average Time all MR's are open", unit: "minutes", icon: ['fa', 'hourglass-end'], info: "How long in total is the pull request open on average?" },
    { type: "jira_block", title: "Issue Blocked", unit: "minutes", icon: ['fa', 'ban'], info: "How long an issue was in a blocked state. This is an indication of problems in requirements and information gathering stage of workflow." },
    { type: "jira_progress", title: "Actively Worked", unit: "minutes", icon: ['fas', 'diagnoses'], info: "How long an issue was in a blocked state. This is an indication of problems in requirements and information gathering stage of workflow." },
    { type: "jira_review", title: "Waiting for Review", unit: "minutes", icon: ['fa', 'check'], info: "How long an issue was in a blocked state. This is an indication of problems in requirements and information gathering stage of workflow." },
    { type: "meeting_agenda", title: "Meetings without an agenda", unit: "%", digits: 1, icon: ['fa', 'calendar-times'], operator: "<", high: 40, low: 15, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "meeting_cost", title: "Meeting investment estimate", unit: "cost", icon: ['fa', 'pound-sign'], info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "meeting_duration", title: "Average meeting length", unit: "minutes", icon: ['fa', 'hourglass-half'], info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "meeting_survey", title: "Meeting Survey Score", unit: "", digits: 0, icon: ['fa', 'user-clock'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "prod_mt", title: "Multitasking", unit: "minutes", icon: ['fas', 'diagnoses'], operator: "<", high: 25, low: 15, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "prod_mmt", title: "Multitasking in meetings", unit: "minutes", icon: ['fa', 'user-times'], operator: "<", high: 20, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "prod_ot", title: "Out of Hours Collaboration", unit: "minutes", icon: ['fa', 'cloud-moon'], operator: "<", high: 30, low: 15, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "prod_survey", title: "Productivity Survey Score", unit: "", digits: 0, icon: ['fas', 'cogs'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "company_leader_survey", title: "Company Leadership Survey Score", unit: "", digits: 0, icon: ['far', 'building'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "company_manager_survey", title: "Management Survey Score", unit: "", digits: 0, icon: ['fas', 'user-tie'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "company_cross_team_survey", title: "Cross-Team Collaboration Survey Score", unit: "", digits: 0, icon: ['fas', 'people-carry'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "engagement_measured", title: "Measured Engagement Score", unit: "", digits: 0, icon: ['fas', 'user-cog'], operator: ">", high: 55, low: 40, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices." },
    { type: "engagement_survey", title: "Engagement Survey Score", unit: "", digits: 0, icon: ['fas', 'star-half-alt'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "engagement_enps", title: "eNPS Score", unit: "", digits: 0, icon: ['fa', 'thumbs-up'], operator: ">", high: 50, low: 10, info: "This value is a indicator of your meeting quality. High number of meetings without an agenda can be an indication of poor quality meeting practices.", error: "Please enable surveys from the settings." },
    { type: "slack_active", title: "Active Channels", unit: "%", digits: 0, icon: ['fas', 'heartbeat'], operator: ">", high: 75, low: 45, info: "This value indicates how many of your channels were active (tracked by Flowtrace) during the period of all the channels you have." },
    { type: "slack_message_type", title: "Public/private Channel Activity", unit: "%", digits: 0, icon: ['fas', 'shield-alt'], operator: ">", high: 90, low: 50, info: "This value indicates how much of communication (tracked by Flowtrace) takes part in public channels over private channels." },
    { type: "slack_timezone", title: "Timezone with most users", unit: "string", icon: ['fas', 'globe-europe'], info: "You can identify the timezone where most of your Slack users reside." },
    { type: "slack_most_messages", title: "Most active channel", unit: "string", icon: ['fab', 'slack-hash'], info: "This vanity metrics shows you the most active channel during the timeperiod." },
    { type: "team_teamwork", title: "Teamwork Survey Score", unit: "", digits: 0, icon: ['fas', 'hands-helping'], operator: ">", high: 50, low: 10, info: "This value indicates how well your teams work together." },
    { type: "team_team_composition", title: "Team Composition Survey Score", unit: "", digits: 0, icon: ['fas', 'users'], operator: ">", high: 50, low: 10, info: "This value indicates the quality of team members as your employees rate their colleagues." },
    { type: "team_response_rate", title: "Survey Response Rate", unit: "%", digits: 0, icon: ['fa', 'percent'], operator: ">", high: 60, low: 30, info: "This value indicates the quality of team members as your employees rate their colleagues." },
]


function convertMinutesToCostString(costProps, minutes) {
    if (!minutes) return "-";
    // Average hours worked by employee in UK / year is 1730 https://clockify.me/working-hours
    // Average salary for tech company in UK is 74k https://www.computerweekly.com/news/252478407/Average-UK-tech-salary-grows-to-74000-a-year-in-2019
    // Average employe costs for employer in UK for 74k Gross are £84,216.92 using https://www.pcpayroll.co.uk/uk-salary-calculator/
    const yearlyCost = costProps ? costProps.hours / (costProps.salary * 1000) : 1730 / 84217;
    const cost = minutes / 60 / yearlyCost;
    const currency = costProps ? costProps.currency : "GBP";
    const formatter = new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions().locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: 0,
        maximumFractionDigits:0
    });

    const costString = formatter.format(cost);
    const timeString = convertMinutesToTimeString(minutes);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
                <span className={"font-size-xl"}>{costString} </span>
            </Grid>
            <Grid item xs={12} md={12}>
                <span className={"font-size-md"}>({timeString})</span>
            </Grid>
        </Grid>
    );
}

function convertMinutesToTimeString(minutes) {
    let timeString = ""
    if (!minutes) return "-"
    if (minutes >= 1440)
        timeString = Math.floor(minutes / 1440).toFixed(0) + "d " + Math.floor(minutes % 1440 / 60).toFixed(0) + "h"
    else if (minutes >= 60)
        timeString = Math.floor(minutes / 60).toFixed(0) + "h " + (minutes % 60).toFixed(0) + "m"
    else
        timeString = (minutes % 60).toFixed(0) + " min" || "-"
    return timeString
}

// This function return's card's body color depending if the value is highlighted, and if the value is good or bad
function getBenchmarkStyle(card, currentValue) {

    if (card.operator === "<" && currentValue < card.low) return "bg-success"
    if (card.operator === "<" && currentValue > card.high) return "bg-danger"
    if (card.operator === ">" && currentValue > card.high) return "bg-success"
    if (card.operator === ">" && currentValue < card.low) return "bg-danger"

    return "bg-white";
}
// This function returns Card's icon
function getIcon(card, benchmark, currentValue) {
    const color = "bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center"
    const benchmarkColor = getBenchmarkStyle(card, currentValue) + " text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center"
    return (
        <div className={benchmark?benchmarkColor:color}>
            <FontAwesomeIcon icon={card?card.icon:[]} className="font-size-xl" />
        </div>
    )
}
// This function returns Card's icon
function getTitle(card) {
    return <small className="text-white-50 d-block mb-1 text-uppercase">{card?card.title:""}</small>
}

function getValue(costProps, card, currentValue) {
    const error = currentValue ? null : card.error
    let value
    if (card.unit === "minutes")
        value = convertMinutesToTimeString(currentValue)
    else if (card.unit === "cost")
        value = convertMinutesToCostString(costProps, currentValue)
    else if (card.unit === "string")
        value = currentValue
    else if (card.digits >= 0)
        value = currentValue.toFixed(card.digits) + card.unit
    else
        value = currentValue.toFixed(0) + card.unit


    // Return either error text or value converted into right unit
    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
            {error?
                <span className="font-size-sm ">{ error }</span>
            :
                <span className={"font-size-"+ (card.unit === "string"?"sm":"xl")}>{ value } </span>
            }
            </Grid>
        </Grid>
    )
}

// This returns arrow up/arrow down as a green or red, depending on bad/good definition of where the delta should progress toward. It also handles the unit of delta.
function getChangeBadge(card, currentValue, previousValue) {
    const changeValue = currentValue / previousValue * 100 - 100

    // Trending Flat, Up or Down?
    const trendIcon = changeValue > 0 ? <TrendingUpIcon/> : (changeValue < 0 ? <TrendingDownIcon/> : <TrendingFlatIcon/>)

    // Default to grey text and only change it to green/red in case the change is real
    let trendColor = "text-dark"
    if (card.operator === ">" && (changeValue < 0 || changeValue > 0)) trendColor = changeValue > 0 ? "text-success" : "text-danger"
    if (card.operator === "<" && (changeValue < 0 || changeValue > 0)) trendColor = changeValue < 0 ? "text-success" : "text-danger"

    // Parse the change to integer (to remove Infinity values) and test if the output is success === reasonable number to show to user
    const showDeltaNumber = Number.isInteger(parseInt(changeValue)) ? true : false

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
            {
            // Don't add change badge element if we have NaN value for detal or we received null ===> one values is null or something similar
            !isNaN(changeValue) && previousValue !== null  ?
                <span className={"badge badge-neutral-info mt-1 ml-2 " + trendColor}>{trendIcon} <span className="font-size-l font-weight-bold"> { showDeltaNumber?changeValue.toFixed(0) +"%":"" }</span></span>
                :
                null
            }
            </Grid>
        </Grid>
    )
}


export default function StatsCard(props) {
    const card = cardTypes.find(i => i.type === props.KPI)
    if (!card) return "huhuu" + JSON.stringify(props)
    const account = props.flowtraceUser && props.flowtraceUser.account ? props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;
    const value = getValue(costProps, card, props.value)
    const change = getChangeBadge(card, props.value, props.compareValue)
    const title = getTitle(card)
    const icon = getIcon(card, props.benchmark, props.value)
    const cardClasses = "card-box border-0 text-light m-1 bg-dark"

    return (
        <Fragment>
            <Card className={cardClasses}>
                <CardContent className="p-3">
                    <div className="font-weight-bold">
                        <Grid container spacing={0} alignContent={"space-between"} justifyContent={"flex-start"} alignItems={"center"}>
                            <Grid item xs={10}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        {title}
                                    </Grid>
                                    <Grid item >
                                        {value}
                                    </Grid>
                                    <Grid item xs={4} md={4}>
                                        {change}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2}>
                                {icon}
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Fragment>
    );
}
