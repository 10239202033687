import React, { Fragment } from 'react';
import NetworkOverview from '../../flowtrace-components/Collaboration/ChannelNetwork';

export default function Dashboard(props) {
  return (
    <Fragment>

      <NetworkOverview  flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
