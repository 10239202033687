import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import FlightRisk from '../../flowtrace-components/FlightRisk';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1"> Flight Risk</h4>)
explanation.push(<p key="paragraph1"> <b>Engagement</b> score is equally weighted average of all other indexes of an employee. These scores are weighted based on the past 12 weeks, latest full week carrying most weight.</p>)
explanation.push(<p key="paragraph2"> <b>Activity</b> score is a metric based on activity of an employee. Sub-metrics for this score are derived from activity data points, active hours, employee mentions, and employees who they mention.</p>)
explanation.push(<p key="paragraph3"> <b>Sentiment</b> score is simplistic metric calculated based on employee's and company's positive and negative sentiment averages. Positive sentiment yields maximum 100 score when employee's sentiment average is two fold to company's average. Negative sentiment works reverse to positive, where least negative sentiment collaboration yields highest score. Sentiment score should be assessed carefully as your team's key problem-solver can be involved with negative topics more than others.</p>)
explanation.push(<p key="paragraph5"> <b>Connectedness</b> score is based on graph theory's degree centrality and hub concept. We give additional weight foremployee's in-degree, and out-degree metrics as well. High score indicates that an employee can be an information broker and source of innovation.</p>)
explanation.push(<p key="paragraph6"> <b>Influence</b> score is calculated based on graph theory's betweeness, weighted betweenness, authority and pagerank algorithms. For any given moment, we use user graph from past 3 months to find shortest paths between nodes. Betweenness describes how often a node (an employee) is part of the shortest route between all other nodes (employees) in the graph. Employee scoring high on this can be regarded as central authority to silent information.</p>)
explanation.push(<p key="paragraph7"> Sub metrics for <b>Activity</b>, <b>Connectedness</b>, and <b>Influence</b> scores are balanced based on company's mean, and standard deviation information. Considering normal distribution, we give highest score (100) for mean + 1 sigma standard deviation. A first sigma away from this point yields 25 point reduction, next sigma 12.5 point reduction, and so on.</p>)

export default function Dashboard() {
  return (
    <Fragment>
    Laalaa
      <PageTitle key="title" titleHeading="" titleDescription="Employee Flight Risk Overview" explanation={explanation}/>

      <FlightRisk />
    </Fragment>
  );
}
