exports.extractData = (data) => {
    const ap = data?data.activePeople:0;
    const tool = data ? data.tool : null;
    const time = data ? data.time : null;
    const punctuality = data ? data.calculation.punctuality : null;
    const timeType = data ? data.timeType : null;

    return {
      activePeople: ap,
      activeHours: ap > 0 && data && data.activeHours.sum ? data.activeHours.sum / ap : null,

      slack: ap > 0 && tool && tool.slack.sum ? tool.slack.sum / ap : null,
      email: ap > 0 && tool && tool.email.sum ? tool.email.sum / ap : null,
      meeting: ap > 0 && tool && tool.calendar?tool.calendar.sum / ap:null,

      internalMeeting: ap > 0 && time && time.internalMeeting.sum ? time.internalMeeting.sum / ap : null,
      externalMeeting: ap > 0 && time && time.externalMeeting.sum ? time.externalMeeting.sum / ap : null,
      multitasking: ap > 0 && time && time.multitasking.sum ? time.multitasking.sum / ap : null,
      meetingMultitasking: ap > 0 && time && time.meetingMultitasking.sum ? time.meetingMultitasking.sum / ap : null,
      one2oneMeeting: ap > 0 && time && time.one2oneMeeting.sum ? time.one2oneMeeting.sum / ap : null,
      outOfHoursCollaboration: ap > 0 && time && time.outOfHoursCollaboration.sum ? time.outOfHoursCollaboration.sum / ap : null,

      organizedLate: ap > 0 && punctuality && punctuality.organizedLate.sum ? punctuality.organizedLate.sum / ap : null,
      attendedLate: ap > 0 && punctuality && punctuality.attendedLate.sum ? punctuality.attendedLate.sum / ap : null,
      totalLate: ap > 0 && punctuality && punctuality.totalLate.sum ? punctuality.totalLate.sum / ap : null,
      meetingCount: ap > 0 && punctuality && punctuality.totalCount.sum ? punctuality.totalCount.sum / ap : null,

      focus: ap > 0 && timeType && timeType.focus.sum ? timeType.focus.sum / ap : null,
      shallow: ap > 0 && timeType && timeType.shallow.sum ? timeType.shallow.sum / ap : null,
      collaboration: ap > 0 && timeType && timeType.collaboration.sum ? timeType.collaboration.sum / ap : null,
    };
  };
