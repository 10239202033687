import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Select, MenuItem } from '@material-ui/core';
import Loading from '../../../../flowtrace-components/Loading'
import { API } from 'aws-amplify';

export default function InviteUser(props) {

  const [validating, setValidating] = React.useState(false);
  const [role, setRole] = React.useState("");

  const [saveError, setSaveError] = React.useState(null);
  const [saveSuccess, setSaveSuccess] = React.useState(null);

  const updateUser = async() => {
    const flowtraceAPI = 'slackintegration';
    const callHeaders = { headers: {} };
    const responses = {success:true, message:""};
    // Update all identities of the user to the same role at once
    for(const i of props.user.identities) {
      const url = `/user/${i.cognito_id}/role/${role}`;
      const response = await API.patch(flowtraceAPI, url, callHeaders)

      if(response.message)
        responses.message += response.message;
      if(!response.success)
        responses.success = false;

    }
    return responses;
  }

  const roleSelected = (e) => {
    setRole(e.target.value)
  }

  const handleSave = async() => {
    try {
      setValidating(true);
      setSaveSuccess(null);


      if (role) {
        const response = await updateUser();
        //console.log(response)
        if (response.success){
          setSaveSuccess("User role updated.")
          setSaveError(null);
        }
        else {
          setSaveError("Error: " + response.message + " Please try again later or contact your account manager.")
        }
      }
    }
    catch (e) {
      console.error("Update Failed:", e.message)
      setSaveError("Update Unsuccesful: " + e.message)
    }
    setValidating(false)
  };


  const currentRole = role && role.length ? role : (props.user ? props.user.user_role : "");
  return (
    <Fragment>
      <Dialog open={props.dialogState} onClose={() => { props.onClose(); }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update user role:</DialogTitle>
        <DialogContent>
          <div >
            <Select labelId="demo-controlled-open-select-label" id="select-role" value={currentRole} onChange={roleSelected} >
              <MenuItem value={"administrator"}>Administrator</MenuItem>
              <MenuItem value={"insights"}>Insights User</MenuItem>
              <MenuItem value={"billing"}>Insights + Billing</MenuItem>
              <MenuItem value={"integration"}>Insights + Integrations</MenuItem>
            </Select>
          </div>
          <DialogContentText className="text-success"> {saveSuccess}</DialogContentText>
          <DialogContentText className="text-danger"> {saveError}</DialogContentText>
          <div>
            {validating? <Loading minimize/> : null }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} variant={"contained"} disabled={validating} color="primary"> Save </Button>
          <Button onClick={() => { props.onClose(); }} disabled={validating} color="primary"> Close </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
