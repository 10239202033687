import React, { Component, Fragment } from 'react';
import Loading from '../../Loading'
import TotalStats from './totalStats'
import AttendanceStats from './attendanceStats'
import MeetingStats from './meetingStats'
import MeetingMetrics from './meetingMetrics'
import MeetingHeatmap from './meetingHeatmap'
import FilterBar from '../../Common/FilterBar';
import meetingAPI from '../../../flowtrace-api/meetingAPI';
import productivityAPI from '../../../flowtrace-api/dataAPI/productivity';

let start;

const timestamp = (text, reset) => {
  if(reset) start = new Date();
  var today = new Date();
  var s = today.getSeconds();
  var ms = today.getMilliseconds();
  if(reset)
    console.log(`${s}.${ms}`, text);
  else
    console.log(`${s}.${ms}`, today.getTime() - start.getTime() + "ms" ,text);
};


const tooManyDatapoints = {text:"Too many datapoints.", tooltip:"Please refine your search for complete results. (i.e. change from daily breakdown to monthly, or refine your search results with type filters.)"};

class MeetingOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, searchWarning:null, queryFilters: { range_type: "calendar", range_period:"week", range_limit: 4} };
  }


  async loadMeetingStatistics(queryFilters) {
    this.setState({ searchWarning:null});

    const setNetworkError = (e) => {
      console.error(e);
      const searchWarning = {text:"Unkonwn search error.", tooltip:"Please contact our support if this error persists. The error we encountered: " + e.message};
      this.setState({searchWarning});
    };


    //timestamp("loading start ", true)
    const [overview, stats, heatmap] = await Promise.all([
      meetingAPI.overview(queryFilters, this.props.flowtraceUser),
      productivityAPI.statistics(queryFilters, this.props.flowtraceUser),
      meetingAPI.heatmap(queryFilters, this.props.flowtraceUser)
      ]);
    // const stats = require('./test/statistics.json');
    // const overview = {error:"TOO_MANY_BUCKETS"};//require('./test/overview.json');
    //timestamp("loading end")
    // Error handling
    if(overview.exception) setNetworkError(overview.exception);
    if(stats.exception) setNetworkError(stats.exception);
    if(heatmap.exception) setNetworkError(heatmap.exception);
    if(overview.error === "TOO_MANY_BUCKETS" || heatmap.error === "TOO_MANY_BUCKETS")
      this.setState({searchWarning : tooManyDatapoints});

    const inviteData = heatmap.events?heatmap.events.map(e => e.invites):[];
    const internalData = inviteData.map(i => i.internal.invites + i.group.invites);
    const inviteCount = internalData.reduce((cur, acc) => acc += cur, 0);
    overview.video.inviteCount = inviteCount; // This can be used to extrapolate the delay costs

    const dataSet = {
      video: overview.video,
      times: overview.times,
      events: heatmap.events,
      histogram: overview.histogram,
      members: overview.members,
      statistics: stats
    };

    return this.setState({loading: false, ...dataSet});
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadMeetingStatistics(queryFilters);
  }

  componentDidMount() {
    this.loadMeetingStatistics(this.state.queryFilters);
  }

  componentWillUnmount() { this.setState = () => { return; }; }

  render() {
    if(this.state.loading)
    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} />
        <Loading text={"This feature requires Google or Outlook integration. Please inquire your account manager for more details."}/>
      </Fragment>);

    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} />
        <TotalStats timestamp={timestamp} times={this.state.times} video={this.state.video} members={this.state.members} flowtraceUser={ this.props.flowtraceUser } />
        <MeetingMetrics timestamp={timestamp} histogram={this.state.histogram} />
        <MeetingHeatmap timestamp={timestamp} events={this.state.events}/>
        <AttendanceStats timestamp={timestamp} histogram={this.state.histogram} />
        <MeetingStats queryFilters={this.state.queryFilters} timestamp={timestamp} statistics={this.state.statistics} histogram={this.state.histogram} period={this.state.queryFilters.range_period} />
      </Fragment>
    );
  } // end of render()
} // end of class

export default MeetingOverview
