import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import TeamChart from './teamChart';
import Loading from '../../Loading'
import FilterBar from '../../Common/FilterBar';

class ProductivityStatisticsTeamOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: "week" } };
  }

  async loadProductivityStatistics(queryFilters) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/productivity/statistics';
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };

    const response = await API.get(dataAPI, statisticsPath, initAPI)

    this.setState({ loading: false, total: response.data ? response.data.total : null, teamStats: response.data ? response.data.teamStats : [], stats: response.data ? response.data.stats : [] });

    return
  }

  async componentDidMount() {
    this.loadProductivityStatistics(this.state.queryFilters)
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadProductivityStatistics(queryFilters)
  }
  render() {
    return (
      <Fragment>
          <FilterBar teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />

          {!this.state.loading ?
          <Grid container spacing={0}>
            <TeamChart key={"teamchart"} total={this.state.total} company={this.state.stats} teamStats={this.state.teamStats} timeSpan={this.state.queryFilters.range_period}  />
          </Grid>
          :
            <div><Loading/><center>This feature requires Google Workspace Meeting Analytics or Google Workspace Service Account integration. Please inquire your account manager for more details.</center></div>
          }
        </Fragment>
    );

  } // end of render()

} // end of class

export default ProductivityStatisticsTeamOverview
