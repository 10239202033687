import React, { Fragment } from 'react';
import { PageTitle } from '../../layout-components';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import { ExampleWrapperSeamless } from '../../layout-components';
import Tooltip from '@material-ui/core/Tooltip';
import YouTube from 'react-youtube';

import MeetingCard from '../../assets/images/resources/meeting_guide_card.jpg';
import SlackCard from '../../assets/images/resources/slack_guide_card.png';
import ArticleCard from '../../assets/images/resources/article_card.jpg';
import HealthCheckCard from '../../assets/images/resources/health_check_card.jpg';


const resources = [{
    title: "Collaboration Health Check",
    url: "https://www.flowtrace.co/company-collaboration-health-check",
    actionText: "Do the Test",
    cardImage: HealthCheckCard,
    type: "Guide",
    external: false,
    featured: false,
    description: "This self-assessment tool will ask you questions about your internal collaboration and communication. It takes only 5 minutes to complete and highlights you the areas you should focus on improving first."

  },
  {
    title: "How to get more focus-time",
    url: "https://www.flowtrace.co/collaboration-blog/track-and-improve-focus-time",
    actionText: "Read",
    type: "Article",
    external: false,
    featured: false,
    description: "You know focus time is important right? By reading this article you will learn 25 new ways to gain more high quality focus time to your work day."
  },
  {
    title: "Slack Guide",
    url: "https://hubs.ly/H0ZcX6D0",
    cardImage: SlackCard,
    actionText: "Download",
    type: "Guide",
    featured: true,
    description: "Struggling to set the tone for your team in Slack? Everything you need to know to get your team using Slack effectively in your startup. This guide includes Slack etiquette template you can share with your team."
  },
  {
    title: "How to create collaboration culture",
    url: "https://www.flowtrace.co/collaboration-blog/track-and-improve-collaboration",
    actionText: "Read",
    type: "Article",
    external: false,
    featured: false,
    description: "Read this article if you want to get practical tips on improving your collaboration culture. You will find 25 new ways how to make your teams work better together."
  },
  {
    title: "Meeting Guide",
    url: "https://hubs.ly/H0Z9QX00",
    cardImage: MeetingCard,
    actionText: "Download",
    type: "Guide",
    external: false,
    featured: true,
    description: "Do you know what good meeting culture looks like? Read this guide to bootstart your meeting culture to the next level. This guide includes meeting etiquette template you can share with your team."
  },
  // {
  //   title: "Benefits of Collaboration",
  //   url: "https://www.youtube.com/watch?v=zSuiQsBRTEI",
  //   youtubeId: "zSuiQsBRTEI",
  //   actionText: "Watch",
  //   type: "Video",
  //   featured: false,
  //   description: "Watch this video to see what benefits you get by using insights provided by a platform like Flowtrace."
  // },
];

export default function Resources(props) {

  const opts = { height: '200', width: "100%", playerVars: { modestbranding: 1, autoplay: 1 } };


  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <Fragment>
      <PageTitle titleHeading="Handy resources for you and your teams" titleDescription="Collaboration guides, self assessments, and important how-to articles." />
      <ExampleWrapperSeamless sectionHeading="Featured">
        <Grid container spacing={4}>
          {resources.filter(i => i.featured).map((card,index) =>
            <Grid key={"featured-content-"+index} item xs={12} sm={6} md={4}>
              <Card className="mb-4">
                <img alt="..." className="card-img-top" src={card.cardImage} style={{maxHeight:"300px"}}/>
                <CardContent className="p-3">
                  <h5 className="card-title font-weight-bold font-size-lg"> {card.title} </h5>
                  <p className="card-text"> {card.description} </p>
                  <div className="text-center">
                    <Button color="primary" variant="contained" onClick={() => window.open(card.url)}> {card.actionText} </Button>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </ExampleWrapperSeamless>
      <Card className="mb-4 p-3">
        <ExampleWrapperSeamless sectionHeading="All Free Resources">
          <Grid container spacing={2}>
          {resources.map((card,index) =>
            <Grid key={"featured-content-"+index} item xs={6} sm={4} md={4} lg={3} xl={2}>
              <Tooltip title={card.description} placement="top">
                <Card className="flex-box mb-4" style={{height:"100%"}}>
                  <Grid container style={{height:"100%"}} direction="column" justifyContent="flex-end" alignItems="stretch" >
                    <Grid item >
                      {
                        card.type === "Video"?
                          <YouTube videoId={card.youtubeId} opts={opts} onReady={onReady} />
                      :
                        card.cardImage?
                          <img alt="..." className="card-img-top" src={card.cardImage} style={{maxHeight:"100%",maxWidth:"100%"}}/>
                        :
                          <img alt="..." className="card-img-top" src={ArticleCard} style={{maxHeight:"100%",maxWidth:"100%"}}/>
                      }
                      </Grid>
                      <Grid item style={{width:"100%"}}>
                        <CardContent className="card-box p-3 text-center">
                          <h5 className="card-title font-weight-bold font-size-md"> {card.title} </h5>
                          <Button color="primary" variant="outlined" onClick={() => window.open(card.url)}> {card.actionText}</Button>
                        </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Tooltip>
            </Grid>
          )}
          </Grid>
        </ExampleWrapperSeamless>
      </Card>
    </Fragment>
  );
}
