import React from 'react';
import { Grid, Card, CardContent, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";

const eNPSMissing =
  <MuiAlert key="ssoFailedToLoad" className="mb-4 mt-4 mr-4" severity="info">
        <div className="d-flex align-items-center align-content-center">
            <span>
                <strong className="d-block mb-5 mt-5">We don't have any eNPS data for your teams.</strong>
                <p> Please make sure you have turned on team surveying and eNPS option. </p>
            </span>
        </div>
    </MuiAlert>;
const effectivenessMissing =
  <MuiAlert key="ssoFailedToLoad" className="mb-4 mt-4 mr-4" severity="info">
        <div className="d-flex align-items-center align-content-center">
            <span>
                <strong className="d-block mb-5 mt-5">We don't have any team effectivess data for your teams.</strong>
                <p> Please make sure you have turned on team effectivess surveying in your survey settings. </p>
            </span>
        </div>
    </MuiAlert>;


export default function CombinedSurveyCharts(data) {
  const history = useHistory();

  const routeChange = () => {
    history.push("/Account/Survey");
  };


  return (
    <Card className="card-box d-flex m-3" style={{width:"100%"}}>
      <CardContent style={{width:"100%"}}>
        <Grid key="firstGrid"item xs={12} lg={12}>
          <Grid container spacing={1} className="mt-1">
            <Grid item xs={12} sm={6} lg={6}>
              <div className="text-left">
                <div className="ml-3 line-height-sm align-items-center justify-content-left">
                  <div className=" br-5" ><b className="font-size-lg " >Company Wide eNPS</b></div>
                  <span className="text-black-50 d-block">Employee net promoter score</span>
                  {eNPSMissing}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <div className="text-left">
                <div className="line-height-sm align-items-center justify-content-left">
                  <div className="br-5" ><b className="font-size-lg " >Company Wide Pulse Survey</b></div>
                  <span className="text-black-50 d-block">Team effectiveness scores</span>
                  {effectivenessMissing}
                </div>
              </div>
            </Grid>
          </Grid>
            <Grid item xs={12} >
                <div className="ml-3 line-height-sm align-items-center justify-content-left">
                  <Button onClick={routeChange} rel="noopener" size="large" variant="contained" color="primary">Take me to survey configuration</Button>
                </div>
            </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
