import React, { Fragment } from 'react';
import { PageTitle } from '../../layout-components';

import Billing from '../../flowtrace-components/Billing';

export default function BillingPage(props) {
  return (
    <Fragment>
      <PageTitle
        titleHeading="Current Plan and Billing Information"
        titleDescription="Manage your current plan, upgrade your plan, or see your invoicing history."
      />

        <Billing flowtraceUser={ props.flowtraceUser } />
    </Fragment>
  );
}
