import React, { Fragment, useEffect, useState } from 'react';
import { Button, DialogActions, TextField, Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../../../../flowtrace-components/Loading';
import Auth0Button from './../Auth0Button';
import Oauth2 from './oauth2Install';

const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-0 pb-0 mt-3 mb-3 ">
      {message}
    </MuiAlert>
  );
};



export default function JiraConfigureModal(props) {

  const defaultEvents = ["jira:issue_created", "jira:issue_updated", "jira:issue_deleted", "comment_created", "comment_updated", "comment_deleted", "issue_property_set", "issue_property_deleted"];

  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState("");
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState("");
  const [atlassian, setAtlassian] = useState(props.flowtraceUser.account.ATLASSIAN);

  const handleSubmit = async () =>{
    try{
      if(message) setMessage("");
      setLoading(true);
      const account = props.flowtraceUser.account;
      const sub = atlassian.userId;
      const path = `/atlassian/jira/webhook/${sub}/${account.account_id}`;
      const initAPI = { headers: {}, response: true, body:{events:selected, jqlFilter:filter} };
      const {data:{success, results, events, jqlFilter}} = await API.patch('slackintegration', path, initAPI);
      const errors = results && results.length && results.find(i => i.errors) ? results.find(i => i.errors && i.errors.length).errors:null;
      if(success && !errors){
        setSelected(events);
        setFilter(jqlFilter);
        setMessage(createAlert("success","Configuration saved!"));
      } else if(errors){
        const errorString = errors.reduce((acc, cur, index) => acc += (index===0?"":", ") + (index + 1) + ") " + cur, "");
        setMessage(createAlert("warning","Something went wrong! Jira responded with following errors: " + errorString));
      } else {
        setMessage(createAlert("warning","Something went wrong!"));
      }
    }catch(e){
      setMessage(createAlert("error","Something went wrong!"));
    }
    setLoading(false);
    setEdit(false);
  };

  const updateEvent = (event) => {
    setSelected(selected.includes(event)?selected.filter(i => i !== event):selected.concat(event));
    if(message) setMessage("");
    if(!edit) setEdit(true);
  };
  const updateText = (text) => {
    setFilter(text);
    if(message) setMessage("");
    if(!edit) setEdit(true);
  };

  const getJiraWebhooks = async (unMounted) => {
    try{
      const sub = atlassian?atlassian.userId:"install-not-found";
      const path = `/atlassian/jira/webhook/${sub}`;
      const initAPI = { headers: {}, response: true };
      const response = (await API.get('slackintegration', path, initAPI)).data;
      if(unMounted) return;
      if(response.events && response.jqlFilter){
        setSelected(response.events);
        setFilter(response.jqlFilter);
        setLoading(false);
        setEdit(false); // Edit is for user input only so reset the state
        return;
      }
    } catch(e){
      setMessage(createAlert("error","We couldn't retrieve your current configration. Please try configuring your integration or re-install it to ensure you get the relevant events from Jira."));
    }
    setSelected([]);
    setFilter([]);
    setLoading(false);
    setEdit(false); // Edit is for user input only so reset the state
  };

  const reloadUserAndWebhooks = () => props.flowtraceUser.reload().then(() => { setAtlassian(null); setReload(true) });

  useEffect(() => {
    let unMounted = false;

    if(atlassian)
      getJiraWebhooks(unMounted);
    else
      setLoading(false);
    return () => unMounted = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Did we receive update from the app? Reload the webhooks
  if(!atlassian && props.flowtraceUser.account.ws.ATLASSIAN) {
    console.log("We need an update because we received a new user")
    setAtlassian(props.flowtraceUser.account.ws.ATLASSIAN);
    setReload(true);
  }



  if(reload) {
    setReload(false);
    setLoading(true);
    getJiraWebhooks().then(() => { setLoading(false); });
  }

  const authError = (error) => {
    console.log("authError", error);
    setMessage(createAlert("error", "We couldn't complete the verification."));
    if(error.original === "User closed the popup window")
      props.onInstall(false, false, "You didn't complete the integration.");
    else
      props.onInstall(false, false, error.description ? error.description:null); // Tell the page we got a failure
    setLoading(false);
  };

  const authSuccess = (profile) => {
    setLoading(true);
    console.log("auth success", new Date().toISOString());
    Oauth2(profile.sub).then(data => {
      console.log("profile fetch success", new Date().toISOString());
      if(data && data.success){
        // We need to reload the user now after we have installed the token (success or not)
        reloadUserAndWebhooks();
        setMessage(createAlert("success", "Installation succeeded."));
      }
      else
        setMessage(createAlert("warning", "Something didn't go right."));
      props.onInstall(data.success, data.success); // Open the dialog if it is a success
    }).finally(() => {
      setLoading(false);
    });
  };

  const install = <Auth0Button loading={loading} cbProfile={authSuccess} disabled={loading} cbError={authError} reinstall={props.existing} connection={"atlassian"} />;
  const dialogButtons =
    <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
      <Button onClick={() => handleSubmit()} disabled={loading|| !edit} variant="contained" color="primary" > Save Current Configuration </Button>
      {install}
      {props.cancelButton}
    </DialogActions>;


  if(!props.existing)
    return install;

  if (props.flowtraceUser.reloading)
    return <Fragment> <Loading text="Please wait while we update the account settings."/> {dialogButtons} </Fragment>;

  if (reload)
    return <Fragment> <Loading text="Reloading Jira webhook information from the API"/> {dialogButtons} </Fragment>;

  if (loading)
    return <Fragment> <Loading text="Loading Jira webhook information from Atlassian"/> {dialogButtons} </Fragment>;


  return (
    <Fragment>
      {message}
      <Grid container spacing={2}>
        <Grid item md={5} >
          <p>Select the events (all recommended):</p>

          <FormGroup>
            { defaultEvents.map((item, index) => {
              return <FormControlLabel key={'eventLabel-' + index} control={
                <Checkbox
                  key={'event-' + index}
                  checked={selected.includes(item)?true:false}
                  onChange={() => updateEvent(item)}
                />
              } label={item} />;
              })
            }
          </FormGroup>
        </Grid>
        <Grid item md={7} >
          <p>JQL Filter for projects, issues, etc. ("project != EMPTY" for all projects)):</p>
          <TextField variant="outlined" value={filter} onInput={e => updateText(e.target.value)} margin="dense" id="jqlTextBox" label="" multiline fullWidth rows={20} placeholder="project != EMPTY" />
        </Grid>
      </Grid>
      {dialogButtons}
    </Fragment>
  )
}