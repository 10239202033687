import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import KPICard from './genericKPICard'

class CompanyKPIComponent extends Component {
  render() {
    const cards = []
    if (this.props.companySurveyScore && this.props.companySurveyScore.average)
      cards.push(<KPICard key="clscore" KPI="company_leader_survey" benchmark={this.props.benchmark} value={this.props.companySurveyScore.average} compareValue={this.props.companySurveyScore.previous?this.props.companySurveyScore.previous.average:null} />)
    if (this.props.managementSurveyScore && this.props.managementSurveyScore.average)
      cards.push(<KPICard key="msscore" KPI="company_manager_survey" benchmark={this.props.benchmark} value={this.props.managementSurveyScore.average} compareValue={this.props.managementSurveyScore.previous?this.props.managementSurveyScore.previous.average:null} />)
    if (this.props.crossTeamCollaborationSurveyScore && this.props.crossTeamCollaborationSurveyScore.average)
      cards.push(<KPICard key="ctcscore" KPI="company_cross_team_survey" benchmark={this.props.benchmark} value={this.props.crossTeamCollaborationSurveyScore.average} compareValue={this.props.crossTeamCollaborationSurveyScore.previous?this.props.crossTeamCollaborationSurveyScore.previous.average:null} />)

    if (cards.length === 0)
      return null // Return nothing for no cards

    return (
      <Grid item xs={12} sm={6} md={4} xl={2}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
              <Grid container spacing={0}>
                <Grid item xs={12} md={12}>
                  <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Company</div>
                  {cards}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Fragment>
      </Grid>
    );
  } // end of render()
} // end of class

export default CompanyKPIComponent;
