import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import KPICard from './genericKPICard';
import BarChart from './genericBarChartKPI';

// Function to populate sentiment KPIs as an object (current, and pervious)
function sentimentKPIObject(timeStats, previousTimeStats = null) {
  const sentimentKPI = { positive: 0, negative: 0, neutral: 0, mixed: 0, not_detected: 0, count: 0, dataFound: false }
  const current = timeStats.data && timeStats.data.summary && timeStats.data.summary.time ? Object.values(timeStats.data.summary.time) : []

  if (previousTimeStats)
    sentimentKPI.previous = { ...sentimentKPIObject(previousTimeStats) }

  for (const period of current) {
    sentimentKPI.positive += period.sentiment.POSITIVE | 0
    sentimentKPI.negative += period.sentiment.NEGATIVE | 0
    sentimentKPI.neutral += period.sentiment.NEUTRAL | 0
    sentimentKPI.mixed += period.sentiment.MIXED | 0
    sentimentKPI.count += period.doc_count
    sentimentKPI.dataFound = true
  }
  sentimentKPI.not_detected = sentimentKPI.count - (sentimentKPI.positive + sentimentKPI.negative + sentimentKPI.neutral + sentimentKPI.mixed)
  return sentimentKPI
}

// Function to populate sentiment KPIs as an object (current, and pervious)
function usageKPIObject(channelStats, previousChannelStats = null) {
  const usageKPI = { total_channels: 0, active_channels: 0, public_messages: 0, private_messages: 0, active_users: 0, deleted_users: 0, active_bots: 0, deleted_bots: 0, timezone_with_most_users: "", channel_with_most_messages: "" }

  const channels = channelStats.data && channelStats.data.summary && channelStats.data.summary.channels ? Object.values(channelStats.data.summary.channels) : null
  const slackInfo = channelStats.data && channelStats.data.slackInformation ? channelStats.data.slackInformation : null

  if (previousChannelStats)
    usageKPI.previous = { ...usageKPIObject(previousChannelStats) }

  if (slackInfo) {
    usageKPI.total_channels = slackInfo.channels.total - slackInfo.channels.archived | 0
    const timezoneInfo = Object.entries(slackInfo.users.timezone).sort((a, b) => b - a)[0]
    usageKPI.timezone_with_most_users = (timezoneInfo ? timezoneInfo[0] + " (" + timezoneInfo[1] + ")" : "Not found.")
    usageKPI.active_users = slackInfo.users.active_people | 0
    usageKPI.deleted_users = slackInfo.users.deleted_people | 0
    const deletedBots = slackInfo.users.deleted - slackInfo.users.deleted_people | 0
    usageKPI.active_bots = slackInfo.users.total_bots - deletedBots | 0
    usageKPI.deleted_bots = deletedBots
  }

  if (channels) {
    const channelMessageInfo = channels.reduce((a, c) => a.doc_count > c.doc_count ? a : c, { doc_count: 0, metadata: { name: "not-found" } })
    usageKPI.channel_with_most_messages = (channelMessageInfo ? "#" + channelMessageInfo.metadata.name + " (" + channelMessageInfo.doc_count + ")" : "Not found.")
    for (const channel of channels) {
      if (channel.metadata && channel.metadata.is_private) usageKPI.private_messages += channel.doc_count
      if (channel.metadata && !channel.metadata.is_private) usageKPI.public_messages += channel.doc_count
      if (channel.metadata && !channel.metadata.is_archived) usageKPI.active_channels += 1
    }
    usageKPI.active_channel_ratio = usageKPI.active_channels / usageKPI.total_channels * 100
    usageKPI.public_message_ratio = usageKPI.public_messages / (usageKPI.public_messages + usageKPI.private_messages) * 100
  }
  return usageKPI
}

class SentimentKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }


  async loadSentimentStatistics() {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/sentiment/aggregate';
    const fetchTimeAPI = { headers: {}, response: true, queryStringParameters: { type: "time", ...this.props.queryFilters.current } };
    const fetchChannelsAPI = { headers: {}, response: true, queryStringParameters: { type: "channel", ...this.props.queryFilters.current } };
    const fetchPreviousTimeAPI = { headers: {}, response: true, queryStringParameters: { type: "time", ...this.props.queryFilters.previous } };
    const fetchPreviousChannelsAPI = { headers: {}, response: true, queryStringParameters: { type: "channel", ...this.props.queryFilters.previous } };

    try {
      // Fetch the data for time period and channels
      const [timeStats, channelStats, previousTimeStats, previousChannelStats] = await Promise.all([
        API.get(dataAPI, statisticsPath, fetchTimeAPI),
        API.get(dataAPI, statisticsPath, fetchChannelsAPI),
        API.get(dataAPI, statisticsPath, fetchPreviousTimeAPI),
        API.get(dataAPI, statisticsPath, fetchPreviousChannelsAPI)
      ])

      const sentimentKPI = sentimentKPIObject(timeStats, previousTimeStats);
      const usageKPI = usageKPIObject(channelStats, previousChannelStats);
      return this.setState({ loading: false, sentimentKPI, usageKPI });
    }
    catch (e) {}
    return this.setState({ loading: false, sentimentKPI: null, usageKPI: null });
  }

  async componentDidMount() {
    this.loadSentimentStatistics();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }

  render() {
    if (this.state.loading)
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Slack Sentiment</div>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );


    const leftHandColumn = [];
    const centerColumn = [];
    const rightHandColumn = [];

    if (this.state.sentimentKPI) {
      const sentimentMetadata = {
        title: "Slack Channel Sentiment",
        type: "100%",
        categories: [
          { cat: "Negative", data: this.state.sentimentKPI.negative, color: "#FB6962" },
          { cat: "Mixed", data: this.state.sentimentKPI.mixed, color: "#FCFC99" },
          { cat: "Neutral", data: this.state.sentimentKPI.neutral, color: "#A8E4EF" },
          { cat: "Positive", data: this.state.sentimentKPI.positive, color: "#0CC078" },
        ]
      };
      leftHandColumn.push(<BarChart key="sentiment_kpi" chartdata={sentimentMetadata} />);
    }
    if (this.state.usageKPI) {
      const slackUserMetadata = {
        title: "Slack Users (at end of the period)",
        categories: [
          { cat: "Deleted Bots", data: this.state.usageKPI.deleted_bots, color: "#CBF6F8" },
          { cat: "Deleted Users", data: this.state.usageKPI.deleted_users, color: "#A8E4EF" },
          { cat: "Bots", data: this.state.usageKPI.active_bots, color: "#79DE79" },
          { cat: "Users", data: this.state.usageKPI.active_users, color: "#0CC078" },
        ]
      };

      rightHandColumn.push(<BarChart key="slack_users_kpi" chartdata={slackUserMetadata} />);
      centerColumn.push(<KPICard key="slack_a" KPI="slack_active" benchmark={this.props.benchmark} value={this.state.usageKPI.active_channel_ratio} compareValue={this.state.usageKPI.previous.active_channel_ratio} />);
      centerColumn.push(<KPICard key="slack_mt" KPI="slack_message_type" benchmark={this.props.benchmark} value={this.state.usageKPI.public_message_ratio} compareValue={this.state.usageKPI.previous.public_message_ratio} />);
      centerColumn.push(<KPICard key="slack_tz" KPI="slack_timezone" value={this.state.usageKPI.timezone_with_most_users}  />);
      centerColumn.push(<KPICard key="slack_mm" KPI="slack_most_messages" value={this.state.usageKPI.channel_with_most_messages}  />);

    }


    if (leftHandColumn.length === 0 && rightHandColumn.length === 0 && centerColumn.length === 0)
      return null; // Return nothing if there is no productivity data available

    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Slack Sentiment</div>
                    {leftHandColumn}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {centerColumn}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    );
  } // end of render()
} // end of class

export default SentimentKPIComponent;
