import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';

import Chart from 'react-apexcharts';

export default function TopicSentimentChart(data) {
  let topicName = data.data.key

  if (topicName.length < 2) return null // Don't render topics which are "too short" as they probably are waste of space
  if (!isNaN(topicName)) return null // Filter out numbers

  if (data.data.metadata) {
    let metadata = data.data.metadata
    //console.log(metadata)
    if (metadata.name && metadata.name.length > 5) topicName = metadata.name // This exists, but is not "real name"
    if (metadata.profile && metadata.profile.display_name && metadata.profile.display_name.length > 5) topicName = metadata.profile.display_name // secondary
    if (metadata.profile && metadata.profile.real_name && metadata.profile.real_name.length > 5) topicName = metadata.profile.real_name // Best option to use

  }

  let neutralData = []
  let negativeData = []
  let positiveData = []
  let categories = []

  for (const key in data.data.sentimentHistogram) {
    const total = data.data.sentimentHistogram[key].doc_count
    const positive = data.data.sentimentHistogram[key].sentiment.POSITIVE || 0
    const negative = data.data.sentimentHistogram[key].sentiment.NEGATIVE || 0
    neutralData.push(total - positive - negative)
    positiveData.push(positive)
    negativeData.push(negative)
    categories.push(key)
  }

  const chartOptions = {
    chart: { animations: { enabled: false }, stacked: true, toolbar: { show: false }, stackType: '100%' },
    dataLabels: { enabled: false },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, labels: { show: false } },
    legend: { show: false },
    tooltip: { y: { formatter: function(value, opts) { let percent = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return percent.toFixed(0) + '% (' + value + ")" } } },
    colors: ['#F44336', '#B4DDEE', '#37a141']
  };

  const series = [{
      name: 'Negative',
      data: negativeData
    },
    {
      name: 'Neutral',
      data: neutralData
    },
    {
      name: 'Positive',
      data: positiveData
    }
  ];

  return (
    <Fragment>
      <Grid item xs={12} lg={3}>
        <Card className="card-box mb-4">
          <CardContent className="p-0">
            <Grid container spacing={4} className="mt-4">
              <Grid item xs={12} sm={12}>
                <div className="text-center">
                  <div className="mt-3 line-height-sm">
                    <b className="font-size-lg">{topicName}</b>
                    <span className="text-black-50 d-block">Topic sentiment over time</span>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="pt-4 pr-4 pl-4">
             <Chart options={chartOptions} series={series} type="bar" height="200"/>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Fragment>
  );
}
