import React, { Component, Fragment } from 'react';
import ChannelSentimentChart from '../Charts/SentimentChannelChart';
import { API } from 'aws-amplify';
import { Grid } from '@material-ui/core';
import TreeComponent from '../../Common/SlackChartComponents/treeComponent';
import ActivityComponent from '../../Common/SlackChartComponents/activityComponent';
import Loading from '../../Loading';

class ChannelDetailsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadChannelOverview(queryFilters) {
    const dataAPI = 'slackintegration';
    const slackDataPath = '/slack/installed/overview';
    const initAPI = { headers: {}, response: false, queryStringParameters: { ...queryFilters, channelFilter: this.props.channelData.id } };

    try {
      const overview = await API.get(dataAPI, slackDataPath, initAPI);
      this.setState({ overview });
    }
    catch (e) { console.error(e) }
  }



  async componentDidMount() {
    //console.log("Rendering channel:", this.props.channelData)
    await this.loadChannelOverview(this.props.queryFilters);
    this.setState({loading:false});
  }


  render() {
    if (this.state.loading)
      return <Loading/>;

    const data = {
      key:this.props.channelData.id,
      metadata: {is_private:this.props.channelData.private, name:this.props.channelData.name, purpose:{value:"Channel sentiment over time:"}},
      sentimentHistogram:this.props.channelData.histogram
    };

    return (
      <Fragment >
        <div className="pt-3 pb-3">
          <Grid container spacing={4}>
            <ChannelSentimentChart key={"channel"+this.props.channelData.id} data={data} />
            {this.state.overview?
              <Grid item xs={4} lg={4}>
                <ActivityComponent dayHours={this.state.overview.dayHours} />
              </Grid>
            :null}
            {this.state.overview?
              <Grid item xs={4} lg={4}>
                <TreeComponent type="Topic" topic={this.state.overview.topics} />
              </Grid>
            :null}
          </Grid>
        </div>

        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default ChannelDetailsPanel
