import { API } from 'aws-amplify';
import cache from '../apiCache';
const flowtraceAPI = 'slackintegration';
const meetingAuditAPIURL = '/meeting/audit';
const meetingOverviewAPIURL = '/meeting/overview';
const heatmapAPIURL = '/meeting/heatmap';
const meetingDistributionAPIURL = '/meeting/distribution';
const initAPI = { headers: {}, timeout:60000 };

const service = "meeting";
const cacheSleep = () => new Promise(r => setTimeout(r, 500));
const tz = {range_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone};

async function heatmap(qf, user) {
    try {
        const cached = cache.getItem(service, user, heatmapAPIURL, qf);
        if(cached) return cached;
        const response = await API.get(flowtraceAPI, heatmapAPIURL, {...initAPI, queryStringParameters: { ...qf, ...tz }});

        cache.setItem(service, user, heatmapAPIURL, qf, response);
        return response;
    }
    catch (e) {
        console.error(e.message, e);
        return {exception:e, events:[]};
    }
}

async function overview(qf, user) {
    try {
        const cached = cache.getItem(service, user, meetingOverviewAPIURL, qf);
        if(cached) {
            await cacheSleep();
            return cached;
        }
        const response = await API.get(flowtraceAPI, meetingOverviewAPIURL, {...initAPI, queryStringParameters: { ...qf, ...tz }});

        cache.setItem(service, user, meetingOverviewAPIURL, qf, response);
        return response;
    }
    catch (e) {
        console.error(e.message, e);
        // Error case object structures:
        const video = {video_call:null, video_late:null, video_difference:null, video_participants:0};
        const times = {accepted:[], domains:[], invited:[], organizer:[]};
        return {exception:e, video, times, histogram: [], members:[]};
    }
}

async function audit(qf, user) {
    try {
        const cached = cache.getItem(service, user, meetingAuditAPIURL, qf);
        if(cached) {
            await cacheSleep();
            return cached;
        }
        const response = await API.get(flowtraceAPI, meetingAuditAPIURL, {...initAPI, queryStringParameters: { ...qf, ...tz }});
        cache.setItem(service, user, meetingAuditAPIURL, qf, response);
        return response;
    }
    catch (e) {
        console.error(e.message, e);
        return { meetings: [], members: [] };
    }
}

async function distribution(qf, user) {
    try {
        const cached = cache.getItem(service, user, meetingDistributionAPIURL, qf);
        if(cached) {
            await cacheSleep();
            return cached;
        }
        const response = await API.get(flowtraceAPI, meetingDistributionAPIURL, {...initAPI, queryStringParameters: { ...qf, ...tz }});
        cache.setItem(service, user, meetingDistributionAPIURL, qf, response);
        return response;
    }
    catch (e) {
        console.error(e.message, e);
        return { meetings: [], members: [] };
    }
}

const functions = { audit, overview, heatmap, distribution };
export default functions;
