import React, { Component, Fragment } from 'react';
import { Grid, Card, CardContent } from '@material-ui/core';
import { API } from 'aws-amplify';
import Loading from '../../Loading';
import FilterBar from '../../Common/FilterBar';
import RecommendationTable from './recommendationTable';
import CategoryFilter from '../../Common/FilterBar/injectedFilter';


class SurveyOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, categoryFilter:null, queryFilters: { range_type: "rolling", range_period: "90", range_limit: 1 }  };
  }


  async loadRecommendations(queryFilters) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/recommendation/list';
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };
    try {
      const recommendationData = await API.get(dataAPI, statisticsPath, initAPI);
      this.setState({ loading: false, recommendations:recommendationData.data });
    }
    catch (e) {
      this.setState({ loading: false, recommendations: []});
    }
  }

  async componentDidMount() {
    this.loadRecommendations(this.state.queryFilters);
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadRecommendations(queryFilters);
  }
  onFilterChange(category) {
    this.setState({ categoryFilter: category.filter });
  }
  componentWillUnmount() { this.setState = () => { return; }; }

  render() {
    if (this.state.loading) {
      return (
        <Fragment >
          <Grid item xs={12}>
          <FilterBar hidePeriod teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
            <Card>
              <CardContent >
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Grid>
        </Fragment>
      );
    }

    const categories = [...new Set(this.state.recommendations.map(r => r.category))];
    const selectCategory = <CategoryFilter options={categories} name="Categories" onChange={this.onFilterChange.bind(this)} />;
    let recommendations = this.state.recommendations;
    if(this.state.categoryFilter !== null)
      recommendations = this.state.recommendations.filter(r => r.category === this.state.categoryFilter);

    return (
      <Fragment>
          <Grid item xs={12}>
          <FilterBar hidePeriod teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} injectedComponent={selectCategory}/>
            <Card>
              <CardContent >
                <RecommendationTable recommendations={recommendations} />
              </CardContent>
            </Card>
          </Grid>
        </Fragment>
    );
  } // end of render()
} // end of class

export default SurveyOverview
