import React, { Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import { TextField, MenuItem, Select, FormControl } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const maxWidth = { style: { maxWidth: "800px" } };

const introductionDefault =
  "Hi Everyone!\n\n" +
  "As we continue to grow and extend our team, I want to ensure we continue our success as a well aligned business. Sometimes growing teams entail hidden pains which go unnoticed. These growing pains can hurt teamwork, productivity, and employee wellbeing. I want to assure we are committed to our success together and are looking to improve the way we work in the future.\n\n" +
  "We are embarking on an initiative to improve the experience of team collaboration and communication. Soon enough you will notice a tool called @Flowtrace, which analyses the way we do work in Slack.\n\n" +
  "I want to ensure that your private communication remains private, and @Flowtrace is giving us insights on trends about how we share the information between us. The tool looks Slack channels, and reveals insights into our sharing patterns. It will not analyse your direct messages, nor private files.\n\n" +
  "Please contact me if you have any questions, or would like more information about our 'Continual Improvement' initiative.\n\n" +
  "Let's make team communication and collaboration at {COMPANY} better for everyone.\n\n" +
  "Best regards,\n" +
  "{NAME}";

const introductionTextHelp =
  <MuiAlert className="mb-4" severity="info" {...maxWidth}>
    <div className="d-flex align-items-center align-content-center" >
      <span>


        <strong className="d-block mb-3">Send a short message to your team to introduce the benefits of Flowtrace</strong>
        <p>1. Please enter the message you’d like us to send on your behalf.</p>
        <ul>
          <li>Feel free to use the template we have prepared for you and adjust it as you see fit</li>
          <li>Please include “@Flowtrace” in your message so we know the introduction has been made.</li>
          <li>Message is sent by Flowtrace on your behalf and will appear as it was sent by you.</li>
        </ul>
        <p>2. Please select the channel you would like to share your 'Introduction Message' in.</p>
        <p>3. Click 'Preview' to see how the message will appear once posted to Slack.</p>
        <p>4. Click ‘Send Introduction’ to post the message into your Slack.</p>

      </span>
    </div>
  </MuiAlert>;

const introductionError =
  <MuiAlert className="mb-4" severity="error" style={{minWidth:"100%", maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">Something went wrong. Please make sure:</strong>
        <ul>
          <li>Your message includes the name @Flowtrace</li>
          <li>You have selected channel that you are currently on</li>
          <li>You have succesfully connected Flowtrace to Slack</li>
        </ul>
        <p>After you have checked your settings and the message, please try again.</p>
      </span>
    </div>
  </MuiAlert>;

const introductionNotOnChannelError =
  <MuiAlert className="mb-4" severity="error" style={{minWidth:"100%", maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">You are not present on the selected channel.</strong>
        Please choose channel you are part of, or join the channel in your Slack before you retry sending the introduction message.<br/>
      </span>
    </div>
  </MuiAlert>;

class IntroductionMessage extends Component {
  constructor(props) {
    super(props);
    if (!this.props.settings.introduction)
      this.props.onUpdate({ introduction: introductionDefault, introductionValid: true });
    if (!this.props.settings.introductionChannel)
      this.props.onUpdate({ introductionChannel: "" });
    this.state = { loading: null, previewOpen: false };
  }


  async introduce() {

    this.setState({ loading: true, introError: null });
    try {
      const name = 'slackintegration';
      const path = '/slack/api/introduction';
      const userName = this.props.flowtraceUser ? (this.props.flowtraceUser.full_name ? this.props.flowtraceUser.full_name : this.props.flowtraceUser.name) : null;
      const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : null;
      // Lets do last check that name and company are definitely sent correct way
      const init = { headers: { "Content-Type": "application/json" }, response: true, body: { message: this.props.settings.introduction.replace(/{NAME}/g, userName).replace(/{COMPANY}/g, companyName), channel: this.props.settings.introductionChannel } };

      const response = await API.post(name, path, init);
      if (response.data.success) {
        this.props.onUpdate({ introductionSent: true });
        this.props.nextStep();
      }
      else
        this.setState({ introError: introductionError });
    }
    catch (e) {
      if (e.response && e.response.data && e.response.data.message === "An API error occurred: not_in_channel")
        return this.setState({ loading: false, introError: introductionNotOnChannelError });
      else
        this.setState({ introError: introductionError });
      console.error(e);
    }
    return this.setState({ loading: false });
  }

  async fetchChannels() {
    const name = 'slackintegration';
    const path = '/slack/api/channels/list';
    const init = { headers: {}, response: true };
    this.setState({ loading: true });

    try {
      const response = await API.get(name, path, init);
      this.props.onUpdate({ installationInfo: { channels:response.data.allChannels } });
      return this.setState({ loading: false });
    }
    catch (e) { console.error(e) }
    this.props.onUpdate({ installationInfo: null });
    return this.setState({ loading: false });
  }

  previewText(event) {
    this.props.onUpdate({ introduction: event.target.value, introductionValid: event.target.value.toLowerCase().includes("@flowtrace") });
  }

  closePreview() {
    this.setState({ previewOpen: false });
  }

  openPreview() {
    const userName = this.props.flowtraceUser ? (this.props.flowtraceUser.full_name ? this.props.flowtraceUser.full_name : this.props.flowtraceUser.name) : "";
    const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : "";
    this.props.onUpdate({ introduction: this.props.settings.introduction.replace(/{NAME}/g, userName).replace(/{COMPANY}/g, companyName) });
    this.setState({ previewOpen: true });
  }

  introductionChannelSelected = (event) => {
    const userName = this.props.flowtraceUser ? (this.props.flowtraceUser.full_name ? this.props.flowtraceUser.full_name : this.props.flowtraceUser.name) : null;
    const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : null;
    if (userName && companyName)
      this.props.onUpdate({ introduction: this.props.settings.introduction.replace(/{NAME}/g, userName).replace(/{COMPANY}/g, companyName) });
    this.props.onUpdate({ introductionChannel: event.target.value });
  };

  async componentDidMount() {
    if (this.props.isActive)
      await this.fetchChannels();
  }

  async componentDidUpdate() {
    if (this.props.isActive && this.state.loading === null)
      await this.fetchChannels();
  }

  render() {
    if(!this.props.isActive) return null;
    if (this.state.loading || this.state.loading === null)
      return (
        <StepNavigation stepTitle="Introduce Flowtrace to your team" {...this.props} nextDisabled={true}>
          <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
            <Grid item xs={12} sm={12} md={12} >
              <Loading/>
            </Grid>
          </Grid>
        </StepNavigation>
      );

    const userName = this.props.flowtraceUser ? (this.props.flowtraceUser.full_name ? this.props.flowtraceUser.full_name : this.props.flowtraceUser.name) : "";
    const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : "";

    let channels = [];
    if (this.props.settings.installationInfo && this.props.settings.installationInfo.channels)
      channels = this.props.settings.installationInfo.channels.filter(i => !i.is_private && i.is_channel && !i.is_archived).sort((a, b) => a.name > b.name ? 1 : -1).map(i => { return { id: i.id, name: i.name } });
    const channelName = channels.find(i => i.id === this.props.settings.introductionChannel) ? channels.find(i => i.id === this.props.settings.introductionChannel).name : "channel-not-selected";

    const nextAction = () => this.introduce.bind(this);

    const skipIntroduction = <Button onClick={() => {this.props.onUpdate({ introductionSent: true }); this.props.nextStep(); window.scrollTo(0, 0);}} rel="noopener" size="medium" variant="outlined" color="primary" className="mr-3">Our team knows about Flowtrace already</Button>;


    return (
      <StepNavigation additionalAction={skipIntroduction} stepTitle="Introduce Flowtrace to your team" {...this.props} nextAction={nextAction} nextText="Send Introduction" nextDisabled={!(this.props.settings.introductionValid && this.props.settings.introductionChannel.length>0)}>
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12} {...maxWidth}>
            <Grid container spacing={0} justifyContent="center" alignItems="stretch" style={{maxWidth:"100%"}}>
              <Grid item xs={12}>
                {introductionTextHelp}
                <FormControl>
                  <Select value={this.props.settings.introductionChannel} onChange={this.introductionChannelSelected} displayEmpty variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled> Please select channel to post your introduction message. </MenuItem>
                    {channels.map(i => <MenuItem value={i.id} key={"channel-" + i.id}>{"#" + i.name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField className="mt-3 mb-3" id="introText" onChange={this.previewText.bind(this)} label="Introduction Message" multiline defaultValue={this.props.settings.introduction.replace(/{NAME}/g, userName).replace(/{COMPANY}/g, companyName)} variant="outlined" style={{width:"100%"}}/>
              </Grid>
              <Grid item xs={12}>
                <Button variant="outlined" color="primary" onClick={this.openPreview.bind(this)}> Preview </Button>
                <Dialog maxWidth="md" fullWidth={true} open={this.state.previewOpen} onClose={this.closePreview.bind(this)} >
                  <DialogTitle id="preview-title">Preview Introduction Text</DialogTitle>
                  <DialogContent style={{background: "url('/images/slack_message_background.png') no-repeat",backgroundSize: "auto"}}>
                    <Grid className="pl-5 pt-3" container spacing={2} direction="column" justifyContent="center" alignItems="stretch">
                      <Grid item xs={12} sm={12} md={12} >
                        <strong><h5>{ "#" + channelName}</h5></strong><br/>
                      </Grid>
                      <Grid className="pl-5" item xs={12} sm={12} md={12} >
                        <strong className="pl-3">{ userName}</strong>
                      </Grid>
                      <Grid className="pl-5" item xs={12} sm={12} md={12} >
                        <TextField className="pl-3" id="previewText" label="" multiline defaultValue={this.props.settings.introduction } disabled style={{width:"100%"}}/>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button className="pt-3 mt-3" onClick={this.closePreview.bind(this)} color="primary" autoFocus> Close </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid className="pt-3" item xs={12}>
                {this.state.introError?this.state.introError:null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StepNavigation>
    );
  }
}

export default withRouter(IntroductionMessage);
