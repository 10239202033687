import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(1), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function CombinedSurveyCharts(data) {

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const series = {
    nps: [],
    productivity: [],
    meeting: [],
    engagement: [],
    management: [],
    teamwork: [],
    team_composition: [],
    company: [],
    cross_team_collaboration: [],
    companyNPS: [],
    companyEffectiveness: []
  };

  // Get all X-axis items as a sorted array:
  const NPScategories = [...new Set(data.teamStats.reduce((acc, cur) => { return acc.concat(cur.NPS) }, []).map(i => i.date))].sort((a, b) => (a.date > b.date) ? 1 : -1);
  const effectivenessCategories = [...new Set(data.teamStats.reduce((acc, cur) => { return acc.concat(cur.effectiveness) }, []).map(i => i.date))].sort((a, b) => (a.date > b.date) ? 1 : -1);

  // Return nothing as there is nothing to render in this component
  if (NPScategories.length === 0 && effectivenessCategories.length === 0)
    return null;

  for (const team of data.teamStats ? data.teamStats : []) {
    const teamData = { nps: [], productivity: [], meeting: [], engagement: [], management: [], teamwork: [], team_composition: [], company: [], cross_team_collaboration: [] };
    for (const cat of NPScategories) {
      // Loop every category through so the stats are aligned
      const stat = team.NPS.find(stat => stat.date === cat);
      teamData.nps.push(stat && stat.score ? stat.score : null);
    }
    series.nps.push({ name: team.team, type: "line", data: teamData.nps });
    for (const cat of effectivenessCategories) {
      const stat = team.effectiveness.find(stat => stat.date === cat);
      for (const category of Object.keys(teamData)) {
        if (category === "nps") continue; // This is populated already above
        teamData[category].push(stat && stat[category] ? stat[category].score : null);
      }

    }

    series.productivity.push({ name: team.team, type: "line", data: teamData.productivity });
    series.meeting.push({ name: team.team, type: "line", data: teamData.meeting });
    series.engagement.push({ name: team.team, type: "line", data: teamData.engagement });
    series.management.push({ name: team.team, type: "line", data: teamData.management });
    series.teamwork.push({ name: team.team, type: "line", data: teamData.teamwork });
    series.team_composition.push({ name: team.team, type: "line", data: teamData.team_composition });
    series.cross_team_collaboration.push({ name: team.team, type: "line", data: teamData.cross_team_collaboration });
    series.company.push({ name: team.team, type: "line", data: teamData.company });
  }


  const NPSConfig = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: NPScategories },
    yaxis: { min: -100, max: 100, forceNiceScale: true, labels: { formatter: function(val) { return val ? parseInt(val) : 0 } } },
    legend: { position: "right", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };
  const SurveyConfig = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: effectivenessCategories },
    yaxis: { min: -100, max: 100, forceNiceScale: true, labels: { formatter: function(val) { return val !==null ? val.toFixed(1) : 0 } } },
    legend: { position: "right", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };


  // Return NPS component only without the tabs etc.
  if (NPScategories.length > 0 && effectivenessCategories.length === 0)
    return (<Card className="card-box d-flex m-3" style={{width:"100%"}}>
      <CardContent style={{width:"100%"}}>
        <div className="mt-3 line-height-sm align-items-center justify-content-left" >
          <Grid item xs={12} sm={12} >
            <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
              <div className=" br-5" ><b className="font-size-lg " >Employee Netpromoter Score, eNPS - Team Breakdown</b></div>
              <span className="text-black-50 d-block">How your teams improve over time</span>
              <Chart options={NPSConfig} series={series.nps} type="line" height="250"/>
            </div></div>
          </Grid>
        </div>
      </CardContent>
    </Card>)

  return (
    <Card className="card-box d-flex m-3" style={{width:"100%"}}>
      <CardContent style={{width:"100%"}}>
        <div className="mt-3 line-height-sm align-items-center justify-content-left" >
        <div className={classes.root}>
            <div className={classes.themeColorTabs}>
              <StyledTabs value={value} onChange={handleChange} aria-label="Team breakdown" variant="scrollable" scrollButtons="auto">
                <StyledTab label="eNPS" />
                <StyledTab label="Productivity" />
                <StyledTab label="Meetings" />
                <StyledTab label="Engagement" />
                <StyledTab label="Management" />
                <StyledTab label="Teamwork" />
                <StyledTab label="Team Composition" />
                <StyledTab label="Company Leadership" />
                <StyledTab label="Cross-team Collaboration" />
              </StyledTabs>
            </div>
            <TabPanel value={value} index={0}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">eNPS by the team</span>
                  <Chart options={NPSConfig} series={series.nps} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Productivity survey by the team</span>
                  <Chart options={SurveyConfig} series={series.productivity} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Meeting effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.meeting} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Employee engagement by the team</span>
                  <Chart options={SurveyConfig} series={series.engagement} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Management effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.management} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Team work effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.teamwork} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Team composition effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.team_composition} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Company vision and strategy effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.company} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Cross-team collaboration effectiveness by the team</span>
                  <Chart options={SurveyConfig} series={series.cross_team_collaboration} type="line" height="250"/>
                </div></div>
              </Grid>
            </TabPanel>
          </div>
        </div>
      </CardContent>
    </Card>
  );


}
