import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Card, CardContent, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
const info = <HelpOutline style={{ fontSize: 15 }}/>;

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {

    const countData = {
      internal:{accepted: [], declined:[], tentative:[], pending:[], optional:[]},
      total:{accepted: [], declined:[], tentative:[], pending:[], optional:[]},
      group:{accepted: [], declined:[], tentative:[], pending:[]}
    };
    // Loop every category through so the stats are aligned
    for (const stat of this.props.histogram) {
      const cat = stat.key_as_string;

      const ia = stat.internal;
      countData.internal.accepted.push({x:cat, y:ia.accepted.sum/ia.invites.sum*100, original:ia.accepted.sum});
      countData.internal.declined.push({x:cat, y:ia.declined.sum/ia.invites.sum*100, original:ia.declined.sum});
      countData.internal.tentative.push({x:cat, y:ia.tentative.sum/ia.invites.sum*100, original:ia.tentative.sum});
      countData.internal.pending.push({x:cat, y:ia.pending.sum/ia.invites.sum*100, original:ia.pending.sum});
      countData.internal.optional.push({x:cat, y:ia.optional.sum/ia.invites.sum*100, original:ia.optional.sum});

      const it = stat.invite;
      countData.total.accepted.push({x:cat, y:it.accepted.sum/it.invites.sum*100, original:it.accepted.sum});
      countData.total.declined.push({x:cat, y:it.declined.sum/it.invites.sum*100, original:it.declined.sum});
      countData.total.tentative.push({x:cat, y:it.tentative.sum/it.invites.sum*100, original:it.tentative.sum});
      countData.total.pending.push({x:cat, y:it.pending.sum/it.invites.sum*100, original:it.pending.sum});
      countData.total.optional.push({x:cat, y:it.optional.sum/it.invites.sum*100, original:it.optional.sum});

      const gt = stat.group;
      countData.group.accepted.push({x:cat, y:(!gt.invites.sum?0:gt.accepted.sum/gt.invites.sum*100), original:gt.accepted.sum});
      countData.group.declined.push({x:cat, y:(!gt.invites.sum?0:gt.declined.sum/gt.invites.sum*100), original:gt.declined.sum});
      countData.group.tentative.push({x:cat, y:(!gt.invites.sum?0:gt.tentative.sum/gt.invites.sum*100), original:gt.tentative.sum});
      countData.group.pending.push({x:cat, y:(!gt.invites.sum?0:gt.pending.sum/gt.invites.sum*100), original:gt.pending.sum});
    }

    const trendLinedSeries = { total: [], internal: [], group:[] };

    trendLinedSeries.internal.push({ name: "Accepted", type: "bar", data: countData.internal.accepted });
    trendLinedSeries.internal.push({ name: "Declined", type: "bar", data: countData.internal.declined });
    trendLinedSeries.internal.push({ name: "Pending", type: "bar", data: countData.internal.pending });
    trendLinedSeries.internal.push({ name: "Tentative", type: "bar", data: countData.internal.tentative });
    trendLinedSeries.internal.push({ name: "Optional", type: "line", data: countData.internal.optional });

    trendLinedSeries.total.push({ name: "Accepted", type: "bar", data: countData.total.accepted });
    trendLinedSeries.total.push({ name: "Declined", type: "bar", data: countData.total.declined });
    trendLinedSeries.total.push({ name: "Pending", type: "bar", data: countData.total.pending });
    trendLinedSeries.total.push({ name: "Tentative", type: "bar", data: countData.total.tentative });
    trendLinedSeries.total.push({ name: "Optional", type: "line", data: countData.total.optional });

    trendLinedSeries.group.push({ name: "Accepted", type: "bar", data: countData.group.accepted });
    trendLinedSeries.group.push({ name: "Declined", type: "bar", data: countData.group.declined });
    trendLinedSeries.group.push({ name: "Pending", type: "bar", data: countData.group.pending });
    trendLinedSeries.group.push({ name: "Tentative", type: "bar", data: countData.group.tentative });

    this.setState({ loading: false, trendLinedSeries, categories:this.props.histogram.map(s => s.key_as_string) });
  }


  render() {

    if (this.state.loading)
      return <div/>;

    const meetingAttendaceTrendLine = {
      chart: { animations: { enabled: false }, type: "bar", stacked: true,  toolbar: { show: false } },
      dataLabels: { enabled: false },
      xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: this.state.categories ? this.state.categories : [] },
      yaxis: { min:0, max:100, labels: {formatter: (val) => { return val.toFixed(0) + "%"; } }},
      tooltip: { y: { formatter: function(value, { seriesIndex, dataPointIndex, w }) {
        return value.toFixed(1) +"% (" + w.config.series[seriesIndex].data[dataPointIndex].original + ")";
      }}},
      stroke: { width: [0,0,0,0,3]},
      markers: { size: [0,0,0,0,3], strokeWidth: [0,0,0,0,1], shape: "circle", radius: [0,0,0,0,2], },
      legend: { position: "right", show: true },
      theme: { mode: 'light', palette: 'palette2', },
    };

    return (
      <Fragment >
          <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
          <div className=" font-weight-bold font-size-xl text-uppercase" >Meeting Invite Trends</div>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <div className="d-flex align-items-start">
                <Tooltip title="This chart shows the status of internal direct invites.">
                  <div className="font-weight-bold mr-3"> Internal Direct Invites (%) {info}</div>
                </Tooltip>
              </div>
              <Chart options={meetingAttendaceTrendLine} series={this.state.trendLinedSeries.internal} height="200"/>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-flex align-items-start">
                <Tooltip title="This chart shows the status of internal and external direct invites.">
                  <div className="font-weight-bold mr-3"> All Direct Invites (%) {info}</div>
                </Tooltip>
              </div>
              <Chart options={meetingAttendaceTrendLine} series={this.state.trendLinedSeries.total} height="200"/>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="d-flex align-items-start">
                <Tooltip title="This chart shows the status of group invites. This number is often the lowest of these all invite types.">
                  <div className="font-weight-bold mr-3"> Group Invites (%) {info}</div>
                </Tooltip>
              </div>
              <Chart options={meetingAttendaceTrendLine} series={this.state.trendLinedSeries.group} height="200"/>
            </Grid>
          </Grid>
          </CardContent>
         </Card>

        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
