import { API } from 'aws-amplify';

async function slackSurveyScopeInstalled() {
    const flowtraceAPI = 'slackintegration';
    const oauth2InstallURLs = '/oauth2/install/current';
    const payload = { headers: {}, response: true };

    try {
        return await API.get(flowtraceAPI, oauth2InstallURLs, payload).then((response, data, body) => {
            const permissions = response.data.permissions.reduce((acc, i) => {
                acc[Object.keys(i)[0]] = Object.values(i)[0];
                return acc;
            }, {});
            // Does Slack installation contain the right scope? (This is required for surveys -> write to user and reporting -> write to channels)
            if (permissions.slack_surveys)
                return true;
        });
    }
    catch (e) {
        console.error(e);
    }
    return false;
}

export default slackSurveyScopeInstalled;
