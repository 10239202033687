import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import surveyHeadline from './surveyScoreHeadline';


export default function CompanyCharts(props) {
  const companyNPS = [];
  const NPScategories = props.company.NPS.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date });

  // Populate company NPS data series array:
  const datapoints = [];
  const responseRateDataPoints = [];
  for (const cat of NPScategories) {
    // Loop every category through so the stats are aligned
    const stat = props.company.NPS.find(stat => stat.date === cat);
    datapoints.push(stat.score !== undefined ? stat.score : null);
    responseRateDataPoints.push(stat.responseRate !== undefined ? stat.responseRate : null);
  }
  companyNPS.push({ name: "eNPS", type: "line", data: datapoints });
  companyNPS.push({ name: "Response Rate (%)", type: "line", data: responseRateDataPoints });


  const CompanyNPSConfig = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: NPScategories },
    yaxis: { min: -100, max: 100, forceNiceScale: true, labels: { formatter: function(val) { return val ? parseInt(val) : 0 } } },
    legend: { position: "top", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    stroke: { width: 2, dashArray: [0, 3] },
    markers: { size: [4, 2], strokeColors: '#fff', strokeWidth: [2, 1], shape: "circle", radius: [2, 1], }
  };

  const notNullScores = datapoints.filter(dp => dp !== null)

  const current = notNullScores.length > 0 ? notNullScores[notNullScores.length - 1] : null;
  const previous = notNullScores.length > 1 ? notNullScores[notNullScores.length - 2] : null;

  const headline = surveyHeadline.getNPSHeadLine(current, previous, "Employee NPS" + (props.minimize ? "" : " (Net Promoter Score)"), props.minimize);

  return (
    <Grid item xs={props.minimize?6:12}sm={props.minimize?6:12} m={props.minimize?6:12} lg={props.minimize?3:6} >
      <Card className="card-box d-flex " >
        <CardContent className="p-3" style={{width:"100%"}}>
          <Grid item sm={12} lg={12}>
            <div className="text-left">
              <div className="line-height-sm align-items-center justify-content-left">
                {props.minimize?
                  <div>
                    <div><b className="font-size-md" >{headline}</b></div>
                  </div>
                :
                <div>
                  <div className="br-5 mb-3" ><b className="font-size-lg " >{headline}</b></div>
                  <span className="text-black-50 d-block mb-3">Company wide pulse survey trend line and score over time</span>
                  <Chart options={CompanyNPSConfig} series={companyNPS} type="line" height="300"/>
                </div>
                }
              </div>
            </div>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );


}
