import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
//import moment from 'moment'

class GenericChart extends Component {

  render() {
    const series = [];
    for(const key of Object.keys(this.props.data))
      series.push({ name: key, data: this.props.data[key] });

    if (!series.length)
      return <div/>;

  const cycleTimeFormatter = (val, index) => {
    // This is highly likely non-datapoint (i.e. grid marking)
    const minutes = val / 60;
    if(Number.isInteger(index))
      return Math.floor((minutes - minutes % 1440) / 60 / 24).toFixed(0) + " days";
    // Creates style for: 0d 0h 0m
    const m = (minutes % 60).toFixed(0) + "m";
    const h = Math.floor(minutes % 1440 / 60).toFixed(0) + "h ";
    const d = Math.floor((minutes - minutes % 1440) / 60 / 24).toFixed(0) + "d ";
    return d + h + m;
  }

  const metricFormatter = (val, index) => {
    if(val)
      return val + " %";
    return "0 %";
  };

  const chartConfig = {
    chart: { animations: { enabled: false }, stacked:true, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: this.props.categories },
    yaxis: {
      forceNiceScale: true,
      labels: { formatter: (this.props.metric==="cycle-time"?cycleTimeFormatter:metricFormatter) }},

    legend: { position: "right", show: true },
   markers: { size: 3, strokeColors: '#fff', strokeWidth: 1, shape: "circle", radius: 2, }
};

    return (
      <Fragment >
              <Chart options={chartConfig} series={series} type="line" height="300"/>
        </Fragment>
    );
  }
}

export default GenericChart;
