import React, { Fragment, useState } from 'react';
import { Button, DialogActions } from '@material-ui/core';
import Auth0Button from './../Auth0Button';
import Oauth2 from './oauth2Install';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../../../../flowtrace-components/Loading';


const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-0 pb-0">
      {message}
    </MuiAlert>
  );
};

export default function ZoomConfigureModal(props) {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);


  const authError = (error) => {
    console.log("authError", error);
    setMessage(createAlert("error", "We couldn't complete the verification."));
    if(error.original === "User closed the popup window")
      props.onInstall(false, false, "You didn't complete the integration.");
    else
      props.onInstall(false, false, error.description ? error.description:null); // Tell the page we got a failure
    setLoading(false);
  };

  const authSuccess = (profile) => {
    setLoading(true);
    Oauth2(profile.sub).then(data => {
      if(data && data.success)
        setMessage(createAlert("success", "Installation succeeded."));
      else
        setMessage(createAlert("warning", "Something didn't go right."));
      props.onInstall(data.success);
    }).finally(() => {
      setLoading(false);
    });
  };

  const uninstall = <Button color="primary" variant="outlined" onClick={() => window.open("https://marketplace.zoom.us/user/installed")}>Uninstall</Button>;
  const install = <Auth0Button loading={loading} cbProfile={authSuccess} cbError={authError} reinstall={props.existing} connection={"zoom"} />;

  const dialogButtons =
    <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
      {install}
      {uninstall}
      {props.cancelButton}
    </DialogActions>;

  if(!props.existing)
    return install;

  if (loading)
    return <Fragment> <Loading minimize={true}/> {dialogButtons} </Fragment>;

  return (
    <Fragment>
        <div>
          <p>You can choose to re-install Zoom integration, or uninstall it by navigating to Zoom App Marketplace:</p>
          {message}
          {dialogButtons}
        </div>
    </Fragment>
  )
}