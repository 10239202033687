import React, { Fragment } from 'react';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import MaterialTable from '@material-table/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { API } from 'aws-amplify';

import { getLogo } from '../../../../flowtrace-components/Common/Logos'


const mergeUser = async (selectedMember, masterMember) => {

    const callHeaders = { headers: {}, response: true };
    const response = await API.patch('slackintegration', `/members/clientAccountMembers/${selectedMember.id}/to/${masterMember.id}`, callHeaders)

    return (response.data && response.data.message ? response.data.message : true)

}

const unmergeUser = async (selectedMember) => {

    const params = { headers: {}, response: true, body: { properties: { mapped_member: ''}  } };
    const response = await API.patch('slackintegration', `/members/clientAccountMembers/${selectedMember.id}`, params);

    return (response.data && response.data.message ? response.data.message : true)
}

const extractId = (id) => {
  if (id) {
    const extractedId = id.toString().split('.');
    return extractedId[extractedId.length -1];
  }

  return '';
}

const flattenUsers = (members) => {

  const allUsers = [];

  for (let member of members) {
    let { id, user, email, name } = member
    let memberId = id;

    for (let u of user) {
      const {id, type, account, merge, orgUnitPath, manualOrgUnitPath} = u;
      const userCompleteId = memberId.find(m => m.includes(id)).split('.');

      let teamId;

      if (type === 'SLACK') {
        teamId = userCompleteId[0].replace('SLACK-', '');
      }

      const newUser = {
        id: type + '-' + account +'.' + id,
        type,
        email,
        name,
        merge,
        orgUnitPath: manualOrgUnitPath || orgUnitPath,
        account: teamId || account
      };

      allUsers.push(newUser)
    }
  }

  return allUsers;
}

export default function MergeMember(props) {

  const {teamMembers} = props;
  const globalAdmin = props.flowtraceUser.flowtrace === "global_admin";
  const [selectedMember, setSelectedMember] = React.useState(null);
  const [selectedMemberFromUser, setSelectedMemberFromUser] = React.useState(null);
  const [selectedUnmergeMember, setSelectedUnmergeMember] = React.useState(null);
  const [selectedUnmergeMemberFromUser, setSelectedUnmergeMemberFromUser] = React.useState(null);
  const [masterMember, setMasterMember] = React.useState(null);


  let filteredTeamMembers = teamMembers.filter(m => !m.is_deleted &&  !m.is_bot && m.user[0].id !== 'USLACKBOT' && m.email && !m.email.includes('[bot]'));

  if (selectedMember) {
    filteredTeamMembers = filteredTeamMembers.filter(t => t.email !== selectedMember.email);
  }
  const memberData = filteredTeamMembers.map(m => { return {...m, userCount:m.user.length}});

  const handleSave = async() => {
    if (selectedMember && !selectedUnmergeMember) {
      await mergeUser(selectedMember, masterMember).then(() => {
        setMasterMember(null);
        setSelectedMember(null);
        props.onClose();
      });
    }

    if (!selectedMember && selectedUnmergeMember) {
      await unmergeUser(selectedUnmergeMember).then(() => {
        setSelectedUnmergeMember(null);
        props.onClose();
      });
    }

  };

  const handleClose = async() => {
      setMasterMember(null);
      setSelectedMember(null);
      setSelectedUnmergeMember(null);
      props.onClose();
  };

  const handleSelectedUser = (user) => {
    if (!selectedMember && !masterMember) {
      setSelectedMember(user);
      setSelectedMemberFromUser(getSelectedMemberFromUser(user));
    }

    if (selectedMember && !masterMember) {
      setMasterMember(user);
    }
  }

  const handleUnmergeUser = (user) => {
      setSelectedUnmergeMember(user);
      setSelectedUnmergeMemberFromUser(getSelectedMemberFromUser(user));
  }

  const userAccountTypeRender = (type) => <img style={{maxHeight:"25px", maxWidth:"25px"}} alt="Logo" src={getLogo(type.toLowerCase())} />;

  const generateActionButton = (user) => {
    if (user.merge === 'manual') {
      return  <div onClick={() => handleUnmergeUser(user)}>
                <ButtonGroup variant="contained" color="secondary" aria-label="split button">
                <Button color="secondary">Un-merge<ArrowForwardIcon/></Button>
                </ButtonGroup>
              </div>
    }
    return  <div onClick={() => handleSelectedUser(user)}>
              <ButtonGroup variant="contained" color="secondary" aria-label="split button">
              <Button color="secondary">Select<ArrowForwardIcon/></Button>
              </ButtonGroup>
            </div>
  }

  const getSelectedMemberFromUser = (user) => {

    const currentMember = teamMembers.filter(t => t.id.includes(user.id));
    return currentMember
  }

  const selectedMemberColums = [
      { title: "Name", field:"name", defaultSort:"asc", editable: 'never'},
      { title: "Email", field:"email", editable: 'never'},
      { title: "Users", field:"userCount", editable: 'never', render: rowData => rowData.user.length},
      { title: "Team", field:"orgUnitPath"},
      { title: "User ID", field:"id", render: rowData => extractId(rowData.id)},

    ];
  const selectedMemberOptions = { search: false, tableLayout: "fixed", toolbar: false, padding: "dense", pageSize: 5, paging: false, showDetailPanelIcon: false };

  const allUsersColumns = [
      { title: "", width: 30, field:"type", editable: 'never', render: ( rowData => rowData.type? userAccountTypeRender(rowData.type) : '')},
      { title: "Type", width: 80, field:"type", editable: 'never'},
      { title: "Name", field:"name", editable: 'never'},
      { title: "Email", field:"email", editable: 'never'},
      { title: "Account ID", field:"account", editable: 'never'},
      { title: "Team", field:"orgUnitPath"},
      { title: "User ID", field:"id", render: rowData => extractId(rowData.id)}
    ];
  if(globalAdmin)
    allUsersColumns.push({ title: "", field:"selected", editable: 'never', render: rowData => generateActionButton(rowData)});

  const userDetailsColumns = [
      { title: "", width: 30, field:"type", editable: 'never', render: ( rowData => rowData.type? userAccountTypeRender(rowData.type) : '')},
      { title: "Name", field:"name"},
      { title: "Email", field:"email"},
      { title: "Account ID", field:"account"},
      { title: "User ID", field:"id", render: rowData => extractId(rowData.id)},
    ];

  const detailOptions = { search: false, emptyRowsWhenPaging:false, tableLayout:"auto", toolbar:false, padding:"dense", pageSize:20, paging:false, showDetailPanelIcon: true };
  const allUsersOptions = { tableLayout: "fixed", padding: "dense", pageSize: 10, showTitle: false};

  const geAdditionalUserData = (member) => {
    const  {name, email, orgUnitPath} = member;
    const usersWithAdditinalData = [];

    for (let user of member.user) {
      usersWithAdditinalData.push({email, name, orgUnitPath, ...user});
    }
    return usersWithAdditinalData;
  }

  const userDetailPanel = (r) =>  <MaterialTable columns={userDetailsColumns} data={geAdditionalUserData(r.rowData)} options={detailOptions} />

  return (
    <Fragment>
      <Dialog fullWidth={true} maxWidth="xl" open={props.dialogState} onClose={() => { props.onClose(); }} aria-labelledby="form-dialog-title">

          {memberData.length > 0  && !selectedMember && !masterMember && !selectedUnmergeMember &&
            <DialogContent>
              {globalAdmin?
                <h5>Select member record you want to merge:</h5>
                :
                <h5>All integration source user records:</h5>
              }
              <MaterialTable
                columns={allUsersColumns}
                data={flattenUsers(memberData)}
                options={allUsersOptions}
              />
            </DialogContent>
          }

          {memberData.length > 0 && selectedMember && !masterMember && !selectedUnmergeMember &&
            <DialogContent>

              <h5>Selected member record to merge with:</h5>
              <MaterialTable
                columns={selectedMemberColums}
                data={selectedMemberFromUser.map((d, i) => ({
                    ...d,
                    tableData: {
                        showDetailPanel: userDetailPanel,
                    },
                }))}
                options={selectedMemberOptions}
                detailPanel={  userDetailPanel }
              />
              <h5 className="mt-6 pt-5">Select member record you want to merge with:</h5>
            <MaterialTable
                columns={allUsersColumns}
                data={flattenUsers(memberData).filter(m => m.merge !== 'manual').filter(u => ['GSUITE', 'SLACK'].includes(u.type))}
                options={allUsersOptions}
              />
            </DialogContent>
          }

          {selectedMember && masterMember &&  !selectedUnmergeMember &&

            <DialogContent>

              <h5>Merge user:</h5>
              <MaterialTable
                columns={userDetailsColumns}
                data={[selectedMember]}
                options={{ tableLayout: "fixed", padding: "dense", pageSize: 10, paging: false, search: false, showTitle: false}}
              />
              <h5 className="mt-6 pt-5">To another user:</h5>
              <MaterialTable
                columns={userDetailsColumns}
                data={[masterMember]}
                options={{ tableLayout: "fixed", padding: "dense", pageSize: 10, paging: false, search: false, showTitle: false}}
              />
            </DialogContent>
          }

          {memberData.length > 0 && selectedUnmergeMember && !selectedMember && !masterMember &&
            <DialogContent>
              <h5>Separate selected user record:</h5>
              <MaterialTable
                columns={[
                  { title: "Name", field:"name", defaultSort:"asc", editable: 'never'},
                  { title: "Email", field:"email", editable: 'never'},
                  { title: "Team", field:"orgUnitPath"},
                  { title: "Users", field:"userCount", editable: 'never', render: rowData => rowData.user.length},
                ]}
                data={selectedUnmergeMemberFromUser.map((d, i) => ({
                    ...d,
                    tableData: {
                        showDetailPanel: userDetailPanel,
                    },
                }))}
                options={selectedMemberOptions}
                detailPanel={  userDetailPanel }
              />

              <h5 className="mt-6 pt-5"c>User record to be separated:</h5>
              <MaterialTable
                columns={userDetailsColumns}
                data={[selectedUnmergeMember]}
                options={selectedMemberOptions}
              />
            </DialogContent>

          }

        <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
          {globalAdmin?
            <div>
              <Button onClick={() => handleSave()} disabled={(!selectedMember && !selectedUnmergeMember) && !masterMember} variant="contained" color="primary" size="large"> Confirm </Button>
              <Button onClick={() => handleClose()} size="large" color="primary"> Cancel </Button>
            </div>
            :
            <Button onClick={() => handleClose()} size="large" color="primary"> Close </Button>
          }
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}