import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import Integration from '../../../flowtrace-components/Configuration/Integration';
import Loading from '../../../flowtrace-components/Loading';
import { withRouter } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import queryString from 'query-string';

class ConfigurationControls extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, slackInstallUrl: '' };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }


  render() {
    if (this.state.loading) {
      return <Loading/>;
    }
    const queryParams = queryString.parse(this.props.location.search);
    let message;
    const error = queryParams.flowtrace_code;
    const success = queryParams.success === "true" ? true : false;
    const team = queryParams.team_id;
    const teamName = queryParams.team_name;
    if (success)
      message =
      <MuiAlert className="mb-4" severity="success">
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">Success</strong> Installing Flowtrace to your Slack workspace {team && teamName?"'" + teamName + "' with ID: " + team:""} was success!
          </span>
        </div>
      </MuiAlert>;
    else if (!success && error)
      message =
      <MuiAlert className="mb-4" severity={error==="user_declined"?"info":"error"}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">Installation did not complete!</strong>
            {(error && error==="client_account_not_found")? "We encountered a problem with your client account. Please try again later or contact your account manager!" : null}
            {(error && error==="code_exchange_failed")? "There was a problem exchanging code between Slack and Flowtrace. Please try again!" : null}
            {(error && error==="missing_configuration")? "We encountered unknown problem. Please try again later or contact your account manager!" : null}
            {(error && (error==="missing_parameters" || error==="failed_to_store"))? "Something went wrong which should not have happened... Please try again!" : null}
            {(error && error==="invalid_code" )? "It took too long to exchange code between Slack and Flowtrace. Please try again!" : null}
            {(error && error==="user_declined" )? "You declined the permission to install Flowtrace app into your Slack workspace. Please proceed with different installation type!" : null}
          </span>
        </div>
      </MuiAlert>;

    if (this.props.flowtraceUser && this.props.flowtraceUser.account) {
      return (
        <Fragment>
          <PageTitle titleHeading="Integrations" titleDescription="Here you can see all your configured integrations in Flowtrace." />
          {message}
          <Integration flowtraceUser={ this.props.flowtraceUser } />
        </Fragment>
      );
    }
    return <Loading error={true} />; // Just in case
  }
}

export default withRouter(ConfigurationControls);
