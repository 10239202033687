import { Menu, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import React, { Component } from 'react';
//import { API } from 'aws-amplify';

class InjectedFilter extends Component {
    constructor(props) {
        super(props);
        if(props.default)
            this.state = { filter: props.default};
        else
            this.state = { filter: "All " + props.name};
    }

    selectionChanged(key) {
        this.setState({ filter: key, filterAnchor: null });
        // Send null if All selected
        this.props.onChange({ filter: key === "All " + this.props.name ? null : key });
    }


    render() {

        const disabled = this.props.option && this.props.options.length < 2;
        return (
            <div style={{float:'left', marginLeft:"25px", marginBottom:"5px"}}>{this.props.showTitle?<b>{this.props.name}</b>:""}
                <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                    <Button disabled={disabled} color="secondary" onClick={(e) => {this.setState({filterAnchor:e.currentTarget})}}>{this.state.filter}</Button>
                    <Button disabled={disabled} color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({filterAnchor:e.currentTarget})}>
                    <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Menu id={this.props.name + "-menu"} anchorEl={this.state.filterAnchor} keepMounted open={Boolean(this.state.filterAnchor)} onClose={(e) => this.setState({filterAnchor:null})}>
                {
                    (this.props.default?[]:["All " + this.props.name]).concat(this.props.options).map(filter =>
                        <MenuItem key={filter} onClick={async (e) => { await this.selectionChanged(filter)   }}>{filter}</MenuItem>
                        )
                }
                </Menu>
            </div>
        );

    }
} // end of class

export default InjectedFilter;
