import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import TopicSentimentChart from '../Charts/SentimentTopicChart';
import Loading from '../../Loading'
import FilterBar from '../../Common/FilterBar';

class SentimentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: "week" } };
  }

  async loadSlackSentimentData(queryFilters) {
    const dataAPI = 'slackintegration';
    const sentimentPath = '/sentiment/aggregate';
    const initAPI = { headers: {}, response: true, queryStringParameters: { type: "entity", ...queryFilters } };
    const response = await API.get(dataAPI, sentimentPath, initAPI)

    this.setState({ loading: false, topicSentiments: response.data ? response.data.summary.entities : [] })
    return
  }

  async componentDidMount() {
    this.loadSlackSentimentData(this.state.queryFilters)
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadSlackSentimentData(queryFilters);
  }

  render() {
    const topicControls = [];
    for (const key in this.state.topicSentiments)
      topicControls.push(<TopicSentimentChart key={"topic"+key} data={this.state.topicSentiments[key]} />);

    return (
      <Fragment>
          <FilterBar queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} teamFilter />
            {!this.state.loading ?
              <Grid container spacing={2}>
                {topicControls}
              </Grid>
            :
              <Loading/>
            }
        </Fragment>
    );
  } // end of render()
} // end of class

export default SentimentOverview;
