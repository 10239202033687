import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import NetworkChart from './networkChart';
import Loading from '../../Loading';
import { Grid, Slider, Button, Backdrop } from '@material-ui/core';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  backdrop: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 100,
    //color: '#fff',
  },
});

const names = require('../../../utils/anonymizedNames.js');
const localStore = require('store');



function createPlaybackMonths(firstDataDate = "2019-06-01") {
  const dateArray = [];
  const now = moment();
  do {
    if (now.month() === 0)
      dateArray.push({ value: dateArray.length, label: now.format("YYYY MMM"), date: now.format("YYYY-MM-") + now.daysInMonth() });
    else
      dateArray.push({ value: dateArray.length, label: now.format("MMM"), date: now.format("YYYY-MM-") + now.daysInMonth() });

    now.subtract(1, "months");
  } while (now.diff(moment(firstDataDate), "months", true) > 0);
  return dateArray;
}


async function loadSlackUserData(dateLimit, networkType) {
  const dataAPI = 'slackintegration';
  const teamNetworkDataPath = '/slack/installed/teamNetwork';
  const initAPI = { headers: {}, response: true, };
  if (dateLimit) {
    initAPI.queryStringParameters = { range_end: dateLimit, range_start: moment(dateLimit).subtract(2, "months").format("yyyy-MM-DD") };
  }
  if (networkType && networkType !== "useronly") {
    if (initAPI.queryStringParameters)
      initAPI.queryStringParameters.networkType = networkType;
    else
      initAPI.queryStringParameters = { networkType: networkType };
  }
  const networkData = await API.get(dataAPI, teamNetworkDataPath, initAPI).then(({data}) => {
    return {...data.network, reload:true, dates:createPlaybackMonths(data.firstDataDate)};
  }).catch(error => {
    console.error(error);
    return { nodes: [], edges: [], dates:[], reload:true  }; // return empty so things don't crash and burn?
  });

  if (localStore.get("anonymize"))
    names.anonymizeNodes(networkData.nodes);
  return networkData; // return it for storing into state
}

class AccountOverview extends Component {
  constructor(props) {
    super(props);
    const tier = this.props.flowtraceUser.account && this.props.flowtraceUser.account.props.type ? this.props.flowtraceUser.account.props.type : "free";
    const networkType = tier === "free" ? "user" : "team";
    this.state = { loading: true, tier, networkData:{ nodes: [], edges: [], dates:[] }, networkType, currentMonth:null };
  }

  async networkTypeChanged() {
    const changeType = this.state.networkData.networkType === "team" ? "user" : "team";
    this.setState({ loading: true });
    await loadSlackUserData(this.state.currentMonth, changeType).then((data) => {
      this.setState({ networkData: data, loading: false, networkType: data.networkType });
    });
  }

  async monthChanged(event, value) {
    this.setState({ loading: true, currentMonth: this.state.networkData.dates[value] });
    //await new Promise(r => setTimeout(r, 3000)); // Wait for 3 seconds to mimic overlay
    await loadSlackUserData(this.state.networkData.dates[value].date, this.state.networkData.networkType).then((data) => {
      this.setState({ networkData: data, loading:false, networkType: data.networkType });
    });
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const limitParams = {};
    if(this.state.tier === "free") limitParams.range_start = moment().subtract(28, 'days').format("YYYY-MM-DD");
    if(this.state.tier === "team") limitParams.range_start = moment().subtract(12, 'months').format("YYYY-MM-DD");
    const limitType = this.state.tier === "free" ? "user" : null;
    await loadSlackUserData(limitParams, limitType).then((data) => {
      this.setState({ networkData: data, loading: false, networkType: data.networkType });
    });
  }
  componentWillUnmount() { this.setState = () => { return; }; }
  render() {

    let sliderMonths = this.state.networkData.dates;
    if(this.state.tier === "free" && this.state.networkData.dates.length > 9)
      sliderMonths = [this.state.networkData.dates[0]];
    if(this.state.tier === "team" && this.state.networkData.dates.length > 9)
      sliderMonths = this.state.networkData.dates.slice(0,11);

    const { classes } = this.props;
    const dateText = (val) => val === 0 ? "Now" : (this.state.networkData.dates[val]?this.state.networkData.dates[val].label : "");
    return (
      <Fragment>
        <Backdrop className={classes.backdrop} open={this.state.loading}>
          <Loading minimize/>
        </Backdrop>
        <span>
          <h5 className="ml-5">This is a relationship map on how your
            {this.state.tier === "free" ?
              " workspace members "
              :
              <Button className="mr-2 ml-2" variant="contained" color="primary" onClick={ this.networkTypeChanged.bind(this) }> {this.state.networkData.networkType +"s"} </Button>
            }
          communicate with each other.
          </h5>
        </span>

      <Slider disabled={this.state.tier === "free"} onChangeCommitted={this.monthChanged.bind(this)} min={0} max={sliderMonths.length - 1} defaultValue={0} valueLabelFormat={dateText} step={1} marks={sliderMonths} valueLabelDisplay="on" />
        <Grid container spacing={0}>
          <Grid item xs={12} lg={12}>
            <NetworkChart networkType={this.state.networkData.networkType} data={this.state.networkData}/>
          </Grid>
        </Grid>
      </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default withStyles(styles, { withTheme: true })(AccountOverview);
