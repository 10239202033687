import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import AccountSettings from '../../../flowtrace-components/Configuration/AccountManagement/AccountSettings';
import MeetingFeedback from '../../../flowtrace-components/Configuration/AccountManagement/MeetingFeedback';
import ChromeExtenstionSettings from '../../../flowtrace-components/Configuration/AccountManagement/ChromeExtensionSettings';

class Settings extends Component {

  render() {
    return (
      <Fragment>
          <PageTitle titleHeading="Account Settings" titleDescription="These settings control your account default settings." />
          <AccountSettings flowtraceUser={this.props.flowtraceUser}/>
          <ChromeExtenstionSettings flowtraceUser={this.props.flowtraceUser}/>
          <MeetingFeedback flowtraceUser={this.props.flowtraceUser}/>

        </Fragment>
    );
  }
}

export default Settings;
