import React, { Component } from 'react';
import { Accordion, AccordionSummary, Dialog, DialogActions, DialogContent, Button, Switch, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class IncludedChannels extends Component {
  constructor(props) {
    super(props);
    this.state = { includedChannels: false };
  }

  handleChange() {
    return this.setState({ includedChannels: !this.state.includedChannels });
  }

  render() {
    return (
      <Accordion id="includedChannels" expanded={this.state.includedChannels} onChange={() => this.handleChange()} >
        <AccordionSummary expandIcon={<ExpandMoreIcon id="includeChannels" />}>
          <h5 className="card-title font-weight-bold font-size-md">Included channels: {this.props.current.length}</h5>
        </AccordionSummary>
        <Dialog fullWidth={true} maxWidth="xl" open={this.state.includedChannels} aria-labelledby="form-dialog-title">
          <DialogContent>
            <h5 className="card-title mb-5 font-weight-bold font-size-md">Channels Included in Flowtrace Insigths:</h5>
            <Grid container spacing={2}>
              {this.props.current.map(i =>
                <Grid item sm={6} md={4} xl={2} key={"channel-id-" + i.id}>
                  <Switch id={i.id} checked={true} onChange={(event) => this.props.leaveChannel(event)} color="primary" name="enabled" />
                  {"#" + i.name}
                </Grid>
                )}
            </Grid>
            <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
              <div />
              <Button onClick={() => this.handleChange()} color="primary" size="large"> Close </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Accordion>
    );

  }
}

export default IncludedChannels;
