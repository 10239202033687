import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import { blue } from '@material-ui/core/colors';

const maxWidth = { style: { maxWidth: "800px" } };
const wizardSteps =
  <MuiAlert className="mb-4" severity="info" {...maxWidth}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <strong className="d-block">Next up: We need you to complete following steps</strong>
        <br/>
          <ul>
            <li>We verify you are an administrator for your Google Workspace account</li>
            <li>You install Meeting Analytics from Google Workspace Marketplace</li>
            <li>We verify the Meeting Analytics integration is operational</li>
          </ul>
      </span>
    </div>
  </MuiAlert>;

class Onboarding extends Component {


  selectType(type) {this.props.onUpdate({ wizardType: type });}
  render() {
    if(!this.props.isActive) return null;
    return (
      <StepNavigation stepTitle="Review Meeting Analytics Scopes" {...this.props} >
        <div className="mt-2" />
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <h5 className="text-weight-bold">Flowtrace Meeting Analytics for Google Workspace requires following APIs and permissions:</h5>
          </Grid>
          <Grid className="m-3" container spacing={0}>
            <Grid className="pt-3" item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  <b>Name of Google API</b>
                </Grid>
                <Grid item md={6} >
                  <b>Purpose and usage</b>
                </Grid>
                <Grid item md={2} >
                  <b>Status</b>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  Google Workspace Marketplace API
                </Grid>
                <Grid item md={6} >
                  Licence information about Flowtrace installation
                </Grid>
                <Grid item md={2} >
                  <HelpOutline style={{ color: blue[500] }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  Calendar API
                </Grid>
                <Grid item md={6} >
                  Company calendars and events from Google Calendar™
                </Grid>
                <Grid item md={2} >
                  <HelpOutline style={{ color: blue[500] }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  Admin SDK API: User Directory
                </Grid>
                <Grid item md={6} >
                  Users and organization structure
                </Grid>
                <Grid item md={2} >
                  <HelpOutline style={{ color: blue[500] }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  Admin SDK API: Group Directory
                </Grid>
                <Grid item md={6} >
                  Groups and group members for calendar invites
                </Grid>
                <Grid item md={2} >
                  <HelpOutline style={{ color: blue[500] }}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} >
              <Grid container spacing={0} alignItems="center">
                <Grid item sm={4} >
                  Admin SDK API: Audit reports
                </Grid>
                <Grid item md={6} >
                  Video call start, and end information from Google Meets™
                </Grid>
                <Grid item md={2} >
                  <HelpOutline style={{ color: blue[500] }}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            { wizardSteps }
          </Grid>
        </Grid>
      </StepNavigation>
    );

  }
}

export default Onboarding;
