import React, { Fragment } from 'react';
import { Select, FormControl, TextField, Switch, Button, Grid, Card, Divider } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import SurveyExamples from './surveyExamples';
import { API } from 'aws-amplify';
import commonAnalytics from "../../../commonAnalytics";


async function storeToDatabase(settings) {
  const flowtraceAPI = 'slackintegration';
  const storeSettingsAPI = '/survey/account/settings';
  const payload = { body: settings, headers: {}, response: true };
  const response = await API.post(flowtraceAPI, storeSettingsAPI, payload).then((response, data, body) => {
    if (response.status !== 200)
      return { success: false, message: response.data.message };
    //console.log(response.data)
    return { success: true };
  }).catch((error, data) => {
    console.error(error, error.message);
    return { success: false, message: "Exception: Failed to store the settings." };
  });
  return response;
}

const surveyAlert = () => {
  return (
    <MuiAlert key="surveyConfigurationWarning" severity={"warning"}>
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block">Survey Configuration Error</strong>
          <p>You must select either eNPS or Team Effectiveness survey to proceed.</p>
        </span>
      </div>
    </MuiAlert>
  );
};

function surveyTimeString(seconds) {
  // Make sure seconds are always 00:00 format (even if less than 10 hours, or 10 minutes)
  const hours = Math.floor(seconds / 60 / 60)
  const minutes = seconds / 60 % 60
  let timeString = ""
  if (hours < 10)
    timeString = "0" + hours + ":"
  else
    timeString = hours + ":"

  if (minutes < 10)
    timeString = timeString + "0" + minutes
  else
    timeString = timeString + minutes

  return timeString
}

export default function SurveySettings(props) {
  const exampleQuestions = props.survey.questions
  const exampleSurvey = props.survey.exampleSurvey
  const settings = props.survey.settings
  const reload = props.reload
  const [settingsChanged, setSettingsChanged] = React.useState(false)
  const [configurationWarning, setConfigurationWarning] = React.useState(false);

  const [state, setState] = React.useState({
    enabled: settings.enabled,
    audienceExternal: settings.audienceExternal,
    audienceActivity: settings.audienceActivity,
    useTimezoneOffset: settings.useTimezoneOffset,
    useNPS: settings.useNPS,
    useTeamEffectiveness: settings.useTeamEffectiveness,
    useLimitedTeamEffectiveness: settings.useLimitedTeamEffectiveness,
    weekday: settings.weekday,
    surveyTime: settings.surveyTime,
    settingsChanged: settings.settingsChanged
  });

  const handleChange = (event, button = null) => {
    setConfigurationWarning(null);
    setSettingsChanged(true);
    if (button === "startup")
      return setState({ ...state, useLimitedTeamEffectiveness: true });
    if (button === "scaleup")
      return setState({ ...state, useLimitedTeamEffectiveness: false });
    switch (event.target.name) {
      case "audienceActivity":
        setState({ ...state, audienceActivity: parseInt(event.target.value) });
        break;
      case "weekday":
        setState({ ...state, weekday: parseInt(event.target.value) });
        break;
      case "enabled":
        setState({ ...state, enabled: event.target.checked });
        break;
      case "useNPS":
        setState({ ...state, useNPS: event.target.checked });
        break;
      case "useTeamEffectiveness":
        setState({ ...state, useTeamEffectiveness: event.target.checked });
        break;
      case "surveyTime":
        const hoursInSeconds = event.target.value.split(':')[0] * 60 * 60
        const minutesInSeconds = event.target.value.split(':')[1] * 60
        let seconds = 0
        // The component can fail in multiple ways, eg. user inputting text, or 75 minutes etc... Do some quick parsing to get the hours / minutes from the string:
        if (Number.isInteger(hoursInSeconds))
          seconds += hoursInSeconds
        if (Number.isInteger(minutesInSeconds))
          seconds += minutesInSeconds

        setState({ ...state, surveyTime: seconds });
        break;
      case "audienceExternal":
        setState({ ...state, audienceExternal: event.target.checked });
        break;
      case "useTimezoneOffset":
        setState({ ...state, useTimezoneOffset: event.target.checked });
        break;
      default:
        console.log("Event not recognized", event.target)
    }


  };

  const saveChanges = async (settings) => {
    if(settings && settings.enabled && !(settings.useNPS || settings.useTeamEffectiveness))
      setConfigurationWarning(surveyAlert());
    else {
      setSettingsChanged(false); // Disable button to show user that something is happening.
      await storeToDatabase(settings);
      reload();
      commonAnalytics.track('survey-settings', settings)
    }
  };

  // Otherwise render the component:
  return (
    <Fragment>
      <Card className="p-4 mb-4">
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <div className="font-size-lg font-weight-bold mb-3">Weekly Employee Pulse Surveys via Slack</div>
              </Grid>
              <Grid item xs={12} md={2}>
                <Switch checked={state.enabled} onChange={handleChange} color="primary" name="enabled"  />
              </Grid>
            </Grid >
          </Grid >
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="font-size-md font-weight-bold mb-3">Survey Audience Configuration:</div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="font-size-md d-flex align-items-center justify-content-left">
                  Include guest, and single channel guest users
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Switch checked={state.audienceExternal} onChange={handleChange} color="primary" disabled={!state.enabled} name="audienceExternal" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="font-size-md d-flex align-items-center justify-content-left">
                  Last 30 day collaboration activity
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl >
                  <Select native value={state.audienceActivity} onChange={handleChange} name="audienceActivity" disabled={!state.enabled}  >
                    <option value={0}>Everyone (no limit)</option>
                    <option value={1}>1 day or more of activity</option>
                    <option value={5}>5 days or more of activity</option>
                    <option value={10}>10 days or more of activity</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid >
          </Grid >
          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div className="font-size-md font-weight-bold mb-3">Survey Time Configuration:</div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="font-size-md d-flex align-items-center justify-content-left">
                Time to survey
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField id="time" onChange={handleChange} type="time" disabled={!state.enabled} value={surveyTimeString(state.surveyTime)} name="surveyTime" inputProps={{ step: 30 * 60 }} />
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="font-size-md d-flex align-items-center justify-content-left">
                Weekday to survey
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl >
                  <Select native value={state.weekday} onChange={handleChange} name="weekday" disabled={!state.enabled}  >
                    <option value={1}>Monday</option>
                    <option value={2}>Tuesday</option>
                    <option value={3}>Wednesday</option>
                    <option value={4}>Thursday</option>
                    <option value={5}>Friday</option>
                    <option value={6}>Saturday</option>
                    <option value={7}>Sunday</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <div className="font-size-md d-flex align-items-center justify-content-left">
                Send survey based on user's local time
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Switch checked={state.useTimezoneOffset} onChange={handleChange} color="primary" disabled={!state.enabled} name="useTimezoneOffset" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid >

          <Grid item xs={12}>
            {state.enabled?<SurveyExamples useNPS={state.useNPS} useTeamEffectiveness={state.useTeamEffectiveness} useLimitedTeamEffectiveness={state.useLimitedTeamEffectiveness} onChange={handleChange.bind(this)} examples={exampleSurvey} questions={exampleQuestions} />:"You need to enable the survey module to see available surveys"}
          </Grid >

          <Divider className="my-4" />
          {configurationWarning?<Grid item xs={12}>{configurationWarning}</Grid >:null}
          <Button disabled={!settingsChanged} onClick={async() => await saveChanges(state)} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Save Survey Settings</Button>

      </Grid>
    </Card>
  </Fragment>
  );
}
