import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import StatsCard from '../../Common/Cards/productivityStatsCard';

export default function TeamStatisticsChart(data) {
  const toolSeries = [];
  const categories = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date })
  const companyData = {}

  const toolsWithData = {}
  for (const dataPoint of Object.values(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1))) {
    // Score metrics
    for (const tool of Object.keys(dataPoint.tool)) {
      if (!companyData[tool]) companyData[tool] = [];
      if (dataPoint.tool[tool].sum) toolsWithData[tool] = true;
      companyData[tool].push(dataPoint.tool[tool].sum/dataPoint.activePeople);
    }
  }

  for (const series of Object.keys(toolsWithData)) {
    toolSeries.push({ name: series, type: "bar", data: companyData[series] });
  }


  const graphChartOptionsOverview = {
    chart: { animations: { enabled: false }, stacked: true, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val ? (Math.abs(val) / 60).toFixed(1) + "h" : "" } } },
    legend: { position: "right", show: true },
  };

  const lastMonthData = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[1];
  const secondLastMonth = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[2];
  const toolUsage = [];
  for(const tool of (lastMonthData && lastMonthData.tool?Object.keys(lastMonthData.tool):[])){
    if(!lastMonthData.tool[tool].sum && !(secondLastMonth && secondLastMonth.tool[tool] && secondLastMonth.tool[tool].sum))
      continue;
    toolUsage.push({
      tool,
      value: lastMonthData.tool[tool].sum?lastMonthData.tool[tool].sum/lastMonthData.activePeople:0,
      previous: secondLastMonth && secondLastMonth.tool[tool] && secondLastMonth.tool[tool].sum?secondLastMonth.tool[tool].sum/secondLastMonth.activePeople:0
  });
  }

  return (

    <Card className="card-box mb-0" >
      <CardContent className="p-3">
        <Grid container spacing={0}>
          {toolUsage.map(t => { return(
            <Grid key={t.tool+"grid"} item xs={12} sm={6} md={4}>
              <StatsCard key={t.tool} id={t} data={t} period={data.timeSpan}/>
            </Grid>
          )})}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} >
            <div className="mt-3 p-3 text-left">
              <b className="font-size-lg " >Tool Usage Overview</b>
              <span className="text-black-50 d-block">Here you see how your SaaS tool usage average over time (hours / person / {data.timeSpan})</span>
              <Chart options={graphChartOptionsOverview} series={toolSeries} type="bar" height="300"/>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
