import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import FlowtraceKPI from '../../../flowtrace-components/Admin/FlowtraceKPI';
//import Loading from '../../../flowtrace-components/Loading'
const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Flowtrace Internal KPIs</h4>)
explanation.push(<p key="paragraph1">Flowtrace businessKPIs.</p>)

export default function FlowtraceKPIDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="" titleDescription="Flowtrace Business KPI's" explanation={explanation}/>
      <FlowtraceKPI flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
