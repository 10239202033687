import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import MeetingStatistics from '../../../flowtrace-components/MeetingStatistics/Team';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Team Meeting Insights</h4>)
explanation.push(<p key="paragraph1">Flowtrace breaks down every every meeting to its core. Assess the cost, quality, and attendace of meetings with below data.</p>)

export default function MeetingDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Benchmark your teams and their meeting habits" titleDescription="Identify the teams who need more support" explanation={explanation}/>

      <MeetingStatistics flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
