import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import {PersonAddDisabled, AccountTree, GroupAdd} from '@material-ui/icons';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import Loading from '../Loading';

const maxWidth = { style: { maxWidth: "800px" } };

const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-1 mt-1 mb-4 pb-1" {...maxWidth}>
      {message}
    </MuiAlert>
  );
};

const completeGoogleAlert = () => {
  const bullets =
    <ul>
      <li>We analyze your corporate calendar(s) from <strong>TODAY</strong> onward!</li>
      <li>In the coming days and weeks, you account builds up the insight trends</li>
      <li>You can contact us for an onboarding call through in-app chat</li>
    </ul>;

  return (<MuiAlert className="mb-4" severity="info" {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">What happens when you click COMPLETE?</strong>
            <br/>
            {bullets}
          </span>
        </div>
      </MuiAlert>);
};

const wizardSteps = (accountType, wizardType) => {
  if(!accountType) return null;

  const freeSteps =
    <ul>
      {wizardType==="slack"?<li>Channel limit: Your freemium account is limited to 5 channels.</li> : null}
      {wizardType==="slack"?<li>Engagement metrics: Organizational network analysis requires team and user association.</li> : null}
      <li>Filters: Time limited and company wide metrics</li>
      <li>Time utilization metrics: We require user records to consolidate individuals across your SaaS tools</li>
      <li>Organizational segmentation: Team benchmarks and team cost analysis</li>
      {wizardType==="google"?<li>Employee Dashboard: Your team won't have access to personalize metrics for self improvement.</li> : null}
    </ul>;
  const trialSteps =
    <ul>
      {wizardType==="slack"?<li>Unlimited channels: With automated analysis for new public channels.</li> : null}
      <li>Organization structure: automated through your Google Workspace or Slack user directory</li>
      <li>Filters: Team and departmental segmentation across the insights views</li>
      <li>Time utilization metrics: Deep-work, shallow, and collaboration time</li>
      <li>AI recommendations: Meeting and Slack best practices</li>
      {wizardType==="google"?<li>Employee Dashboard: Your team will gain access to their personal metrics in 100% private manner through our Slack app.</li> : null}
    </ul>;

  const alertHeader = accountType === "freemium" ? "What are you missing out?" : "Included in your Team plan trial:";
  return (<MuiAlert className="mb-4" severity="info" {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">{alertHeader}</strong>
            <br/>
            {accountType==="freemium"?freeSteps:trialSteps}
          </span>
        </div>
      </MuiAlert>);
};

class OnboardingTrial extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  async createCheckoutPortal() {
    this.setState({ loading: true, apiError:null });
    try {
      const name = 'slackintegration';
      const path = '/stripe/create-checkout-session/trial-onboarding';
      const init = { headers: { "Content-Type": "application/json" }, response: true };

      const response = await API.get(name, path, init);
      if (response.data.url)
        window.location.href = response.data.url;
      else
        return this.setState({ loading: false, apiError: response.data.error});
    }
    catch (e) {
      console.error(e);
      return this.setState({ loading: false, apiError: "Coudn't connect to Flowtrace. Please contact support for help." });
    }
  }

  async loadUsers() {
    this.setState({loading:true, userLoading:true});
    try {
      const accountId = this.props.flowtraceUser.account.account_id;
      const name = 'slackintegration';
      const path = `/googleWorkspace/${accountId}/users`;
      await API.get(name, path);
    }
    catch (e) {
      console.error(e);
    }
    this.setState({loading:false, userLoading:false});
  }

  selectType(type) {this.props.onUpdate({ accountType: type });}
  scrollToBottom = () => window.scroll({ top: document.body.offsetHeight, bottom: 0, left: 0,  behavior: 'smooth' });

  async completeRedirect() {
    if(this.props.settings.accountType === "trial"){
      await Promise.all([
        this.props.flowtraceUser.reload(),
        this.loadUsers()
      ]);
    }
    this.props.history.push("/Dashboard");
    this.props.onUpdate("onboardingComplete");
  }

  render() {
    if(!this.props.isActive) return null;
    if(this.state && this.state.loading && this.state.userLoading) return <Loading text="Please wait while we establish the user directory..."/>;
    if(this.state && this.state.loading) return <Loading text="Please wait while we are creating a secure payment session and forward you to our payment provider..."/>;
    if(this.props.flowtraceUser.reloading) return <Loading text="Please wait while we prepare your account..."/>;

    const s = this.props.settings;
    const freemiumIcon = <div><PersonAddDisabled fontSize="large"/></div>;
    const triaIcon = <div><GroupAdd fontSize="large"/><AccountTree fontSize="large"/></div>;
    const freeButton = <Button disabled={Boolean(s.session)} size="large" variant={s.accountType === "freemium"?"contained":"outlined"} color="primary" startIcon={freemiumIcon} onClick={() => {this.selectType("freemium");}}>No users or organizational structure</Button>;
    const trialButton = <Button disabled={Boolean(s.session)} size="large" variant={s.accountType === "trial"?"contained":"outlined"} color="primary" startIcon={triaIcon} onClick={() => {this.selectType("trial");}}>Free 7-day trial of our Team plan</Button>;

    let nextAction = null;
    let disableNext = false;
    let nextText = "Next";
    let nextSuggestionText = "";
    let message = "";

    console.log(s)
    if(!s.accountType){
      nextSuggestionText = "Select the type of account you want to proceed.";
      disableNext = true;
    } // First check if this is TRIAL and we have not returned from STRIPE:
    else if(s.accountType === "trial" && !s.stripe ) {
      nextSuggestionText = "Click START FREE TRIAL to create your 7-day free trial subscription to Flowtrace.";
      disableNext = true;
    } // GOOGLE wizard type options:
    else if(s.wizardType === "google") {
      // TRIAL and SUCCESS
      if(s.accountType === "trial" && s.stripe && s.stripe.session_id) {
        message = <div>{createAlert("success", "7 day trial started. Your account has been upgraded to Team plan!")}{completeGoogleAlert()}</div>
        nextSuggestionText = "Click COMPLETE for your first calendar insights!";
        nextText = "Complete";
        nextAction = () => this.completeRedirect.bind(this);

      } // TRIAL and FAILED checkout
      else if(s.accountType === "trial" && !s.stripe.session_id) {
        message = createAlert("warning", "Something went wrong and we could not start the free trial. You can proceed by selecting the other account type.");
        nextSuggestionText = "Please contact us, select a freemium plan, or try again later.";
        nextText = "Complete";
        disableNext = true;
      } // Otherwise fall back to assumption this is a FREE account
      else {
        nextText = "Complete";
        message = completeGoogleAlert();
        nextAction = () => this.completeRedirect.bind(this);
        nextSuggestionText = "Click COMPLETE to start using Flowtrace.";
      }
    } // SLACK wizard type options
    else if(s.wizardType === "slack") {
      // TRIAL and FAILED checkout
      if(s.accountType === "trial" && s.stripe && !s.stripe.session_id){
        message = createAlert("warning", "Something went wrong and we could not start the free trial. You can proceed by selecting the other account type.");
        disableNext = true;
        nextSuggestionText = "Please contact us, select a freemium plan, or try again later.";
      } // TRIAL and SUCCESS checkout
      else if(s.accountType === "trial" && s.stripe.session_id) {
        message = createAlert("success", "7 day trial started. Your account has been upgraded to Team plan!");
        nextSuggestionText = "Click NEXT to choose the channel configuration for the analytics.";
      } // Otherwise assume this is FREE account
      else {
        nextSuggestionText = "Click NEXT to choose up to 5 channels.";
      }
    }

    const stripeButton = <Button disabled={Boolean(s.stripe && s.stripe.session_id)} onClick={this.createCheckoutPortal.bind(this)} rel="noopener" size="medium" variant="contained" color="primary" className="mr-3">Start Free Trial</Button>;
    const stripe = s.accountType === "trial" && !(s.stripe && s.stripe.session_id) ? stripeButton : null;

    return (
      <StepNavigation stepTitle="Supercharge your account with our Free trial? " {...this.props} additionalAction={stripe} nextAction={nextAction} nextDisabled={disableNext} nextText={nextText}>
        <div className="mt-2" />
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="stretch">
          <Grid item xs={12}>
            <h4 className="text-weight-bold">Choose your account type for Flowtrace.</h4>
          </Grid>
          <Grid item xs={12}>
            <div {...maxWidth}>
              <p>To get the most benefits of our analytics platform you need an organizational structure and user records to associate team, and departmental filters. We require credit card details to start your trial due to the nature of personal data processing. You can cancel your trial any time.</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4} justifyContent="flex-start">
              <Grid item >
                {freeButton}
              </Grid>
              <Grid item >
                {trialButton}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {message ? message: wizardSteps(s.accountType, s.wizardType)}
          </Grid>
          { this.state.apiError ?
            <Grid item xs={12}>
              {createAlert("warning", this.state.apiError)}
            </Grid>
          : null }
          <Grid item xs={12}>
            {nextSuggestionText}
          </Grid>
        </Grid>

      </StepNavigation>
    );

  }
}

export default withRouter(OnboardingTrial);
