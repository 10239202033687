import React from 'react'
import { Graph } from "react-d3-graph";
import { useRef, useEffect, useLayoutEffect, useState } from "react";
import DetailNetworkDialog from '../../DetailNetworkDialog';
import { FormGroup, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';

function createNodeStructure(networkData) {
    // Cleanup nodes from the data in case they don't appear on edges
    let nodes = {}
    for (const key in networkData.data.edges) {
        nodes[networkData.data.edges[key].source] = networkData.data.nodes[networkData.data.edges[key].source]
        nodes[networkData.data.edges[key].target] = networkData.data.nodes[networkData.data.edges[key].target]
    }
    const returnNodes = [];
    for (const key in nodes) {
        let nodeToPush = { id: key, label: nodes[key].label }
        if (nodes[key].type === "PERSON" || nodes[key].type === "User") {
            nodeToPush.svg = "/images/ona_icons/user_icon.svg"
            nodeToPush.color = "green"
        }
        if (nodes[key].type === "Channel") {
            nodeToPush.label = (nodes[key].metadata && nodes[key].metadata.is_private) ? "🔒" + nodeToPush.label : "#" + nodeToPush.label
            nodeToPush.svg = "/images/ona_icons/channel_icon.svg"
            nodeToPush.color = "blue"
        }
        if (nodes[key].type === "LOCATION") {
            nodeToPush.svg = "/images/ona_icons/map_icon.svg"
            nodeToPush.color = "red"
        }
        if (nodes[key].type === "ORGANIZATION") {
            nodeToPush.svg = "/images/ona_icons/company_icon.svg"
            nodeToPush.color = "yellow"
        }
        if (nodes[key].type === "TITLE") {
            nodeToPush.svg = "/images/ona_icons/topic_icon.svg"
            nodeToPush.color = "orange"
            nodeToPush.size = 200
        }
        returnNodes.push(nodeToPush);
    }

    return returnNodes;
}

function createLinkStructure(networkData) {
    const returnLinks = [];
    const maxValue = Math.max.apply(Math, Object.values(networkData.data.edges).map(function(edge) { return edge.count; }))
    // As every channel is associated with another channel at least with some volume, let's remove all edges where volume is less than 15% of max
    //channelEdges = Object.values(channelEdges).filter(edge => edge.count > maxCount * 0.15)



    for (const key in networkData.data.edges) {
        let edgeToPush = {
            source: networkData.data.edges[key].source,
            target: networkData.data.edges[key].target,
            strokeWidth: (networkData.data.edges[key].count / maxValue * 3),
        }
        //#474747 = darkish grey
        //#4d4d4d = lighter
        //#575757 = lightest

        edgeToPush.color = "#575757" // Make them darker depending on the value
        if (networkData.data.edges[key].count / maxValue > 0.5) edgeToPush.color = "#4d4d4d"
        if (networkData.data.edges[key].count / maxValue > 0.66) edgeToPush.color = "#474747"

        returnLinks.push(edgeToPush);
    }


    // console.log(edgeToPush)
    // FIXME: There is console exception thrown as many times as edges are pushed.
    // Even bare minimum edges (source/targe) throws the error (Error: <path> attribute d: Expected number, "MNaN,NaNANaN,NaN …".)
    //console.log(returnLinks.length)
    return returnLinks;
}

// Function to trigger resize less often
function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}
let storedViewSettings = {}

const BarChart = props => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [data, setData] = useState(null);

    function createNetworkDataStructure(viewSettings) {
        //console.log("Create data structure with parameters", viewSettings.directional, viewSettings.showPrivate, viewSettings.showDirect)

        const data = { network: { nodes: [], links: [] }, viewSettings: viewSettings };
        const dataCopy = JSON.parse(JSON.stringify(props)) // Make a copy of data


        // Filter out the channel relationships which are less active than 5% of the most active ones (to avoid one person saying someting on one channel to be included as channel relationship)
        const maxCount = Math.max.apply(Math, Object.values(dataCopy.data.edges).map(i => i.count))
        dataCopy.data.edges = Object.values(dataCopy.data.edges).filter(e => e.count > maxCount * 0.05)

        //console.log("Step 1", Object.values(dataCopy.data.nodes).length, dataCopy.data.edges.length)
        if (!data.viewSettings.showDirect) // Remove direct messages and group messages from the data unless they need to be shown
        {

            const groupsDMs = Object.values(dataCopy.data.nodes).filter(node => !node.metadata).map(node => node.id) // No metadata = not a channel / named group
            dataCopy.data.edges = Object.values(dataCopy.data.edges).filter(edge => !(groupsDMs.includes(edge.source) || groupsDMs.includes(edge.target)))
            //console.log("Group/DM channels filtered out:", groupsDMs.length, groupsDMs)
        }
        //console.log("Step 2", Object.values(dataCopy.data.nodes).length, dataCopy.data.edges.length)

        if (!data.viewSettings.showPrivate) // Remove bots from the data unless they need to be shown
        {
            const privateChannels = Object.values(dataCopy.data.nodes).filter(node => node.metadata && node.metadata.is_private).map(node => node.id)
            dataCopy.data.edges = Object.values(dataCopy.data.edges).filter(edge => !(privateChannels.includes(edge.source) || privateChannels.includes(edge.target)))
            //console.log("Private channels filtered out:", privateChannels.length, privateChannels)
        }
        //console.log("Step 3", Object.values(dataCopy.data.nodes).length, dataCopy.data.edges.length)

        if (!data.viewSettings.showArchived) // Remove archived channels from the data unless they need to be shown
        {
            const archivedChannels = Object.values(dataCopy.data.nodes).filter(node => node.metadata && node.metadata.is_archived).map(node => node.id)
            dataCopy.data.edges = Object.values(dataCopy.data.edges).filter(edge => !(archivedChannels.includes(edge.source) || archivedChannels.includes(edge.target)))
            //console.log("Archived channels filtered out:", archivedChannels.length, archivedChannels)
        }
        //console.log("Step 4", Object.values(dataCopy.data.nodes).length, dataCopy.data.edges.length)

        data.network.nodes = createNodeStructure(dataCopy);
        data.network.links = createLinkStructure(dataCopy);


        if (data.viewSettings.directional) {
            chartConfig.directed = true;
            chartConfig.link.type = "CURVE_SMOOTH";
        }
        else {
            chartConfig.directed = false;
            chartConfig.link.type = "STRAIGHT";
        }

        data.chartConfig = chartConfig

        // if selected filters results into nothing to draw, let the user know of it:
        if (data.network && data.network.nodes && data.network.nodes.length === 0) {
            data.network.nodes.push({ id: 1, label: "Selected filters produce", size: 500, svg: "/images/ona_icons/topic_icon.svg" })
            data.network.nodes.push({ id: 2, label: "0 results", size: 100, })
            data.network.edges = []
            data.network.links.push({ source: 1, target: 2, strokeWidth: 3, color: "#575757" })
        }
        storedViewSettings = viewSettings
        delete data.reload
        setData(data);
    }
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    });

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    const chartConfig = {
        nodeHighlightBehavior: true,
        highlightOpacity: 0.8,
        d3: { gravity: -500 },
        width: dimensions.width,
        directed: false,
        node: {
            color: "lightgreen",
            size: 240,
            fontSize: 16,
            highlightStrokeColor: "blue",
            highlightFontSize: 24,
            labelProperty: "label"
        },
        link: {
            highlightColor: "darkblue",
            semanticStrokeWidth: true,
            type: "CURVE_SMOOTH",
            markerWidth: 4,
        },
    };
    const [openDialog, setOpenDialog] = React.useState(false);
    const [graphClickData, setGraphClickData] = useState({ type: "", node1: false, node2: false });

    if (props && props.data && props.data.reload) {
        //console.log("Need to reload the node structure after new data was loaded.")
        delete props.data.reload // This is only done once per data load.
        if (storedViewSettings && Object.keys(storedViewSettings).length > 0)
            createNetworkDataStructure(storedViewSettings)
    }

    const styles = { overlay: { position: 'absolute', top: '25px', left: '10px', color: 'black', } };
    const changeViewSettings = name => event => {
        let viewSettings = data.viewSettings

        switch (name) {
            case "showArchived":
                viewSettings.showArchived = event.target.checked
                break;
            case "showDirect":
                viewSettings.showDirect = event.target.checked
                break;
            case "showPrivate":
                viewSettings.showPrivate = event.target.checked
                break;
            case "directional":
                viewSettings.directional = event.target.checked
                break;
            default:
                // code block
        }
        createNetworkDataStructure(viewSettings);
    };

    const handleClickLinkDialog = (param1, param2) => {
        setGraphClickData({ type: "link", node1: param1, node2: param2 })
        setOpenDialog(true);
    };
    const handleClickNodeDialog = (param1, param2) => {
        setGraphClickData({ type: "node", node1: param1, node2: param2 })
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (!data) // First render with empty div
    {
        const defaultSettings = { directional: false, showPrivate: false, showArchived: false, showDirect: false }
        createNetworkDataStructure(defaultSettings); // Default when coming to the page
        return (<div height="95%" width="100%" ref={targetRef}></div>);
    }
    // Height calculations
    // HeaderBox: 78 pixels
    // FooterBox: 70 pixels
    // SliderBox: 84 pixels
    // Container: 32 pixels, and 16 pixels (top and bottom)
    // Total: 280 pixels
    data.chartConfig.height = window.innerHeight - 280
    data.chartConfig.width = window.innerWidth

    return (
        <div ref={targetRef}>
            <Graph id="graph-id-networkData"
                    data={data.network}
                    config={data.chartConfig}
                    onClickNode={handleClickNodeDialog}
                    onClickLink={handleClickLinkDialog}
            />
            <div style={styles.overlay}>
              <FormControl component="fieldset" className="pr-4">
                  <FormGroup>
                    <FormControlLabel style={{'marginTop':'40px'}} control={ <Checkbox checked={data.viewSettings.directional} onChange={changeViewSettings('directional')} value="directional" /> } label="Directional participation" />
                    <FormControlLabel style={{'marginTop':'-25px'}} control={ <Checkbox checked={data.viewSettings.showPrivate} onChange={changeViewSettings('showPrivate')} value="showPrivate" /> } label="Show private channels" />
                    <FormControlLabel style={{'marginTop':'-25px'}} control={ <Checkbox checked={data.viewSettings.showDirect} onChange={changeViewSettings('showDirect')} value="showDirect" /> } label="Show direct and groups" />
                    <FormControlLabel style={{'marginTop':'-25px'}} control={ <Checkbox checked={data.viewSettings.showArchived} onChange={changeViewSettings('showArchived')} value="showArchived" /> } label="Show archived channels" />
                  </FormGroup>
              </FormControl>

            </div>

            <DetailNetworkDialog open={openDialog} clickEvent={graphClickData} data={props.data} onClose={handleCloseDialog} />
        </div>
    )
}
export default BarChart
