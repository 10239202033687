import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';

import { connect } from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';

import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarShadow,
    wizard,
    currentStep,
    navi
  } = props;
  navi.content = navi.content.map((i, index) => {
    if (index < currentStep.activeStep - 1)
      i.icon = CheckCircleOutlineIcon;
    else if (index === currentStep.activeStep - 1)
      i.icon = RadioButtonCheckedIcon;
    else
      i.icon = RadioButtonUncheckedIcon;

    return i;
  });
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader flowtraceUser={props.flowtraceUser}/>
          <PerfectScrollbar>
            <div>
              <SidebarMenu component="div" currentStep={props.currentStep} wizard={wizard} key={navi.label} pages={navi.content} title={navi.label} />
            </div>
        </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader flowtraceUser={props.flowtraceUser}/>
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
                <div>
                  <SidebarMenu component="div" wizard={wizard} key={navi.label} pages={navi.content} title={navi.label} />
                </div>
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
