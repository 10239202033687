import React from 'react';
import { Button } from '@material-ui/core';
import { Auth } from 'aws-amplify';

export function LogoutButton() {
  const signOut = async() => {
      try {
          // This triggers Hub event signout, which then calls logout from auth0
          await Auth.signOut({ global: true });
      } catch (error) {
          console.log('error signing out: ', error);
      }
  };

  return (
    <div>
      <Button size="medium" variant="contained" color="primary" onClick={async() => await signOut()}>Logout</Button>
    </div>
  );
}

export default LogoutButton;
