import React, { Component } from 'react';
import { API } from 'aws-amplify';
import Loading from '../../../flowtrace-components/Loading';
import { Link, Grid, Button, Card, Divider } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Launch } from '@material-ui/icons';
import GitHubLogo from '../../../assets/images/integration_logos/github.png';
import GitLabLogo from '../../../assets/images/integration_logos/gitlab.png';
import JiraLogo from '../../../assets/images/integration_logos/jira.png';
import AsanaLogo from '../../../assets/images/integration_logos/asana.png';
import SalesForceLogo from '../../../assets/images/integration_logos/salesforce.png';
import HubSpotLogo from '../../../assets/images/integration_logos/hubspot.png';
import ZoomLogo from '../../../assets/images/integration_logos/zoom.png';
import FrontLogo from '../../../assets/images/integration_logos/front.png';
import ClickupLogo from '../../../assets/images/integration_logos/clickup.png';
import TeamsAndOutlookLogo from '../../../assets/images/integration_logos/ms_teams_and_outlook.jpg';
import GoogleWorkspaceLogo from '../../../assets/images/integration_logos/google_calendar_meets.png';

import IntegrationConfiguration from './IntegrationConfiguration';

const getLogo = (type) => {
  if(type === "google-oauth2") return GoogleWorkspaceLogo;
  if(type === "atlassian") return JiraLogo;
  if(type === "microsoft") return TeamsAndOutlookLogo;
  if(type === "github") return GitHubLogo;
  if(type === "gitlab") return GitLabLogo;
  if(type === "asana") return AsanaLogo;
  if(type === "zoom") return ZoomLogo;
  if(type === "hubspot") return HubSpotLogo;
  if(type === "salesforce") return SalesForceLogo;
  if(type === "front") return FrontLogo;
  if(type === "clickup") return ClickupLogo;
  return null;
};
const latestEvent = (event, type) => {
  if(type === "google-oauth2" && event === "installed")
    return (
      <MuiAlert key="latestMessage" severity={"success"} className="pt-0 pb-0">
        <strong className="d-block">Installed</strong>
      </MuiAlert>
    );
  if(!event || !event.timestamp)
    return null;
  return (
    <MuiAlert key="latestMessage" severity={"success"} className="pt-0 pb-0 mb-0">
      <strong>Last active:</strong> {event.since}
    </MuiAlert>
  );
};

const enabled = (user, type) => {
  // True for non-free for Google Marketplace Install
  if(type === "google-oauth2")
    return true;
  if(type === "microsoft")
    return true;
  if(user && user.account && user.account.props && user.account.props.type === "scale")
      return true;
  return false;
};

const toolInformation = [
  {id:"google-oauth2", title:"Meeting Analytics for Google", configuration: true, kb_url:"https://help.flowtrace.co/en/articles/7870430-install-flowtrace-meeting-analytics-from-google-workspace-marketplace" },
  {id:"github", title:"GitHub", configuration: true, featureFlagEnabled: true, kb_url:"https://help.flowtrace.co/en/articles/6294373-integrate-github-with-flowtrace"},
  {id:"asana", title:"Asana", development:true},
  {id:"gitlab", title:"GitLab", configuration: true, featureFlagEnabled: true, kb_url: "https://help.flowtrace.co/en/articles/7733799-integrate-gitlab-with-flowtrace"},
  {id:"atlassian", title:"Jira", configuration: true, kb_url:"https://help.flowtrace.co/en/articles/6312152-integrate-jira-with-flowtrace" },
  {id:"zoom", title:"Zoom", configuration: true, kb_url:"https://help.flowtrace.co/en/articles/7183277-integrate-zoom-with-flowtrace"},
  {id:"microsoft", title:"Meeting Analytics for Outlook", configuration: true, kb_url:"https://help.flowtrace.co/en/articles/8694042-meeting-analytics-for-m365"},
  {id:"hubspot", title:"HubSpot", configuration: true},
  {id:"clickup", title:"ClickUp", configuration: true, kb_url: "https://help.flowtrace.co/en/articles/7857625-integrate-clickup-with-flowtrace"},
  {id:"salesforce", title:"Salesforce", development:true},
  {id:"front", webhook:true, title:"Front" },
  ];


const available = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  if(info && info.development)
    return false;
  return true;
};

const webhook = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  if(info && info.webhook)
    return true;
  return false;
};

const isConfigurationEnabled = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  if(info && info.configuration)
    return true;
  return false;
};

const isFeatureEnabled = (tool, user) => {

  // We can show this if the account has feature flag for this enabled:
  const featureFlags =  user.account && user.account.props && user.account.props.featureFlags ? user.account.props.featureFlags : [];
  if(featureFlags.includes(tool)){
    console.log("Feature on because: Feature Flags");
    return true;
  }

  // Configuration is always ok for global admin and non-production
  if(user.flowtrace === "global_admin" ||  window.location.hostname !== 'app.flowtrace.io')
    return true;

  const info = toolInformation.find(t => t.id === tool);
  // In production, this is hidden from "normal" users when featureFlag is up
  if(info && info.featureFlagEnabled && user.flowtrace !== "global_admin" && window.location.hostname === 'app.flowtrace.io')
    return false;
  return true;
};



const title = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  if(info && info.title)
    return info.title;
  return "Unknown";
};

const knowledgeBaseUrl = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  if(info && info.kb_url && info.kb_url.length > 0)
    return info.kb_url;
  return "";
};

const getConfigurationModalProps = (tool) => {
  const info = toolInformation.find(t => t.id === tool);
  return info;
};

class WebhookIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showConfigureModalDialog: false
      };
  }

  async loadLatestEvent(tool, user) {
    if(tool === "google-oauth2" && user && user.account && user.account.workspaces.find(ws => ws.type === "GSUITE" && ws.subtype === "MEETING")){
      return "installed";
    }
    if(tool === "microsoft" && user && user.account && user.account.workspaces.find(ws => ws.type === "MICROSOFT")){
      return "installed";
    }

    // Not installed:
    if(user && user.account && !user.account.ws[tool.toUpperCase()]){
      return null;
    }
    const flowtraceAPI = 'slackintegration';
    const slackDashboardPath = "/metrics/" + (tool==="atlassian"?"jira":tool) + "/latest";
    const callHeaders = { headers: {}, response: true };

    const event = await API.get(flowtraceAPI, slackDashboardPath, callHeaders);
    if(event.data)
      return event.data;
    return null;

  }
  async load() {
    this.setState({ loading: true });
    const response = await this.loadLatestEvent(this.props.type, this.props.flowtraceUser);
    this.setState({ latestEvent:latestEvent(response, this.props.type), loading: false });
  }

  async componentDidMount() {
    await this.load();
  }
  componentWillUnmount() { this.setState = () => { return; }; };

  async copyToClipboard(user, tool) {
    let url = "";
    if(user && user.account)
      url = "https://api.flowtrace.io/webhook/" + user.account.account_id + "/" + tool;

    if(url.length < 1 || user.account.account_id.length < 1)
      return this.setState({copied:"There was a problem fetching your unique URL."});

    if ('clipboard' in navigator){
      console.log("using new school", url)
      await navigator.clipboard.writeText(url);
    } else {
      console.log("using old school", url)
      document.execCommand('copy', true, url);
    }
    this.setState({copied:"URL copied!"});
    setTimeout(() => { this.setState({copied:null}); }, 1500);
  }



  render() {
    const viewInstructions = <Link underline="hover" variant="body2" target={"_blank"} href={knowledgeBaseUrl(this.props.type)}>Instructions<Launch fontSize="small"/></Link>;
    const copyButton = <Button variant="outlined" color="primary" onClick={async() => {await this.copyToClipboard(this.props.flowtraceUser, this.props.type)}}>Copy URL</Button>;
    const comingSoon = <Button disabled variant="outlined" color="primary">Coming soon</Button>;

    const headerContent =
      <Grid container spacing={0} >
        <Grid item xs={2} >
          { this.props.type === "google-oauth2" ?
              <img style={{maxHeight:"45px", maxWidth:"75px"}} alt="Paywall" src={GoogleWorkspaceLogo} />
            :
              <img style={{maxHeight:"30px", maxWidth:"85px"}} alt="Integration Logo" src={getLogo(this.props.type)} />
          }
        </Grid>
        <Grid item xs={7}>
          <div className="font-size-lg font-weight-bold">{title(this.props.type)} Integration </div>
        </Grid>
        <Grid item className="pt-2" xs={3}>
          { knowledgeBaseUrl(this.props.type).length > 0 ? viewInstructions : "Ask for a guide." }
        </Grid>
      </Grid>;

    if (this.state.loading) {
      return (
        <Grid item xs={12} md={6}>
          <Card className="p-4 mb-4">
            {headerContent}
            <Divider className="my-4" />
            <Loading tiny/>
          </Card>
        </Grid>
        );
    }
    // This this is a configuration end point and feature flag limits, then return nothing to hide this given integration
    if(available(this.props.type) && isConfigurationEnabled(this.props.type) && !isFeatureEnabled(this.props.type, this.props.flowtraceUser))
      return null;


    const installButton = () => {

      if(available(this.props.type) && webhook(this.props.type))
        return copyButton;


      if(available(this.props.type) && !webhook(this.props.type)) {
        if(isConfigurationEnabled(this.props.type)) {
          if(isFeatureEnabled(this.props.type, this.props.flowtraceUser))
            return <IntegrationConfiguration flowtraceUser={this.props.flowtraceUser} modal={{...getConfigurationModalProps(this.props.type)}} />;
        }
      }
      return comingSoon;
    };

    const showStatus = this.state.latestEvent && enabled(this.props.flowtraceUser, this.props.type);

    const installSize = this.loading || showStatus ? 6 : 12;
    return (
        <Grid item xs={12} md={6}>
          <Card className="p-4 mb-4">
            {headerContent}
            <Divider className="my-4" />
            {enabled(this.props.flowtraceUser, this.props.type) && !(this.props.type === "salesforce" || this.props.type === "asana") ?
              <Grid container spacing={2}>
                <Grid item xs={installSize}>
                  {installButton()}
                </Grid>
                <Grid item xs={6}>
                  {this.state.loading? <Loading tiny/> : null }
                  {!this.state.loading && showStatus ? this.state.latestEvent : null }
                </Grid>
                {this.state.copied || !available(this.props.type) ?
                  <Grid item xs={7}>
                    {this.state.copied?<p className="card-text mt-1 font-size-sm">{this.state.copied}</p>:null}
                    {available(this.props.type)?null:"Contact support for early access."}
                  </Grid>
                  : null}
              </Grid>
            :
              <div>
                {this.props.type === "salesforce" || this.props.type === "asana" ? "Please contact us for early access." : "Please upgrade to integrate " + title(this.props.type) + "."}
              </div>
            }
          </Card>
        </Grid>
    );

  }
}

export default WebhookIntegration;
