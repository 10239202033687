import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import AccountSettings from '../Configuration/AccountManagement/AccountSettings';

const maxWidth = { style: { maxWidth: "700px" } };


class Complete extends Component {

  render() {
    if(!this.props.isActive) return null;

    return (
      <StepNavigation stepTitle="Cost estimate engine configuration" {...this.props} >
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h5 className="text-center mb-5">Here you can configure your company's cost estimate settings</h5>
            <p {...maxWidth}>These settings are used for meeting cost estimates making the algorithm use your company's own context.</p>
            <p {...maxWidth}>Example: In 2019 UK average yearly tech working hours were ~1'730 and average net salary was £73'000 resulting in gross of £84'217.</p>
          </Grid>
          <Grid item xs={12}>
            <AccountSettings onboarding={true} flowtraceUser={this.props.flowtraceUser}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          </Grid>
        </Grid>
      </StepNavigation>
    );
  }
}

export default withRouter(Complete);
