import React, { Component } from 'react';
import { Box, Button, ButtonGroup, Grid, Switch, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../Loading';

const maxWidth = { style: { maxWidth: "700px" } };

const wizardComplete = (s) => {
  const cond = {
    channel: Boolean(s.channel),
    install: Boolean(s.slackInstalled),
    intro: Boolean(s.introductionSent),
    account: Boolean(s.accountType),
    bot: s.autoJoin === true || s.autoJoin === false,
    channelAll: s.joinAll === true || s.joinAll === false,
    channelSelect: Boolean(s.joinAll === true || (s.joinAll === false && s.selectedChannels && s.selectedChannels.length>0)),
    backfill: s.backfill >0
  };
  return {ok: Object.values(cond).reduce((c, a) => a && c, true), cond, message:
    <MuiAlert className="mt-5 mb-4" severity="warning" {...maxWidth}>
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block">We are missing some of information to complete the onboarding. Please make sure you have:</strong>
          <br/>
          <ul>
            { !cond.channel ? <li>configured Flowtrace scopes (step "Configure Flowtrace")</li> : null }
            { !cond.install ? <li>connected Flowtrace to your Slack workspace (step "Install Flowtrace")</li> : null }
            { !cond.intro ? <li>sent introduction message to your team (step "Introduction")</li> : null }
            { !cond.account ? <li>configured your Flowtrace account type (step "Review your plan")</li> : null }
            { !cond.bot ? <li>configured automatic joining on this step</li> : null }
            { !cond.channelAll || !cond.channelSelect ? <li>configured the channels you want us to analyze (this step)</li> : null }
            { !cond.backfill ? <li>selected the period of how long back we should look at upon completion of this wizard (this step)</li> : null }
          </ul>
        </span>
      </div>
    </MuiAlert>
  }
};

class Complete extends Component {
  constructor(props) {
    super(props);
    const selectedChannels = this.props.settings.selectedChannels?this.props.settings.selectedChannels:[];
    this.state = {autoJoin:null, publicPanel:false, privatePanel:false, dialogOpen:false, selectedChannels, joinAll:null};
  }

  backfillPeriod = (value) => {
    this.props.onUpdate({ backfill: value });
  }

  nextStepsMessage() {
    return (
      <MuiAlert className="mb-4" severity="info" {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">What to expect when you click 'Complete' ?</strong>
            <p><br/>We start crunching your data for the insights in following steps:</p>
            1. Flowtrace joins selected <strong>public</strong> channels above.<br/>
            2. We will process the messages of selected channels for the past <strong>{this.props.settings.backfill} days</strong>.<br/>
            3. We finalize the installation by calculating collaboration statistics.<br/>
            4. You are ready to improve your team's collaboration with factual insights!<br/>
            <p><br/>It can take couple of minutes to process your data depending on the selection of channels and days you have selected.</p>
          </span>
        </div>
      </MuiAlert>
    );
  }

  async completeRedirect() {
    if(this.props.settings.accountType === "trial")
      await this.props.flowtraceUser.reload();
    this.props.history.push("/Processing");
    this.props.onUpdate({ processing: true });
  }

  handleChange(event) {
    return this.setState({
      [event.target.id]: !this.state[event.target.id]
    });
  }
  handleChannelSelect(event) {
    const selections = this.state.selectedChannels;
    if(event.target.checked && (this.props.settings.accountType === "trial" || selections.length < 5)){
      const selected = selections.find(s => s === event.target.id);
      if (!selected)
        selections.push(event.target.id);
    }
    else{
      // Make sure the channel is deselected from the state array
      const index = selections.indexOf(event.target.id);
      if (index > -1)
        selections.splice(index, 1);
    }
    this.props.onUpdate({ selectedChannels: selections });
    return this.setState({ selectedChannels: selections });
  }

  updateStateAndWizard(state) {this.setState(state); this.props.onUpdate(state);}
  autoJoin(autoJoin) {this.updateStateAndWizard({autoJoin})}
  joinAllChannels() {this.updateStateAndWizard({joinAll:true})}
  openChannelSelector() {this.updateStateAndWizard({joinAll:false, dialogOpen:true})}
  closeChannelSelector() {this.updateStateAndWizard({dialogOpen:false})}
  render() {
    if(!this.props.isActive) return null;
    if(this.state.loading || this.props.flowtraceUser.reloading)
      return <Loading text="Preparing your account."/>;

    const install = this.props.settings.installationInfo ? this.props.settings.installationInfo : null;
    const publicChannels = install && install.channels ? install.channels.filter(i => !i.is_private && i.is_channel && !i.is_archived) : [];
    const channels = publicChannels.sort((a, b) => a.name > b.name ? 1 : -1).map(i => { return { id: i.id, name: i.name } });
    // If user have introduced us to Slack, that means we have installed Slack, and they are ready to proceed.
    const s = this.props.settings;
    const publicChannelFreeTierMessage =
      <MuiAlert key="publicMessage" severity={this.state.selectedChannels.length<5?"success":"warning"}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <p><strong className="d-block">
              {this.state.selectedChannels.length<5? "You can select up to 5 channels as part of the Flowtrace free tier:" : "You have reached the limit of 5 channels included in the Flowtrace free tier:" }
            </strong></p>
            <p>Flowtrace will process the selected public channel conversations automatically when you complete the onboarding.</p>
            <p>You can later choose to upgrade your tier and include unlimited number of channels.</p>
          </span>
        </div>
      </MuiAlert>;
    const disableOnFreemium = s.accountType === "freemium" ? true : false;
    return (
      <StepNavigation stepTitle="Last step is to activate Flowtrace analytics and prepare your account" {...this.props} nextAction={() => this.completeRedirect.bind(this)} nextDisabled={!wizardComplete(s).ok} nextText="Complete">
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h5 className="text-center">Do you want Flowtrace Slack bot to join all public channels?</h5>
            <p {...maxWidth}>We do this automatically for you when your team creates new public channels. This option requires Team plan or higher.</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="d-flex align-items-center">
              <ButtonGroup color="primary" size="large" variant="contained">
                <Button color={s.autoJoin === true?"primary":"default"} variant={s.autoJoin === true?"contained":"outlined"} onClick={() => this.autoJoin(true)} disabled={disableOnFreemium}>Yes, please.</Button>
                <Button color={s.autoJoin === false?"primary":"default"} variant={s.autoJoin === false?"contained":"outlined"} onClick={() => this.autoJoin(false)}>No, thanks.</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h5 className="text-center">How far back would you like Flowtrace to look?</h5>
            <p {...maxWidth}>Please choose the period for historical data processing. Do remember that we only look at public channels you select.</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="d-flex align-items-center">
              <ButtonGroup color="primary" size="large" variant="contained">
                <Button color={s.backfill===1?"primary":"default"} variant={s.backfill===1?"contained":"outlined"} onClick={() => this.backfillPeriod(1)}>1 Day</Button>
                <Button color={s.backfill===3?"primary":"default"} variant={s.backfill===3?"contained":"outlined"} onClick={() => this.backfillPeriod(3)}>3 Days</Button>
                <Button color={s.backfill===7?"primary":"default"} variant={s.backfill===7?"contained":"outlined"} onClick={() => this.backfillPeriod(7)}>1 Week</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <h5 className="text-center">Which public channels you want included?</h5>
            <p {...maxWidth}>You can ask us to attempt join every public channel automatically, or choose the channels you want to include in your account.</p>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="d-flex align-items-center">
              <ButtonGroup color="primary" size="large" variant="contained">
                <Button color={s.joinAll===true?"primary":"default"} variant={s.joinAll===true?"contained":"outlined"} onClick={() => this.joinAllChannels()} disabled={disableOnFreemium}>Join all public channels ({channels.length})</Button>
                <Button color={s.joinAll===false?"primary":"default"} variant={s.joinAll===false?"contained":"outlined"} onClick={() => this.openChannelSelector()}>Let me select the channels ({s.selectedChannels?s.selectedChannels.length:0})</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Dialog fullWidth={true} maxWidth="lg" open={this.state.dialogOpen} aria-labelledby="form-dialog-title">
            <DialogContent>
              <h5>Select public channels for the analysis</h5>
              <Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start" style={{maxWidth:"100%"}}>
                {s.accountType === "freemium"? <Grid item xs={12} > {publicChannelFreeTierMessage} </Grid> : null}
                {channels.map(i =>
                  <Grid item sm={12} md={6} lg={4} key={"channel-id-" + i.id}>
                    <Switch id={i.id} checked={this.state.selectedChannels && this.state.selectedChannels.includes(i.id)} onChange={(event) => this.handleChannelSelect(event)} color="primary" name="enabled" />
                    {"#" + i.name}
                  </Grid>
                  )}
              </Grid>
              <DialogActions style={{ justifyContent: "flex-start" }} className="mx-auto pt-5 pb-3">
                <Button color="primary" variant="outlined" onClick={() => this.closeChannelSelector()}>Close</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          {s.channel==="private" && s.joinAll !== null?
            <Grid item xs={8} sm={8} md={8}>
              <MuiAlert key="publicPrivateMessage" severity="success" {...maxWidth}>
                <div className="d-flex align-items-center align-content-center">
                  <span>
                    <p><strong >Gain more insights through 'Private Channel' analytics:</strong></p>
                    <p>The more channels you include in Flowtrace analytics, the more accurate collaboration picture you will be able to get. Which is why we recommend inviting @Flowtrace to your private channels where possible. </p>
                    <p className="mb-2">You can invite Flowtrace into a private channel within Slack by typing the following command in your chosen private channel:</p>
                    <p><span className="text-black mb-2 font-weight-bold font-size-m">/invite @Flowtrace</span> </p>
                    <p>You will be prompted to confirm the invitation and can revoke the access at any point.</p>
                  </span>
                </div>
              </MuiAlert>
            </Grid>
          :null}
          <Grid item xs={12} sm={12} md={12}>
            {!wizardComplete(s).ok?wizardComplete(s).message:this.nextStepsMessage()}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          </Grid>
        </Grid>
      </StepNavigation>
    );
  }
}

export default withRouter(Complete);
