import React, { Component, Fragment } from 'react';
import Loading from '../../../../flowtrace-components/Loading';
import { Card, CardContent, Box, Button, Grid, Switch, FormControl, Select, MenuItem, Tooltip, Link, List, ListItem, ListItemIcon, IconButton,TextField  } from '@material-ui/core';
import { Delete, AddCircle, Launch } from '@material-ui/icons';
import accountAPI from '../../../../flowtrace-api/Account/propertyActions';
import MuiAlert from '@material-ui/lab/Alert';
import webstoreLogo from '../../../Common/webstoreLogo';
import edgestoreLogo from '../../../Common/edgeStoreLogo';
import addinLogo from '../../../Common/addinLogo';

const maxWidth = { style: { maxWidth: "600px" } };
const createAlert = (type, message, alertHeader = null) => {
  return (
    <MuiAlert key={"alertMessage" + message.replace(/\s+/g, '')} severity={type} className="pt-1 mt-1 mb-4 pb-1"  {...maxWidth}>
      {alertHeader ? <span><strong className="d-block">{alertHeader}</strong><br/></span> : null}

      {message}
    </MuiAlert>
  );
};


const chromeExtensionDefault = {
	enabled:true,
	buffer: 10, // Padding around meetings for 10 minutes
	tooltip:true, // Good for debugging
	showCurrency: true, // Time or Currency?
	show90day : false, // Recurring meeting cost estimates if available
	requireAuthentication: true, // Client can allow unauthenticated access based on Chrome profile to their account configuration
	alert: {
		enabled: true,
		type: "event", // event or recurring
		low: 0, // GREEN:
		medium: 180, // ORANGE: 3 people for 1 hour
		high: 480, // RED: 8 people for 1 hour
	},
	policy:{
		enabled:false,
		url: "https://hubs.ly/Q01-lv7T0",
		url_text:"Click for policy guide",
		header:"Flowtrace Meeting Policy",
		policy:[
			"Agenda must be sent 24h prior to meeting",
			"Meetings >10 hours trigger feedback automation",
			"Ask manager approval for >10 people meetings"
		]
	}
};


const openInNewTab = (url) => { window.open(url, "_blank", "noreferrer"); };
const webstoreUrl = "https://chrome.google.com/webstore/detail/bjfhkjgmnlabpopcmgagbdgjfelndpfj";
const edgestoreUrl = "https://microsoftedge.microsoft.com/addons/detail/moajkmbnajhhaojofiknkcdfapfkdjej";
const edgestoreKBUrl = "https://help.flowtrace.co/en/articles/8486124-meeting-cost-for-outlook-edge-extension";
const webstoreKBUrl = "https://help.flowtrace.co/en/articles/8198383-meeting-cost-for-google-calendar-chrome-extension";
const addinUrl = "https://api.flowtrace.io/outlook/manifest.xml";
const addinKBUrl = "https://help.flowtrace.co/en/articles/8850883-installing-flowtrace-meeting-costs-for-outlook-add-in";

const webStore = <IconButton id={"getFromWebStore"} onClick={() => openInNewTab(webstoreUrl)}>
    <Box component="img" src={webstoreLogo.src} alt={"Get it from Google Chrome Web Store"} sx={{ height: "50px", width: "auto" }} />
  </IconButton>;
const edgeStore = <IconButton id={"getFromEdgeStore"} onClick={() => openInNewTab(edgestoreUrl)}>
    <Box component="img" src={edgestoreLogo.src} alt={"Get it from Microsoft Edge Web Store"} sx={{ height: "50px", width: "auto" }} />
  </IconButton>;
const addinStore = <IconButton id={"getAddIn"} onClick={() => openInNewTab(addinUrl)}>
    <Box component="img" src={addinLogo.src} alt={"Outlook Add In via administrator portal"} sx={{ height: "50px", width: "auto" }} />
  </IconButton>;



class ChromeExtensionSettings extends Component {
  constructor(props) {
    super(props);
    const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const chromeExtension = a.props.chromeExtension ? a.props.chromeExtension : null;

    if(chromeExtension)
      this.state = {loading:false, reload:false, chromeExtension:{...chromeExtensionDefault, ...chromeExtension}, previousExtension:chromeExtension, changed: false};
    else
      this.state = {loading:false, reload:false, chromeExtension:chromeExtensionDefault, changed: this.props.onboarding ? true: false};
  }

  async updateSettings() {
    try{
      this.setState({loading:true});
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      const chromeExtension = a.props.chromeExtension ? a.props.chromeExtension : null;
      // Remove existing properties before adding a new one
      if(chromeExtension)
        await accountAPI.deleteProperty(this.props.flowtraceUser.account.account_id, {chromeExtension});
      const response = await accountAPI.addProperty(this.props.flowtraceUser.account.account_id, {chromeExtension:this.state.chromeExtension});
      if(response.success){
        await this.props.flowtraceUser.reload();
        return this.setState({changed:false, reload:true, loading:false});
      }
    } catch (e) {
      console.error(e);
    }
    return this.setState({error:"Something went wrong.", loading:false});
  }

  componentDidUpdate() {
    if(this.state.reload && !this.props.flowtraceUser.reloading){
      const a = this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
      const chromeExtension = a && a.props.chromeExtension ? a.props.chromeExtension : chromeExtensionDefault;
      this.setState({reload:false, chromeExtension});
    }
  }


  handleChange = (event) => {
    const clicked = event.target.name.split(".");
    const chromeExtension = this.state.chromeExtension;
    if(clicked.length === 3)
      chromeExtension[clicked[1]][clicked[2]] = event.target.checked;
    if(clicked.length === 2)
      chromeExtension[clicked[1]] = event.target.checked;
    this.setState({changed:true, chromeExtension});
  }
  updateValue = (event) => {
    const index = event.target.name.includes("|") ? event.target.name.split("|")[1] : null;
    const clicked = event.target.name.split(".");
    const chromeExtension = this.state.chromeExtension;
    if(clicked.length === 3 && index !== null)
      chromeExtension[clicked[1]][clicked[2].split("|")[0]][index] = event.target.value;
    else if(clicked.length === 3)
      chromeExtension[clicked[1]][clicked[2]] = event.target.value;
    else if(clicked.length === 2)
      chromeExtension[clicked[1]] = event.target.value;
    else
      return console.log("updateValue failed to parse the object path.", event.target.name, event.target.value);
    this.setState({changed:true, chromeExtension});
  }

  addPolicy = () => {
    const chromeExtension = this.state.chromeExtension;
    chromeExtension.policy.policy.push("");
    this.setState({changed:true, chromeExtension});
  }
  deletePolicy= (removeIndex) => {
    const chromeExtension = this.state.chromeExtension;
    chromeExtension.policy.policy = chromeExtension.policy.policy.filter((i, index) => index !== removeIndex);
    this.setState({changed:true, chromeExtension});
  }

  render() {
    if (this.state.loading)
      return <Loading text="Saving Flowtrace Chrome Extension settings."/>;
    if (this.props.flowtraceUser.reloading)
      return <Loading text="Reloading account settings."/>;

    const conf = this.state.chromeExtension;

    const createHeader = (text, tooltip) => {
     return <Grid item>
        <Tooltip title={tooltip}>
          <div className="font-weight-bold mr-3">{text}</div>
        </Tooltip>
      </Grid>;
    };


    const generalSettings =
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Meeting Costs Estimate Enabled", "You can disabled the estimates if you want to stop showing any cost, or time estimates to your team. This setting does not stop showing the meeting policy if you have it enabled.")}
              <Grid item>
                <Switch checked={conf.enabled} onChange={this.handleChange} color="primary" name="chromeExtension.enabled" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Show Estimate Tooltip", "User can hover over the cost estimate and see the details how the estimate was created.")}
              <Grid item>
                <Switch checked={conf.tooltip} onChange={this.handleChange} color="primary" name="chromeExtension.tooltip" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Show Quarterly Estimates", "Show estimate of cost for recurring meetings when creating new meeting and when the recurrence rule is available for the existing event.")}
              <Grid item>
                <Switch checked={conf.show90day} onChange={this.handleChange} color="primary" name="chromeExtension.show90day" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Use Currency Estimates", "You can choose to show currency based estimate, or by disabling this, show time cost estimate instead.")}
              <Grid item>
                <Switch checked={conf.showCurrency} onChange={this.handleChange} color="primary" name="chromeExtension.showCurrency" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Additional Meeting Time Padding", "This setting adds selected amount of minutes to every meeting participation. I.e. 10 minute setting would calculate 50 minute meeting as costing 60 minutes times the number of participants.", 4)}
              <Grid item>
                <FormControl>
                  <Select value={conf.buffer} onChange={this.updateValue} displayEmpty name="chromeExtension.buffer" variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled>Select approximate lead-in and lead-out time as 'time lost' to add to every meeting participation.</MenuItem>
                    {[0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60].map(i => <MenuItem value={i} key={"buffer-" + i}>{i + " minutes lost per invited participant"}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>;
    const requireAuthentication =
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Require Authentication", "You can disabled the requirement of strong authentication for your organization's customised settings with alternative security controles (i.e. IP whitelisting).")}
              <Grid item>
                <Switch checked={conf.requireAuthentication} disabled onChange={this.handleChange} color="primary" name="chromeExtension.requireAuthentication" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <MuiAlert className="m-2" severity={conf.requireAuthentication ? "info" : "warning"}>
              <div className="d-flex align-items-center align-content-center" >
                <span>
                  {conf.requireAuthentication ?
                    <strong className="d-block mb-3">Chrome extension configuration is protected with login.</strong>
                  :
                    <strong className="d-block mb-3">Configuration is accessed using Chrome profile information.</strong>
                  }
                  { conf.requireAuthentication ?
                      <p>We require your users to login to gain access to the cost and meeting policy configuration.</p>
                    :
                    <div>
                      <p>We do not require user login to access the configuration.</p>
                      <p>Your <b>Flowtrace and Calendar data will always be safe</b> and this warning applies to the Chrome Extension configuration only.</p>
                      <p>In rare circumstances an outsider can gain access to the configuration (i.e. meeting policy, alert levels or hourly cost).</p>
                    </div>
                  }
                </span>
              </div>
            </MuiAlert>
          </Grid>
        </Grid>
      </Grid>


    const tresholds = [0,1,2,3,4,5,6,7,8,9,10,12,14,16,18,20,25,30,35,40,45,50,55,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,220,240,260,280,300] // hour tresholds
    // 300h = 1h meeting, for 25 people, 12 times => costing about ~15k / quarter
    const costAlert =
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Show Alerts on Estimates", "You can disable the alerting based on costs. This will stop showing green/orange/red alerts based on meeting costs.")}
              <Grid item>
                <Switch checked={conf.alert.enabled} onChange={this.handleChange} color="primary" name="chromeExtension.alert.enabled" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Alert Type", "You can choose to show the alert based on individual event, or based on recurring event time estimate if available.")}
              <Grid item>
                <FormControl>
                  <Select value={conf.alert.type} onChange={this.updateValue} displayEmpty name="chromeExtension.alert.type" variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled>Select to apply alert to single event, or recurring event time estimate.</MenuItem>
                    {["event", "recurring"].map(i => <MenuItem value={i} key={"alert-type-" + i}>{i}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Low (green)", "If meeting time [(time + buffer) * participants]  estimate exceeds this value, show the estimate with green backdrop.")}
              <Grid item>
                <FormControl>
                  <Select value={conf.alert.low} onChange={this.updateValue} displayEmpty name={"chromeExtension.alert.low"} variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled>Select low estimate treshold.</MenuItem>
                    {tresholds.map(i => <MenuItem value={i * 60} key={"alert-low-" + i}>{i + " hours"}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Medium (orange)", "If meeting time [(time + buffer) * participants]  estimate exceeds this value, show the estimate with orange backdrop.")}
              <Grid item>
                <FormControl>
                  <Select value={conf.alert.medium} onChange={this.updateValue} displayEmpty name={"chromeExtension.alert.medium"} variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled>Select medium estimate treshold.</MenuItem>
                    {tresholds.map(i => <MenuItem value={i * 60} key={"alert-medium-" + i}>{i + " hours"}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("High (red)", "If meeting time [(time + buffer) * participants]  estimate exceeds this value, show the estimate with red backdrop.")}
              <Grid item>
                <FormControl>
                  <Select value={conf.alert.high} onChange={this.updateValue} displayEmpty name={"chromeExtension.alert.high"} variant="outlined" autoWidth={true}>
                    <MenuItem value="" disabled>Select high estimate treshold.</MenuItem>
                    {tresholds.map(i => <MenuItem value={i * 60} key={"alert-high-" + i}>{i + " hours"}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>;

    const meetingPolicy =
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item>
            <Grid container spacing={1} direction="column" >
              {createHeader("Show Meeting Policy", "You can choose to show or hide meeting policy section in Flowtrace Chrome Extension.")}
              <Grid item>
                <Switch checked={conf.policy.enabled} onChange={this.handleChange} color="primary" name="chromeExtension.policy.enabled" inputProps={{ 'aria-label': 'primary checkbox' }} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container spacing={2} direction="column" >
              {createHeader("Policy Header", "Header for your meeting policy on the event. i.e. Flowtrace Meeting Policy")}
              <Grid item >
                <TextField id={"policy-header"} name={"chromeExtension.policy.header"} onChange={this.updateValue} value={conf.policy.header} fullWidth variant="outlined" />
              </Grid>
              {createHeader("Policy items", "You can add any number of bullet points to your meeting policy. We recommend using short sentences and linking the the policy to your meeting policy document/article.")}
              <Grid item >
                <List dense={true}>
                  {
                    conf.policy.policy.map((i, index) =>
                      <ListItem key={"policy-list-item-"+index}>
                        <ListItemIcon id={"policy-list-item-icon"+index}>
                          <IconButton id={"policy-list-item-icon-button-"+index} onClick={() => this.deletePolicy(index)} aria-label="Delete this item">
                            <Delete id={"policy-list-item-delete-"+index}/>
                          </IconButton>
                        </ListItemIcon>
                        <TextField id={"policy-item-text-"+index} value={i} onChange={this.updateValue} name={"chromeExtension.policy.policy|" + index} fullWidth variant="outlined" />
                      </ListItem>
                    )
                  }
                  <ListItem key={"policy-list-item-add"}>
                    <ListItemIcon>
                      <IconButton onClick={() => this.addPolicy()} aria-label="Add new item">
                        <AddCircle  />
                      </IconButton>
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Grid>
              <Grid container spacing={1}  >
                <Grid item xs={4}>
                  {createHeader("Policy Link Text", "Add here the text to link to your policy URL.")}
                  <Grid item >
                    <TextField id={"policy-url-text"} name={"chromeExtension.policy.url_text"} onChange={this.updateValue} value={conf.policy.url_text} fullWidth variant="outlined" />
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  {createHeader("URL to Policy", "Add here your company's meeting policy URL/link.")}
                  <Grid item >
                    <TextField id={"policy-url"} name={"chromeExtension.policy.url"} onChange={this.updateValue} value={conf.policy.url} fullWidth variant="outlined" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>;

    const saveButton =
      <Grid item xs={12} sm={12} md={12}>
        <Button disabled={!this.state.changed} onClick={async() => await this.updateSettings()} size="medium" variant="contained" color="primary" className="ml-1 mt-3 mr-3">Save Settings</Button>
      </Grid>;



    // if(this.props.onboarding)
    // return (
    //   <Fragment>
    //     <Grid container spacing={4} direction="column" >
    //     {costAlert}
    //     {saveButton}
    //     </Grid>
    //   </Fragment>
    // );
    const infoText = "We use caching to serve the configuration for you and your end users. It can take up to 60 minutes to see all the changes across the browsers. You might need to refresh your Google Calendar browser page for your configuration.";
    const infoPlanUpgrade = "These settings are available only to paid Flowtrace plans. ";
    const infoTitle = "When do I see the settings updated in Google Calendar / Outlook?";
    const plan = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.props.type : "free";
    const saveInfo = createAlert("info", (plan === "free" ? infoPlanUpgrade : "") + infoText, infoTitle);

    return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-4">
            <Grid container spacing={1} direction="column" >
              <Grid item xs={12}>
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={12}>
                    <h5 className="mb-3">Configure 'Meeting Cost for Google Calendar™ / Outlook™' Extension </h5>
                    <p>You can configure how the extension appears for your logged in users in their calendar.</p>
                  </Grid>
                  <Grid item xs={4}>
                    {webStore}
                    <Link underline="hover" variant="body2" target={"_blank"} href={webstoreKBUrl}>Google Calendar Instructions <Launch fontSize="small"/></Link>;
                  </Grid>
                  <Grid item xs={4}>
                    {addinStore}
                    <Link underline="hover" variant="body2" target={"_blank"} href={addinKBUrl}>Outlook Add-In Instructions <Launch fontSize="small"/></Link>;
                  </Grid>
                  <Grid item xs={4}>
                    {edgeStore}
                    <Link underline="hover" variant="body2" target={"_blank"} href={edgestoreKBUrl}>Outlook & Edge Instructions <Launch fontSize="small"/></Link>;
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <h6 className="m-0">General configuration:</h6>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                {generalSettings}
                {requireAuthentication}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <h6 className="m-0">Cost alerting configuration:</h6>
              </Grid>
              {costAlert}
              <Grid item xs={12} sm={12} md={12}>
                <h6 className="m-0">Meeting policy configuration:</h6>
              </Grid>
              {meetingPolicy}

              {saveInfo}

              {saveButton}
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );

  }
}

export default ChromeExtensionSettings;
