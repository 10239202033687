import { Menu, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LoadingButton from './loadingButton';

import React, { Component } from 'react';

const rollingTimeOptions = {
    7: "Past 7 days",
    28: "Past 28 days",
    90: "Past 90 days",
    180: "Past 180 days",
    360: "Past 360 days",
    0: "All time"
};

function getSelectionFilters(key) {
    if (key === "7") return { range_type: "rolling", range_period: 7, range_limit: 1 }
    if (key === "28") return { range_type: "rolling", range_period: 7, range_limit: 4 }
    if (key === "90") return { range_type: "rolling", range_period: 30, range_limit: 3 }
    if (key === "180") return { range_type: "rolling", range_period: 30, range_limit: 6 }
    if (key === "360") return { range_type: "rolling", range_period: 90, range_limit: 4 }
    if (key === "0") return { range_type: "rolling", range_period: 90, range_limit: 40 } // 10 years
}

class FilterBarRollingOptions extends Component {
    constructor(props) {
        super(props);

        // Find the component's desired default value from queryFilters, or set to component's default:
        let rollingTimeOption = null
        if (props.defaults && props.defaults.range_type === "rolling" && props.defaults.range_period)
            rollingTimeOption = props.defaults.range_period
        this.state = { loading: true, rollingTimeOption, rollingTimeOptionsAnchor: null };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    selectionChanged(key) {
        this.setState({ rollingTimeOption: key, rollingTimeOptionsAnchor: null })
        this.props.onChange(getSelectionFilters(key))
    }

    render() {
        if (this.props.loading) {
            return <LoadingButton />;
        }

        return (
            <div style={{float:'left', marginBottom:"15px", width: '200px'}}>
                <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                    <Button color="secondary" onClick={(e) => this.setState({rollingTimeOptionsAnchor:e.currentTarget})}>{this.state.rollingTimeOption ? rollingTimeOptions[this.state.rollingTimeOption]:"Select..."}</Button>
                    <Button color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({rollingTimeOptionsAnchor:e.currentTarget})}>
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Menu id="rollingTimeOptions-menu" anchorEl={this.state.rollingTimeOptionsAnchor} keepMounted open={Boolean(this.state.rollingTimeOptionsAnchor)} onClose={() => this.setState({rollingTimeOptionsAnchor:null})}>
                    {
                        Object.entries(rollingTimeOptions).map(([key, value]) =>
                            <MenuItem key={"rollingTimeOptions"+key} onClick={() => { this.selectionChanged(key) }}>{value}</MenuItem>
                            )
                    }
                </Menu>
            </div>
        );

    }
} // end of class

export default FilterBarRollingOptions;
