import React, { Component } from 'react';
import { Accordion, AccordionSummary, Dialog, DialogActions, DialogContent, Button, Switch, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class IncludedChannels extends Component {
  constructor(props) {
    super(props);
    this.state = { excludedChannels: false };
  }

  handleChange() {
    return this.setState({ excludedChannels: !this.state.excludedChannels });
  }

  render() {
    return (
      <Accordion id="excludedChannels" expanded={this.state.excludedChannels} onChange={() => this.handleChange()} >
        <AccordionSummary expandIcon={<ExpandMoreIcon id="excludeChannels" />}>
          <h5 className="card-title font-weight-bold font-size-md">Excluded channels: {this.props.other.length} </h5>
        </AccordionSummary>
        <Dialog fullWidth={true} maxWidth="xl" open={this.state.excludedChannels} aria-labelledby="form-dialog-title">
          <DialogContent>
            <h5 className="card-title mb-5 font-weight-bold font-size-md">Channels Excluded from Flowtrace Insigths:</h5>
            <Grid container spacing={2}>
              { this.props.accountType !== "free" && this.props.other.length > 1  ?
                <Grid item sm={12}>
                  <Button onClick={this.props.joinAllChannels.bind(this)} size="medium" variant="contained" color="primary" >Join All Channels</Button>
                </Grid>
              :null }
              {this.props.other.map(i =>
                <Grid item sm={6} md={4} xl={2} key={"channel-id-" + i.id}>
                  <Switch id={i.id} checked={false} onChange={(event) => this.props.joinChannel(event)} color="primary" name="enabled" />
                  {"#" + i.name}
                </Grid>
                )}
            </Grid>
            <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
              <div />
              <Button onClick={() => this.handleChange()} color="primary" size="large"> Close </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Accordion>
    );

  }
}

export default IncludedChannels;
