import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import TeamProductivityStatistics from '../../../flowtrace-components/ProductivityStatistics/Team';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1"> Collaboration Analytics</h4>)
explanation.push(<p key="paragraph1"> Flowtrace breaks down every day to 15 minute periods and calculates your employee collaboration activity statistics.</p>)
explanation.push(<p key="paragraph2"> <b>Emailing</b> tells you how many hours per employee is spent emailing.</p>)
explanation.push(<p key="paragraph3"> <b>Slacking</b> tells you how many hours per employee is spent using slack.</p>)
explanation.push(<p key="paragraph4"> <b>Internal Meeting</b> tells you how many hours per employee is spent in internal meetings.</p>)
explanation.push(<p key="paragraph5"> <b>External Meeting</b> tells you how many hours per employee is spent in meetings which have external attendees. </p>)
explanation.push(<p key="paragraph6"> <b>Multitasking</b> tells you how many hours per employee is spent in multitasking where we have captured at least two types of activity.</p>)
explanation.push(<p key="paragraph7"> <b>Meeting multitasking</b> tells you how many hours per employee is spent in meeting, meanwhile we have captured email or slack activity.</p>)
explanation.push(<p key="paragraph8"> <b>Focus time</b> tells you how many hours per employee can focus on work uninterrupted during the day (>60 minute slots not collaborating).</p>)
explanation.push(<p key="paragraph9"> <b>Out of hours collaboration time</b> tells you how many hours per employee is being active in any channel during the non-working days (Sat & Mon) and non-working hours (18:00 - 08:00).</p>)

export default function Dashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Team Productivity Benchmark" titleDescription="Understand which teams need most help to get more focus time" explanation={explanation}/>

      <TeamProductivityStatistics flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
