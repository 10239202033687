import React, { Component, Fragment } from 'react';
import { TreeView, TreeItem } from '@material-ui/lab';
import { Business, DomainDisabled, DomainDisabledTwoTone, BusinessTwoTone, FolderShared, FolderOpen, Delete, DeleteOutline } from '@material-ui/icons';
import { Card, CardContent, Grid, Button } from '@material-ui/core';
import { API } from 'aws-amplify';
import cache from '../../../../flowtrace-api/apiCache';

import Loading from '../../../../flowtrace-components/Loading'
import NodeMembers from './nodeMembers';
import MergeMemberDialog from './mergeMemberDialog';

const createNodeTree = (paths) => {
    let result = [];
    let level = {result};

    paths.forEach(path => {
        path = path.replace("/", ""); // replace first sladh away
        path.split('/').reduce((r, name, i, a) => {
            if(!r[name]) {
              r[name] = {result: []};
              if(name.length)
                r.result.push({path:"/" + path, name, children: r[name].result});
            }
        return r[name];
      }, level);
    });
    return result;
};

const createTeamStructure = (members) => {
  const allTeams = [...new Set(members.filter(m => m.orgUnitPath).map(m => { return m.orgUnitPath }))];
  const explodedTeams = [];
  for(const t of allTeams)
      for(let i = 1 ; i < t.split("/").length ; i++)
          explodedTeams.push(t.split("/").slice(0, i + 1).join("/"));

  return [...new Set(explodedTeams)].sort();
};


const noTeamNodeValue = "no-team";
const pastTeamNodeValue = "past-team";

const waitFor = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

class AccountMembersForUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentlySelected: noTeamNodeValue,
      teamStructure: [],
      teamMembers: [],
      pastMembers: [],
      mergeMemberDialogState: false
    };
  }


  async loadTeamFilters() {
        try{
            const dataAPI = 'slackintegration';
            const path = '/members/clientAccountMergedMembers/' + this.props.flowtraceUser.account.account_id;
            const initAPI = { headers: {}, response: true };
            const { data: { result } } = await API.get(dataAPI, path, initAPI);

            const teamMembers = result.filter(m => !m.is_deleted && !m.is_bot && m.user[0].id !== 'USLACKBOT' && typeof m.email === "string" && !m.email.includes('[bot]')).map(m => { return {orgUnitPath:"", ...m}});
            const pastMembers = result.filter(m => m.is_deleted && !m.is_bot && m.user[0].id !== 'USLACKBOT' && typeof m.email === "string" && !m.email.includes('[bot]')).map(m => { return {orgUnitPath:"", ...m}});
            const teamStructure = createTeamStructure(teamMembers);

            return this.setState({ loading: false, teamStructure, teamMembers, pastMembers });
        } catch (e) {
            console.error(e);
        }
        return this.setState({ loading: false, teamStructure: [], teamMembers: [], pastMembers: [] });
    }

    async componentDidMount() {
      this.setState({ loading: true, mergeMemberDialogState: false });
      // every time we come to this page, let's empty team filter cache so it is repopulate the next time
      cache.clear("team");
      await this.loadTeamFilters();
    }

    handleNodeSelect(event, value) {
        this.setState({currentlySelected: value[0]});
    }

    handleMergeMemberDialog = () => {
      this.setState({mergeMemberDialogState: true});
    }


    handleClose = async () => {
      this.setState({mergeMemberDialogState: false, loading: true});
      await waitFor(1500);
      await this.loadTeamFilters();
    }


    renderTree(nodes) {
        const tree = [];
        for(const node of nodes) {
            const nodeIcon = this.state.currentlySelected === node.path?<FolderShared />:<FolderOpen />;
            const nodeMembers = this.state.teamMembers.filter(m => m.orgUnitPath === (node.path === noTeamNodeValue ? "" : node.path));
            const label = node.name + " (" + (nodeMembers?nodeMembers.length:"NA")+ ")";
            const nodeContent = [];
            if(node.children.length)
              nodeContent.push(this.renderTree(node.children));
            const nodeItem = <TreeItem className="mr-2 ml-2 mt-2 pr-3" key={node.path} icon={nodeIcon} nodeId={node.path} label={label}> {nodeContent} </TreeItem>;
            tree.push(nodeItem);
        }
        return tree;
    }

    async onChangeTeam(selection) {
      if(!selection) return;

      try{
        this.setState({ loading: true});

        // Team info and manualOrgUnitPath are stored in Google Suite so we are getting the GSuite id here.
        const memberId = selection.currentMember.id[0];//.filter(id => id.includes('GSUITE'));
        const dataAPI = 'slackintegration';
        const params = { headers: {}, response: true, body: { properties: { manualOrgUnitPath: selection.team }  } };

        await API.patch(dataAPI, '/members/clientAccountMembers/' + memberId, params);

        // Update the org unit path override to the state:
        const currentMembers = this.state.teamMembers;
        const member = currentMembers.find(m => m.id.includes(memberId));


        if(selection.team === "") {
          member.manualOrgUnitPath = false;
          // If user orgunit path is set to "" -> this is revert manual override -> get user's gsuite record org unit path if it exists
          const userWithOrgUnitPath = member.user.find(u => u.orgUnitPath && u.orgUnitPath.length>0);
          if(userWithOrgUnitPath)
            member.orgUnitPath = userWithOrgUnitPath.orgUnitPath;
          else
            member.orgUnitPath = "";

        } else {
          member.manualOrgUnitPath = true;
          member.orgUnitPath = selection.team;
        }


        return this.setState({loading:false, teamMembers:currentMembers, teamStructure: createTeamStructure(currentMembers)});
      } catch (e) {
          console.error(e);
      }
      // Something went wrong, reload the component
      return await this.loadTeamFilters();
    }

  render() {
    if (this.state.loading) {
      return <Loading/>;
    }

    // Removing bots and Slackbot
    const currentMembers = this.state.teamMembers.filter(m => m.orgUnitPath === (this.state.currentlySelected === noTeamNodeValue ? "" : this.state.currentlySelected));
    const currentTeamName = this.state.currentlySelected?this.state.currentlySelected.split("/").at(-1):"";
    const rootTeamCount = this.state.teamMembers.filter(m => m.orgUnitPath === "/").length;
    const noTeamCount = this.state.teamMembers.filter(m => m.orgUnitPath === "").length;
    const pastTeamCount = this.state.pastMembers.length;
    const rootLogo = this.state.currentlySelected !== "/" ? <Business/> : <BusinessTwoTone />;
    const noTeamLogo = this.state.currentlySelected !== noTeamNodeValue ? <DomainDisabled/> : <DomainDisabledTwoTone/>;
    const pastTeamLogo = this.state.currentlySelected === pastTeamNodeValue ? <Delete/> : <DeleteOutline/>;

    return (
      <Fragment>
        <Card className="card-box mt-4 mb-4">
          <CardContent className="p-4">
            <h5>
              Team Members and Structure in Flowtrace from Integration Sources
              <Button className="align-items-center ml-4 mt-3 mb-3" onClick={this.handleMergeMemberDialog} size="medium" variant="contained" color="primary" >View Integration User Records</Button>
            </h5>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={4}>
                <TreeView multiSelect={true}
                    expanded={["/"].concat(this.state.teamStructure)}
                    selected={this.state.currentlySelected}
                    onNodeSelect={this.handleNodeSelect.bind(this)}
                    >
                    <TreeItem className="m-3 mb-6 pr-3" key={"no-team"} icon={noTeamLogo} nodeId={noTeamNodeValue} label={`Members without a team (${noTeamCount})`} ></TreeItem>

                    <TreeItem className="m-3 pr-3" key={"/"} icon={rootLogo} nodeId={"/"} label={`${this.props.flowtraceUser.account.account_name} (${rootTeamCount})`} >
                      {this.renderTree(createNodeTree(this.state.teamStructure))}
                    </TreeItem>

                    <TreeItem className="m-3 mb-6 pr-3" key={"past-team"} icon={pastTeamLogo} nodeId={pastTeamNodeValue} label={`Past members (${pastTeamCount})`} ></TreeItem>
                </TreeView>
              </Grid>
              <Grid item xs={12} lg={8}>
                <MergeMemberDialog
                  dialogState={this.state.mergeMemberDialogState}
                  onClose={this.handleClose.bind(this)}
                  teamMembers={this.state.teamMembers}
                  flowtraceUser={this.props.flowtraceUser}
                />

                <NodeMembers
                  onChangeTeam={this.onChangeTeam.bind(this)}
                  flowtraceUser={this.props.flowtraceUser}
                  currentMembers={this.state.currentlySelected === pastTeamNodeValue? this.state.pastMembers : currentMembers }
                  teamName={currentTeamName}
                  teamFilters={this.state.teamStructure}
                  mergeMemberDialogState={this.state.mergeMemberDialogState}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );

  }
}

export default AccountMembersForUsers;
