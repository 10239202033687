import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import Loading from '../Loading';
import KPICard from './genericKPICard';
import BarChart from './genericBarChartKPI';
import meetingAPI from '../../flowtrace-api/meetingAPI';

function calculateMeetingKPIs(histogram, previousMeetings = null) {
  // Calculate the KPIs and deltas (attendance, average meeting length, agenda%):
  // Let's start looping all meetings (as we want to drill down to the events themselves to calculate it granular level)
  // TODO: Move this calculation to backend eventually with unified filtering and date histogram paradigms
  const attendanceKPI = { invites: 0, pending: 0, declined: 0, tentative: 0, accepted: 0 };
  const agendaKPI = { long: 0, short: 0, missing: 0, missing_percentage: 0 };
  const metricKPI = { count: 0, duration: 0, scheduled_duration: 0, average_duration: 0 };
  if (previousMeetings) {
    const previous = calculateMeetingKPIs(previousMeetings);
    attendanceKPI.previous = previous.attendanceKPI;
    agendaKPI.previous = previous.agendaKPI;
    metricKPI.previous = previous.metricKPI;
  }

  for (const h of histogram ? histogram : []) {
    // Attendance (internal) calculations:
    attendanceKPI.invites += h.group.invites.sum + h.internal.invites.sum;
    attendanceKPI.pending += h.group.pending.sum + h.internal.pending.sum;
    attendanceKPI.declined += h.group.declined.sum + h.internal.declined.sum;
    attendanceKPI.tentative += h.group.tentative.sum + h.internal.tentative.sum;
    attendanceKPI.accepted += h.group.accepted.sum + h.internal.accepted.sum;

    // Calculate the meeting durations
    metricKPI.count += h.event_count;
    metricKPI.duration += h.duration.ALL.sum;
    metricKPI.scheduled_duration += h.duration.event.sum;

    // Static, dynamic, and no agenda meeting calculations:
    agendaKPI.missing += h.agenda_range.no;
    agendaKPI.short += h.agenda_range.short;
    agendaKPI.long += h.agenda_range.long;

  }
  // Calculate derived percentage values:
  agendaKPI.missing_percentage = (agendaKPI.missing / (agendaKPI.missing + agendaKPI.short + agendaKPI.long) * 100);
  metricKPI.average_duration = (metricKPI.scheduled_duration / metricKPI.count).toFixed(0);

  return { attendanceKPI, agendaKPI, metricKPI };
}
class MeetingKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadMeetingStatistics() {

    try {
      const [current, previous] = await Promise.all([
        meetingAPI.overview(this.props.queryFilters.current, this.props.flowtraceUser),
        meetingAPI.overview(this.props.queryFilters.previous, this.props.flowtraceUser)
      ]);

      const KPIs = calculateMeetingKPIs(current.histogram, previous.histogram);
      this.setState({ loading: false, meetingsFound: KPIs.metricKPI.count > 0, ...KPIs });
      return
    }
    catch (e) {}
    this.setState({ loading: false, meetingsFound: false, attendanceKPI: null, agendaKPI: null, metricKPI: null });
    return;
  }

  async componentDidMount() {
    // No need to load if we don't have an account installed for the user
    if (!this.props.flowtraceUser || !this.props.flowtraceUser.account)
      return this.setState({ loading: false, meetingsFound: false });

    // Load meetings stats only in case we know GSuite is installed:
    if (this.props.flowtraceUser.account.workspaces.find(ws => ws.type === "GSUITE")) // without subtype
      this.loadMeetingStatistics();
    else
      this.setState({ loading: false, meetingsFound: false });
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => { return; };
  }

  render() {
    if(this.state.loading) {
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                <div className="p-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Meetings</div>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );
    }

    const leftHandColumn = [];
    const rightHandColumn = [];

    if (this.state.attendanceKPI) {
      const attendanceMetadata = {
        title: "Meeting Attendance",
        type: "100%",
        categories: [
          { cat: "Accepted", data: this.state.attendanceKPI.accepted, color: "#0CC078", operator: "<", high: 90, low: 70 },
          { cat: "Pending", data: this.state.attendanceKPI.pending, color: "#FCFC99", operator: ">", high: 20, low: 15 },
          { cat: "Declined", data: this.state.attendanceKPI.declined, color: "#FB6962", operator: ">", high: 2 },
          { cat: "Tentative", data: this.state.attendanceKPI.tentative, color: "#A8E4EF" }
        ]
      };
      leftHandColumn.push(<BarChart key="attendace_kpi" chartdata={attendanceMetadata} />);
      rightHandColumn.push(<KPICard key="agendakpi" KPI="meeting_agenda" value={this.state.agendaKPI.missing_percentage} compareValue={this.state.agendaKPI.previous.missing_percentage} benchmark={this.props.benchmark}/>);
      rightHandColumn.push(<KPICard key="costkpi" KPI="meeting_cost" value={this.state.metricKPI.duration} flowtraceUser={this.props.flowtraceUser} benchmark={this.props.benchmark}/>);
      rightHandColumn.push(<KPICard key="durationkpi" KPI="meeting_duration" value={this.state.metricKPI.average_duration} compareValue={this.state.metricKPI.previous.average_duration} benchmark={this.props.benchmark}  />);
    }

    if (this.props.meetingSurveyScore && this.props.meetingSurveyScore.average)
      rightHandColumn.push(<KPICard key="meetingSurveyScore" KPI="meeting_survey" value={this.props.meetingSurveyScore.average} compareValue={this.props.meetingSurveyScore.previous?this.props.meetingSurveyScore.previous.average:null} benchmark={this.props.benchmark} />);

    if (leftHandColumn.length === 0 && rightHandColumn.length === 0)
      return null; // Return nothing if there is no productivity data available

    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Meetings</div>
                    {leftHandColumn.length >0 ? leftHandColumn : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {rightHandColumn.length >0 ? rightHandColumn : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    ); // end of return
  } // end of render()
} // end of class

export default MeetingKPIComponent;
