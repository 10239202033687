import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import MeetingDetails from '../../../flowtrace-components/MeetingStatistics/Details';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Company Meeting Overview</h4>)
explanation.push(<p key="paragraph1">In this view you can see our breakdown of calendar events in a histogram format.</p>)
explanation.push(<p key="paragraph2">This helps you understand the type of meetings and what impact your initiatives are having on your meeting culture when comparing to previous period.</p>)

export default function MeetingDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Company's Meeting Details" titleDescription="Distribution details of your calendar and meetings" explanation={explanation}/>

      <MeetingDetails flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
