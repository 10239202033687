import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, } from '@material-ui/core';
import { API } from 'aws-amplify';
import { Logger } from 'aws-amplify';

const logger = new Logger('admin', 'DEBUG');

async function storeToDatabase(accountName) {
  const flowtraceAPI = 'slackintegration';
  const url = '/members/clientAccounts';
  const callHeaders = {
    body: { clientAccount: { accountName: accountName } },
    response: true
  };

  const stored = await API.post(flowtraceAPI, url, callHeaders).then((response, data, body) => {
    if (response.status !== 200) {
      logger.debug("Error saving the account.")
      return false
    }
    return true;
  }).catch((error) => {
    logger.error(error, error.message);
    return false
  });
  return stored;
}


//Usage: <AddClientAccountDialog dialogState={this.state.dialogOpen} onClose={this.handleClose.bind(this)} />
export default function AddClientAccount(data) {


  const [accountName, setAccountName] = React.useState('');
  const [validating, setValidating] = React.useState(false);
  const [validationError, setValidationError] = React.useState('');
  const [validationSuccess, setValidationSuccess] = React.useState('');

  const handleClose = () => { data.onClose(); };
  const handleSave = async() => {
    try {

      if (!accountName && accountName.length <= 5)
        throw new Error("Account name must be longer than 5 characters")

      // Init the values:
      setValidating(true)
      setValidationSuccess("")
      setValidationError("")
      // Store to DB
      await storeToDatabase(accountName).then(response => {
        if (response)
          setValidationSuccess("Account " + accountName + " stored to the database.")
        else
          setValidationError("Failed to store " + accountName + " to the database.")
      })
    }
    catch (e) {
      console.error("Validation Failed:", e.message)
      setValidationError("Validation Failed: " + e.message)

    }
    setValidating(false)

  };

  return (
    <Fragment>
      <Dialog open={data.dialogState} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create new client account</DialogTitle>
        <DialogContent>
          <DialogContentText>Name must be longer than 5 characters long.</DialogContentText>
          <DialogContentText className="text-danger"> {validationError}</DialogContentText>
          <DialogContentText className="text-success"> {validationSuccess}</DialogContentText>
          <TextField disabled={validating} onInput={e => setAccountName(e.target.value)} autoFocus margin="dense" id="name" label="Account name" type="text" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} disabled={validating} color="primary"> Save </Button>
          <Button onClick={handleClose} disabled={validating} color="primary"> Close </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
