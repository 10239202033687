import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading'
import KPICard from './genericKPICard'
import BarChart from './genericBarChartKPI';


class FlowtraceKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }


  async loadStatistics() {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/usageStatistics';

    const initAPI = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters } };

    let statisticsData = await API.get(dataAPI, statisticsPath, initAPI).then((response, data, body) => {
      if (!response.data.sanitizedStatistics) {
        console.error("No sanitizedStatistics data ");
        return [];
      }

      let filterStats = response.data.sanitizedStatistics
      if (this.state.timeSpan === "30") filterStats = filterStats.slice(-1) // Take the last month
      if (this.state.timeSpan === "90") filterStats = filterStats.slice(-3) // Take the last 3 months
      if (this.state.timeSpan === "365") filterStats = filterStats.slice(-12) // Take the last 12 months
      return filterStats ? filterStats : [];

    }).catch(error => {
      console.error(error);
      return null;
    });

    // Calculate the KPIs and deltas (attendance, average meeting length, agenda%):
    const usageKPI = { activity: { days: 0, people: 0, fulltime_people: 0, gsuite_users: 0, slack_users: 0 }, survey: { sent: 0, sent_fulltime: 0, response: 0, response_fulltime: 0 }, integrations: 0, count: 0, dataFound: false };

    for (const stat of statisticsData ? statisticsData : []) {
      usageKPI.activity.days += stat.activity.days | 0;
      usageKPI.activity.people += stat.activity.people | 0;
      usageKPI.activity.fulltime_people += stat.activity.fulltime_people | 0;
      usageKPI.activity.gsuite_users += stat.activity.gsuite_users | 0;
      usageKPI.activity.slack_users += stat.activity.slack_users | 0;

      usageKPI.survey.sent += stat.survey.sent | 0;
      usageKPI.survey.sent_fulltime += stat.survey.sent_fulltime | 0;
      usageKPI.survey.response += stat.survey.response | 0;
      usageKPI.survey.response_fulltime += stat.survey.response_fulltime | 0;

      usageKPI.count += 1;
      usageKPI.dataFound = true;
    }
    usageKPI.survey.response_rate = usageKPI.survey.sent?(usageKPI.survey.response / usageKPI.survey.sent * 100):0;
    usageKPI.survey.response_rate_fulltime = usageKPI.survey.sent_fulltime?(usageKPI.survey.response_fulltime / usageKPI.survey.sent_fulltime * 100):0;

    usageKPI.activity.days /= usageKPI.count;

    usageKPI.activity.parttime_people = Math.round((usageKPI.activity.people - usageKPI.activity.fulltime_people) / usageKPI.count);
    usageKPI.activity.fulltime_people = Math.round(usageKPI.activity.fulltime_people / usageKPI.count);
    usageKPI.activity.people = Math.round(usageKPI.activity.people / usageKPI.count);
    usageKPI.activity.gsuite_users = Math.round(usageKPI.activity.gsuite_users / usageKPI.count);
    usageKPI.activity.slack_users = Math.round(usageKPI.activity.slack_users / usageKPI.count);

    this.setState({ loading: false, usageKPI });
    return;
  }

  async componentDidMount() {
    this.loadStatistics();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }

  render() {
    if (this.state.loading)
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                <div className="p-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Team and Usage</div>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );


    const leftHandColumn = [];
    const rightHandColumn = [];

    if (this.state.usageKPI && this.state.usageKPI.activity) {
      const peopleMetadata = {
        title: "Active Users and Survey Responses",
        categories: [
          { cat: "All Users", data: this.state.usageKPI.activity.parttime_people, color: "#79DE79" },
          { cat: "Active Employees", data: this.state.usageKPI.activity.fulltime_people, color: "#0CC078" },
        ]
      };
      leftHandColumn.push(<BarChart key="people_kpi" chartdata={peopleMetadata} />);
    }

    if (this.props.teamworkScore && this.props.teamworkScore.latestMovingAverage)
      rightHandColumn.push(<KPICard key="teamworkScore" KPI="team_teamwork" benchmark={this.props.benchmark} value={this.props.teamworkScore.latestMovingAverage} compareValue={this.props.teamworkScore.average} />);
    if (this.props.teamCompositionScore && this.props.teamCompositionScore.latestMovingAverage)
      rightHandColumn.push(<KPICard key="teamCompScore" KPI="team_team_composition" benchmark={this.props.benchmark} value={this.props.teamCompositionScore.latestMovingAverage} compareValue={this.props.teamCompositionScore.average} />);
    if (this.state.usageKPI && this.state.usageKPI.survey)
      rightHandColumn.push(<KPICard key="surveyResponseRate" KPI="team_response_rate" benchmark={this.props.benchmark} value={this.state.usageKPI.survey.response_rate}  />);

    if (leftHandColumn.length === 0 && rightHandColumn.length === 0)
      return null; // Return nothing if there is no productivity data available



    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    <div className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Flowtrace & Usage</div>
                    {leftHandColumn}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {rightHandColumn}
                  </Grid>
                </Grid>
            </CardContent>
          </Card>
        </Fragment>
      </Grid>
    ); // end of return
  } // end of render()
} // end of class

export default FlowtraceKPIComponent;
