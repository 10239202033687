import React from 'react';
import { Button, Card, Dialog, Link, DialogContent, Grid, Divider } from '@material-ui/core';
import { Launch } from '@material-ui/icons';
import SlackConfiguration from './slackConfiguration';
import MuiAlert from '@material-ui/lab/Alert';
import SlackLogo from '../../../../assets/images/integration_logos/slack.png';

export default function Configuration(props) {

  const [showConfigureModalDialog, setShowConfigureModalDialog] = React.useState(false);
  const handleClose = () => { setShowConfigureModalDialog(false); };
  const showConfigureModal = () => { setShowConfigureModalDialog(true); };

  const configureText = props.flowtraceUser.account.ws.SLACK?"Configure":"Install Slack";
  const configureVariant = props.flowtraceUser.account.ws.SLACK?"contained":"outlined";
  const configureButton = <Button onClick={() => showConfigureModal()} color="primary" variant={configureVariant}>{configureText}</Button>;
  const dialogHeader = props.flowtraceUser.account.ws.SLACK?<h5>Configure Slack</h5>:<h5>Install Slack Integration</h5>;
  const viewInstructions = <Link underline="hover" variant="body2" target={"_blank"} href={"https://help.flowtrace.co/en/articles/5893312-getting-started-with-flowtrace-free-plan-and-slack-integration"}>Instructions<Launch fontSize="small"/></Link>;

  return (
    <Card className="p-4 mb-4">
      <Grid container spacing={0}>
        <Grid item xs={2} >
          <img style={{maxHeight:"40px", maxWidth:"40px"}} alt="Paywall" src={SlackLogo} />
        </Grid>
        <Grid item xs={7}>
          <div className="font-size-lg font-weight-bold">Slack Integration </div>
        </Grid>
        <Grid item className="pt-2" xs={3}>
          {viewInstructions}
        </Grid>
      </Grid>
      <Divider className="my-4" />
      <Grid container spacing={0}>
        <Grid item xs={6} >
          {configureButton}
        </Grid>
        <Grid item xs={6}>
        {props.flowtraceUser.account.ws.SLACK?
          <MuiAlert key="latestMessage" severity={"success"} className="pt-0 pb-0">
            <strong className="d-block">Installed</strong>
          </MuiAlert>
        : null }
        </Grid>
      </Grid>
      <Dialog fullWidth={true} maxWidth="lg" open={showConfigureModalDialog} aria-labelledby="form-dialog-title">
        <DialogContent>
          {dialogHeader}
          <SlackConfiguration onClose={handleClose} flowtraceUser={props.flowtraceUser}/>

        </DialogContent>
      </Dialog>
    </Card>
  );
}