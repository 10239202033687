import React, { Component, Fragment } from 'react';
import Loading from '../../../flowtrace-components/Loading'
import FilterBar from '../../Common/FilterBar';
import { Grid, Checkbox, FormControlLabel } from '@material-ui/core';
import moment from 'moment'

import KPITable from './KPITable'
import KPIChart from './KPIChart'
import InboundFunnel from './inboundFunnel'
import CSVFileSelectAndUpload from './CSVImport'

import { API } from 'aws-amplify';

function filterCurrentPeriodData(originalData, period) {
  let periodToFilter;
  if (period === "week") periodToFilter = moment().subtract(moment().isoWeekday() - 1, "days").format("YYYY-MM-DD");
  if (period === "month") periodToFilter = moment().format("YYYY-MM-01");
  if (period === "quarter") periodToFilter = moment().subtract((moment().month % 3) - 1, "months").format("YYYY-MM-01");

  const filteredData = [];
  for (const metric of originalData) {
    const filteredMetric = { ...metric };
    filteredMetric.data = filteredMetric.data.filter(d => d.date !== periodToFilter);
    filteredData.push(filteredMetric);
  }
  console.log("Filter out incomplete period:" + periodToFilter);
  return filteredData;
}

class AdminBusinessKPIs extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, incomplete: true, selection: "Select", queryFilters: { range_type: "calendar", range_period: "month" } };
  }

  async loadReport(queryFilters) {
    this.setState({ loading: true, queryFilters });
    const flowtraceAPI = 'slackintegration';
    const URL = '/flowtrace/analytics/report';

    const options = { headers: { "Content-Type": "application/json" }, response: true };
    options.queryStringParameters = { ...queryFilters };

    const response = await API.get(flowtraceAPI, URL, options);
    this.setState({ loading: false, reportData: response.data.results });
    return;
  }

  async componentDidMount() {
    await this.loadReport(this.state.queryFilters);
  }

  onChange = async(queryFilters) => {
    await this.loadReport(queryFilters);
  }
  incompleteOnChange() {
    this.setState({ incomplete: !this.state.incomplete });
  }

  render() {
    if (this.state.loading)
      return <Loading />;

    if (!this.state.reportData)
      return <Loading error/>;

    // Filter out incomplete month/week/quarter if show incomplete is not selected:
    const reportData = this.state.incomplete ? this.state.reportData : filterCurrentPeriodData(this.state.reportData, this.state.queryFilters.range_period);

    const KPICategories = [...new Set(this.state.reportData.map(i => i.category))].sort();
    const KPICharts = [];
    const funnel = [];
    for (const cat of KPICategories) {
      if(cat === "Inbound Funnel")
        funnel.push(
          <Grid item xs={12} >
            <InboundFunnel key={cat+"-chart"} loading={this.state.loading} category={cat} reportData={reportData} />
          </Grid>
        );
      else
      KPICharts.push(
        <Grid item xs={12} md={4}>
          <KPIChart key={cat+"-chart"} loading={this.state.loading} category={cat} reportData={reportData} />
        </Grid>
      );
    }
    const injectComponents =
      <div>
        <div style={{float:'left', marginBottom:"5px", marginTop:"5px", marginLeft:"15px"}}>
            <FormControlLabel color="secondary" control={<Checkbox checked={this.state.incomplete} onClick={(e) => {this.incompleteOnChange()}} name="incomplete" />} label="Show incomplete data" />
        </div>
        <CSVFileSelectAndUpload />
      </div>;


    return (
      <Fragment>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FilterBar flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} injectedComponent={injectComponents} />
            </Grid>
            {funnel}
            {KPICharts}
          </Grid>
          <KPITable loading={this.state.loading} reportData={reportData} />
        </div>
      </Fragment>
    );
  }
}
export default AdminBusinessKPIs;
