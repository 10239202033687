import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import queryString from 'query-string';
import { API } from 'aws-amplify';
import Loading from '../Loading';
//import Auth0Button from '../Configuration/Integration/Auth0Button';

const maxWidth = { style: { maxWidth: "800px" } };
const readyToInstall =
  <MuiAlert className="mb-2" severity="info" {...maxWidth}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">What happens when I click 'Add to Slack'?</strong>
        <p>You will be taken to Slack's authorization screen (or asked to login to Slack). Please select the Slack workspace you’d like analysed and approve the connection with Flowtrace to continue.</p>
        <p>You should review the permissions you grant to Flowtrace. Slack authorization framework ensures we have permissions you explicitly agree to. This is how we use the permissions we request:</p>
        <p><strong>Conversions:</strong> We require access to conversations so you get insights and recommendations.</p>
        <p><strong>People:</strong> We require access to the people of your workspace so we can send surveys (if you so choose to do).</p>
        <p><strong>Actions:</strong> Our bot needs permission to join/leave channels you choose to include in analysis.</p>
        <p><strong>Messages:</strong> We offer surveys, reports, and employee interaction through our app.</p>
        <p><strong>Note:</strong> We will never post anything on your behalf without your consent (i.e. introduction message).</p>
      </span>
    </div>
  </MuiAlert>;

const permissionReview =
  <MuiAlert className="mb-2" severity="warning"  {...maxWidth}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">When will we access the data for analytics?</strong>
        <p>We know you value transparency and data protection as much as we do. Which is why we require you to send out an introductory message to your team about Flowtrace later in this process.</p>
        <p>This is to ensure that your teams are aware of your commitment to team satisfaction and collaboration excellence and that Flowtrace is here to help. We have prepared a template message for your convenience and you will be able to adjust it as you see fit.</p>
      </span>
    </div>
  </MuiAlert>;

class InstallationScope extends Component {

  async redirectToSlack() {
    this.setState({loading:true});
    const permission = this.props.settings.channel === "public" ? "slack_public" : "slack_public,slack_private"; // Set either public, or publich and private

    const flowtraceAPI = 'slackintegration';
    const oauth2InstallURLs = '/oauth2/install/redirect/slack?permissions=' + permission + (this.props.settings.survey ? ",slack_surveys" : "");
    const payload = { headers: {}, response: true };

    try {
      const redirectUrlResponse = await API.get(flowtraceAPI, oauth2InstallURLs, payload);
      if (redirectUrlResponse.data && redirectUrlResponse.data.redirect) {
        window.location = redirectUrlResponse.data.redirect;
        return true;
      }
    }
    catch (e) { console.error(e); }

    this.setState({loading:false});
    return false;
  }

  parseQueryString() {
    const queryParams = this.props.settings.slackInstalled ? this.props.settings.slackInstalled : queryString.parse(this.props.location.search);

    const error = queryParams.flowtrace_code;
    const success = queryParams.success === "true" ? true : false;
    const team = queryParams.team_id;
    const type = queryParams.type;
    const name = queryParams.team_name;
    // Send conversion event to google ads (event name: "Slack Install")
    if (success && window.gtag)
      window.gtag('event', 'conversion', {'send_to': 'AW-625517572/xRjYCLDkkcgDEITIoqoC'});
    if (success || type)
      return { error, success, team, type, name };
    return null;
  }

  installationMessage() {
    const params = this.parseQueryString() ? this.parseQueryString() : {};
    const contact = " Please try again later or contact us with blue chat bubble in right bottom corner!";
    if (params.success && params.type === "slack")
      return (
        <MuiAlert className="m-4 p-5" severity="success"  {...maxWidth}>
          <div className="d-flex align-items-center align-content-center">
            <span>
              <h5>Your workspace {params.name?params.name:""} is now connected!</h5>
            </span>
          </div>
        </MuiAlert>
      );
    else if (params.error && params.type === "slack")
      return (
        <MuiAlert className="mb-4" severity={params.error==="user_declined"?"info":"error"}  {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">Installation did not complete!</strong>
            {(params.error && params.error==="client_account_not_found")? "We encountered a problem with your client account." + contact : null}
            {(params.error && params.error==="code_exchange_failed")? "There was a problem exchanging code between Slack and Flowtrace." + contact : null}
            {(params.error && params.error==="missing_configuration")? "We encountered an unknown problem." + contact : null}
            {(params.error && (params.error==="missing_parameters" || params.error==="failed_to_store"))? "Something went wrong which should not have happened... Please try again below!" : null}
            {(params.error && params.error==="invalid_code" )? "It took too long to exchange code between Slack and Flowtrace. Please try again below!" : null}
            {(params.error && params.error==="user_declined" )? "You declined the permission to install Flowtrace app into your Slack workspace. You can try again below!" : null}
          </span>
        </div>
      </MuiAlert>
      );
    else
      return (
        <MuiAlert className="mb-4" severity={"error"}  {...maxWidth}>
        <div className="d-flex align-items-center align-content-center">
          <span>
            <strong className="d-block">Installation did not complete!</strong>
            { "We encountered an unknown problem." + contact }
          </span>
        </div>
      </MuiAlert>
      );
  }

  render() {
    if(!this.props.isActive) return null;

    if(this.state && this.state.loading) return <Loading text="Please wait while we redirect your to Slack."/>;

    const installOK = this.parseQueryString() && this.parseQueryString().success;
    // Define next text and next action based on is this before, or after install and how the install worked:
    const nextAction = !installOK ? () => this.redirectToSlack.bind(this) : null;

    // Below Auth0Button, slack-bot IdP, and Step navigation provides successfuly integration option for Slack tokens in Auth0 Identity DB
    //const authorize = <Auth0Button loading={false} scopes="chat:write channels:read" connection={"slack-bot"} cbProfile={(p) => console.log(p)} cbError={(e) => console.error(e)} buttonText={"Add to Slack"}/>;
    //<StepNavigation stepTitle="Connect to Slack" {...this.props} nextDisabled={true} nextAction={nextAction} additionalAction={authorize} >
    //<StepNavigation stepTitle="Connect to Slack" {...this.props} nextAction={nextAction} nextAddToSlack={!installOK} >

    return (
      <StepNavigation stepTitle="Connect to Slack" {...this.props} nextAction={nextAction} nextAddToSlack={!installOK} >
        <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center">
          { this.parseQueryString() && this.parseQueryString().success?
            <Grid item xs={12}><h5 className="mb-3">Connection between your Slack workspace and Flowtrace has been established!</h5></Grid>
          :
            <Grid item xs={12} sm={12} md={12} >
              <h5 className="mb-3">Ready to establish connection between Flowtrace and Slack? </h5>
              <p {...maxWidth}>In order to generate insights about your teams’ collaboration Flowtrace needs access to your Slack workspace. Flowtrace will only process the data in accordance with the permissions you define. You can revoke our access at any point.</p>
            </Grid>
          }
          <Grid item xs={12} sm={12} md={12}>
            {this.parseQueryString()?this.installationMessage():readyToInstall}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {this.parseQueryString()?null:permissionReview}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          </Grid>
        </Grid>

      { this.parseQueryString() && this.parseQueryString().success?
        <p className="mt-5">Please click ‘Next’ to proceed.</p>
      :
        <p className="mt-5">Please click the ‘Add to Slack’ button to proceed and follow the instructions on the screen.</p>
      }
      </StepNavigation>
    );
  }
}

export default withRouter(InstallationScope);
