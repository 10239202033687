import React, { Component, Fragment } from 'react';
import { Card, CardContent } from '@material-ui/core';
import Loading from '../../../flowtrace-components/Loading';
import Chart from 'react-apexcharts';

class KPIChart extends Component {

  render() {
    if (this.props.loading)
      return <Loading minimize/>;

    if (!this.props.reportData)
      return <Loading error/>;

    const uniqueDates = [...new Set(this.props.reportData.reduce((acc, cur) => {
      return acc.concat(cur.data.map(i => i.date));
    }, []))].sort();

    const KPIConfiguration = {
      chart: { animations: { enabled: false }, stacked: this.props.category.startsWith("Website Traffic - Sources") ? true : false, toolbar: { show: true, tools: { download: true, selection: false, zoom: false, zoomin: false, zoomout: false, pan: false } } },
      dataLabels: { enabled: false },
      xaxis: { type: "datetime", labels: { show: true }, categories: uniqueDates },
      yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(0) : 0 } } },
      legend: { position: "bottom", show: true, height: "80" },
      //tooltip: { fixed: { enabled: true, position: "bottomRight" } },
      colors: ["#0a3455", "#6ebdc4", "#416d90", "#b4ebf0", "#7cc5cc", "#61a6b5", "#548fa6", "#2e5f80", "#174366"]
    };
    if (this.props.category === "Product" || this.props.category === "SEO" || this.props.category === "Pipeline") {
      KPIConfiguration.yaxis = { logarithmic: false, decimalsInFloat: false }; // TODO: fix logarithmic=true when apexchart works again
    }


    const chartCategories = [];
    for (const metricRow of this.props.reportData) {
      const data = [];
      for (const date of uniqueDates) {
        const found = metricRow.data ? metricRow.data.find(i => i.date === date) : null;
        // These need special handling as 0 is not supported on logarithmic scale
        if (this.props.category.startsWith("Product Tool") || this.props.category === "SEO" || this.props.category === "Pipeline") {
          const value = found && found.value !== "NaN" ? found.value : 0
          data.push(value === 0 ? 0.1 : found.value);
        }
        else
          data.push(found && found.value !== "NaN" ? found.value : 0);
      }
      let metricName = metricRow.metric.charAt(0).toUpperCase() + metricRow.metric.slice(1);
      // Check for metrics like "new_customer" and remove the 'new_' part of the metric name
      if (metricRow.metric.split("_").length > 1)
        metricName = metricRow.metric.split("_")[1].charAt(0).toUpperCase() + metricRow.metric.split("_")[1].slice(1);


      chartCategories.push({ category: metricRow.category, series: { name: metricName, type: 'line', data } });
    }

    const filteredSeries = chartCategories.filter(i => i.category === this.props.category).map(i => i.series);

    return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-0">
            <div style={{marginTop:"10px",marginBottom:"10px"}}>
              <div>
                <div className="pl-3 mt-3 font-weight-bold font-size-lg text-uppercase" >{this.props.category}</div>
                <Chart options={KPIConfiguration} series={filteredSeries} type="line" height="500"/>
              </div>
            </div>
          </CardContent>
        </Card>
      </Fragment>
    );
  }
}

export default KPIChart;
