import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import NetworkChart from './networkChart';
import Loading from '../../Loading'
import Slider from '@material-ui/core/Slider';
import moment from 'moment';

const dates = [];

function createPlaybackMonths(firstDataDate = "2019-06-01") {
  const length = Math.ceil(moment().diff(new moment(firstDataDate), "months", true))
  dates.length = 0
  for (let i = 0; i < length; i++) {
    const currentMonth = new moment(firstDataDate).add(i, "months")
    if (currentMonth.month() === 0)
      dates.push({ value: i + 1, label: currentMonth.format("YYYY MMM"), date: currentMonth.format("YYYY-MM-") + currentMonth.daysInMonth() })
    else
      dates.push({ value: i + 1, label: currentMonth.format("MMM"), date: currentMonth.format("YYYY-MM-") + currentMonth.daysInMonth() })
  }
}

function valuetext(value) {
  try {
    const currentItem = dates.find(item => item.value === value).label || "Empty"
    // Return last 3 characters (e.g. 2020 Jan and Feb needs to be returned correctly)
    return currentItem.substring(currentItem.length - 3, currentItem.length)
  }
  catch (e) {
    console.error(e, e.message)
    return "NaN"
  }
}

async function loadSlackUserData(queryParam) {
  const dataAPI = 'slackintegration';
  const networkDataPath = '/slack/installed/channelNetwork';
  const initAPI = { headers: {}, response: true, };
  if (queryParam) {
    initAPI.queryStringParameters = { range_end: queryParam, range_start: moment(queryParam).subtract(2, "months").format("yyyy-MM-DD") }
  }

  let networkData = await API.get(dataAPI, networkDataPath, initAPI).then((response, data, body) => {
    if (!response.data.network) {
      console.error("No usage data");
      return { nodes: [], entityEdges: [], userEdges: [], channelEdges: [] }; // return empty so things don't crash and burn?
    }
    if (!queryParam) // If it's first load, we need to create data structure for play/pause slider.
      createPlaybackMonths(response.data.firstDataDate)

    return response.data.network;
  }).catch(error => {
    console.error(error); // CORS hits quite often... Figure out why Lambda keeps on failing...
    return { nodes: [], entityEdges: [], userEdges: [], channelEdges: [] }; // return empty so things don't crash and burn?
  });

  return networkData; // return it for storing into state
}

class AccountOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };

  }

  async monthChanged(event, value) {
    await loadSlackUserData(dates[value - 1].date).then((data) => {
      data.reload = true
      this.setState({ networkData: data });
    });
  }

  async componentDidMount() {
    this.setState({ loading: 'true' });
    await loadSlackUserData().then((data) => {
      this.setState({ networkData: data });
      this.setState({ loading: 'false' });
    });
  }
  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }
  render() {
    //console.log(this.state.loading)
    if (this.state.loading === 'true') {
      return (
        <Loading />
      );
    }

    if (this.state.loading === 'false') {
      //console.log(this.state.networkData)
      let channelData = { nodes: this.state.networkData.nodes, edges: this.state.networkData.channelEdges }
      if (this.state.networkData.reload) channelData.reload = true

      return (
        <Fragment>
          <div >
            <h5>This is an overview how your team communicates about work topics in collaboration channels.</h5>
            <Slider onChangeCommitted={this.monthChanged.bind(this)} min={1} max={dates.length} defaultValue={dates.length} valueLabelFormat={valuetext} aria-labelledby="discrete-slider-always" step={1} marks={dates} valueLabelDisplay="on" />
          </div>
            <NetworkChart data={channelData}/>
        </Fragment>
      ); // end of return

    } // end or IF
    return (<Loading/>); // Init stage render return
  } // end of render()

} // end of class

export default AccountOverview
