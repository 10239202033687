import React from 'react';
import { Grid, FormControlLabel, Switch, Button, ButtonGroup } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAlert from '@material-ui/lab/Alert';

const effectivenessHelp =
  <MuiAlert className="mb-4" severity="info" style={{width:"100%"}}>
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">You can choose between StartUp and ScaleUp option.</strong>
          <p>ScaleUp contains 64 questions and Startup option has reduced 40 questions. StartUp option is recommended for teams of 15 employees or less.</p>
          <p>ScaleUp survey contains cross-team collaboration, and team-of-teams type of questions with higher relevancy with larger organizations.</p>
      </span>
    </div>
  </MuiAlert>;



export default function SurveyConfiguration(props) {
  const [expanded, setExpanded] = React.useState(false);

  const examples = [];
  // Filter example list based on small/comprehensive list
  const ranExamples = props.examples.filter(i => !props.useLimitedTeamEffectiveness ? true : i.simple).sort(() => Math.random() - 0.5);
  const p = ranExamples.find(i => i.category === "productivity");
  const m = ranExamples.find(i => i.category === "meeting");
  const e = ranExamples.find(i => i.category === "engagement");
  const mn = ranExamples.find(i => i.category === "management");
  const tc = ranExamples.find(i => i.category === "team_composition");
  const t = ranExamples.find(i => i.category === "teamwork");
  const ctc = ranExamples.find(i => i.category === "cross_team_collaboration");
  const c = ranExamples.find(i => i.category === "company");

  examples.push(<Grid key="col1" item xs={3}>Category</Grid>);
  examples.push(<Grid key="col2" item xs={2}># Questions</Grid>);
  examples.push(<Grid key="col3" item xs={7}>Example</Grid>);

  examples.push(<Grid key="col4" item xs={3}>Productivity</Grid>);
  examples.push(<Grid key="col5" item xs={2}>8</Grid>);
  examples.push(<Grid key="col6" item xs={7}>{<strong>{p.text}</strong>}</Grid>);

  examples.push(<Grid key="col7" item xs={3}>Meeting</Grid>);
  examples.push(<Grid key="col8" item xs={2}>{props.useLimitedTeamEffectiveness?4:8}</Grid>);
  examples.push(<Grid key="col9" item xs={7}>{<strong>{m.text}</strong>}</Grid>);

  examples.push(<Grid key="col10" item xs={3}>Engagement</Grid>);
  examples.push(<Grid key="col11" item xs={2}>8</Grid>);
  examples.push(<Grid key="col12" item xs={7}>{<strong>{e.text}</strong>}</Grid>);

  examples.push(<Grid key="col13" item xs={3}>Management</Grid>);
  examples.push(<Grid key="col14" item xs={2}>{props.useLimitedTeamEffectiveness?4:8}</Grid>);
  examples.push(<Grid key="col15" item xs={7}>{<strong>{mn.text}</strong>}</Grid>);

  examples.push(<Grid key="col16" item xs={3}>Team Composition</Grid>);
  examples.push(<Grid key="col17" item xs={2}>{props.useLimitedTeamEffectiveness?4:8}</Grid>);
  examples.push(<Grid key="col18" item xs={7}>{<strong>{tc.text}</strong>}</Grid>);

  examples.push(<Grid key="col19" item xs={3}>Teamwork</Grid>);
  examples.push(<Grid key="col100" item xs={2}>8</Grid>);
  examples.push(<Grid key="col101" item xs={7}>{<strong>{t.text}</strong>}</Grid>);

  if (!props.useLimitedTeamEffectiveness) {
    examples.push(<Grid key="col102" item xs={3}>Cross-Team Collaboration</Grid>);
    examples.push(<Grid key="col103" item xs={2}>8</Grid>);
    examples.push(<Grid key="col104" item xs={7}>{<strong>{ctc.text}</strong>}</Grid>);
  }
  examples.push(<Grid key="col105" item xs={3}>Company Vision and Alignment</Grid>);
  examples.push(<Grid key="col106" item xs={2}>{props.useLimitedTeamEffectiveness?4:8}</Grid>);
  examples.push(<Grid key="col107" item xs={7}>{<strong>{c.text}</strong>}</Grid>);

  const handleChange = panel => (event, isExpanded) => {

    // If the type is checkbox, and it's been enabled, make sure the panel is expanded:
    if(event.target.type === "checkbox" && event.target.checked)
      setExpanded(event.target.name);

    // Otherwise skip the checkbox events to expand/shrink the panel
    if(event.target.type !== "checkbox")
      setExpanded(isExpanded ? panel : false);

    //console.log(event.target.type, event.target.checked, isExpanded)
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12}>
        <ExpansionPanel expanded={expanded === 'useNPS'} onChange={handleChange('useNPS')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel className="mb-0" control={<Switch checked={props.useNPS} onChange={props.onChange} color="primary" name="useNPS" />} />
            <h5 className="pt-2 font-weight-bold font-size-lg"> eNPS - Employee Net Promoter Score Survey</h5>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="">
              <p className="font-weight-bold font-size-md">Example question:</p>
              <p className="card-text font-weight-bold"> "I would recommend our workplace to a friend, or ex-colleague?"</p>
              <p className="card-text"> Answer range: Strongly Disagree 1 - Strongly Agree 5 </p>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
      <Grid item xs={12} md={12}>
        <ExpansionPanel expanded={expanded === 'useTeamEffectiveness'} onChange={handleChange('useTeamEffectiveness')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <FormControlLabel className="mb-0" control={<Switch checked={props.useTeamEffectiveness} onChange={props.onChange} color="primary" name="useTeamEffectiveness" />} />
            <h5 className="pt-2 font-weight-bold font-size-lg"> Flowtrace Team Effectiveness Survey</h5>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
              <Grid container spacing={1} direction="column" justifyContent="center" alignItems="stretch">
                {effectivenessHelp}
                <Grid item xs={12} md={12}>
                  <p className="font-weight-bold font-size-md"> Choose the type of effectiveness survey:</p>
                  <ButtonGroup className="mb-4" color="primary" size="large" variant="contained">
                    <Button color={props.useLimitedTeamEffectiveness?"primary":"default"} onClick={(event) => props.onChange(event, "startup")} name="startup" >StartUp</Button>
                    <Button color={props.useLimitedTeamEffectiveness?"default":"primary"} onClick={(event) => props.onChange(event, "scaleup")} name="scaleup" >ScaleUp</Button>
                  </ButtonGroup>
                  <p className="mb-1 font-weight-bold font-size-md">Example question:</p>
                </Grid>
                <Grid item xs={12} md={12}>
                  <Grid container spacing={1}>
                    {examples}
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p className="card-text"> Answer range: Strongly Disagree 1 - Strongly Agree 5 </p>
                </Grid>
              </Grid>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Grid>
    </Grid>
  );
}
