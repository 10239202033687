import React from 'react';
import { Grid, Button } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory } from "react-router-dom";

const slackMissing = (action) => {
  return (
    <MuiAlert key="slackMissingAlert" style={{height:"100%"}} severity="info">
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block font-size-md">We don't have enough Slack message data.</strong>
          <p>We require over 100 messages for given filters to show you Slack best practice recommendations.</p>
          <p>You can see how many messages we have analyzed from Slack overview page.</p>
          <div className="mt-3">
            <Button onClick={action} rel="noopener" size="large" variant="contained" color="primary">Slack Overview</Button>
          </div>
        </span>
      </div>
    </MuiAlert>);
};

const surveyMissing = (action) => {
  return (
    <MuiAlert key="surveyMissingAlert" style={{height:"100%"}} severity="info">
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block font-size-md">Surveys not configured or high scores.</strong>
          <p>We give you team effectiveness recommendation when your survey scores indicate you are below industry benchmarks.</p>
          <p>You can see your Survey configuration using button below.</p>
          <div className="mt-3">
            <Button onClick={action} rel="noopener" size="large" variant="contained" color="primary">Survey Configuration</Button>
          </div>
        </span>
      </div>
    </MuiAlert>);
};

const productivityMissing = (action) => {
  return (
    <MuiAlert key="surveyMissingAlert" style={{height:"100%"}} severity="info">
      <div className="d-flex align-items-center align-content-center">
        <span>
          <strong className="d-block font-size-md">You are on a free plan or have limited integrations.</strong>
          <p>We offer most of our advanced recommendations from common collaboration and productivity tool categories:</p>
          <strong>Meeting and productivity:</strong>
          <ul>
            <li>Google Calendar and Meets - meetings and punctuality</li>
            <li>Google Workspace - productivity</li>
            <li>Google Docs - knowledge sharing</li>
          </ul>
          <strong>Product and Project Management:</strong>
          <ul>
            <li>Jira - product management</li>
            <li>Asana - project management</li>
            <li>Trello - project management</li>
          </ul>
          <strong>Software and Product development:</strong>
          <ul>
            <li>GitHub - software and product development</li>
            <li>GitLab - software development</li>
          </ul>
          <strong>Sales Process and Deal Flow:</strong>
          <ul>
            <li>HubSpot - sales process, deal flow, and collaboration</li>
            <li>Pipedrive - sales process and deal flow </li>
          </ul>
          <p>You can see your current plan and upgrade from link below.</p>
          <div className="mt-3">
            <Button onClick={action} rel="noopener" size="large" variant="contained" color="primary">Current Plan</Button>
          </div>
        </span>
      </div>
    </MuiAlert>);
};


export default function RecommendationTroubleshooting(data) {
  const history = useHistory();

  const routeChange = (route) => {
    history.push(route);
  };


  return (
    <Grid key="firstGrid"item xs={12} lg={12}>
      <Grid container spacing={1} className="mt-1">
        <Grid item xs={12}>
          <div className=" br-5" ><b className="font-size-lg " >We didn't find any recommendations for your account</b></div>
          <span className="font-size-md text-black-50 d-block">Why you cannot see our recommendations?</span>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          {slackMissing(() => routeChange("/Slack/Overview"))}
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          {surveyMissing(() => routeChange("/Account/Survey"))}
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          {productivityMissing(() => routeChange("/Account/Billing"))}
        </Grid>
      </Grid>
    </Grid>
  );


}
