import React, { Component } from 'react';
import { Menu, Grid, Button, ButtonGroup, TextField } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import AddIcon from '@material-ui/icons/Add';
import { Business, Clear, ArrowDropDown, Undo, AccountTree, Add, Edit } from '@material-ui/icons';
import { getLogo } from '../../../../flowtrace-components/Common/Logos';
import { Tooltip, IconButton } from '@material-ui/core';
import RemoveTeamOverrideDialog from './removeTeamOverrideDialog';

const createNodeTree = (paths) => {
    let result = [];
    let level = {result};

    paths.forEach(path => {
        path = path.replace("/", ""); // replace first sladh away
        path.split('/').reduce((r, name, i, a) => {
            if(!r[name]) {
              r[name] = {result: []};
              if(name.length)
                r.result.push({path:"/" + path, name, children: r[name].result});
            }
        return r[name];
      }, level);
    });

    return result;
};


class TeamFilterDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = { edit: false, team: null, currentlySelected: this.props.currentMember.orgUnitPath, teamFilterAnchor: null, revertDialog: false };
    }

    handleNodeSelect(event, value) {
        this.setState({currentlySelected: value[0]});
    }

    applyNodeSelect() {
        this.setState({teamFilterAnchor:null});
        if (this.state.currentlySelected)
            return this.props.onChange({ team: this.state.currentlySelected, currentMember: this.props.currentMember});
        return this.props.onChange({team:null});
    }

    resetNodeSelect() {
        this.setState({currentlySelected: null, team:null});
        this.setState({teamFilterAnchor:null});
    }

    onTeamChange = (i) => {
        this.setState({currentlySelected:i});
    }

    renderTree(nodes) {
        const tree = [];
        for(const node of nodes) {
            const addNewIcon = <Tooltip key={"nodett-" + node.path} title={"Add new team under " + node.name} ><AddIcon onClick={() => this.displayAddNewTeamForm(node.path)} /></Tooltip>;
            const label = (this.state.currentlySelected === node.path ? <b>{node.name}</b> : node.name);
            const nodeContent = [];
            if(node.children.length)
                nodeContent.push(this.renderTree(node.children));

            const nodeItem = <TreeItem className="m-2 pr-3" key={node.path} onClick={() => this.onTeamChange(node.path)} icon={addNewIcon} nodeId={node.path} label={label}> {nodeContent} </TreeItem>;
            tree.push(nodeItem);
        }
        return tree;
    }

    displayAddNewTeamForm = (team) => {
        this.setState({newTeamParent: team});
    }

    saveNewTeam = (e) => {
        const newTeam = [this.state.newTeamParent==="/" ? "" : this.state.newTeamParent, e.target.value].join('/');
        this.setState({currentlySelected:newTeam});
    }

    handleRevertDialogClose(selection) {
        this.setState({revertDialog: false});
        if(selection)
            this.props.onChange({...selection, currentMember: this.props.currentMember});
    }


    render() {
        const editButton = <IconButton key="editbutton" color="secondary" size="small" aria-haspopup="true" onClick={() => this.setState({edit:true})}><Edit/></IconButton>;
        const addButton = <IconButton key="addbutton" color="secondary" size="small" aria-haspopup="true" onClick={() => this.setState({edit:true})}><Add/></IconButton>;
        const undoButton = <IconButton key="undobutton" color="secondary" size="small" aria-haspopup="true" onClick={() => this.setState({revertDialog:true})}><Undo/></IconButton>;
        const cancelButton = <IconButton key="canceledit" color="secondary" size="small" aria-haspopup="true" onClick={() => this.setState({edit:false})}><Clear/></IconButton>;


        // First render the user's centrally managed / manual edit mode/action buttons
        if(!this.state.edit && !this.state.revertDialog) {
            const components = [];
            if(this.props.currentMember.manualOrgUnitPath){
                components.push(<Tooltip key="ttip1" title={"Managed manually"} ><AccountTree className="mr-4"/></Tooltip>);
                components.push(<Tooltip key="ttip2" title={"Edit team override."} >{ editButton }</Tooltip>);
                components.push(<Tooltip key="ttip3" title={"Removes the manually set team from this team member."} >{ undoButton }</Tooltip>);
            }
            else if(this.props.currentMember.orgUnitPath) {
                const nonMerged = this.props.currentMember.user.find(u => !u.merge);
                const mergeType = nonMerged ? nonMerged.type : "GSUITE";
                if(mergeType === "GSUITE")
                    components.push(<Tooltip key="ttip"title={"Centrally Managed by Google Workspace"} ><img className="mr-4" style={{maxHeight:"25px", maxWidth:"25px"}} alt="Logo" src={getLogo("gsuite")} /></Tooltip>);
                else
                    components.push(<Tooltip key="ttip"title={"Centrally Managed by Microsoft Directory"} ><img className="mr-4" style={{maxHeight:"25px", maxWidth:"25px"}} alt="Logo" src={getLogo("microsoft")} /></Tooltip>);
                components.push(<Tooltip key="ttip2" title={"Add team override."} >{ editButton }</Tooltip>);
            }
            else {
                components.push(<Tooltip key="ttip2" title={"Add team override."} >{ addButton }</Tooltip>);
            }
            return components;
        }


        const currentTeamName = this.props.currentMember.orgUnitPath ? this.props.currentMember.orgUnitPath.split("/").at(-1) : "";
        const selectedText = currentTeamName ? currentTeamName : 'Select';
        const companyName = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account.account_name : "Company";
        const moveWho = <Grid item xs={12}>Move <b>{this.props.currentMember.name}</b> to:</Grid>;
        const moveToTeam = <Grid item xs={12}>{this.state.currentlySelected}</Grid>;
        const companyLabel =
            <div className={{ display: 'flex', alignItems: 'center'}}>
                {<Business onClick={() => this.displayAddNewTeamForm("/")}/>} {companyName}
            </div>;

        const addTeamIcon = <Tooltip key="ttip2" title={"Add to new team."} ><AddIcon onClick={() => this.displayAddNewTeamForm("/")}/></Tooltip>;


        return (
            <div>
                <div style={{ float:'left' }}>
                    <ButtonGroup variant="contained" color="secondary" aria-label="split button">
                        <Button disabled={this.props.teamFilters && this.props.teamFilters.length<=1} color="secondary" onClick={(e) => {this.setState({teamFilterAnchor:e.currentTarget})}}>{selectedText}</Button>
                        <Button disabled={this.props.teamFilters && this.props.teamFilters.length<=1} color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({teamFilterAnchor:e.currentTarget})}>
                            <ArrowDropDown />
                        </Button>
                    </ButtonGroup>
                    <Menu id="simple-menu" PaperProps={{ style: { width: 400 }}}  anchorEl={this.state.teamFilterAnchor} keepMounted open={Boolean(this.state.teamFilterAnchor)} onClose={() => this.setState({teamFilterAnchor:null})}>

                        <Grid className="p-3" container direction="row" justifyContent="space-between" alignItems="center" >
                        {moveWho}
                        <Grid item xs={12}>
                            {this.state.newTeamParent ?

                                <div className="m-4" style={{float:'left', marginBottom:"5px"}}>
                                    <TextField autoFocus={true} required={true} id="new-team" label="Create new team:" variant="outlined" onKeyDown={(e) => e.stopPropagation()} onChange={this.saveNewTeam} />
                                    <Clear className="ml-4 mt-3" onClick={() => this.setState({newTeamParent:null})}/>
                                </div>
                            :
                                <TreeView
                                    onNodeSelect={this.handleNodeSelect.bind(this)}
                                    expanded={["/"].concat(this.props.teamFilters)}
                                    selected={this.state.currentlySelected} >
                                    <TreeItem className="m-3 pr-3" key={"/"} icon={addTeamIcon} nodeId={"/"} label={ companyLabel } >
                                        {this.renderTree(createNodeTree(this.props.teamFilters))}
                                    </TreeItem>
                                </TreeView>
                            }
                        </Grid>
                            {this.state.currentlySelected !== this.props.currentMember.orgUnitPath ?  moveToTeam : null }

                            <Grid item xs={6}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    onClick={this.applyNodeSelect.bind(this)}
                                    disabled={(this.state.currentlySelected === this.props.currentMember.orgUnitPath)}
                                    color="primary"
                                    className="m-2">Move</Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button size="small" variant="contained" onClick={this.resetNodeSelect.bind(this)} className="m-2">Cancel</Button>
                            </Grid>
                        </Grid>
                    </Menu>

                </div>
                <RemoveTeamOverrideDialog dialogState={this.state.revertDialog} onClose={this.handleRevertDialogClose.bind(this)} selectedMember={this.props.currentMember} />
                <Tooltip key="canceltooltip" title={"Cancel the team change."} >{ cancelButton }</Tooltip>
            </div>
        );

    }
} // end of class

export default TeamFilterDropdown;
