import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import StatsCard from '../../Common/Cards/productivityStatsCard';
import extract from '../dataPointExtractor';

export default function TeamStatisticsChart(data) {
  const series = { overview: [], volume: [], meetings: [], focus: [], multitasking: [] };
  const categories = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date });
  const companyData = {
    e: [], // emails
    s: [], // slacks
    mi: [], // meetings internal
    me: [], // meetings external
    mmt: [], // meeting multitasking
    mt: [], // multitasking
    f: [], // focus time
    a: [], // activity time
    ooh: [], // out of hours time
  };


  for (const dataPoint of Object.values(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1))) {
    const data = extract.extractData(dataPoint);
    companyData.e.push(data.email);
    companyData.s.push(data.slack);
    companyData.mi.push(data.internalMeeting);
    companyData.me.push(data.externalMeeting);
    companyData.mmt.push(data.meetingMultitasking);
    companyData.mt.push(data.multitasking * -1);
    companyData.f.push(data.focus);
    companyData.a.push(data.activeHours);
    companyData.ooh.push(data.outOfHoursCollaboration * -1);
  }

  series.overview.push({ name: "Emailing", type: "bar", data: companyData.e }); // green
  series.overview.push({ name: "Chatting", type: "bar", data: companyData.s }); // green
  series.overview.push({ name: "Meetings (Internal)", type: "bar", data: companyData.mi });
  series.overview.push({ name: "Meetings (External)", type: "bar", data: companyData.me });
  series.overview.push({ name: "Focus-time", type: "bar", data: companyData.f });
  series.overview.push({ name: "Out of hours", type: "bar", data: companyData.ooh });
  series.overview.push({ name: "Multitasking", type: "bar", data: companyData.mt });

  const graphChartOptionsOverview = {
    chart: { animations: { enabled: false }, stacked: true, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val ? (Math.abs(val) / 60).toFixed(1) + "h" : "" } } },
    legend: { position: "right", show: true },
    fill: {
      type: ['solid', 'solid', 'solid', 'solid', 'solid', 'pattern', 'pattern'],
      pattern: {
        style: 'squares',
        width: 3,
        height: 3,
        strokeWidth: 1
      }
    },
    colors: ['#32a871', '#60bd79', '#608bbd', '#6071bd', '#e87694', '#7216b8', '#cf3a29']
  };



  const last = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[1]);
  const comp = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[2]);

  const slackStat = { value: last.slack, previous: comp.slack };
  const emailStat = { value: last.email, previous: comp.email };
  const meetingStat = { value: last.meeting, previous: comp.meeting };
  const multitaskingStat = { value: last.multitasking, previous: comp.multitasking };
  const focusTimeStat = { value: last.focus, previous: comp.focus };
  const outOfHoursStats = { value: last.outOfHoursCollaboration, previous: comp.outOfHoursCollaboration };

  return (
    <Card className="card-box mb-0" >
      <CardContent className="p-3">
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="slack" componentColor="bg-brand-slack" data={slackStat} period={data.timeSpan}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="email" componentColor="bg-primary" data={emailStat} period={data.timeSpan} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="meeting" componentColor="bg-plum-plate" data={meetingStat} period={data.timeSpan}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="focus" componentColor="bg-dark" data={focusTimeStat} period={data.timeSpan}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="multitasking" componentColor="bg-warning" data={multitaskingStat} period={data.timeSpan}/>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StatsCard id="outOfHours" componentColor="bg-brand-facebook" data={outOfHoursStats} period={data.timeSpan}/>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} >
          <div className="mt-3 text-left">
            <div className="ml-3 mt-1 line-height-sm">
              <div className=" br-5" ><b className="font-size-lg " >Collaboration Overview</b></div>
                <span className="text-black-50 d-block">Here you see how your team spends their days (hours / person / {data.timeSpan})</span>
                <Chart options={graphChartOptionsOverview} series={series.overview} type="bar" height="300"/>
              </div>
          </div>
        </Grid>
      </CardContent>
    </Card>
  );


}
