import React, { Component, Fragment } from 'react';

import Loading from '../../../../flowtrace-components/Loading'
import moment from 'moment'
import InviteDialog from './inviteDialog'
import RoleDialog from './roleDialog'
import { Button } from '@material-ui/core';

import { API } from 'aws-amplify';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class AccountMembers extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, inviteDialog: false, roleDialog: false, cognitoIdentityId:null, role:null };
  }

  async loadClientAccount() {
    this.setState({ loading: true });
    const flowtraceAPI = 'slackintegration';
    const callHeaders = { headers: {}, response: true };

    const response = await API.get(flowtraceAPI, '/user/account', callHeaders);
    const account = response.data && response.data.success ? response.data.accountInfo : null;
    this.setState({ account, loading: false });
    //console.log(account);
    return;
  }


  async componentDidMount() {
    await this.loadClientAccount();
  }
  openInviteDialog(event) {
    this.setState({ inviteDialog: true });
  }
  openRoleDialog(user) {
    //console.log("click", user)
    this.setState({ roleDialog: true, user });
  }
  async handleClose() {
    this.setState({ inviteDialog: false, roleDialog: false });
    await this.loadClientAccount();
  }


  render() {
    if (this.state.loading) {
      return <Loading/>;
    }
    if (!this.state.account) {
      return <Loading error="Couldn't find your account." />;
    }

    // Create a user list with unique emails and multiple identities for the data table (with their statuses):
    const users = [];

    const getIdentity = (u) => {
      const identity = {};
      if(u.auth0)
        identity.sub = u.auth0.sub.split("|")[0];
      else if(u.login_type === "CognitoUser")
        identity.sub = "aws-cognito";
      else if(u.login_type === "Google SSO")
        identity.sub = "google-oauth2";
      else if(u.login_type === "Auth0")
        identity.sub = "auth0";
      else
        identity.sub = "unknown";
      identity.last_login = u.last_login;
      identity.created = u.created;
      identity.cognito_id = u.cognito_id;
      return identity;
    };
    for(const u of this.state.account.users){
      // Check invites:
      if(u.invite && u.cognito_id.startsWith("invite"))
        users.push({name:null, email:u.email, status:"INVITED", created:u.created, user_role:u.user_role});
      else if(users.find(user => user.email === u.email)){
        users.find(user => user.email === u.email).identities.push(getIdentity(u));
      }
      else {
        const user = {...u, identities:[getIdentity(u)]};
        users.push(user);
      }
    }
    const displayUsers = users.map(u => { return {...u, id:"key-" + (u.cognito_id ? u.cognito_id : u.created)}})

    const invite =<div>Flowtrace Web App Users <Button className="align-items-center ml-4 mt-3 mb-3" onClick={this.openInviteDialog.bind(this)} size="medium" variant="contained" color="primary" >Invite Colleague</Button></div>;

    const roleText = (role) => {
      if(role === "administrator") return "Administrator";
      if(role === "insights") return "Insights";
      if(role === "billing") return "Billing";
      if(role === "integration") return "Integration";
    }
    const roleButton = (u) => {
      if(!u) return;
      if(!u.cognito_id) return u.user_role ? roleText(u.user_role) : "";
      return <Button onClick={() => this.openRoleDialog(u)} fullWidth={true} style={{justifyContent: "flex-start"}} size="small" variant="text" color="primary" >{roleText(u.user_role)}</Button>

    }
    return (
      <Fragment>
            <InviteDialog dialogState={this.state.inviteDialog} onClose={this.handleClose.bind(this)} />
            <RoleDialog dialogState={this.state.roleDialog} user={this.state.user} onClose={this.handleClose.bind(this)} />
            <MaterialTable
              key={"userTable"}
              columns={[
                { title: "Name", field:"name", render: ( rowData => { return rowData.name?rowData.name:rowData.full_name } ) },
                { title: "Email", field:"email", defaultSort:"asc" },
                { title: "Role", field:"user_role", render: rowData => { return roleButton(rowData) }  },
                { title: "Login method", field:"status", render: ( rowData => {
                  if(rowData.status === "INVITED") return "Invited to Flowtrace";
                  return rowData.identities.map(i => <p key={"sub-key-"+i.created}>{i.sub}</p> );
                  } ) },
                { title: "Last Login", field:"last_login", render: ( rowData => {
                  if(!rowData.last_login) return "";
                  if(rowData.status === "INVITED") return "";
                  return rowData.identities.map(i => <p key={"login-key-"+ i.created}>{moment(i.last_login).calendar()}</p> );
                  } ) },
                { title: "Created", field:"created", render: ( rowData => {
                  if(!rowData.created) return "";
                  if(rowData.status === "INVITED") return moment(rowData.created).calendar();
                  return rowData.identities.map(i => <p key={"created-key-"+i.created}>{moment(i.created).calendar()}</p> );

                } ) },
              ]}
              data={displayUsers}
              icons={tableIcons}
              title={<h5 className="display-5 mb-4 mt-4 font-weight-bold">{invite}</h5>}
              options={
                {
                padding:"dense",
                pageSize: this.state.account.users.length<10?this.state.account.users.length:10,
                pageSizeOptions:this.state.account.users.length<10?[this.state.account.users.length]:[10,25],
                }
              }
            />
        </Fragment>
    );

  }
}

export default AccountMembers;
