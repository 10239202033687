import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';

class GenericComparisonColumnChart extends Component {
  constructor(props) {
    super(props);

    const metricSeries = {name:"Current", data:[]};
    for(const [metric, value] of props.metrics?Object.entries(props.metrics):[]){
      // Skip the metric if it should not be shown (previous has the previous values)
      if(metric === "previous" || props.exclude.includes(metric)) continue;
      const previousValue = props.metrics.previous[metric]?parseInt(props.metrics.previous[metric],10):null;
      const currentValue = parseInt(value,10);
      const goals = [{name:"Previous ", value: previousValue, strokeHeight: 5, strokeColor: '#775DD0' }];
      metricSeries.data.push({x:metric[0].toUpperCase() + metric.substring(1), y: currentValue, goals});
    }
    this.state = { loading: false, series:[metricSeries]};
  }



  async componentDidMount() {

  }


  render() {

    if (this.state.loading)
      return <div/>

    const barChartConfig = {
      chart: {animations: { enabled: false }, toolbar: { show: false } },
      plotOptions: { bar: { columnWidth: '60%' } },
      colors: ['#00E396'],
      animations: { enabled: false },
      toolbar: { show: false },
      dataLabels: { enabled: false },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ['Current', 'Previous'],
        markers: { fillColors: ['#00E396', '#775DD0'] }
      },
    };


    return (
      <Fragment >
              <span className="pl-3 d-block font-weight-bold">{this.props.title}</span>
              <Chart options={barChartConfig} series={this.state.series} type="bar" height="300"/>
        </Fragment>
    );
  } // end of render()
}

export default GenericComparisonColumnChart;
