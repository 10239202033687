import { API } from 'aws-amplify';

const genericInstallation = async(sub) => {
  try{
    const flowtraceAPI = 'slackintegration';
    const endpoint = '/oauth2/install/auth0Integration';
    const callHeaders = { body: { sub }, headers: {}, response: true };
    const {data : {success, message, ...payload}} = await API.post(flowtraceAPI, endpoint, callHeaders);
    return { success, message, payload };
  }catch(e){
    return { success: false, message: "Unknown failure happened during integration." };
  }
}

const microsoftInstallation = async(sub) => {
  try{
    const flowtraceAPI = 'slackintegration';
    const validateEndpoint = '/microsoft/validate/' + sub;
    const runEndpoint = '/oauth2/install/microsoft';
    const validate = await API.get(flowtraceAPI, validateEndpoint, { response: true });
    if(validate && !validate.success)
      return validate

    const callHeaders = { body: { sub }, headers: {}, response: true };
    const {data : {success, message, ...payload}} = await API.post(flowtraceAPI, runEndpoint, callHeaders);
    return { success, message, payload };
  }catch(e){
    if(e.response && e.response.data)
      return e.response.data;
    return { success: false, message: "Unknown failure happened during integration." };
  }
}

const installNewIntegrationToken = async(sub) => {
  if(sub.includes("oauth2|microsoft|"))
    return await microsoftInstallation(sub);
  else
    return await genericInstallation(sub);

};

export default installNewIntegrationToken;