import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { Card, CardContent, Tooltip } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
const info = <HelpOutline style={{ fontSize: 15 }}/>;
const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;


class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }



  async componentDidMount() {
    const tooltipYFormatter = (val, {series, seriesIndex}) => (val / series[seriesIndex].reduce((acc, cur) => acc += cur, 0) * 100).toFixed(1) +"% (" + val + ")";
    //const yAxisLabelFormatter = (val) => val.toFixed(0) + "%";
    // Common apexchart configuration for all series
    const common = {
      chart: { type:"bar", zoom:{ enabled:true, type:"xy"}, animations: { enabled: false }, toolbar: { show: true, tools:{ download:false, zoom:true, selection:false, pan:false, reset:false} } },
      dataLabels: { enabled: false },
      legend: { position: "right", show: true },
      plotOptions:{bar:{horizontal:false}},
      theme: { mode: 'light', palette: 'palette2', },
      xaxis:{ title: {}, tickPlacement:"on", tickAmount:10, labels: { show: true }, crosshairs: { width: 1 }, categories: [] },
      yaxis: { title: { }, labels: {}},
      tooltip: {
        y: { title:{}},
        x: { },
      },
    };
    const commonCopy = () => {
      const copy = JSON.parse(JSON.stringify(common));
      copy.tooltip.y.formatter = tooltipYFormatter;
      //copy.tooltip.y.title.formatter = (seriesName) => seriesName + " (x days)"; // Use to render: "Current:" => "Last 30 days"
      //com.yaxis.labels.formatter = yAxisLabelFormatter;
      return copy;
    };

    const cstart = new Date(Date.parse(this.props.distribution.current.slice(0,10)));
    const cend = new Date(Date.parse(this.props.distribution.current.slice(11,21)));
    const currentDates = cstart.toLocaleDateString() + " - " + cend.toLocaleDateString();
    const pstart = new Date(Date.parse(this.props.distribution.previous.slice(0,10)));
    const pend = new Date(Date.parse(this.props.distribution.previous.slice(11,21)));
    const previousDates = pstart.toLocaleDateString() + " - " + pend.toLocaleDateString();
    const distData = {
      invitesLow:{series:[], seriesComp:[], config: commonCopy() },
      invites:{series:[], seriesComp:[], config: commonCopy() },
      optional:{series:[], seriesComp:[], config: commonCopy() },
      agenda:{series:[], seriesComp:[], config: commonCopy() },
      duration:{series:[], seriesComp:[], config: commonCopy() },
      weekday:{series:[], seriesComp:[], config: commonCopy() },
      hour:{series:[], seriesComp:[], config: commonCopy() },
      notice:{series:[], seriesComp:[], config: commonCopy() },
      peopleMeetingTime:{series:[], seriesComp:[], config: commonCopy() },
      hourlyMeetingDuration:{series:[], seriesComp:[], config: commonCopy() },
      videoCallTime:{series:[], seriesComp:[], config: commonCopy() },
      videoCallLate:{series:[], seriesComp:[], config: commonCopy() },
    };
    Object.values(distData).forEach(value => {
      value.series.push({name:currentDates, data:[]});
      value.seriesComp.push({name:currentDates, data:[]});
      value.seriesComp.push({name:previousDates, data:[]});
    });

    const processDataPoint = (dist, o) => {
      dist.series[0].data.push(o.count);
      dist.seriesComp[0].data.push(o.count);
      dist.seriesComp[1].data.push(o.previous ? o.previous : null);
      dist.config.xaxis.categories.push(o.b);
    };
    if(this.props.distribution.invitesLow)
      this.props.distribution.invitesLow.forEach(o => processDataPoint(distData.invitesLow, o));
    if(this.props.distribution.invites)
      this.props.distribution.invites.forEach(o => processDataPoint(distData.invites, o));
    if(this.props.distribution.optional)
      this.props.distribution.optional.forEach(o => processDataPoint(distData.optional, o));
    if(this.props.distribution.agenda)
      this.props.distribution.agenda.forEach(o => processDataPoint(distData.agenda, o));
    if(this.props.distribution.hour)
      this.props.distribution.hour.forEach(o => processDataPoint(distData.hour, o));
    if(this.props.distribution.notice)
      this.props.distribution.notice.forEach(o => processDataPoint(distData.notice, o));
    if(this.props.distribution.weekday)
      this.props.distribution.weekday.forEach(o => processDataPoint(distData.weekday, o));
    if(this.props.distribution.videoCallTime)
      this.props.distribution.videoCallTime.forEach(o => processDataPoint(distData.videoCallTime, o));
    if(this.props.distribution.videoCallLate)
      this.props.distribution.videoCallLate.forEach(o => processDataPoint(distData.videoCallLate, o));
    if(this.props.distribution.duration)
      this.props.distribution.duration.forEach(o => processDataPoint(distData.duration, o));
    if(this.props.distribution.peopleMeetingTime)
      this.props.distribution.peopleMeetingTime.forEach(o => processDataPoint(distData.peopleMeetingTime, o));
    if(this.props.distribution.hourlyMeetingDuration)
      this.props.distribution.hourlyMeetingDuration.forEach(o => processDataPoint(distData.hourlyMeetingDuration, o));

    const getCat = ({dataPointIndex, w}) => {
     return  w && w.globals && w.globals.categoryLabels ? w.globals.categoryLabels[dataPointIndex] : 0;
    };
    distData.invitesLow.config.xaxis.title.text="Invitees";
    distData.invitesLow.config.tooltip.x.formatter = (val, conf) => `Meetings with ${getCat(conf)} invitees`;
    distData.invites.config.xaxis.title.text="Invitees";
    distData.invites.config.tooltip.x.formatter = (val, conf) => `Meetings with ${getCat(conf)}-${getCat(conf)+10} invitees`;
    distData.optional.config.xaxis.title.text="Optional invitees";
    distData.optional.config.tooltip.x.formatter = (val, conf) => `Meetings with ${getCat(conf)} optional invitees`;

    distData.agenda.config.xaxis.title.text="Agenda length";
    distData.agenda.config.tooltip.x.formatter = (val, conf) => `Meetings with agenda ${getCat(conf)}-${getCat(conf)+100} characters long`;
    distData.duration.config.xaxis.title.text="Meeting duration";
    const durationMinToHourFormatter = (min) => {
      const start = min;
      const end = min + 5;
      if(start >= 60)
        return `Meetings with duration ${Math.floor(start/60) + "h" + start%60 + "m"} - ${Math.floor(end/60) + "h" + end%60 + "m"}`;
      return `Meetings with duration ${start + "m"} - ${end + "m"}`;
    }
    distData.duration.config.tooltip.x.formatter = (val, conf) => durationMinToHourFormatter(distData.duration.config.xaxis.categories[conf.dataPointIndex]);
    distData.duration.config.xaxis.labels.formatter = (val) => (val/60).toFixed(1) + " h" ;

    distData.hour.config.xaxis.title.text="Starting hour of meeting";
    distData.hour.config.tooltip.x.formatter = (val, {dataPointIndex}) => `Meetings starting ${distData.hour.config.xaxis.categories[dataPointIndex]}:00-${distData.hour.config.xaxis.categories[dataPointIndex]+1}:00`;
    distData.hour.config.xaxis.labels.formatter = (val) => val + ":00";

    const w = {7:"Sun", 1: "Mon", 2: "Tue", 3: "Wed", 4: "Thu", 5: "Fri", 6: "Sat"};
    distData.weekday.config.xaxis.labels.formatter = (val) => w[val] ;
    distData.weekday.config.xaxis.title.text="Weekday of meeting";

    distData.notice.config.xaxis.title.text="Invite to Meeting Notice";
    distData.notice.config.xaxis.labels.formatter = (val) => (val/24).toFixed(0) + " d" ;
    distData.notice.config.tooltip.x.formatter = (val, {dataPointIndex}) => `Meetings within ${dataPointIndex + 1} day${dataPointIndex >0 ? "s":""} from the invite being sent.`;
    //distData.notice.config.tooltip.x.formatter = (val) => `Meetings where video call lasts ${Math.floor(val/24)}-${Math.floor((val+300)/60)} minutes`;


    distData.peopleMeetingTime.config.xaxis.title.text="Weekly meeting hours";
    distData.peopleMeetingTime.config.yaxis.title.text="Employees";
    distData.peopleMeetingTime.config.xaxis.labels.formatter = (val) => val + "h" ;
    distData.peopleMeetingTime.config.tooltip.x.formatter = (val, {dataPointIndex}) => `${dataPointIndex > 0 ? dataPointIndex + 1 + " hours": "< 1 hour"} of weekly meeting time.`;

    distData.hourlyMeetingDuration.config.xaxis.title.text="Starting hour of meeting";
    distData.hourlyMeetingDuration.config.yaxis.title.text="Meeting time";
    distData.hourlyMeetingDuration.config.tooltip.x.formatter = (val, {dataPointIndex}) => `Meetings starting ${distData.hour.config.xaxis.categories[dataPointIndex]}:00-${distData.hour.config.xaxis.categories[dataPointIndex]+1}:00`;
    distData.hourlyMeetingDuration.config.xaxis.labels.formatter = (val) => val + ":00";
    distData.hourlyMeetingDuration.config.yaxis.labels.formatter = (val) => Math.floor(val/60) + "h";
    distData.hourlyMeetingDuration.config.tooltip.y.formatter = (val, {series, seriesIndex}) => (val / series[seriesIndex].reduce((acc, cur) => acc += cur, 0) * 100).toFixed(1) +"% (" + (val/60).toFixed(0) + "h)";


    distData.videoCallTime.config.xaxis.title.text="Video call duration";
    distData.videoCallTime.config.xaxis.labels.formatter = (val) => {const min = val ? parseInt(val.split("-")[1]/60) : 0; return Math.floor(min/60) + "h " + min%60 + "m";}
    distData.videoCallTime.config.tooltip.x.formatter = (val, {dataPointIndex}) => `Meetings where video call lasts ${dataPointIndex ? (dataPointIndex * 300)/60 : "0"}-${(dataPointIndex * 300+300)/60 } minutes`;

    distData.videoCallLate.config.xaxis.title.text="Video call late start";
    distData.videoCallLate.config.xaxis.labels.formatter = (val) => {const sec = val ? parseInt(val.split("-")[1]) : 0; return sec > 60 ? (sec/60).toFixed(0) + " m" : sec + " s";}
    distData.videoCallLate.config.tooltip.x.formatter = (val, {dataPointIndex}) => `Meetings where video call starts ${dataPointIndex ? dataPointIndex * 15 : 1}-${dataPointIndex * 15+15} seconds late`;

    this.setState({ loading: false, distData });
  }


  render() {

    if (this.state.loading)
      return null;


    const renderKey = !this.props.compare ? "series" : "seriesComp";

    return (
      <Fragment >
        <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
            <div className=" font-weight-bold font-size-xl text-uppercase" >Meeting Invite Details</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings you organise per number invitees (for meetings with 10 or less invitees).">
                    <div className="font-weight-bold mr-3">Meeting invitee breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.invitesLow.config} series={this.state.distData.invitesLow[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings you organize per number of invitees (for meetings with 500 or less invitees).">
                    <div className="font-weight-bold mr-3">Meeting invitee breakdown (big meetings) {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.invites.config} series={this.state.distData.invites[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings you have with per optional invitees (up to 20 optional invitees).">
                    <div className="font-weight-bold mr-3">Meeting optional invitee breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.optional.config} series={this.state.distData.optional[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings have certain length of an agenda and up to 2.5k characters. The length is in characters and excludes video call invite detail (where known)">
                    <div className="font-weight-bold mr-3">Meeting agenda length breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.agenda.config} series={this.state.distData.agenda[renderKey]} type="bar" height="200"/>
              </Grid>
            </Grid>



            <div className=" font-weight-bold font-size-xl text-uppercase" >Meeting Time Details</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings by the duration of it (duration in 5 minute steps). We don't show data of meetings over >8 hours">
                    <div className="font-weight-bold mr-3">Meeting duration breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.duration.config} series={this.state.distData.duration[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings have had advance notice in hours when the invite was sent out">
                    <div className="font-weight-bold mr-3">Invite to meeting notice {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.notice.config} series={this.state.distData.notice[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title={"Here you see how many meetings you organize per starting hour (the hour is timezone specific (" + tz + ") and uses your browser's timezone to base the chart)."}>
                    <div className="font-weight-bold mr-3">Meetings by starting hour {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.hour.config} series={this.state.distData.hour[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title={"Here you see how many hours of meetings you organize per starting hour (the hour is timezone specific (" + tz + ") and uses your browser's timezone to base the chart)."}>
                    <div className="font-weight-bold mr-3">Meeting time by starting hour {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.hourlyMeetingDuration.config} series={this.state.distData.hourlyMeetingDuration[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title={"Here you see how many meetings you organize per weekday (the weekday is timezone specific (" + tz + ") and uses your browser's timezone to base the chart)."}>
                    <div className="font-weight-bold mr-3">Meetings by weekday {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.weekday.config} series={this.state.distData.weekday[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meeting hours your employees have on weekly basis.">
                    <div className="font-weight-bold mr-3">Weekly meeting hours {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.peopleMeetingTime.config} series={this.state.distData.peopleMeetingTime[renderKey]} type="bar" height="200"/>
              </Grid>
            </Grid>


            <div className=" font-weight-bold font-size-xl text-uppercase" >Video Call Time Details</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings by the duration of it's video call (duration in 5 minute steps).">
                    <div className="font-weight-bold mr-3">Video call duration breakdown {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.videoCallTime.config} series={this.state.distData.videoCallTime[renderKey]} type="bar" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="Here you see how many meetings you organize per how late the participants join the call (15 second steps)">
                    <div className="font-weight-bold mr-3">Video call delay {info}</div>
                  </Tooltip>
                </div>
                <Chart options={this.state.distData.videoCallLate.config} series={this.state.distData.videoCallLate[renderKey]} type="bar" height="200"/>
              </Grid>
            </Grid>

          </CardContent>
         </Card>
        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
