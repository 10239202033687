import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StatsCard(passedData) {
    const component = passedData.id.tool?passedData.id.tool:passedData.id;
    const data = passedData.data
    const period = passedData.period
    let componentColor = passedData.componentColor // this can be overwriten by certain tools

    data.change = (passedData.data.value / passedData.data.previous * 100 - 100).toFixed(0)
    //console.log((passedData.data.value / passedData.data.previous * 100).toFixed(0))
    let title = ""
    let icon = ""
    const versus = " per employee per " + period + " (vs. previous " + period + ")"
    //console.log("Component:", component)
    if (component === "tLate") {
        title = "Late from meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'business-time']} className="font-size-xl" />;
    }
    if (component === "oLate") {
        title = "Late from own meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'calendar-alt']} className="font-size-xl" />;
    }
    if (component === "aLate") {
        title = "Late from attended meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'user-clock']} className="font-size-xl" />;
    }
    if (component === "mmt") {
        title = "Meeting Multitasking last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'ban']} className="font-size-xl" />;
    }
    if (component === "mi") {
        title = "Internal meetings last " + period;
        icon = <FontAwesomeIcon icon={['fas', 'user']} className="font-size-xl" />;
    }
    if (component === "me") {
        title = "External meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'user-tie']} className="font-size-xl" />;
    }
    if (component === "o2o") {
        title = "1:1 meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'user-friends']} className="font-size-xl" />;
    }
    if (component === "email") {
        title = "Emailing last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'envelope']} className="font-size-xl" />;
    }
    if (component === "slack") {
        title = "Chatting last " + period;
        icon = <FontAwesomeIcon icon={['far', 'keyboard']} className="font-size-xl" />;
    }
    if (component === "meeting") {
        title = "Meetings last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'calendar']} className="font-size-xl" />;
    }
    if (component === "multitasking") {
        title = "Multitasking last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'ban']} className="font-size-xl" />;
    }
    if (component === "focus") {
        title = "Focus time last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'tasks']} className="font-size-xl" />;
    }
    if (component === "activity") {
        title = "Collaboration hours last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'user-clock']} className="font-size-xl" />;
    }
    if (component === "outOfHours") {
        title = "Out of Hours activity last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'user-clock']} className="font-size-xl" />;
    }
    if (component === "email") {
        title = "Email activity last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'envelope']} className="font-size-xl" />;
        componentColor = "bg-brand-youtube";
    }
    if (component === "slack") {
        title = "Slack activity last " + period;
        icon = <FontAwesomeIcon icon={['fab', 'slack']} className="font-size-xl" />;
        componentColor = "bg-brand-slack";
    }
    if (component === "calendar") {
        title = "Calendar events last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'calendar']} className="font-size-xl" />;
        componentColor = "bg-brand-twitter";
    }
    if (component === "github") {
        title = "GitHub activity last " + period;
        icon = <FontAwesomeIcon icon={['fab', 'github']} className="font-size-xl" />;
        componentColor = "bg-second";
    }
    if (component === "gitlab") {
        title = "GitLab activity last " + period;
        icon = <FontAwesomeIcon icon={['fab', 'gitlab']} className="font-size-xl" />;
        componentColor = "bg-second";
    }
    if (component === "front") {
        title = "Front activity last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'dot-circle']} className="font-size-xl" />;
        componentColor = "bg-first";
    }
    if (component === "jira") {
        title = "Jira activity last " + period;
        icon = <FontAwesomeIcon icon={['fab', 'jira']} className="font-size-xl" />;
        componentColor = "bg-primary";
    }
    if (component === "meets") {
        title = "Google Meets activity last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'video']} className="font-size-xl" />;
        componentColor = "bg-brand-google";
    }
    if (component === "zoom") {
        title = "Zoom activity last " + period;
        icon = <FontAwesomeIcon icon={['fa', 'video']} className="font-size-xl" />;
        componentColor = "bg-info";
    }

    const changeUp = <span key="changeUp" className="font-size-l"><FontAwesomeIcon icon={['fa', 'arrow-up']} className="font-size-l" /></span>
    const changeDown = <span key="changeDown" className="font-size-l"><FontAwesomeIcon icon={['fa', 'arrow-down']} className="font-size-l" /></span>

    let change = [];

    if (data.change < 0) {
        change.push(<span key="changeDown" className="ml-3 badge badge-danger">{changeDown}<span key="delta" className="font-size-l "> { data.change }% </span></span>)
    }
    if (data.change > 0) {
        change.push(<span key="changeUp" className="ml-3 badge bg-brand-slack">{changeUp}<span key="delta" className="font-size-l "> { data.change }% </span></span>)
    }
    if (isNaN(data.change)) {
        change.push(<span key="delta" className="font-size-l mt-1"> </span>)
    }
    const cardClasses = componentColor + " card-box border-0 text-light mr-3 mb-3"

    let timeString = ""
    if (data && data.value >= 60)
        timeString = Math.floor(data.value / 60).toFixed(0) + "h " + (data.value % 60).toFixed(0) + "m"
    else
        timeString = (data.value % 60).toFixed(0) + " minutes" || "-"

    return (
        <Fragment>
            <Card className={cardClasses}>
                <CardContent className="p-2">
                    <div className="d-flex align-items-start">
                        <div className="font-weight-bold">
                            <span className="text-white-50 d-block mb-1 text-uppercase">{title}</span>
                            <small className="text-white-50 d-block mb-1 text-uppercase">{versus}</small>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={12}>
                                    <span className="font-size-xxl ">{ timeString } </span> {change}
                                </Grid>
                            </Grid>
                        </div>
                        <div className="ml-auto">
                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                                {icon}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Fragment>
    );
}
