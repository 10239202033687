import React, { Fragment } from 'react';
import SlackReport from './slackReport';


export default function AllReports(props) {

  const reports = props.accountReports.map(r => <SlackReport key={"slackReport_"+r.channel} reload={props.reload} channels={props.accountChannels} report={r} />);
  reports.push(<SlackReport key="newreport" create reload={props.reload} channels={props.accountChannels} />);

  return (
    <Fragment>
      {reports}
    </Fragment>
  );
}
