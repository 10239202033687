import { API } from 'aws-amplify';

const flowtraceAPI = 'slackintegration';
const settingsAPIURL = '/members/clientAccounts/';

async function addProperty(accountId, property) {
    try {
        const command = {command:"add", variable:"properties", value: property};
        return await API.put(flowtraceAPI, settingsAPIURL + accountId, { body: command });
    }
    catch (e) {
        console.error(e.message, e);
        return false;
    }
}
async function deleteProperty(accountId, property) {
    try {
        const command = {command:"delete", variable:"properties", value: property};
        return await API.put(flowtraceAPI, settingsAPIURL + accountId, { body: command });
    }
    catch (e) {
        console.error(e.message, e);
        return false;
    }
}

async function createAccount(accountName) {
    try {
        return await API.post(flowtraceAPI, settingsAPIURL, { body: {clientAccount:{accountName, properties: [{ type: "free"}] }} });
    }
    catch (e) {
        console.error(e.message, e);
        return false;
    }
}

const account = { addProperty, deleteProperty, createAccount };
export default account;
