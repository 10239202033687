import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';
import SurveySettings from '../../flowtrace-pages/Configuration/Survey';

const surveyHelp =
  <MuiAlert className="mb-4" severity="info">
    <div className="d-flex align-items-center align-content-center" >
      <span>
        <strong className="d-block mb-3">What to expect?</strong>
        <p>Flowtrace can send a weekly pulse-check for your teams in a non-intrusive automated Slack message. Since it only takes a literal second to answer we find the response rates higher compared to other survey formats.</p>
        <p>With Flowtrace weekly surveys you get an accurate real-time picture of your team engagement, productivity and satisfaction. You can analyse the trends and take timely actions to keep your team both happy and effective.</p>
        <p>Flowtrace supports widely adopted Employee <a href="https://en.wikipedia.org/wiki/Net_promoter_score" target="_blank" rel="noreferrer">Net Promoter Score</a> (eNPS) and Flowtrace proprietary Team Effectiveness Survey you can choose from:</p>
        <ul>
          <li><strong>eNPS</strong> (1 question per week)</li>
          <li><strong>Team Effectiveness</strong> (2 rolling questions per week)</li>
          <li><strong>Both</strong> (3 questions per week)</li>
        </ul>
        <p>Please choose below what works best for your team or click 'Next' if you prefer to configure surveys later.</p>
      </span>
    </div>
  </MuiAlert>;

class ConfigureSurvey extends Component {

  render() {
    if(!this.props.isActive) return null;
    return (
      <StepNavigation stepTitle="Let's Configure Your Employee Surveys" {...this.props} nextText="Next">
        <Grid container spacing={0} direction="column" justifyContent="center" alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} >
            {surveyHelp}
          </Grid>
            <SurveySettings flowtraceUser={this.props.flowtraceUser} installWizard={true} />
        </Grid>
      </StepNavigation>
    );
  }
}

export default withRouter(ConfigureSurvey);
