import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';

import Chart from 'react-apexcharts';

export default function ChannelSentimentChart(data) {
  let channelName = data.data.key
  let channelPurpose = ""
  //console.log("Processing ", channelName, data.data)
  if (data.data.metadata) {
    channelName = data.data.metadata.name
    channelPurpose = data.data.metadata.purpose.value
  }

  let neutralData = []
  let negativeData = []
  let positiveData = []
  let categories = []

  for (const key in data.data.sentimentHistogram) {
    //console.log("Processing ", channelName, data.data)
    //console.log("Values", key, data.data.sentimentHistogram[key], data.data.sentimentHistogram[key].doc_count)
    const total = data.data.sentimentHistogram[key].doc_count
    const positive = data.data.sentimentHistogram[key].sentiment.POSITIVE || 0
    const negative = data.data.sentimentHistogram[key].sentiment.NEGATIVE || 0
    neutralData.push(total - positive - negative)
    positiveData.push(positive)
    negativeData.push(negative)
    categories.push(key)
  }

  const chartOptions = {
    chart: { animations: { enabled: false }, stacked: true, toolbar: { show: false }, stackType: '100%' },
    dataLabels: { enabled: false },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, labels: { show: false } },
    legend: { show: false },
    tooltip: { y: { formatter: function(value, opts) { let percent = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return percent.toFixed(0) + '% (' + value + ")" } } },
    colors: ['#F44336', '#B4DDEE', '#37a141']
  };

  const series = [{
      name: 'Negative',
      data: negativeData
    },
    {
      name: 'Neutral',
      data: neutralData
    },
    {
      name: 'Positive',
      data: positiveData
    }
  ];


  return (
    <Fragment>
      <Grid item xs={12} lg={4}>
        <Card className="card-box mb-1">
          <CardContent className="p-0">
            <Grid container spacing={1} className="mt-1">
              <Grid item xs={12} sm={12}>
                <div className="text-left">
                  <div className="ml-3 mt-3 line-height-sm">
                    <b className="font-size-l">#{channelName}</b><br/>
                    <b className="font-size-s">{channelPurpose ? channelPurpose: <br/>}</b>

                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="pt-1 pr-1 pl-1">
             <Chart options={chartOptions} series={series} type="bar" height="200"/>
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Fragment>
  );
}
