import React, { Fragment } from 'react';
import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import ConfigureGitLab from './gitlab.js';
import ConfigureZoom from './zoom.js';
import ConfigureJira from './jira.js';
import ConfigureGeneric from './generic.js';
import ConfigureGoogleWorkspace from "./googleWorkspace";
import ConfigureGithub from './github';

const getComponent = (type) => {
  if(type === "atlassian") return ConfigureJira;
  if(type === "zoom") return ConfigureZoom;
  if(type === "google-oauth2") return ConfigureGoogleWorkspace;
  if(type === "gitlab") return ConfigureGitLab;
  if(type === "github") return ConfigureGithub;
  return ConfigureGeneric;
};

const createAlert = (type, message) => {
  return (
    <MuiAlert key="alertMessage" severity={type} className="pt-0 pb-0">
      {message}
    </MuiAlert>
  );
};

export default function Configuration(props) {
  const urlParams = new URLSearchParams(window.location.search);
  const configure = urlParams.get('configure');
  // If we use configure search param, open the dialog by default (otherwise fals)
  const showDialog = (configure === "googleWorkspace" && props.modal.id === "google-oauth2") || (configure === "github" && props.modal.id === "github");

  const [freshInstall, setFreshInstall] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [freshInstallMessage, setFreshInstallMessage] = React.useState(null);
  const [showConfigureModalDialog, setShowConfigureModalDialog] = React.useState(showDialog);

  const handleClose = () => { 
    setShowConfigureModalDialog(false); 
    
    if (props.modal.id === 'github') {
      window.history.pushState({}, '', '/Configuration');
    }
  };
  const showConfigureModal = () => { setShowConfigureModalDialog(true); };

  const installed = (user, tool) => {
    if(tool === "google-oauth2")
      return user.account && user.account.ws.GSUITEMEETING?true:false;
    // If we have a fresh install (without page load) mark this as installed
    if(freshInstall && !error) return true;
    return user.account && user.account.ws[tool.toUpperCase()]?true:false;
  };

  const onInstall = (success, openDialog = false, errorMessage) => {
    console.log(success, openDialog, errorMessage)
    if(success){
      setFreshInstall(true);
      setError(null);
      setFreshInstallMessage(createAlert("success", "Integration established."));
    } else if(errorMessage) {
      setError(true);
      setFreshInstallMessage(createAlert("warning", "We couldn't establish the integration. Please try again, or contact our support for help. Error: " + errorMessage ));
    } else {
      setError(true);
      setFreshInstallMessage(createAlert("warning", "We couldn't establish the integration. Please try again, or contact our support for help."));
    }
    if(openDialog)
      showConfigureModal();
  };
  const renderConfigurationModal = () => {
    const PropsPanel = getComponent(props.modal.id);
    // If there is issue finding a component, at least provide user a way to close the dialog...
    const cancelButton = <Button onClick={() => handleClose()} color="primary" variant="outlined" size="large"> Close </Button>;
    if(!PropsPanel)
      return cancelButton;
    return <PropsPanel open={showConfigureModalDialog} openDialog={showConfigureModal} onInstall={onInstall} existing={installed(props.flowtraceUser, props.modal.id)} flowtraceUser={props.flowtraceUser} modal={props.modal} cancelButton={cancelButton} />;
  };

  return (
    <Fragment>
      { !installed(props.flowtraceUser, props.modal.id) && !showConfigureModalDialog?
          <Grid container spacing={2}>
            <Grid item sm={4}>
              {renderConfigurationModal()}
            </Grid>
            {freshInstallMessage? <Grid item sm={8}> {freshInstallMessage} </Grid> : null }
          </Grid>
      :
        <div>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <Button onClick={() => showConfigureModal()} color="primary" variant="contained">Configure</Button>
            </Grid>
            {freshInstallMessage? <Grid item sm={8}> {freshInstallMessage} </Grid> : null }
          </Grid>
          {showConfigureModalDialog?
          <Dialog fullWidth={true} maxWidth="lg" open={showConfigureModalDialog} aria-labelledby="form-dialog-title">
            <DialogContent>
              <h5>Configure {props.modal.title}</h5>
              { renderConfigurationModal() }
            </DialogContent>
          </Dialog>
          :null}
        </div>
      }
    </Fragment>
  )
}