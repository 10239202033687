import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import ToolChart from './toolChart';
import Loading from '../../Loading'
import FilterBar from '../../Common/FilterBar';


class ProductivityStatisticsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, stats:[], total:[], queryFilters: { range_type: "calendar", range_period: "week" } };
  }


  async loadProductivityStatistics(queryFilters) {
    try{
      const dataAPI = 'slackintegration';
      const statisticsPath = '/productivity/statistics';
      const initAPI = { headers: {}, response: true, queryStringParameters: { ...queryFilters } };

      const response = await API.get(dataAPI, statisticsPath, initAPI)

      this.setState({ loading: false, total: response.data ? response.data.total : null, stats: response.data ? response.data.stats : [] });
      //this.setState({ loading: false, total: [], stats: [] });
    } catch(e) {
      console.error(e);
      this.setState({ loading: false, total: [], stats: [] });
    }
    return
  }

  async componentDidMount() {
    this.loadProductivityStatistics(this.state.queryFilters)
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadProductivityStatistics(queryFilters)
  }

  render() {
    return (
      <Fragment>
        <FilterBar loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} teamFilter />
        {this.state.loading? <Loading/> : <ToolChart key={"toolchart"} total={this.state.total} company={this.state.stats} timeSpan={this.state.queryFilters.range_period}  /> }

      </Fragment>
    );

  } // end of render()

} // end of class

export default ProductivityStatisticsOverview
