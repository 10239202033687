import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

export default function Paywall(props) {
  const history = useHistory();

  const routeChange = () => {
    history.push("/Account/Billing");
  }

  return (
    <div style={{marginBottom:"15px",marginTop:"15px"}}>
        <Button onClick={routeChange} rel="noopener" size="large" variant="contained" color="primary">See All Flowtrace Plans</Button>
      </div>
  );
}
