import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import MeetingStatistics from '../../../flowtrace-components/MeetingStatistics/Insights';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Meeting Insights</h4>)
explanation.push(<p key="paragraph1">Flowtrace breaks down every every meeting to its core. Find out the meetings which are most costly with this handy tool..</p>)

export default function MeetingDashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Company's Meeting Insights" titleDescription="Find out details about your company's meetings" explanation={explanation}/>

      <MeetingStatistics flowtraceUser={props.flowtraceUser} />
    </Fragment>
  );
}
