import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading'
import KPICard from './genericKPICard'
import BarChart from './genericBarChartKPI'

function calculateJiraKPIs(current, previous = null) {
  // Calculate the status KPIs:
  const allStatuses = [...new Set([...new Set(Object.keys(current)), ...new Set(Object.keys(previous?previous:[]))])];
  const timeInStatus = {total:{sum:0, count:0, avg:0}};
  for(const status of allStatuses){
    if(status === "key") continue; // Skip the key field
    timeInStatus[status] = {avg: current[status]?current[status].avg:0, count: current[status]?current[status].count:0};

    timeInStatus.total.sum += current[status]?current[status].sum:0;
    timeInStatus.total.count += current[status]?current[status].count:0;
    timeInStatus.total.avg = timeInStatus.total.sum/timeInStatus.total.count;

    if(status.includes("review"))
      timeInStatus.reviewKPI = current[status]?current[status].avg:0;
    if(status.includes("block"))
      timeInStatus.blockKPI = current[status]?current[status].avg:0;
    if(status.includes("progress"))
      timeInStatus.progressKPI = current[status]?current[status].avg:0;
  }

  if (previous) {
    timeInStatus.previous = calculateJiraKPIs(previous);
  }

  return timeInStatus;
}

// By default, HubSpot includes a sales pipeline with seven deal stages:
const hubspotStageNames = {
  appointmentscheduled: "Appointment scheduled",
  qualifiedtobuy: "Qualified to buy",
  presentationscheduled: "Presentation scheduled",
  decisionmakerboughtin: "Decision maker bought-in",
  contractsent: "Contract sent",
  closedwon: "Closed won",
  closedlost: "Closed lost"
};

function calculateHubSpotKPIs(current, previous = null) {
  // Calculate the status KPIs:
  const allStatuses = [...new Set([...new Set(Object.keys(current)), ...new Set(Object.keys(previous?previous:[]))])];

  const timeInStatus = {total:{sum:0, count:0, avg:0}, short:null, long:null, salesCycle:0};

  for(const status of allStatuses){
    if(status === "key" || status.includes("closed")) continue; // Skip the key field and closed stages

    timeInStatus[status] = {avg: current[status]?current[status].avg:0, count: current[status]?current[status].count:0};
    if(!timeInStatus.short || timeInStatus[status].avg < timeInStatus[timeInStatus.short].avg)
      timeInStatus.short = status;
    if(!timeInStatus.long || timeInStatus[status].avg > timeInStatus[timeInStatus.long].avg)
      timeInStatus.long = status;
    timeInStatus.total.sum += current[status]?current[status].sum:0;
    timeInStatus.total.count += current[status]?current[status].count:0;
    timeInStatus.total.avg = timeInStatus.total.sum/timeInStatus.total.count;

    timeInStatus.salesCycle += current[status]?current[status].avg:0;
  }

  if (previous) {
    timeInStatus.previous = calculateHubSpotKPIs(previous);
  }

  return timeInStatus;
}

function calculateGitKPIs(current, previous = null) {
  // Calculate the status KPIs:
  const allStatuses = [...new Set([...new Set(Object.keys(current)), ...new Set(Object.keys(previous?previous:[]))])];

  const timeInStatus = {total:{sum:0, count:0, avg:0}};

  for(const status of allStatuses){
    if(status === "key") continue; // Skip the key field
    timeInStatus[status] = {avg: current[status]?current[status].avg:0, count: current[status]?current[status].count:0};

    timeInStatus.total.sum += current[status]?current[status].sum:0;
    timeInStatus.total.count += current[status]?current[status].count:0;
    timeInStatus.total.avg = timeInStatus.total.sum/timeInStatus.total.count;

    if(status.includes("open")) // This is the time pull request is open before first action is taken
      timeInStatus.openKPI = current[status]?current[status].avg:0;
  }

  if (previous) {
    timeInStatus.previous = calculateGitKPIs(previous);
  }

  return timeInStatus;
}

class ToolKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async loadStatistics() {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/metrics/' + this.props.tool;
    const currentPeriodAPIParams = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.current } };
    const previousPeriodAPIParams = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.previous } };

    try {
      const [current, previous] = await Promise.all([
        API.get(dataAPI, statisticsPath, currentPeriodAPIParams),
        API.get(dataAPI, statisticsPath, previousPeriodAPIParams)
      ]);

      const cur = current.data && current.data.totalStats && Object.keys(current.data.totalStats).length > 1 ? current.data.totalStats : null;
      const prev = previous.data && previous.data.totalStats && Object.keys(previous.data.totalStats).length > 1? previous.data.totalStats : null;
      if(this.props.tool === "jira")
        this.setState({loading: false, KPIs: calculateJiraKPIs(cur, prev)})
      if(this.props.tool === "github" || this.props.tool === "gitlab")
        this.setState({loading: false, KPIs: calculateGitKPIs(cur, prev)})
      if(this.props.tool === "hubspot")
        this.setState({loading: false, KPIs: calculateHubSpotKPIs(cur, prev)})

      return;
    }
    catch (e) { }

    this.setState({ loading: false, KPIs: null });
    return;
  }

  async componentDidMount() {
    // No need to load if we don't have an account installed for the user
    if (!this.props.flowtraceUser || !this.props.flowtraceUser.account)
      return this.setState({ loading: false });

    // Load KPIs only in case user has a scale plan (with KPIs included):
    if (this.props.flowtraceUser.account.props.type === "scale" || this.props.flowtraceUser.account.props.type === "internal")
      await this.loadStatistics();
    else
      this.setState({ loading: false});
  }

  componentWillUnmount() { this.setState = () => { return; }; }

  render() {
    const title = [];
    if(this.props.tool === "jira")
      title.push(<div key="jira" className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Jira Issues</div>)
    if(this.props.tool === "github")
      title.push(<div key="githu" className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >GitHub Pull Requests</div>)
    if(this.props.tool === "gitlab")
      title.push(<div key="gitlab" className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >GitLab Merge Requests</div>)
    if(this.props.tool === "hubspot")
      title.push(<div key="hubspot" className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >HubSpot Deal Stages</div>)

    if (this.state.loading) {
      return (
        <Grid item xs={12} md={6} xl={4}>
          <Fragment >
            <Card>
              <CardContent >
                {title}
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );
    }

    const leftHandColumn = [];
    const rightHandColumn = [];

    if (this.state.KPIs) {
      const categories = [];
      for(const [key, value] of Object.entries(this.state.KPIs)){
        if(key !== "previous" && key !== "total" && !key.endsWith("KPI") && value.avg>0)
          if(this.props.tool === "hubspot"){
            if(["closedwon", "closedlost"].includes(key))
              continue; // Don't add close stage times as they are maningless
            const translation = Object.keys(hubspotStageNames).find(n => n === key);
            const cat = translation? hubspotStageNames[translation]:key[0].toUpperCase() + key.substring(1) + " (" + value.count +")";
            categories.push({cat, data: value.avg/60});
          }
          else
            categories.push({cat: key[0].toUpperCase() + key.substring(1) + " (" + value.count +")", data: value.avg/60});
      }

      const statusChartData = {
        title: "Average time in each status (and how many events)",
        unit: "minutes",
        categories
      };
      //console.log(this.props.tool + "chartdata:", statusChartData, categories)

      if(this.props.tool === "jira")
        statusChartData.title = "Time in Each Status";
      if(this.props.tool === "github")
        statusChartData.title = "Average time at each action.";
      if(this.props.tool === "gitlab")
        statusChartData.title = "Average time at each action.";
      if(this.props.tool === "hubspot")
        statusChartData.title = "Average time at stage.";

      leftHandColumn.push(<BarChart key="tool_kpi" chartdata={statusChartData} />);

      // Find interesting status types for each tool type:
      const prev = this.state.KPIs.previous;
      if(this.props.tool === "jira"){
        const review = this.state.KPIs.reviewKPI?this.state.KPIs.reviewKPI/60:null;
        const block = this.state.KPIs.blockKPI?this.state.KPIs.blockKPI/60:null;
        const progress = this.state.KPIs.progressKPI?this.state.KPIs.progressKPI/60:null;

        const prev = this.state.KPIs.previous;
        const reviewPrevious = prev && prev.reviewKPI?prev.reviewKPI/60:null;
        const blockPrevious = prev && prev.blockKPI?prev.blockKPI/60:null;
        const progressPrevious = prev && prev.progressKPI?prev.progressKPI/60:null;

        rightHandColumn.push(<KPICard key="blockkpi" KPI="jira_block" value={block} compareValue={blockPrevious} benchmark={this.props.benchmark}/>);
        rightHandColumn.push(<KPICard key="reviewkpi" KPI="jira_review" value={review} compareValue={reviewPrevious} benchmark={this.props.benchmark}/>);
        rightHandColumn.push(<KPICard key="progresskpi" KPI="jira_progress" value={progress} compareValue={progressPrevious}  benchmark={this.props.benchmark}  />);
      }
      if(this.props.tool === "github" || this.props.tool === "gitlab" ){
        const open = this.state.KPIs.openKPI?this.state.KPIs.openKPI/60:null;
        const total = this.state.KPIs.total?this.state.KPIs.total.avg/60:null;
        const openPrevious = prev && prev.openKPI?prev.openKPI/60:null;
        const totalPrevious = prev && prev.total?prev.total.avg/60:null;
        rightHandColumn.push(<KPICard key={this.props.tool + "_openkpi"} KPI={this.props.tool + "_open"} value={open} compareValue={openPrevious} benchmark={this.props.benchmark}/>);
        rightHandColumn.push(<KPICard key={this.props.tool + "_totalkpi"} KPI={this.props.tool + "_total"} value={total} compareValue={totalPrevious} benchmark={this.props.benchmark}/>);
      }
      if(this.props.tool === "hubspot"){
        const long = this.state.KPIs.long?this.state.KPIs[this.state.KPIs.long].avg/60:null;
        const short = this.state.KPIs.short?this.state.KPIs[this.state.KPIs.short].avg/60:null;
        const total = this.state.KPIs.salesCycle?this.state.KPIs.salesCycle/60:null;
        const prev = this.state.KPIs.previous;
        const longPrevious = this.state.KPIs.long && prev && prev[this.state.KPIs.long]?prev[this.state.KPIs.long].avg/60:null;
        const shortPrevious = this.state.KPIs.short && prev && prev[this.state.KPIs.short]?prev[this.state.KPIs.short].avg/60:null;
        const totalPrevious = prev && prev.salesCycle?prev.salesCycle/60:null;
        rightHandColumn.push(<KPICard key={this.props.tool + "_shortkpi"} KPI={this.props.tool + "_short"} value={short} compareValue={shortPrevious} benchmark={this.props.benchmark}/>);
        rightHandColumn.push(<KPICard key={this.props.tool + "_longkpi"} KPI={this.props.tool + "_long"} value={long} compareValue={longPrevious} benchmark={this.props.benchmark}/>);
        rightHandColumn.push(<KPICard key={this.props.tool + "_totalkpi"} KPI={this.props.tool + "_total"} value={total} compareValue={totalPrevious} benchmark={this.props.benchmark}/>);
      }
    }

    if (leftHandColumn.length === 0 && rightHandColumn.length === 0)
      return null; // Return nothing if there is no data available

    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    {title}
                    {leftHandColumn.length >0 ? leftHandColumn : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {rightHandColumn.length >0 ? rightHandColumn : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    ); // end of return
  } // end of render()
} // end of class

export default ToolKPIComponent;
