import React, { Fragment } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';

export default function RemoveTeamOverrideDialog(props) {

  const {selectedMember} = props;

  const handleSave = async() => {
      return props.onClose({team: ''});
  };

  const handleClose = async() => {
      return props.onClose();
  };
  const currentTeamName = selectedMember.orgUnitPath ? selectedMember.orgUnitPath.split("/").at(-1) : "";

  return (
    <Fragment>
      <Dialog fullWidth={true} maxWidth="xs" open={props.dialogState} onClose={() => { props.onClose(); }} aria-labelledby="form-dialog-title">
      { selectedMember &&
        <DialogContent>
          <h5 className="m-6 pt-3">Do you want to remove manually configured {currentTeamName} team from {selectedMember.name}?</h5>
          <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5">
            <Button onClick={() => handleSave()} disabled={(!selectedMember)} variant="contained" color="primary" size="large"> Confirm </Button>
            <Button onClick={() => handleClose()} size="large" color="primary"> Cancel </Button>
          </DialogActions>
        </DialogContent>
      }
      </Dialog>
    </Fragment>
  )
}