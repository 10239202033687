import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';


export default function RelationshipStatisticsChart(data) {

  // 100% area chart positive/negative/netural slack messages:
  const slackDataSeries = { positive: [], negative: [], neutral: [] };
  const slackCategories = data.networkStats.sort((a, b) => (a.month > b.month) ? 1 : -1).map(item => { return item.month })
  for (const cat of slackCategories) {
    const stat = data.networkStats.find(i => i.month === cat)
    slackDataSeries.positive.push(stat.edges.reduce((acc, val) => { return acc + (val.positive ? val.positive : 0) }, 0))
    slackDataSeries.negative.push(stat.edges.reduce((acc, val) => { return acc + (val.negative ? val.negative : 0) }, 0))
    slackDataSeries.neutral.push(stat.edges.reduce((acc, val) => { return acc + (val.slack_count ? val.slack_count : 0) }, 0))
  }
  const slackSeries = [{ name: "Positive", data: slackDataSeries.positive }, { name: "Neutral", data: slackDataSeries.neutral }, { name: "Negative", data: slackDataSeries.negative }]
  const SlackConfig = {
    chart: { animations: { enabled: false }, stacked: true, toolbar: { show: false }, stackType: '100%' },
    plotOptions: { bar: { columnWidth: '95%', horizontal: false, }, },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: slackCategories },
    yaxis: { min: 0, max: 100, labels: { formatter: function(val) { return val ? val.toFixed() + "%" : 0 } } },
    legend: { position: "bottom", show: true },
    tooltip: { y: { formatter: function(value, opts) { let percent = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return percent.toFixed(0) + '% (' + value + ")" } } },
    colors: ['#37a141', '#B4DDEE', '#F44336'],
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };

  // "volume" line chart for slack, email, and meetings:
  const volumeDataSeries = { slack: [], email: [], meeting: [] };
  const volumeCategories = data.networkStats.sort((a, b) => (a.month > b.month) ? 1 : -1).map(item => { return item.month })
  for (const cat of volumeCategories) {
    const stat = data.networkStats.find(i => i.month === cat)
    volumeDataSeries.meeting.push(stat.edges.reduce((acc, val) => { return acc + (val.meeting_count ? val.meeting_count : 0) }, 0))
    volumeDataSeries.email.push(stat.edges.reduce((acc, val) => { return acc + (val.email_count ? val.email_count : 0) }, 0))
    volumeDataSeries.slack.push(stat.edges.reduce((acc, val) => { return acc + (val.slack_count ? val.slack_count : 0) }, 0))
  }
  const volumeSeries = [{ name: "Meetings", data: volumeDataSeries.meeting }, { name: "Emails Sent", data: volumeDataSeries.email }, { name: "Slack Mentions", data: volumeDataSeries.slack }]
  const volumeConfig = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: slackCategories },
    yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed() : 0 } } },
    legend: { position: "bottom", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };

  const initiatorSeries = []
  const initiatorLabels = [...new Set(data.networkStats.reduce((acc, val) => { return acc.concat(val.edges.map(edge => edge.source)) }, []))]

  for (const source of initiatorLabels) {
    let labelValue = 0
    for (const stat of data.networkStats) {
      const edges = stat.edges.filter(edge => edge.source === source)
      labelValue += edges.reduce((acc, val) => { return acc + (val.email_count ? val.email_count : 0) + (val.slack_count ? val.slack_count : 0) }, 0)

    }
    initiatorSeries.push(labelValue)
  }


  const initiatorConfig = {
    chart: { type: 'pie', },
    labels: initiatorLabels,
    legend: { position: "bottom", show: true },
    colors: ['#32a871', '#608bbd', '#60bd79', '#6071bd', '#e87694', '#7216b8', '#cf3a29']
  };


  return (
    <Card className="card-box mb-4" style={{width:"100%"}}>
      <CardContent className="p-0">
        <Grid key = "firstGrid"item xs={12} lg={12}>
          <Grid container spacing={1} className="mt-1">
            <Grid item xs={12} sm={12} lg={12}>
              <div className="text-left">
                <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                  <div className=" br-5" ><b className="font-size-lg " >Cross-Team Relationship Insights:</b></div>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} lg={4}>
              <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                Collaboration volume and method over time:
                <Chart options={volumeConfig} series={volumeSeries} type="line" height="350"/>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} lg={4}>
              <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                Slack communication sentiment over time:
                <Chart options={SlackConfig} series={slackSeries} type="bar" height="350"/>
              </div>
            </Grid>
            <Grid item xs={4} sm={4} lg={4}>
              <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                Communication initiator balance:
                <Chart options={initiatorConfig} series={initiatorSeries} type="pie" height="350"/>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
