import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';

import CompanyRecommendations from '../../../flowtrace-components/Recommendations/Company';

const explanation = [];
explanation.push(<h4  key="header" className="heading-2 pb-1"> Flowtrace Recommendations</h4>);
explanation.push(<p key="sp"> Flowtrace brings you this power to your systematic internal communication, collaboration, and productivity analysis.</p>);

export default function Recommendations(props) {
  return (
    <Fragment>
      <PageTitle titleHeading="Flowtrace Recommendations" titleDescription="Here you see all the recommendations we have gained from analyzing your company." explanation={explanation} />

      <CompanyRecommendations flowtraceUser={ props.flowtraceUser } />
    </Fragment>
  );
}
