import Chart from 'react-apexcharts';
import { API } from 'aws-amplify';
import { Grid } from '@material-ui/core';
import Loading from '../../Loading'
import React, { Component, Fragment } from 'react';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import { HelpOutline } from '@material-ui/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const info = <HelpOutline style={{ fontSize: 15 }}/>;

function convertMinutesToTimeString(minutes) {
  if (!minutes)
    return "-";
  if (minutes >= 60)
    return Math.floor(minutes / 60).toFixed(0) + "h " + (minutes % 60).toFixed(0) + "m";
  return (minutes % 60).toFixed(0) + " min" || "-";

}

function convertEventDateToString(eventDate) {
  const date = moment(eventDate, 'YYYY-MM-DD HH:mm:ss');
  return localAwareMoment(date).format("LLL");
}

const arrAvg = arr => arr.reduce((a,b) => a + b, 0) / arr.length;

const localAwareMoment = (utcDate) => {
  // Make the time normal/summer time aware based on current user's timezone:
  const offset = utcDate.utcOffset();
  return utcDate.add(offset, "minutes");
};

const localAwareTimeString = (utcDate, format = "HH:mm:ss") => {
  return localAwareMoment(utcDate).format(format);
};

function videoCallAverageStart(row){
  const videoCalls = row.meets.concat(row.zooms);
  const avgStart = moment.utc(arrAvg(videoCalls.map(m => moment.utc(m.startTime).valueOf())));

  const start = moment.utc(row.eventDate, "YYYY/MM/DD HH:mm:ss");

  const lateMinutes = avgStart.diff(start, 'minutes');
  if(lateMinutes>0)
    return <div > {localAwareTimeString(avgStart)} <span className={"text-danger font-weight-bold" }> ({lateMinutes }m) </span> </div>;
  return <div > {localAwareTimeString(avgStart)} </div>;
}
function videoCallAverageEnd(row){
  const videoCalls = row.meets.concat(row.zooms);
  const avgEnd = moment.utc(arrAvg(videoCalls.map(m => moment.utc(m.endTime).valueOf())));
  const end = moment.utc(row.eventDate, "YYYY/MM/DD HH:mm:ss").add(row.duration, 'minutes');
  const lateMinutes = avgEnd.diff(end, 'minutes');
  if(lateMinutes>0)
    return <div > {localAwareTimeString(avgEnd)} <span className={"text-danger font-weight-bold" }> ({lateMinutes }m) </span> </div>;
  return <div > {localAwareTimeString(avgEnd)} </div>;
}

function videoCallAverageDuration(row){
  const videoCalls = row.meets.concat(row.zooms);

  const minutes = moment.utc(arrAvg(videoCalls.map(m => moment.utc(m.endTime).valueOf()))).diff(moment.utc(arrAvg(videoCalls.map(m => moment.utc(m.startTime).valueOf()))), "minutes");
  const lateMinutes = minutes - row.duration;
  if(lateMinutes>0)
    return <div > {convertMinutesToTimeString(minutes)} <span className={"text-danger font-weight-bold" }> ({lateMinutes }m) </span> </div>;
  return <div > {convertMinutesToTimeString(minutes)} </div>;
}

function convertCostToString(costProps, cost) {
  if (!cost) return "-";
  const currency = costProps ? costProps.currency : "GBP";
  const formatter = new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions().locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits:0
  });
  return formatter.format(cost); // "£1,000.00"
}



class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, members: props.members };
  }

  async loadMeetingDetails(meetingId) {
    const dataAPI = 'slackintegration';
    //const statisticsPath = '/productivity/meeting/' + meetingId + "/statistics";
    const statisticsPath = '/meeting/audit/' + meetingId;
    const initAPI = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters } };

    try {
      const response = await API.get(dataAPI, statisticsPath, initAPI);

      const meetCalls = response.data.meetings[0].events.map(e => e.meets ? e.meets.map(m => {return {...m, type:"Meets", event:e.eventDate}}): []).flat();
      const teamsCalls = response.data.meetings[0].events.map(e => e.teams ? e.teams.map(m => {return {...m, type:"Meets", event:e.eventDate}}): []).flat();
      const zoomCalls = response.data.meetings[0].events.map(e => e.zooms ? e.zooms.map(m => {return {...m, type:"Zoom", event:e.eventDate}}): []).flat();
      return this.setState({ loading: false, eventCalls:meetCalls.concat(zoomCalls).concat(teamsCalls), meeting:response.data.meetings[0] });
    } catch(error) {
      console.error(error);
    }
    return this.setState({ loading: false, events:[], meeting:{events:[]} });
  }


  async componentDidMount() {

    await this.loadMeetingDetails(this.props.meetingId);
    const teamData = [];
    for (const member of this.state.members) {
      const value = this.state.meeting.events.filter(e => e.users.internal.accepted.includes(member.id) || e.users.groups.accepted.includes(member.id)).map(e => e.duration).reduce((acc, cur) => acc += cur, 0);
      const videoCallValue = this.state.eventCalls.filter(c => c.user === member.id).map(e => e.duration).reduce((acc, cur) => acc += parseInt(cur, 10), 0) / 60;

      const team = teamData.find(t => t.name === member.team);
      if (!team)
        teamData.push({ name: member.team, value, videoCallValue });
      else {
        team.value += value;
        team.videoCallValue += videoCallValue;
      }
    }
    // Calculate team costs with override settings if available:
    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;
    const defaultCost = costProps ? costProps.hours / (costProps.salary * 1000) : 1730 / 84217;
    //console.log("default cost to use:", 1/defaultCost)

    const teamConfiguration = costProps && costProps.teamConfiguration ? costProps.teamConfiguration : [];
    //if(teamConfiguration)
      //teamConfiguration.forEach(tc => { console.log("hourly configured", (tc.salary * 1000) / tc.hours, tc.team)});
    const findConfigToUse = (path) => {
      if(teamConfiguration.find(t => t.team === path)){
        return teamConfiguration.find(t => t.team === path);
      }
      else if(path.split("/").length > 1){
        const reducedPath = path.split("/");
        reducedPath.length = reducedPath.length - 1;
        return findConfigToUse(reducedPath.join("/"));
      }
      else
        return null;
      };

    // Let's apply default config, company wide config, or given org structure config to the data
    teamData.forEach(td => {
      td.cost = td.value / 60 / defaultCost;
      td.videoCost = td.videoCallValue / 60 / defaultCost;
      // Let's find the exact team configuration (or check for parent until found or not)
      const teamConfig = td.name ? findConfigToUse(td.name) : null;
      //console.log(td.name, teamConfig)
      if(teamConfig) {
        const teamCost = teamConfig.hours / (teamConfig.salary * 1000);
        td.cost = td.value / 60 / teamCost;
        td.videoCost = td.videoCallValue / 60 / teamCost;
      }
      //console.log("calculated hourlies backward:", td.cost/(td.value/60), td.videoCost/(td.videoCallValue/60), td.name)

    });


    const callSeries = [{id:"all", name: "All Calls ", data:[]}];
    const videoEvents = this.state.eventCalls?[...new Set(this.state.eventCalls.map(e => e.event))].length :0;
    for(const callDetail of this.state.eventCalls?this.state.eventCalls:[]){
      const event = moment.utc(callDetail.event, "YYYY/MM/DD HH:mm:ss");
      const start = moment.utc(callDetail.startTime).diff(event, "seconds");
      const end = moment.utc(callDetail.endTime).diff(event, "seconds");
      callSeries[0].data.push({x: callDetail.event, y: [start, end]});
    }


    const attendanceSeries = teamData.filter(d => d.value > 0).map(d => d.value);
    const attendanceCosts = teamData.filter(d => d.value > 0).map(d => d.cost);
    const callAttendanceSeries = teamData.filter(d => d.videoCallValue > 0).map(d => d.videoCallValue);
    const callAttendanceCosts = teamData.filter(d => d.videoCallValue > 0).map(d => d.videoCost);
    const callAttendanceLabels = teamData.filter(d => d.videoCallValue > 0).map(d => d.name);
    const attendanceLabels = teamData.filter(d => d.value > 0).map(d => d.name);

    const events = this.state.meeting?this.state.meeting.events.map(e => {
      return {
        ...e,
        id:e.unique_id,
        calc: {
          i:{
            //i:e.invites.internal_accepted + e.invites.internal_pending + e.invites.internal_tentative + e.invites.internal_declined,
            it:e.invites.internal.invites + e.invites.group.invites,
            //g:e.groupAttendance.invites.length,
            //e:e.invites.invites - e.invites.internal_accepted,
            //t:e.invites.invites + e.groupAttendance.invites.length
          },
          a:{
            //i:e.invites.internal_accepted,
            it:e.invites.internal.accepted + e.invites.group.accepted,
            //g:e.groupAttendance.accepted.length,
            //e:e.invites.accepted - e.invites.internal_accepted,
            //t:e.invites.accepted + e.groupAttendance.accepted.length
          },
          p:{
            //i:e.invites.internal_pending,
            it:e.invites.internal.pending + e.invites.group.pending,
            //g:e.groupAttendance.pending.length,
            //e:e.invites.pending - e.invites.internal_pending,
            //t:e.invites.pending + e.groupAttendance.pending.length
          },
          d:{
            //i:e.invites.internal_declined,
            it:e.invites.internal.declined + e.invites.group.declined,
            //g:e.groupAttendance.declined.length,
            //e:e.invites.declined - e.invites.internal_declined,
            //t:e.invites.declined + e.groupAttendance.declined.length
          },
          t:{
            //i:e.invites.internal_tentative,
            it:e.invites.internal.tentative + e.invites.group.tentative,
            //g:e.groupAttendance.tentative.length,
            //e:e.invites.tentative - e.invites.internal_tentative,
            //t:e.invites.tentative + e.groupAttendance.tentative.length
          }
        }

      };

    }):[];

    this.setState({ events, callSeries, attendanceSeries, callAttendanceSeries, attendanceLabels, callAttendanceLabels, videoEvents, callAttendanceCosts, attendanceCosts });
  }


  render() {

    if (this.state.loading)
      return <Loading/>;




const annotationConfig = {
          xaxis: [{
            x: 0,
            x2: 5,
            fillColor: '#B3F7CA',
            opacity: 0.5,
            label: {
              borderColor: '#B3F7CA',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: '#00E396',
              },
              offsetY: -10,
              text: 'Scheduled Start Time',
            }
          },{
            x: this.state.meeting.duration.meeting * 60 - 5,
            x2: this.state.meeting.duration.meeting * 60,
            fillColor: '#f7c4b3',
            opacity: 0.5,
            label: {
              borderColor: '#f7c4b3',
              style: {
                fontSize: '10px',
                color: '#fff',
                background: '#ff7361',
              },
              offsetY: -10,
              offsetX: 28,
              text: 'Scheduled End Time',
            }
          }]
};

    const videoCallOptions = {
        chart: { animations: { enabled: false }, toolbar: { show: false }, type: 'rangeBar', width:"100%" },
        plotOptions: { bar: { horizontal: true, barHeight: '100%' } },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
        legend: { position: "right", show: false },
        theme: { mode: 'light', palette: 'palette2', },
        tooltip: { enabled:false },
        yaxis: {
          labels: {
            formatter: function(val, timestamp , opts) {
              return moment.utc(val, "YYYY/MM/DD HH:mm:ss").format("YYYY-MM-DD");
            }
          }},
        xaxis: {
          type: 'datetime' ,
          min: -60 * 5, // show 5 minutes early at the most
          labels: {
            formatter: function(val, timestamp , opts) {
              if(val===0)
                return "Scheduled";
              return moment.duration(-1 * val, "seconds").humanize()
            }
          }},
        dataLabels: { enabled: false, },
        stroke: { width: 1 },
        fill: { type: 'solid', opacity: 0.4 },
        annotations: annotationConfig
        };


    const participantOptions = {
        chart: { animations: { enabled: false }, toolbar: { show: false }, type: 'rangeBar', width:"100%" },
        plotOptions: { bar: { horizontal: true, barHeight: '90%' } },
        responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
        legend: { position: "right", show: false },
        theme: { mode: 'light', palette: 'palette2', },
        tooltip: {
          enabled:true,
          x: {
              formatter: function(value) {
                if(typeof value !== "number") return "Join/Leave";
                if(value<0)
                  return "-" + moment("2020-01-01T00:00:00").add((-1*value), 'seconds').format("HH:mm:ss");
                return moment("2020-01-01T00:00:00").add(value, 'seconds').format("HH:mm:ss");
              }
          },
          y: {
              formatter: function(value) {
                return value;
              }
          },
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return moment(val, "YYYY/MM/DD HH:mm:ss").format("YYYY-MM-DD");
            }
          }},
        xaxis: {
          type: 'datetime' ,
          min: -60 * 5, // show 5 minutes early at the most
          labels: {
            formatter: function(val, timestamp , opts) {
              if(val===0)
                return "Scheduled";
              return moment.duration(-1 * val, "seconds").humanize()
            }
          }},
        dataLabels: {
          enabled: true,
          textAnchor: "middle",
          formatter: function(val,  {seriesIndex, dataPointIndex, w}) {
            const value = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
            return moment.duration(value.y[0] - value.y[1], "seconds").humanize();
          },
          style: {
            colors: ['#f3f4f5', '#fff']
          }
        },
        stroke: { width: 1 },
        fill: { type: 'solid', opacity: 0.6 },
        annotations: annotationConfig
        };

    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;
    const invitedLabelFormatter = (val, index) => {return `${convertCostToString(costProps, this.state.attendanceCosts[index.dataPointIndex])} (${convertMinutesToTimeString(val)})`};
    const videoLabelFormatter = (val, index) => {return `${convertCostToString(costProps, this.state.callAttendanceCosts[index.dataPointIndex])} (${convertMinutesToTimeString(val)})`};
    const chartOptions = {
      chart: { animations: { enabled: false }, type: 'pie', },
      responsive: [{ breakpoint: 480, options: { chart: { width: 200 }, legend: { position: 'bottom' } } }],
      yaxis: { labels: { formatter: function(val) { return val ? (Math.abs(val) / 60 / 60).toFixed(1) + "h" : null } } },
      colors: ['#32a871', '#608bbd', '#60bd79', '#6071bd', '#e87694', '#7216b8', '#cf3a29']
    };

    const videoCallHeight = this.state.videoEvents*20<150?150:this.state.videoEvents*20;
    const callSeries = this.state.callSeries;
    const attendanceSeries = this.state.attendanceSeries;
    const callAttendanceSeries = this.state.callAttendanceSeries;
    const renderStatus = (data, compare) =>  {
      if(!compare)
        return <div><span className={"font-weight-bold" }> { data } </span></div>;
      return <div><span className={(compare < data ? "text-danger ":"") + " font-weight-bold" }> { data } </span></div>;
      };

    const title = (title, tooltip) => <div>{tooltip?<Tooltip title={tooltip} ><span>{title}</span></Tooltip>:<span>{title}</span>}</div>;


    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const costExplanationString = costProps ?
      `Account cost configuration: currency ${costProps.currency}, yearly working hours ${costProps.hours}, and yearly average salary of ${costProps.salary}k. You also have ${costProps.teamConfiguration?costProps.teamConfiguration.length : 0} teams with salary configuration override.`
      :
      "We use an average 1'730 hour working year (2021), £74k average tech industry salary (2019), and UK employer costs to calculate esimated total monetary investment of a meeting.";

    const includesTeamConfig = "This estimate uses team cost estimate overrides you have configured in the account settings. " + costExplanationString;

    const invitedTooltip = "This cost estimate is based on invites. " + includesTeamConfig;
    const attendedTooltip = "This cost estimate is based on actual attendance using video call information. " + includesTeamConfig

    return (
      <Fragment >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12}>
                <div className="d-flex align-items-start">
                <Tooltip title={invitedTooltip}>
                  <div className="font-weight-bold m-3"> Attendance based on calendar invites: {info}</div>
                </Tooltip>
                </div>
                {attendanceSeries?<Chart options={{...chartOptions, yaxis: { labels: { formatter: invitedLabelFormatter }}, labels: this.state.attendanceLabels}} height={200} series={attendanceSeries} type="pie" />:null}
              </Grid>
              {callAttendanceSeries && callAttendanceSeries.length?
                <Grid item xs={12} md={12}>
                  <div className="d-flex align-items-start">
                    <Tooltip title={attendedTooltip}>
                      <div className="font-weight-bold m-3"> Attendance based on video calls: {info}</div>
                    </Tooltip>
                  </div>
                  <Chart options={{...chartOptions, yaxis: { labels: { formatter: videoLabelFormatter }}, labels: this.state.callAttendanceLabels}} height={200} series={callAttendanceSeries} type="pie" />
                </Grid>
              :null}
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <div className="d-flex align-items-start">
              <div className="font-weight-bold m-3"> Meeting participant heatmap based on video calls: </div>
            </div>
            {callSeries && callSeries[0] && callSeries[0].data && callSeries[0].data.length?
              <Chart options={videoCallOptions} height={videoCallHeight} series={callSeries} type="rangeBar" />
            :
              <div className="font-weight-bold m-3">No Video Call Participation Found! </div>
            }
          </Grid>
          <Grid item xs={12} md={12}>
            <div className="d-flex align-items-start">
              <div className="font-weight-bold m-3"> Meeting Event Details: (All times in your local timezone: {tz})</div>
            </div>

            <MaterialTable
              title=""
              columns={[
                { title: title("Time and Date", "Time and date based on your local timezone: " + tz), width:180, defaultSort:"desc", field: 'eventDate', render: (rowData => { return ( convertEventDateToString(rowData.eventDate) ) } ) },
                { title: title("Invited (D)","How many people were invited directly to this event."), field:"invites.internal.invites", render: rowData =>  renderStatus(rowData.invites.internal.invites) },
                { title: title("Invited (G)","How many people were invited through group to this event."), field:"invites.group.invites", render: rowData =>  renderStatus(rowData.invites.group.invites) },
                { title: title("Accepted","Internal people who accepted the invite."), field:"calc.a.it", render: rowData =>  renderStatus(rowData.calc.a.it) },
                { title: title("Declined","Internal people who declined the invite."), field:"calc.d.it", render: rowData =>  renderStatus(rowData.calc.d.it) },
                { title: title("Pending","Internal people who have not responded to the invite."), field:"calc.p.it", render: rowData =>  renderStatus(rowData.calc.p.it) },
                { title: title("Tentative","Internal people who tentatively accepted the invite."), field:"calc.t.it", render: rowData =>  renderStatus(rowData.calc.t.it) },
                { title: title("Video Calls","How many participants attended this call with Meets or Zoom."), field:"video.participants",render: (rowData => { return ( <div className="text-center"><span className="font-weight-bold"> { rowData.video.participants } </span></div> ) } ) },
                { title: title("Call Start", 'Average time video call participants joined the meeting and highlight for how late the meeting started. Time based on your local timezone: ' + tz), field:"meets", render: (rowData => { return ( <div className="font-weight-bold "> {rowData.video.participants?videoCallAverageStart(rowData):"-" } </div> )})},
                { title: title("Call End", 'Average time video call participants left the meeting and highlight for how late the meeting ran. Time based on your local timezone: ' + tz), field:"meets", render: (rowData => { return ( <div className="font-weight-bold "> {rowData.video.participants?videoCallAverageEnd(rowData):"-" } </div> )})},
                { title: title("Call Duration", 'Average time video call participants stayed on the meeting and highlight for long the meeting was compared to schedule.'), field:"meets", render: (rowData => { return ( <div className="font-weight-bold "> {rowData.video.participants?videoCallAverageDuration(rowData):"-" } </div> )})},
                { title: title("Scheduled Duration"), width:180, field:"duration", render: (rowData => { return ( <div className="font-weight-bold "> {convertMinutesToTimeString(rowData.duration)} </div> ) } ) },

              ]}
              data={this.state.events}
              icons={tableIcons}
              options={
                {
                headerStyle: { padding: '1px' },
                //rowStyle: { padding: '1px' },
                showTitle:false,
                toolbar:false,
                padding:"dense",
                search:false,
                emptyRowsWhenPaging:false,
                paging:this.state.meeting.events.length>10?true:false,
                pageSize:10,
                pageSizeOptions:[10,25,50],
                }
              }
              detailPanel={[
                {render: ({rowData}) => {
                    const participantSeries = [];
                    for(const callDetail of this.state.eventCalls?this.state.eventCalls.filter(c => c.event === rowData.eventDate):[]){
                      const event = moment.utc(callDetail.event, "YYYY/MM/DD HH:mm:ss");
                      const start = moment.utc(callDetail.startTime).diff(event, "seconds");
                      const end = moment.utc(callDetail.endTime).diff(event, "seconds");
                      const participant = participantSeries.find(s => s.id === callDetail.user);
                      if(!participant) participantSeries.push({id:callDetail.user, name: callDetail.type + " participant " + (participantSeries.length + 1), data:[]});
                      participantSeries.find(s => s.id === callDetail.user).data.push({x: callDetail.event, y: [start, end]});
                    }

              if(!participantSeries.length)
                return (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="font-weight-bold m-3"> No Event Video Call Participation Found! </div>
                  </Grid>
                </Grid>)

              return (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <div className="d-flex align-items-start">
                      <div className="font-weight-bold m-3"> Event Video Call Participation: </div>
                    </div>
                    <Chart options={participantOptions} height={participantSeries.length * 30 < 150?150:participantSeries.length * 30} series={participantSeries} type="rangeBar" />
                  </Grid>
                </Grid>
                )
                }}

                //return ( <div>huhuu</div> ) }}
              ]}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}

            />
          </Grid>
        </Grid>



        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
