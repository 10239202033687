import GitHubLogo from '../../../assets/images/integration_logos/github.png';
import GitLabLogo from '../../../assets/images/integration_logos/gitlab.png';
import JiraLogo from '../../../assets/images/integration_logos/jira.png';
import AsanaLogo from '../../../assets/images/integration_logos/asana.png';
import SalesForceLogo from '../../../assets/images/integration_logos/salesforce.png';
import HubSpotLogo from '../../../assets/images/integration_logos/hubspot.png';
import ZoomLogo from '../../../assets/images/integration_logos/zoom.png';
import FrontLogo from '../../../assets/images/integration_logos/front.png';
import MicrosoftLogo from '../../../assets/images/integration_logos/ms.png';
import ClickupLogo from '../../../assets/images/integration_logos/clickup.png';
import GoogleLogo from '../../../assets/images/integration_logos/google.png';
import SlackLogo from '../../../assets/images/integration_logos/slack.png';


export const getLogo = (type) => {
  if (type === "jira") return JiraLogo;
  if (type === "github") return GitHubLogo;
  if (type === "gitlab") return GitLabLogo;
  if (type === "asana") return AsanaLogo;
  if (type === "zoom") return ZoomLogo;
  if (type === "hubspot") return HubSpotLogo;
  if (type === "salesforce") return SalesForceLogo;
  if (type === "microsoft") return MicrosoftLogo;
  if (type === "front") return FrontLogo;
  if (type === "clickup") return ClickupLogo;
  if (type === "slack") return SlackLogo;
  if (type === "gsuite") return GoogleLogo;
  return null;
};