import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';

import CompanySentiment from '../../flowtrace-components/Sentiment/CompanySentiment';

const explanation = []
explanation.push(<h4  key="header" className="heading-2 pb-1"> Company Sentiment analysis</h4>)
explanation.push(<p key="fp"> <a target="_blank" rel="noopener noreferrer" href='https://en.wikipedia.org/wiki/Sentiment_analysis'>Sentiment analysis</a> (also known as opinion mining or emotion AI) refers to the use of natural language processing, text analysis, and computational linguistics to systematically identify, extract, quantify, and study affective states and subjective information. Sentiment analysis is widely applied to voice of the customer materials such as reviews and survey responses, online and social media, and healthcare materials for applications that range from marketing to customer service to clinical medicine. </p>)
explanation.push(<p key="sp"> Flowtrace brings you this power to your systematic internal communication and collaboration analysis.</p>)

export default function Dashboard(props) {
  return (
    <Fragment>
      <PageTitle titleHeading="Company Sentiment" titleDescription="Here you see overview of your Slack workspace channels and their sentiment over time." explanation={explanation} />

      <CompanySentiment  flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
