import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import ChannelSentimentChart from '../Charts/SentimentChannelChart';
import Loading from '../../Loading'
import FilterBar from '../../Common/FilterBar';

class SentimentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: "week" } };
  }

  async loadSlackSentimentData(queryFilters) {
    const dataAPI = 'slackintegration';
    const sentimentPath = '/sentiment/aggregate';
    const initAPI = { headers: {}, response: true, queryStringParameters: { type: "channel", ...queryFilters } };

    const response = await API.get(dataAPI, sentimentPath, initAPI)
    this.setState({ loading: false, channelSentiments: response.data ? response.data.summary.channels : null })
    return
  }


  async componentDidMount() {
    this.loadSlackSentimentData(this.state.queryFilters)
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadSlackSentimentData(queryFilters);
  }

  render() {

    const channelControls = []
    for (const key in this.state.channelSentiments ? this.state.channelSentiments : []) {
      channelControls.push(<ChannelSentimentChart key={"channel"+key} data={this.state.channelSentiments[key]} />)
    }

    return (
      <Fragment>
          <FilterBar teamFilter queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
            {!this.state.loading ?
              <Grid container spacing={4}>
                {channelControls}
              </Grid>
            :
              <Loading/>
            }
        </Fragment>
    );
  } // end of render()
} // end of class

export default SentimentOverview
