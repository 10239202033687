import { Grid, Card, CardContent, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { TableBody, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'moment';

function deltaIndicator(delta) {
  if (delta > 0)
    return <span key="changeUp" className="font-size-sm mt-1 ml-2 text-success"><FontAwesomeIcon icon={['fa', 'arrow-up']} className="font-size-sm" />{" " + Number.parseInt(delta) + " %"}</span>
  if (delta < 0)
    return <span key="changeDown" className="font-size-sm mt-1 ml-2 text-danger"><FontAwesomeIcon icon={['fa', 'arrow-down']} className="font-size-sm" />{" " + Number.parseInt(delta) + " %"}</span>
  return <span key="noChange" className="font-size-sm mt-1 ml-2">{" "}</span>
}

function createScoreBadgeContent(scoreValue, tooltipText) {
  if (isNaN(scoreValue))
    return [" We weren't able to calculate this score.", "badge badge-darkest-info"]
  if (scoreValue < 20)
    return [tooltipText + " This value indicates this individual has BAD score compared to rest of the company", "badge badge-darkest-danger"]
  if (scoreValue >= 20 && scoreValue < 40)
    return [tooltipText + " This value indicates this individual has POOR score compared to rest of the company!", "badge badge-dark-danger"]
  if (scoreValue >= 40 && scoreValue < 50)
    return [tooltipText + " This value indicates this individual has AVERAGE score compared to rest of the company!", "badge badge-dark-warning"]
  if (scoreValue >= 50 && scoreValue < 60)
    return [tooltipText + " This value indicates this individual has AVERAGE score compared to rest of the company!", "badge badge-darkest-warning"]
  if (scoreValue >= 60 && scoreValue < 80)
    return [tooltipText + " This value indicates this individual has GOOD score compared to rest of the company!", "badge badge-dark-success"]
  if (scoreValue >= 80)
    return [tooltipText + " This value indicates this individual has GREAT score compared to rest of the company!", "badge badge-darkest-success"]
}

const badgeTexts = {
  engagement: { title: "Engagement", explanation: "This is a weighted composite score of all other indexes. " },
  sentiment: { title: "Sentiment", explanation: "This composite value indicates how this user's style of collaborating differs from rest of the company by its sentimental style." },
  flightRisk: { title: "Disengagement", explanation: "This value indicates declining trend in employee's activity, connectedness, influence, and/or engagement." },
  activity: { explanation: "This composite index indicates collaboration activity volume. This is calculates from values like active hours, active communication channels and volume of communication." },
  connectedness: { explanation: "This composite value is calculates based on connectivity degrees of the individual within the company network. For more information, please search for degree centrality and other graph theory based connectedness metrics." },
  influence: { explanation: "This composite value indicates individuals degree of influence within the company network. This metric can be misleading if individual is part ot two or more peripheral communities within the company. For more information, please search for betweenness and other graph theory based weighted betweenness metrics." }
}


function createScoreBadge(latestScore, badgeType) {
  let [scoreValue, scoreDelta] = [0, 0]

  // Get the values for badge (engagement has different data structure...)
  if (badgeType === "engagement")[scoreValue, scoreDelta] = [latestScore.engagement, latestScore.delta]
  else [scoreValue, scoreDelta] = [(badgeType in latestScore) ? latestScore[badgeType].score : "N/A", (badgeType in latestScore) ? latestScore[badgeType].delta : 0]

  const [badgeTooltipText, badgeClassNames] = createScoreBadgeContent(scoreValue, badgeTexts[badgeType].explanation)

  if (badgeType === "engagement" || badgeType === "sentiment" || badgeType === "flightRisk") return (
    <Tooltip key={badgeType+"badge"} arrow title={badgeTooltipText} placement="bottom">
      <div style={{textAlign:"center", width:"100%"}} >
        <div className="font-weight-bold font-size-l mt-2 pt-1 mr-2 ">{badgeTexts[badgeType].title}:</div>
        <span className=" font-weight-bold font-size-xxl">
          <span className={badgeClassNames + " mb-5"}>
            {isNaN(scoreValue)?scoreValue:Number.parseInt(scoreValue)}
          </span>
        {deltaIndicator(scoreDelta)}</span>
      </div>
    </Tooltip>
  )
  else return (
    <Tooltip key={badgeType+"badge"} arrow title={badgeTooltipText} placement="bottom">
      <div className="font-weight-bold font-size-l">
        <span className={badgeClassNames}>
          {Number.parseInt(scoreValue)}
        </span>
      {deltaIndicator(scoreDelta)}
      </div>
    </Tooltip>
  )


}

export default function UserStatisticsChart(data) {

  const user = data.user;
  let userName = user.name //+ " (" + user.user + ")";

  // Score metrics
  let engagement = [];
  let activity = [];
  let sentiment = [];
  let connectedness = [];
  let influence = [];
  let flightRisk = []
  let engagementPrediction = [];
  let activityPrediction = [];
  let sentimentPrediction = [];
  let connectednessPrediction = [];
  let influencePrediction = [];

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  // Create badges here (main ones and for the table)
  const temporaryValidationArray = user.timeStats.filter(element => (element.activeDays !== undefined))
  if (temporaryValidationArray[temporaryValidationArray.length - 1].activeDays === 0) temporaryValidationArray.pop()
  const latestScore = (temporaryValidationArray.length - 1 >= 0 ? temporaryValidationArray[temporaryValidationArray.length - 1].score : null)

  const engagementScore = createScoreBadge(latestScore, "engagement")
  //const sentimentScore = createScoreBadge(latestScore, "sentiment")
  const fligtRiskScore = createScoreBadge(latestScore, "flightRisk")

  const subScoreRow = []
  const badges = new Map()
  badges["activity"] = createScoreBadge(latestScore, "activity")
  badges["connectedness"] = createScoreBadge(latestScore, "connectedness")
  badges["influence"] = createScoreBadge(latestScore, "influence")
  subScoreRow.push(badges)


  // Create data for detail view
  for (const key in user.timeStats.filter(element => (element.activeDays !== undefined))) {
    const stamp = Moment(user.timeStats[key].timeSpan).valueOf()
    engagement.push([stamp, user.timeStats[key].score.engagement]);
    activity.push([stamp, user.timeStats[key].score.activity.score]);
    sentiment.push([stamp, user.timeStats[key].score.sentiment.score]);
    connectedness.push([stamp, user.timeStats[key].score.connectedness.score]);
    influence.push([stamp, user.timeStats[key].score.influence.score]);

    // Need to rebase the value of flight risk e.g. anything below 30 should not be shown, and 30-100 emphasized
    let flightRiskValue = user.timeStats[key].score.flightRisk ? user.timeStats[key].score.flightRisk.score : "0"
    // if (flightRiskValue > 33) {
    //   flightRiskValue = Number.parseInt((flightRiskValue - 33) / 0.66666)

    // }
    // else {
    //   flightRiskValue = 0
    // }
    flightRisk.push([stamp, flightRiskValue]);
  }

  const lastPredictionIndex = user.timeStats.map(prediction => ("activeDays" in prediction)).lastIndexOf(true)
  for (const value of user.timeStats[lastPredictionIndex].spanPrediction.values()) {
    //console.log(key)
    const stamp = Moment(value.key).valueOf()
    engagementPrediction.push([stamp, value.engagement]);
    activityPrediction.push([stamp, value.activity]);
    sentimentPrediction.push([stamp, value.sentiment]);
    connectednessPrediction.push([stamp, value.connectedness]);
    influencePrediction.push([stamp, value.influence]);
  }

  // Check numbers are okay:
  //console.log(engagementPrediction.length, activityPrediction.length, sentimentPrediction.length, connectednessPrediction.length, influencePrediction.length)
  //console.log(engagement.length, activity.length, sentiment.length, connectedness.length, influence.length, flightRisk.length)
  // for (let i = 0; i < engagementPrediction.length; i++)
  //   console.log(engagementPrediction[i], activityPrediction[i], sentimentPrediction[i], connectednessPrediction[i], influencePrediction[i])

  // for (let i = 0; i < engagement.length; i++)
  //   console.log(engagement[i], activity[i], sentiment[i], connectedness[i], influence[i], flightRisk[i])


  const graphChartOptionsMetric = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: false }, crosshairs: { width: 1 } },
    yaxis: { min: 0, max: 99, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : "" } } },
    legend: { position: "bottom", show: true },
    title: { text: "Metric scores over time", style: { fontWeight: "bold" } },
    colors: ['#3786a1', '#F44336', '#37a141'],
    //markers: { size: [6, 6, 6, 0, 0, 0] },
    stroke: { width: [3, 3, 3, 1, 1, 1], dashArray: [0, 0, 0, 3, 4, 5], curve: ["straight", "straight", "straight", "smooth", "smooth", "smooth"] },
    fill: { type: 'solid', opacity: [0.05, 0.05, 0.05, 1, 1, 1], },
  };
  const graphChartOptionsCollaboration = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: false }, crosshairs: { width: 1 } },
    yaxis: { min: 0, max: 99, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : val } } },
    legend: { position: "bottom", show: true },
    title: { text: "Collaboration scores over time", style: { fontWeight: "bold" } },
    colors: ['#4ECDC4', '#662E9B', '#4ECDC4', '#662E9B', '#FEB019'],
    stroke: { width: [3, 3, 1, 1, 1], dashArray: [0, 0, 4, 5, 0], curve: ["straight", "straight", "smooth", "smooth"] },
    fill: { type: 'gradient', gradient: { type: "vertical", shadeIntensity: 1, gradientToColors: ['#4ECDC4', '#662E9B', '#4ECDC4', '#662E9B', '#D7263D'], opacityFrom: [0.05, 0.05, 1, 1, 1], opacityTo: [0.05, 0.05, 1, 1, 0.5], stops: [0, 90, 100] } },
    //fill: { type: 'solid', opacity: [0.05, 0.05, 1, 1, 1], },
  };

  const scoreSeries = [
    { name: 'Engagement', data: engagement, type: "area" },
    { name: 'Activity', data: activity, type: "area" },
    { name: 'Sentiment', data: sentiment, type: "area" },
    { name: 'Prediction', data: engagementPrediction },
    { name: 'Prediction', data: activityPrediction },
    //{ name: 'Prediction', data: sentimentPrediction },
  ];
  //console.log(scoreSeries)
  const scoreNetworkSeries = [
    { name: 'Connectedness', data: connectedness, type: "area" },
    { name: 'Influence', data: influence, type: "area" },
    { name: 'Prediction', data: connectednessPrediction },
    { name: 'Prediction', data: influencePrediction },
    { name: 'Disengagement', data: flightRisk, type: "column" }
  ];

  return (
    <Fragment>
      <div className="text-left align-items-center justify-content-left" style={{width:"100%"}}>
        <div className="ml-3 mt-3 line-height-sm ">
        <ExpansionPanel expanded={expanded === 'userEngagementPanel'} onChange={handleChange('userEngagementPanel')}>
          <ExpansionPanelSummary expandIcon={expanded?<ExpandMoreIcon />:<div><div><b className="font-size-sm">Details</b></div><ExpandMoreIcon /></div>} aria-controls="panel1bh-content" id="panel1bh-header">
            <Grid key = "firstGrid"item xs={12} lg={12}>
              <Grid container spacing={1} className="mt-1">
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="text-left">
                    <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                      <div className=" br-5" ><b className="font-size-lg " >{userName}</b></div>
                      <span className="text-black-50 d-block">Onboarding activity on {user.activeDays} days </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="text-left">
                    <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                      <div className=" justify-content-left br-5" > {engagementScore} </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="text-left">
                    <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                      <div className=" justify-content-left br-5" > {fligtRiskScore} </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} lg={5}>
                  <div className="text-left">
                    <div className="ml-2 line-height-sm ">
                      <TableContainer className=" line-height-sm" >
                        <Table  size="small" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell >Activity</TableCell>
                              <TableCell align="center">Connectedness</TableCell>
                              <TableCell align="center">Influence</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {subScoreRow.map(row => (
                              <TableRow key={"tableRow"+row.name}>
                                <TableCell component="th" scope="row">
                                                 {row.activity}
                                </TableCell>
                                <TableCell align="center">{row.connectedness}</TableCell>
                                <TableCell align="center">{row.influence}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <Grid item xs={12} lg={12}>
              <Card className="card-box mb-4">
                <CardContent className="p-0">
                  <Grid container spacing={1} >
                    <Grid item xs={12} sm={6} >
                      <div className="text-left">
                        <div className="ml-3 mt-1 line-height-sm">
                         <Chart key="chartMetrics" options={graphChartOptionsMetric} series={scoreSeries} type="line" height="200"/>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <div className="text-left">
                        <div className="ml-3 mt-1 line-height-sm">
                         <Chart key="chartGraphs" options={graphChartOptionsCollaboration} series={scoreNetworkSeries} type="line" height="200"/>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>

    </Fragment>
  );


}
