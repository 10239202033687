import React from 'react'
import { Graph } from "react-d3-graph";
import { useRef, useEffect, useLayoutEffect, useState } from "react";
import DetailNetworkDialog from '../../DetailNetworkDialog';

let networkData

// Function to trigger resize less often
function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

const BarChart = props => {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [data, setData] = useState(null);
    networkData = props;

    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
        // Cleanup nodes from the data in case they don't appear on edges
        let nodes = {}
        for (const key in networkData.data.edges) {
            nodes[networkData.data.edges[key].source] = networkData.data.nodes[networkData.data.edges[key].source]
            nodes[networkData.data.edges[key].target] = networkData.data.nodes[networkData.data.edges[key].target]
        }

        const data = { nodes: [], links: [] }
        for (const key in nodes) {
            let nodeToPush = { id: key, label: nodes[key].label }
            if (nodes[key].type === "PERSON" || nodes[key].type === "User") {
                nodeToPush.svg = "/images/ona_icons/user_icon.svg"
                nodeToPush.color = "green"
            }
            if (nodes[key].type === "Channel") {
                nodeToPush.svg = "/images/ona_icons/channel_icon.svg"
                nodeToPush.color = "blue"
            }
            if (nodes[key].type === "LOCATION") {
                nodeToPush.svg = "/images/ona_icons/map_icon.svg"
                nodeToPush.color = "red"
            }
            if (nodes[key].type === "ORGANIZATION") {
                nodeToPush.svg = "/images/ona_icons/company_icon.svg"
                nodeToPush.color = "yellow"
            }
            if (nodes[key].type === "TITLE") {
                nodeToPush.svg = "/images/ona_icons/topic_icon.svg"
                nodeToPush.color = "orange"
                nodeToPush.size = 200
            }
            data.nodes.push(nodeToPush)
        }


        let maxValue = 0 // Get max value of the count
        for (const key in networkData.data.edges)
            if (maxValue <= networkData.data.edges[key].count)
                maxValue = parseInt(networkData.data.edges[key].count)


        for (const key in networkData.data.edges) {
            let edgeToPush = {
                source: networkData.data.edges[key].source,
                target: networkData.data.edges[key].target,
                strokeWidth: (networkData.data.edges[key].count / maxValue * 3),
            }
            //#474747 = darkish grey
            //#4d4d4d = lighter
            //#575757 = lightest

            edgeToPush.color = "#575757" // Make them darker depending on the value
            if (networkData.data.edges[key].count / maxValue > 0.5) edgeToPush.color = "#4d4d4d"
            if (networkData.data.edges[key].count / maxValue > 0.66) edgeToPush.color = "#474747"

            data.links.push(edgeToPush)
        }
        setData(data)

    }, []);

    const myConfig = {
        nodeHighlightBehavior: true,
        highlightOpacity: 0.8,
        height: 700,
        width: dimensions.width,
        d3: {
            gravity: -900,
            linkLength: 200
        },
        directed: false,
        node: {
            color: "lightgreen",
            size: 240,
            fontSize: 16,
            highlightStrokeColor: "blue",
            highlightFontSize: 24,
            labelProperty: "label"
        },
        link: {
            highlightColor: "darkblue",
            semanticStrokeWidth: true,
            type: "STRAIGHT",
            markerWidth: 4,
        },
    };
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)

        }
    });
    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);
    const [graphClickData, setGraphClickData] = useState({ node1: false, node2: false });
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickLinkDialog = (param1, param2) => {
        setGraphClickData({ type: "link", node1: param1, node2: param2 })
        setOpenDialog(true);
    };
    const handleClickNodeDialog = (param1, param2) => {
        setGraphClickData({ type: "node", node1: param1, node2: param2 })
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if (!data) // First render with empty div
        return (<div height="100%" ref={targetRef}></div>)
    // Height calculations
    // HeaderBox: 78 pixels
    // FooterBox: 70 pixels
    // SliderBox: 84 pixels
    // Container: 32 pixels, and 16 pixels (top and bottom)
    // Total: 280 pixels
    myConfig.height = window.innerHeight - 280
    myConfig.width = window.innerWidth

    // Real render with chart:
    return (
        <div ref={targetRef}>
            <Graph id="graph-id-networkData"
                data={data}
                config={myConfig}
                onClickNode={handleClickNodeDialog}
                onClickLink={handleClickLinkDialog}
            />
            <DetailNetworkDialog open={openDialog} clickEvent={graphClickData} data={props.data} onClose={handleCloseDialog} />
        </div>
    )
}
export default BarChart
