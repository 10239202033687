import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import TeamSentimentChart from '../Charts/SentimentTeamChart';
import ChannelTable from './channelTable';
import Loading from '../../Loading';
import FilterBar from '../../Common/FilterBar';

class SentimentOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, queryFilters: { range_type: "calendar", range_period: "day", range_limit:28 } };
  }

  async loadSlackSentimentData(queryFilters) {
    const dataAPI = 'slackintegration';
    const sentimentPath = '/sentiment/aggregate';
    const sentimentAPItime = { headers: {}, response: true, queryStringParameters: { type: "time", ...queryFilters } };
    const sentimentAPIchannel = { headers: {}, response: true, queryStringParameters: { type: "channel", ...queryFilters } };

    const [timeData, channelData] = await Promise.all([
      API.get(dataAPI, sentimentPath, sentimentAPItime),
      API.get(dataAPI, sentimentPath, sentimentAPIchannel)
    ]);
    const sentimentData = { channels: (channelData && channelData.data ? channelData.data.summary.channels : []), time: (timeData && timeData.data ? timeData.data.summary.time : []) };
    // Aggregate all channels and direct messages to same buckets

    const channelSummary = {};
    channelSummary["public"] = { key: "public", metadata: { name: "Public Channels", purpose: "Combined public channel sentiment" }, sentimentHistogram: [], };
    channelSummary["private"] = { key: "private", metadata: { name: "Direct and Private Channels", purpose: "Combined private and direct messaging sentiment" }, sentimentHistogram: [], };

    for (const channel in sentimentData.channels) {
      let summaryKey = "public";
      if (channel.substring(0, 1) !== "C") summaryKey = "private"; // D & G belong to private bucket
      for (const key in sentimentData.channels[channel].sentimentHistogram) {

        // Init the structure if it does not exist yet
        if (!channelSummary[summaryKey].sentimentHistogram[key]) channelSummary[summaryKey].sentimentHistogram[key] = { doc_count: 0, sentiment: { POSITIVE: 0, NEGATIVE: 0 } };

        // Add numbers for the bucket
        channelSummary[summaryKey].sentimentHistogram[key].doc_count += sentimentData.channels[channel].sentimentHistogram[key].doc_count || 0;
        channelSummary[summaryKey].sentimentHistogram[key].sentiment.POSITIVE += sentimentData.channels[channel].sentimentHistogram[key].sentiment.POSITIVE || 0;
        channelSummary[summaryKey].sentimentHistogram[key].sentiment.NEGATIVE += sentimentData.channels[channel].sentimentHistogram[key].sentiment.NEGATIVE || 0;
      }
    }
    // Sort them by the keys and return (as channels can return different number of buckets)
    channelSummary["private"].sentimentHistogram = Object.fromEntries(Object.entries(channelSummary["private"].sentimentHistogram).sort());
    channelSummary["public"].sentimentHistogram = Object.fromEntries(Object.entries(channelSummary["public"].sentimentHistogram).sort());
    sentimentData.channelSummaries = channelSummary;

    this.setState({ loading: false, timeSentiments: sentimentData.time, channelSentiments: sentimentData.channels, summarySentiments: sentimentData.channelSummaries });
    return;
  }

  componentDidMount() {
    this.loadSlackSentimentData(this.state.queryFilters);
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadSlackSentimentData(queryFilters);
  }

  render() {

    if (this.state.loading){
      return (
        <Fragment>
          <FilterBar teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Loading/>
        </Fragment>);
    }
    const teamSentiment = [];
    if (this.state.timeSentiments)
      teamSentiment.push(<TeamSentimentChart key="team" title="Total collaboration for past year:" data={this.state.timeSentiments} />);
    if (this.state.summarySentiments) {
      if (Object.keys(this.state.summarySentiments["public"].sentimentHistogram).length > 0)
        teamSentiment.push(<TeamSentimentChart key="team-public-chart" title="Public collaboration" data={this.state.summarySentiments["public"].sentimentHistogram} />);
      if (Object.keys(this.state.summarySentiments["private"].sentimentHistogram).length > 0)
        teamSentiment.push(<TeamSentimentChart key="team-private-chart" title="Private and direct collaboration" data={this.state.summarySentiments["private"].sentimentHistogram} />);
    }

    return (
      <Fragment>
        <FilterBar teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
            <div>
              <Grid container spacing={4}>
                {teamSentiment.map((i, index) => <Grid key={"sentiment-grid-" + index} item xs={12} lg={teamSentiment.length===2?6:4}> {i} </Grid>)}
              </Grid>
              <ChannelTable channelData={this.state.channelSentiments} queryFilters={this.state.queryFilters} />
            </div>
      </Fragment>
    );
  } // end of render()
} // end of class

export default SentimentOverview
