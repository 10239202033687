import React, { Component, Fragment } from 'react';

//import { PageTitle } from '../../../layout-components';
import { Tooltip } from '@material-ui/core';
import { ExampleWrapperSeamless } from '../../../layout-components';
import Loading from '../../../flowtrace-components/Loading'


import { API } from 'aws-amplify';
import { Logger } from 'aws-amplify';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const logger = new Logger('admin', 'DEBUG');

class AdminClientAccountMembers extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true }
  }

  async loadClientAccountMembers() {
    const flowtraceAPI = 'slackintegration';
    let clientAccountMemberListURL = '/members/clientAccountMergedMembers/';
    if (this.props.match.params.account_id) clientAccountMemberListURL += this.props.match.params.account_id
    const callHeaders = { headers: {}, response: true };

    logger.debug(this.props.match.params.account_id)
    let clientAccounts = await API.get(flowtraceAPI, clientAccountMemberListURL, callHeaders).then((response, data, body) => {
      if (!response && !response.data && !response.data.result) logger.debug("Failed to fetch data")
      logger.debug(clientAccountMemberListURL, response)
      return response.data.result;
    }).catch(error => { logger.error(error); });

    return clientAccounts;
  }

  componentDidMount() {
    this.setState({ loading: 'true' });
    this.loadClientAccountMembers().then(members => {
      this.setState({ clientAccountMembers: members, loading: 'false' });

    });
  }


  render() {
    if (this.state.loading === 'initial') {
      return <h2>Intializing...</h2>;
    }
    if (this.state.loading === 'true') {
      return <Loading/>;
    }
    if (this.state.loading === 'false' && this.state.clientAccountMembers) {

      return (
        <Fragment>
          <ExampleWrapperSeamless hidden="true" sectionHeading="Flowtrace client account members">
           <MaterialTable
              columns={[
                { title: "Name", field:"name", defaultSort:"asc"  },
                { title: "Email", field:"email" },
                { title: "Users", field:"user", render: (
                  rowData => {
                    const list = []
                    for(const user of rowData.user){
                      let badgeColor = "badge badge-darkest-success" // Default to dark green (for master badge)
                      if (user.merge && user.merge ==="email") badgeColor = "badge badge-dark-success" // Automatic as a green
                      if (user.merge && user.merge ==="manual") badgeColor = "badge badge-darkest-warning"
                      list.push(
                      <Tooltip key={user.id+"badge"} arrow title={user.id + ":" + user.account} placement="bottom">
                        <div key={"property-"+user.id} className="text-left pr-2 align-items-center" style={{float:'left'}}>
                          <div className="align-items-center">
                          <span className={""} >{user.type} </span>
                          </div>
                          <span className={badgeColor + " mb-1 "} >{user.merge?user.merge:"Master"} </span>
                        </div>
                      </Tooltip>
                      )
                    }
                    return (list)
                  }

                )},
                { title: "Timezone", field:"timeZone"},
                { title: "Deleted", field:"is_deleted"},
                { title: "Team", field:"orgUnitPath"}


              ]}
              data={this.state.clientAccountMembers.filter(m => !m.is_bot)}
              icons={tableIcons}
              title="Client Accounts"
              options={
                {
                pageSize:25,
                pageSizeOptions:[25,50,100],
                }
              }
            />
          </ExampleWrapperSeamless>
        </Fragment>
      );
    }

    return <Loading/>; // Just in case
  }
}

export default AdminClientAccountMembers;
