import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import moment from 'moment';

const highlightSuccess = "#1bc943"
const highlightDanger = "#f83245"
const highlightNeutral = "#7a7b97"

class GenericBarChart extends Component {
  constructor(props) {
    super(props);
    const series = []
    const categories = props.chartdata.categories.map(i => i.cat)

    const highlightColors = []
    const seriesTotal = props.chartdata.categories.reduce((a, c) => a += c.data, 0)
    for (const category of props.chartdata.categories) {
      series.push({ name: category.cat, data: [category.data] })

      let color = highlightNeutral
      // Use comparison value to deal with absolute and percentage comparisons the same way:
      const comparisonValue = props.chartdata.type === "100%" ? category.data / seriesTotal * 100 : category.data
      const op = category.operator
      const high = category.high
      const low = category.low
      if (op === ">" && high && high > comparisonValue) color = highlightSuccess
      if (op === ">" && low && low < comparisonValue) color = highlightDanger
      if (op === "<" && high && high < comparisonValue) color = highlightSuccess
      if (op === "<" && low && low > comparisonValue) color = highlightDanger
      //console.log(category.cat, comparisonValue, high, low, op)
      highlightColors.push(color)
    }
    const defaultColors = props.chartdata.categories.map(i => i.color)

    this.state = { loading: false, title: props.chartdata.title, defaultColors, highlightColors, series, categories, type: props.chartdata.type, unit: props.chartdata.unit };
  }



  async componentDidMount() {

  }


  render() {

    if (this.state.loading)
      return <div/>

    const barChartConfig = {
      chart: {
        animations: { enabled: false },
        stacked: true,
        height: "100%",
        // Stacking type is normal, unless its 100% stacked
        stackType: this.state.type ? this.state.type : null,
        toolbar: { show: false }
      },
      dataLabels: { enabled: false },
      xaxis: { type: "string", labels: { show: false }, crosshairs: { width: 1 }, categories: [""] },
      yaxis: {
        // Use hours as a value in case minutes is passed
        labels: (this.state.unit ? { formatter: function(val) {
          if (val >= 10080) // over 7 days (i.e. HubSpot deals)
              return Math.round(moment.duration(val, 'minutes').asDays()) + " d"
          else if (val >= 1440) // 24 hours
              return Math.floor(val / 1440).toFixed(0) + "d " + Math.floor(val % 1440 / 60).toFixed(0) + "h"
          else if (val >= 60)
              return Math.floor(val / 60).toFixed(0) + "h " + (val % 60).toFixed(0) + "m"
          else
              return (val % 60).toFixed(0) + " min" || "-"
        }} : {}),
        // Don't force for 100% stacked chart as otherwise it turns out to be not 100%
        forceNiceScale: this.state.type && this.state.type === "100%" ? false : true
      },
      // Format 100% type tooltop style to: "5% (value)"
      tooltip: this.state.type && this.state.type === "100%" ? { y: { formatter: function(value, opts) { const val = opts.w.globals.seriesPercent[opts.seriesIndex][opts.dataPointIndex]; return val.toFixed(0) + '% (' + value + ")" } } } : {},
      legend: { position: "left", show: true, width: 150 },
      markers: { size: 3, strokeColors: '#fff', strokeWidth: 1, shape: "circle", radius: 2, }
    };

    if(this.props.benchmark && this.state.highlightColors)
      barChartConfig.colors = this.state.highlightColors;
    else if(this.state.defaultColors && this.state.defaultColors.reduce((acc, cur) => {return cur||acc?true:false},false))
      barChartConfig.colors = this.state.defaultColors;

    return (
      <Fragment >
              <span className="pl-3 d-block font-weight-bold">{this.state.title}</span>
              <Chart options={barChartConfig} series={this.state.series} type="bar" height="300"/>
        </Fragment>
    ); // end of return
  } // end of render()
} // end of class

export default GenericBarChart;
