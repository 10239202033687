import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StatsCard from '../../Common/Cards/productivityStatsCard';
import extract from '../dataPointExtractor';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(1), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function TeamStatisticsChart(data) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const series = {
    mt: [], // meetings total
    mi: [], // meetings internal
    me: [], // meetings external
    mmt: [], // meeting multitasking
    o2o: [], // meetings one to one
    organizedLate: [], // average how much late from organized meetings
    attendedLate: [], // average how much late from attended meetings
    totalLate: [], // average how much late from meetings
    meetingCount: [], // total number of meetings found for the team
    trackedCount: [], // total number of meetings with video call found for the team

  };
  const categories = data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.date });

  for (const team of data.teamStats) {
    const teamData = {
      mt: [], // meetings total
      mi: [], // meetings internal
      me: [], // meetings external
      mmt: [], // meeting multitasking
      o2o: [], // meetings one to one
      organizedLate: [], // average how much late from organized meetings
      attendedLate: [], // average how much late from attended meetings
      totalLate: [], // average how much late from meetings
      meetingCount: [], // total number of meetings found for the team
      //trackedCount: [], // total number of meetings with video call found for the team

    };
    for (const cat of categories) {
      // Loop every category through so the stats are aligned
      const dataPoint = team.stats.find(stat => stat.date === cat)
      const data = extract.extractData(dataPoint);
      teamData.mt.push(data.totalMeeting);
      teamData.mi.push(data.internalMeeting);
      teamData.me.push(data.externalMeeting);
      teamData.mmt.push(data.meetingMultitasking);
      teamData.o2o.push(data.one2oneMeeting);
      teamData.organizedLate.push(data.organizedLate);
      teamData.attendedLate.push(data.attendedLate);
      teamData.totalLate.push(data.totalLate);
      teamData.meetingCount.push(data.totalCount);
      //teamData.trackedCount.push(data.trackedCount);
    }
    series.mt.push({ name: team.team, type: "line", data: teamData.mt })
    series.mi.push({ name: team.team, type: "line", data: teamData.mi })
    series.me.push({ name: team.team, type: "line", data: teamData.me })
    series.mmt.push({ name: team.team, type: "line", data: teamData.mmt })
    series.o2o.push({ name: team.team, type: "line", data: teamData.o2o })
    series.organizedLate.push({ name: team.team, type: "line", data: teamData.organizedLate })
    series.attendedLate.push({ name: team.team, type: "line", data: teamData.attendedLate })
    series.totalLate.push({ name: team.team, type: "line", data: teamData.totalLate })
    series.meetingCount.push({ name: team.team, type: "line", data: teamData.meetingCount })
    //series.trackedCount.push({ name: team.team, type: "line", data: teamData.trackedCount })
  }

  const options = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories },
    yaxis: { forceNiceScale: true },
    legend: { position: "right", show: true },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };
  const hourFormatter = (val) => { return val ? (Math.abs(val) / 60).toFixed(1) + "h" : 0 };
  const minuteFormatter = (val) => { return val ? (Math.abs(val)).toFixed(1) + " min" : 0 };
  const hourChartOptions = { ...options, yaxis: {...options.yaxis, labels: { formatter: hourFormatter } }};
  const minuteChartOptions = { ...options, yaxis: {...options.yaxis, labels: { formatter: minuteFormatter } }};
  const last = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[1]);
  const previous = extract.extractData(data.company.sort((a, b) => (a.date > b.date) ? 1 : -1).reverse()[2]);

  const mi = { value: last.internalMeeting, previous: previous.internalMeeting };
  const me = { value: last.externalMeeting, previous: previous.externalMeeting };
  const mmt = { value: last.meetingMultitasking, previous: previous.meetingMultitasking };
  const oLate = { value: last.organizedLate, previous: previous.organizedLate };
  const aLate = { value: last.attendedLate, previous: previous.attendedLate };
  const tLate = { value: last.totalLate, previous: previous.totalLate };

  return (
    <Card className="card-box m-3">
      <CardContent className="p-3">
        <div>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="mi" componentColor="bg-brand-slack" data={mi} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="me" componentColor="bg-primary" data={me}period={data.timeSpan} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="mmt" componentColor="bg-plum-plate" data={mmt} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="tLate" componentColor="bg-dark" data={tLate} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="oLate" componentColor="bg-warning" data={oLate} period={data.timeSpan}/>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="aLate" componentColor="bg-brand-facebook" data={aLate} period={data.timeSpan}/>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
            <div className={classes.themeColorTabs}>

              <StyledTabs value={value} onChange={handleChange} aria-label="Team breakdown" variant="scrollable" scrollButtons="auto">
                <StyledTab label="Meeting (time)" />
                <StyledTab label="Internal (time)" />
                <StyledTab label="External (time)" />
                <StyledTab label="One to One (time)" />
                <StyledTab label="Multitasking (time)" />
                <StyledTab label="Late (Total)" />
                <StyledTab label="Late (Organized)" />
                <StyledTab label="Late (Attended)" />
              </StyledTabs>
            </div>
            <TabPanel value={value} index={0}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdown of total meeting time (hours / person / {data.timeSpan}) </span>
                  <Chart options={hourChartOptions} series={series.mt} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdown of internal meetings (hours / person / {data.timeSpan}) </span>
                  <Chart options={hourChartOptions} series={series.mi} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdown of external meetings (hours / person / {data.timeSpan}) </span>
                  <Chart options={hourChartOptions} series={series.me} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdown of 1:1 meetings (hours / person / {data.timeSpan}) </span>
                  <Chart options={hourChartOptions} series={series.o2o} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team breakdown of meeting multitasking (hours / person / {data.timeSpan}) </span>
                  <Chart options={hourChartOptions} series={series.mmt} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team's average meeting punctuality (minutes / person / {data.timeSpan}) </span>
                  <Chart options={minuteChartOptions} series={series.totalLate} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team's average organized meeting punctuality (minutes / person / {data.timeSpan}) </span>
                  <Chart options={minuteChartOptions} series={series.organizedLate} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={7}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <span className="text-black-50 d-block">Here you see team's average attended meeting punctuality (minutes / person / {data.timeSpan}) </span>
                  <Chart options={minuteChartOptions} series={series.attendedLate} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
        </div>
      </CardContent>
    </Card>
  );


}
