import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
//import {AccountSettings, AccountUsers, AccountMembers} from '../../../flowtrace-components/Configuration/AccountManagement';
import AccountUsers from '../../../flowtrace-components/Configuration/AccountManagement/AccountUsers';
import AccountMembersForUsers from '../../../flowtrace-components/Configuration/AccountManagement/AccountMembersForUsers';

import Loading from '../../../flowtrace-components/Loading'
import { API } from 'aws-amplify';

class AccountManagement extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true }
  }

  async getAccountDetails() {
    const flowtraceAPI = 'slackintegration';
    const path = '/oauth2/install/current';
    const payload = { headers: {}, response: true };

    return await API.get(flowtraceAPI, path, payload).then((response, data, body) => {
      const permissions = response.data.permissions.reduce((acc, i) => {
        acc[Object.keys(i)[0]] = Object.values(i)[0]
        return acc
      }, {});

      // Does Slack installation contain the right scope?
      if (permissions.slack_surveys)
        return true

      else return false
    }).catch(error => {
      console.error(error, error.message);
      return false

    });
  }

  async reloadSettings() {
    if (!this.state.loading)
      this.setState({ loading: true });
    this.setState({ account: await this.getAccountDetails(), loading: false });
  }

  componentDidMount() {
    this.reloadSettings()
  }


  render() {
    if (this.state.loading) {
      return <Loading/>;
    }

    return (
      <Fragment>
          <PageTitle titleHeading="Flowtrace Users and Insights Team Structure" titleDescription="Manage your Flowtrace users, and integration source user list." />
          <AccountUsers />
          <AccountMembersForUsers flowtraceUser={this.props.flowtraceUser}/>
        </Fragment>
    );
  }
}

export default AccountManagement;
