import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { API, Cache } from 'aws-amplify';
import Loading from '../Loading';
import { Button, Card, CardContent, Grid } from '@material-ui/core';
import { ExampleWrapperSeamless } from '../../layout-components';
import moment from 'moment';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

class Processing extends Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, joinedChannels: false, complete: false, processResults: [] };
  }

  infograph() {
    const gridItems = [];
    gridItems.push(<Grid key="c0" item xs={4}><strong>Date</strong></Grid>);
    gridItems.push(<Grid key="c1" item xs={2}><strong>Complete</strong></Grid>);
    gridItems.push(<Grid key="c3" item xs={2}><strong>Insights</strong></Grid>);
    gridItems.push(<Grid key="c2" item xs={4}><strong>Processed</strong></Grid>);

    gridItems.push(<Grid key="c0-joining" item xs={4}>Public Channels{this.state.joinedChannels?"":" (Joining)"}</Grid>);
    gridItems.push(<Grid key="c1-joining" item xs={2}>{(this.state.joinedChannels==="error"?<ErrorOutlineIcon/>:(this.state.joinedChannels==="complete"?<CheckCircleOutlineIcon/>:<RadioButtonUncheckedIcon/>))}</Grid>);
    gridItems.push(<Grid key="c3-joining" item xs={2}>{(this.state.joinedChannels==="error"?<ErrorOutlineIcon/>:(this.state.joinedChannels==="complete"?<CheckCircleOutlineIcon/>:<RadioButtonUncheckedIcon/>))}</Grid>);
    gridItems.push(<Grid key="c2-joining" item xs={4}>{this.state.foundChannels}</Grid>);

    for (const i of this.state.processResults) {
      gridItems.push(<Grid  key={"c0-"+i.date} item xs={4}>{moment(i.date).format("LL")}</Grid>);
      gridItems.push(<Grid key={"c1-"+i.date} item xs={2}>{(i.fetch==="error"?<ErrorOutlineIcon/>:(i.fetch==="complete"?<CheckCircleOutlineIcon/>:<RadioButtonUncheckedIcon/>))}</Grid>);
      gridItems.push(<Grid key={"c3-"+i.date} item xs={2}>{(i.stats==="error"?<ErrorOutlineIcon/>:(i.stats==="complete"?<CheckCircleOutlineIcon/>:<RadioButtonUncheckedIcon/>))}</Grid>);
      gridItems.push(<Grid key={"c2-"+i.date} item xs={4}>{i.fetchCount}</Grid>);
    }

    return (
      <MuiAlert className="mb-4" severity={this.state.processing?"warning":"info"} style={{minWidth:"100%", maxWidth:"800px"}}>
        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" >
          {gridItems}
        </Grid>
        {this.state.complete?
        <Grid container spacing={0} direction="row" justifyContent="center" alignItems="center" >
          <br/><br/><strong>Click 'Welcome to Flowtrace' to get started with your insights!</strong>
        </Grid>
        :null}
      </MuiAlert>
    );
  }

  async enableAutoJoin(processResults, channels) {
    const name = 'slackintegration';
    const path = '/slack/api/config/autojoin/true';
    try {
      await API.get(name, path);
    }
    catch (e) {
    }
  }

  async backfill(processResults, channels) {
    const name = 'slackintegration';
    const path = '/slack/api/backfill/';

    const init = { headers: { "Content-Type": "application/json" }, response: true, body: { channels:(channels?channels:null) } };
    Cache.setItem("processingState", { processing: true, processResults });
    this.setState({ processing: true, processResults });

    try {
      // This should try to get messages in future, and only join to the channels
      const { data } = await API.post(name, path + "fetch/" + moment().add(1, 'days').format("YYYY-MM-DD"), init);
      this.setState({ joinedChannels: "complete", foundChannels: data.processed.processedChannels });
    }
    catch (e) {
      this.setState({ joinedChannels: "error", foundChannels: "N/A" });
    }

    // second, let's fetch all the data
    for (const processingRecord of processResults) {
      // If this is a page refresh, make sure we don't trigger new fetches for already happening ones
      if (processingRecord.fetch === "complete" || processingRecord.fetch === "error")
        continue;
      try {
        let result = { data: { processed: { messages: "Moved to background..." } } };
        result = await API.post(name, path + "fetch/" + processingRecord.date, init);
        processingRecord.fetch = "complete";
        processingRecord.fetchCount = result.data.processed.messages;
      }
      catch (e) {
        processingRecord.fetch = "error";
      }
      Cache.setItem("processingState", { processing: true, processResults });
      this.setState({ processResults });
    }
    // Then, let's run user statistics calculation for the dates
    for (const processingRecord of processResults) {

      // In case of a refresh: pass complete, and recalculate errors by emptying the status and recalculate
      if (processingRecord.stats === "complete")
        continue;
      if (processingRecord.stats === "error") {
        processingRecord.stats = "";
        Cache.setItem("processingState", { processing: true, processResults });
        this.setState({ processResults });
      }

      try {
        await API.post(name, path + "calculate/" + processingRecord.date, init);
        processingRecord.stats = "complete";
      }
      catch (e) {
        processingRecord.stats = "error";
      }
      Cache.setItem("processingState", { processing: true, processResults });
      this.setState({ processResults });
    }

    Cache.setItem("processingState", { processing: false, processResults, complete: true });
    return this.setState({ processing: false, complete: true });
  }


  async componentDidMount() {
    const processOngoing = Cache.getItem("processingState");

    let channelsToProcess = this.props.settings.selectedChannels;
    if(this.props.settings.joinAll && this.props.settings.installationInfo.channels){
      channelsToProcess = this.props.settings.installationInfo.channels.filter(i => !i.is_private && i.is_channel && !i.is_archived).map(c => c.id);
    }
    // If processing is ongoing or complete, carry the processing so it can be rendered correct way (just empty the message numbers / error messages)
    if (processOngoing && (processOngoing.processing || processOngoing.complete))
      return await this.backfill(processOngoing.processResults.map(i => { return { ...i, fetchCount: "" } }), channelsToProcess);

    // Set the configuration to backend if it's enabled
    if(this.props.settings.autoJoin)
      await this.enableAutoJoin();

    // Let's create the processing array
    const startDate = moment().subtract(this.props.settings.backfill - 1, 'days').format("YYYY-MM-DD");
    // First, let's fetch all the data
    const processingResults = [];
    for (let i = 0; i < this.props.settings.backfill; i++) {
      processingResults.push({ date: moment(startDate).add(i, 'days').format("YYYY-MM-DD"), fetch: null, stats: null });
    }
    await this.backfill(processingResults, channelsToProcess);
  }

  async completeRedirect() {
    // If this is a trial account, retrigger the user fetching so the names are available when user gets to the account
    if(this.props.settings.accountType === "trial")
      try { await API.get("slackintegration", "/slack/api/users"); } catch (e) { }
    this.props.history.push("/Dashboard");
    this.props.onUpdate("onboardingComplete");
  }


  render() {

    return (
      <Fragment>
        <ExampleWrapperSeamless hidden="true" sectionHeading={this.state.processing?"Preparing your account...":"Your account is ready!"}>
          <Card className="card-box d-flex" style={{width:"100%"}}>
            <CardContent style={{width:"100%"}}>
              <Grid container spacing={0} direction="column" justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={12}>
                  <h5>{this.state.processing?"Please wait while we prepare your account and crunch your data...":"Are you ready for the future of work?"}</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {this.infograph()}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {this.state.processing?<Loading minimize/>:null}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  {this.state.complete?<Button color="primary" size="medium" variant="contained" className="mr-3" onClick={() => this.completeRedirect()}>Welcome to Flowtrace</Button>:null}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </ExampleWrapperSeamless>
      </Fragment>
    );
  }
}

export default withRouter(Processing);
