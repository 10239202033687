import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Chart from 'react-apexcharts';

export default function HourlyChart(props) {
  const componentType = props.type;
  const data = props[props.type.toLowerCase()];
  const positiveItem = data.reduce((acc, cur) => { return (acc.ratio < (cur.positive / cur.count) && cur.positive / cur.count < 1 ? { ratio: cur.positive / cur.count, name: cur.name ? cur.name : cur.id } : acc) }, { ratio: 0 });
  const negativeItem = data.reduce((acc, cur) => { return (acc.ratio < (cur.negative / cur.count) && cur.negative / cur.count < 1 ? { ratio: cur.negative / cur.count, name: cur.name ? cur.name : cur.id } : acc) }, { ratio: 0 });
  const max = Math.max(...data.map(c => c.count));

  const chartOptions = {
    chart: { toolbar: { show: false }, animations: { enabled: false } },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '28px',
      },
      formatter: function(text, op) {
        return [text];
      },
      offsetY: -4
    },
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.2,
        reverseNegativeShade: true,
        colorScale: {
          ranges: [{
            from: 5,
            to: max,
            color: '#37a141'
          }, {
            from: (max * -1),
            to: -5,
            color: '#F44336'
          }, {
            from: -30,
            to: 30,
            color: '#86bbf0'
          }]
        }
      }
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return Math.abs(value) + " messages";
        }
      },
      z: {
        formatter: function([positive, negative], op) {
          return positive + "% positive, " + negative + "% negative";
        },
        title: "Sentiment:"
      }
    },
  };
  const calculateValue = (count, positive, negative) => {
    const negRatio = negative > 0 ? negative / count : 0;
    const posRatio = positive > 0 ? positive / count : 0;
    return (posRatio >= negRatio ? count : count * -1);
  };
  const seriesData = data.map(i => { return { x: i.name ? "#" + i.name : i.id, y: calculateValue(i.count, i.positive, i.negative), z: [parseInt(i.positive / i.count * 100), parseInt(i.negative / i.count * 100)] } });
  const series = [{
    name: componentType,
    data: seriesData
  }];
  return (
    <Fragment>
      <Card className="card-box ">
        <CardContent className="p-2 m-2">
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <div className="d-flex pl-3">
                {componentType==="Topic"?
                  <FontAwesomeIcon icon={['far', 'newspaper']} className="font-size-xxl text-warning"/>
                :
                  <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xxl text-info" />
                }
                <b className="pl-3 font-size-lg">Slack {componentType.toLowerCase() } activity: { data.length }</b>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="text-center">
                <div className="mt-3 line-height-sm">
                  <span className="pb-2 text-black-50 d-block">Most positive {componentType.toLowerCase()}</span>
                  <b className="font-size-md">{ positiveItem? positiveItem.name:"-" }</b>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="text-center">
                <div className="mt-3 line-height-sm">
                  <span className="pb-2 text-black-50 d-block">Most negative {componentType.toLowerCase()}</span>
                  <b className="font-size-md">{ negativeItem? negativeItem.name:"-" }</b>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Chart options={chartOptions} series={series} type="treemap" height="200"/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
