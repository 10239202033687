import React, { Component, Fragment } from 'react';
import { Tooltip, Card, CardContent, Checkbox, FormControlLabel } from '@material-ui/core';
import RollingTimeOptions from './rollingTimeOptions';
import TeamFilterOptions from './teamFilterOptions';
import MeetingOptions from './meetingOptions';
import CalendarTimeOptions from './calendarTimeOptions';
import PeriodOptions from './periodOptions';
import MuiAlert from '@material-ui/lab/Alert';
import Loading from '../../Loading';
import moment from 'moment';
import PremiumFeature from './premiumFeature';

const createAlert = (type, message) => {
    if(message && message.text && message.tooltip)
        return (
        <div style={{float:'left', marginLeft:"5px", marginTop:"7px"}}>
            <Tooltip title={message.tooltip} >
                <MuiAlert key="alertMessage" severity={type} className="pt-0 mt-0 mb-0 pb-0">
                    {message.text}
                </MuiAlert>
            </Tooltip>
        </div>
    );
    return (
        <div style={{float:'left', marginLeft:"5px", marginTop:"7px"}}>
            <MuiAlert key="alertMessage" severity={type} className="pt-0 mt-0 mb-0 pb-0">
                { message.text ? message.text : message }
            </MuiAlert>
        </div>
    );
};

class FilterBar extends Component {
    constructor(props) {
        super(props);
        const tier = props.flowtraceUser && props.flowtraceUser.account && props.flowtraceUser.account.props ? props.flowtraceUser.account.props.type : "free";
        this.state = { loading: true, tier, benchmark: true, selection: { ...props.queryFilters } };
    }


    async componentDidMount() {
        this.setState({ loading: false });
    }

    onLoading(loading) {this.setState({loading})}

    onChange(selection) {
        let queryFilters = { ...this.state.selection }
        // Handle this special case so it doesn't pollute queryFilters
        if (selection.component !== "benchmark")
            queryFilters = { ...queryFilters, ...selection };

        // Check for following values for null to remove them:
        for(const qf of Object.keys(queryFilters))
            if(queryFilters[qf] === null)
                delete queryFilters[qf];

        const warnings = [];
        // Do checkups for freemium tier
        //console.log(this.state.tier)
        if(this.state.tier === "free") {
            // First, make sure there is no team filter:
            if(queryFilters.teamFilter) {
                delete queryFilters.teamFilter;
                warnings.push("team filter");
            }
            if(queryFilters.range_start && moment(queryFilters.range_start).diff(moment(), 'days') < -28) {
                queryFilters.range_start = moment().subtract(28, 'days').format("YYYY-MM-DD");
                warnings.push("start date");
            }
            if(queryFilters.range_end && moment(queryFilters.range_end).diff(moment(), 'days') < -28) {
                queryFilters.range_end = moment().format("YYYY-MM-DD");
                warnings.push("end date");
            }
            if(queryFilters.range_type === "rolling" && queryFilters.range_period && queryFilters.range_limit && (queryFilters.range_period * queryFilters.range_limit > 28)) {
                queryFilters.range_start = moment().subtract(28, 'days').format("YYYY-MM-DD");
                warnings.push("start date");
            }
            console.log(queryFilters)
            if(queryFilters.range_type === "calendar" && queryFilters.range_period && !queryFilters.range_start) {
                let periodMultiplier = 1;
                if(queryFilters.range_period === "week") periodMultiplier= 7;
                if(queryFilters.range_period === "month") periodMultiplier= 30;
                if(queryFilters.range_period === "quarter") periodMultiplier= 90;
                if(queryFilters.range_period === "year") periodMultiplier= 365;
                const limiter = queryFilters.range_limit ? queryFilters.range_limit : 12;

                if(periodMultiplier * limiter > 28){
                    warnings.push("start date");
                    queryFilters.range_start = moment().subtract(28, 'days').format("YYYY-MM-DD");
                }
            }
        }
        // Do checkups for freemium tier
        if(this.state.tier === "team") {
            const dayLimit = moment().subtract(365, 'days').format("YYYY-MM-DD");
            if(queryFilters.range_start && moment(queryFilters.range_start).diff(moment(), 'days') < -365) {
                queryFilters.range_start = dayLimit;
                warnings.push("start date");
            }
            if(queryFilters.range_end && moment(queryFilters.range_end).diff(moment(), 'days') < -365) {
                queryFilters.range_end = moment().format("YYYY-MM-DD");
                warnings.push("end date");
            }
            if(queryFilters.range_type === "rolling" && queryFilters.range_period && queryFilters.range_limit && (queryFilters.range_period * queryFilters.range_limit > 360)) {
                queryFilters.range_start = dayLimit;
                warnings.push("start date");
            }
            if(queryFilters.range_type === "calendar" && queryFilters.range_period && !queryFilters.range_start) {
                let periodMultiplier = 1;
                if(queryFilters.range_period === "week") periodMultiplier= 7;
                if(queryFilters.range_period === "month") periodMultiplier= 30;
                if(queryFilters.range_period === "quarter") periodMultiplier= 90;
                if(queryFilters.range_period === "year") periodMultiplier= 365;

                if(periodMultiplier * queryFilters.range_limit > 365){
                    warnings.push("start date");
                    queryFilters.range_start = moment().subtract(365, 'days').format("YYYY-MM-DD");
                }
            }
        }
        if(warnings.length)
            this.setState({premiumWarning:"Search results limited by: " + warnings.reduce((acc,cur,index) => acc += index < warnings.length ? cur + ", " : "and " + cur, "").replace(/,\s*$/, "")});
        else
            this.setState({premiumWarning:null});

        console.log(this.state.tier, "check:", queryFilters);
        this.props.onChange(queryFilters, selection.component === "benchmark" ? selection.benchmark : this.state.benchmark, selection.component !== "benchmark");
        this.setState({ selection: queryFilters, benchmark: selection.component === "benchmark" ? selection.benchmark : this.state.benchmark });
    }
    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = () => { return; };
    }

    render() {
        const loading = this.state.loading || this.props.loading;
        const loadingComponent = <div style={{float:'left', marginTop:"10px", marginLeft:"15px"}}><Loading tiny/></div>;
        const benchmarkComponent = <div style={{float:'left', marginBottom:"5px", marginTop:"5px", marginLeft:"15px"}}><FormControlLabel color="secondary" control={<Checkbox disabled={loading} checked={this.state.benchmark} onClick={() => {this.onChange({component:"benchmark", benchmark:!this.state.benchmark})}} name="benchmark" />} label="Highlight potential risks/success" /></div>;
        const rollingTimeOptionsComponent = <RollingTimeOptions tier={this.state.tier} loading={loading} defaults={this.state.selection} onChange={this.onChange.bind(this)}/>;
        const calendarTimeOptionsComponent = <CalendarTimeOptions tier={this.state.tier} loading={loading} fixedCalendarDates={this.props.fixedCalendarDates} defaults={this.state.selection} onChange={this.onChange.bind(this)}/>;
        const teamFilterOptionsComponent = <TeamFilterOptions tier={this.state.tier} loading={loading} onLoading={this.onLoading.bind(this)} defaults={this.state.selection} flowtraceUser={ this.props.flowtraceUser } onChange={this.onChange.bind(this)}/>;
        const meetingOptionsComponent = <MeetingOptions tier={this.state.tier} loading={loading} onChange={this.onChange.bind(this)}/>;
        const periodOptionsComponent = <PeriodOptions tier={this.state.tier} loading={loading} defaults={this.state.selection} onChange={this.onChange.bind(this)}/>;
        return (
            <Fragment>
                <Card className="card-box mb-4">
                    <CardContent className="p-0">
                        <div style={{marginTop:"10px",marginBottom:"10px"}}>
                            <div>
                                <span style={{float:'left', marginLeft:"20px", marginRight:"25px", paddingTop:"10px"}} className="font-weight-bold font-size-xl" >Filters:</span>
                                { this.props.queryFilters.range_type==="rolling" ? rollingTimeOptionsComponent : null }
                                { this.props.queryFilters.range_type==="calendar" ? calendarTimeOptionsComponent: null }
                                { !this.props.hidePeriod && (this.props.queryFilters.range_type==="calendar" || this.props.queryFilters.range_period) ? periodOptionsComponent: null }
                                { this.props.teamFilter ? teamFilterOptionsComponent : null }
                                { this.props.meetingFilter ? meetingOptionsComponent : null }
                                { this.props.benchmarking ? benchmarkComponent : null }
                                { this.props.injectedComponent ? this.props.injectedComponent : null }
                                { loading ? loadingComponent : null }
                                { this.props.searchError ? createAlert("error", this.props.searchError) : null }
                                { this.props.searchWarning ? createAlert("warning", this.props.searchWarning) : null }
                                { this.state.premiumWarning ? <span style={{float:'left', marginLeft:"20px", marginRight:"25px", paddingTop:"7px"}}><PremiumFeature tooltip="Current filters not supported on your plan." text={this.state.premiumWarning} /></span> : null }
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Fragment>
        );

    }
} // end of class

export default FilterBar;
