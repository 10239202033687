import React, { Fragment, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Popover } from '@material-ui/core';

function PageTitle(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickPopover = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const popupOpen = Boolean(anchorEl);

  const explanation = []
  if (props.explanation) {
    explanation.push(<span key="popover"  onClick={handleClickPopover}> <FontAwesomeIcon icon={['fa', 'question-circle']} /> </span>)
    explanation.push(<Popover key="popup" open={popupOpen} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }}> <Box className="p-4"> {props.explanation} </Box> </Popover>)
  }

  useEffect(() => {
    document.title = "Flowtrace - " + props.titleHeading || "";
  }, [props.titleHeading]);
  return (
    <Fragment>
      <Paper square elevation={2} className="app-page-title">
        <div>
          <div className="app-page-title--first">
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              <div className="app-page-title--description">
                {props.titleDescription}
                {props.explanation ? explanation : null}
                {props.callToAction ? props.callToAction : null}
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
}

export default PageTitle;
