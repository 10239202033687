import React, { Fragment } from 'react';
import NetworkOverview from '../../flowtrace-components/Collaboration/NetworkOverview';



export default function Dashboard(props) {



  return (
    <Fragment >

      <NetworkOverview  flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
