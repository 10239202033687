import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import TeamChart from './teamChart';
import RelationshipCharts from './relationshipCharts';
import Loading from '../Loading'

// This component loads 1 or more teams worth of data, and shows statistics about the team or relationship between the teams
class NetworkTeamDetails extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, timeSpan: "month", dialogTeams: props.dialogTeams, dialogType: props.dialogType };
  }

  async loadRelationshipStatistics(timeSpan, teamFilter = null) {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/relationshipStatistics';
    const teamAPI = { headers: {}, response: true, queryStringParameters: { timeSpan: this.state.timeSpan, teams: this.state.dialogTeams } };

    const relationshipData = await API.get(dataAPI, statisticsPath, teamAPI).then((response, data, body) => {
      return { relationshipData: response.data.relationshipData, networkData: response.data.networkData, teamFilters: response.data.teamFilters };
    }).catch(error => {
      console.error(error);
      return null
    });

    return relationshipData;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.loadRelationshipStatistics(this.state.timeSpan).then((data) => {
      if (data) {
        this.setState({ teamStats: data.relationshipData, network: data.networkData, teamFilters: data.teamFilters })
      }
      this.setState({ loading: false });
    });
  }

  render() {
    if (this.state.loading) {
      return <Loading/>;
    }

    // Create team grid for 1, or 2 nodes:
    const teamGrid = []
    if (this.state.teamStats.length === 1)
      teamGrid.push(<Grid item xs={12} sm={12} lg={12}><TeamChart teamStats={this.state.teamStats[0]} /></Grid>)
    else {
      teamGrid.push(<Grid item sm={6} lg={6} key="team1grid"><TeamChart key="team1" teamStats={this.state.teamStats[0]} /></Grid>)
      teamGrid.push(<Grid item sm={6} lg={6} key="team2grid"><TeamChart key="team2" teamStats={this.state.teamStats[1]} /></Grid>)
      teamGrid.push(<Grid item sm={12} lg={12} key="relationshipgrid"><RelationshipCharts key="relationship" networkStats={this.state.network} teamStats={this.state.teamStats} /></Grid>)
    }



    if (!this.state.loading) {
      return (
        <Fragment>
          <Grid key = "firstGrid"item xs={12} lg={12}>
            <Grid container spacing={1} className="mt-1">
              { teamGrid }
            </Grid>
          </Grid>
        </Fragment>
      );
    }

    return <Loading/>; // Just in case return to render something
  } // end of render()

} // end of class

export default NetworkTeamDetails
