import React from 'react';
import { Graph } from "react-d3-graph";
import { useRef, useEffect, useLayoutEffect, useState } from "react";
import { FormGroup, Tooltip, FormControlLabel, Checkbox, FormControl } from '@material-ui/core';
import DetailNetworkDialog from '../../DetailNetworkDialog';

// Function to trigger resize less often
function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function createNodeStructure(networkData) {
    const returnNodes = [];
    for (const node of networkData.data.nodes) {
        const source = networkData.data.edges.find(e => e.source === node.id)
        const target = networkData.data.edges.find(e => e.target === node.id)
        if (!(source || target)) continue // Don't add a node if it doesn't appear in either source or target
        let nodeToPush = { id: node.id, label: node.label };
        if (node.type === "PERSON" || node.type === "User") {
            nodeToPush.svg = "/images/ona_icons/user_icon.svg";
            nodeToPush.color = "green";
        }
        if (node.type === "User" && node.is_bot) {
            nodeToPush.svg = "/images/ona_icons/bot_icon.svg";
            nodeToPush.color = "black";
        }
        if (node.type === "Channel") {
            nodeToPush.svg = "/images/ona_icons/channel_icon.svg";
            nodeToPush.color = "blue";
        }
        if (node.type === "Team") {
            nodeToPush.svg = "/images/ona_icons/team_icon.svg";
            nodeToPush.color = "blue";
            nodeToPush.size = 400;
        }
        if (node.type === "LOCATION") {
            nodeToPush.svg = "/images/ona_icons/map_icon.svg";
            nodeToPush.color = "red";
        }
        if (node.type === "ORGANIZATION") {
            nodeToPush.svg = "/images/ona_icons/company_icon.svg";
            nodeToPush.color = "yellow";
        }
        if (node.type === "TITLE") {
            nodeToPush.svg = "/images/ona_icons/topic_icon.svg";
            nodeToPush.color = "orange";
            nodeToPush.size = 200;
        }
        returnNodes.push(nodeToPush);
    }
    return returnNodes;
}

function createNeutralLinkStructure(networkData) {
    const returnLinks = [];
    let maxValue = 0; // Get max value of the count
    for (const edge of networkData.data.edges)
        if (maxValue <= edge.count)
            maxValue = edge.count;

    for (const edge of networkData.data.edges) {
        let edgeToPush = {
            source: edge.source,
            target: edge.target,
            strokeWidth: 1 + (edge.count / maxValue * 10),
        };
        //#474747 = darkish grey
        //#4d4d4d = lighter
        //#575757 = lightest

        edgeToPush.stroke = "black";
        edgeToPush.color = "#575757"; // Make them darker depending on the value
        if (edge.count / maxValue > 0.5) edgeToPush.color = "#4d4d4d";
        if (edge.count / maxValue > 0.66) edgeToPush.color = "#474747";

        returnLinks.push(edgeToPush);

    }

    return returnLinks;
}

function createSentimentLinkStructure(networkData) {
    const returnLinks = [];
    let maxValue = 0; // Get max value of the count
    for (const edge of networkData.data.edges)
        if (maxValue <= edge.count)
            maxValue = edge.count;

    for (const edge of networkData.data.edges) {
        let edgeToPush = {
            source: edge.source,
            target: edge.target,
            strokeWidth: 1 + (edge.count / maxValue * 10),
        };
        let sentiment = 0;
        if (edge.positive)
            sentiment += edge.positive;
        if (edge.negative)
            sentiment -= edge.negative;

        const poor = -0.1;
        const good = 0.1;

        const sentimentRatio = sentiment / edge.slack_count;

        //console.log(sentiment, edge.positive, edge.negative, edge.slack_count, sentimentRatio);

        //#d63706 bright red - bad
        //#611e19 dark red
        //#14a3db +-0.2 blue - neutral
        //#114a1a 0.2->0.3 dark green
        //#1fa133 0.3-> bright green - great

        if (sentimentRatio <= poor) edgeToPush.color = "#d63706";
        else if (sentimentRatio >= good) edgeToPush.color = "#308a1a";
        else edgeToPush.color = "#14a3db";

        returnLinks.push(edgeToPush);
    }
    return returnLinks;
}

//#d63706 bright red - bad
//#611e19 dark red
//#db8f14 +-0.2 orange - neutral
//#114a1a 0.2->0.3 dark green
//#1fa133 0.3-> bright green - great
const sentimentExplanation = [];
sentimentExplanation.push(<div key="title" style={{height: '24px'}} ><b>Relationship sentiment</b></div>);
sentimentExplanation.push(<div key="legend1" style={{height: '24px'}} ><div style={{float:'left', marginTop:'7px', marginRight: '5px',border: '1px solid black', backgroundColor:'#d63706', width:'20px', height: '6px'}} />Negative</div>);
sentimentExplanation.push(<div key="legend2" style={{height: '24px'}} ><div style={{float:'left', marginTop:'7px', marginRight: '5px',border: '1px solid black', backgroundColor:'#14a3db', width:'20px', height: '6px'}} />Neutral</div>);
sentimentExplanation.push(<div key="legend3" style={{height: '24px'}} ><div style={{float:'left', marginTop:'7px', marginRight: '5px',border: '1px solid black', backgroundColor:'#1fa133', width:'20px', height: '6px'}} />Positive</div>);

//#474747 = darkish grey
//#4d4d4d = lighter
//#575757 = lightest
const widthExplanation = [];
widthExplanation.push(<div key="title" style={{height: '24px'}} ><b>Relationship strength</b></div>);
widthExplanation.push(<div key="legend1" style={{height: '24px'}} ><div style={{float:'left', marginRight: '5px', marginTop:'9px', backgroundColor:'#575757', height: '2px', width:'20px'}} />Infrequent</div>);
widthExplanation.push(<div key="legend2" style={{height: '24px'}} ><div style={{float:'left', marginRight: '5px', marginTop:'8px', backgroundColor:'#4d4d4d', height: '4px', width:'20px'}} />Occasional</div>);
widthExplanation.push(<div key="legend3" style={{height: '24px'}} ><div style={{float:'left', marginRight: '5px', marginTop:'7px', backgroundColor:'#474747', height: '6px', width:'20px'}} />Frequent</div>);

let storedViewSettings = {}

const BarChart = props => {

    function createNetworkDataStructure(viewSettings) {

        const data = { network: { nodes: [], links: [] }, networkType: props.networkType, viewSettings: viewSettings };
        const dataCopy = JSON.parse(JSON.stringify(props)) // Make a copy of data


        if (!data.viewSettings.showBots) // Remove bots from the data unless they need to be shown
        {
            const bots = dataCopy.data.nodes.filter(node => node.is_bot).map(node => node.id)
            dataCopy.data.edges = dataCopy.data.edges.filter(edge => !(bots.includes(edge.source) || bots.includes(edge.target)))
        }

        if (!data.viewSettings.showCollaborators) // Remove past employees from the data unless they need to be shown
        {
            const leavers = dataCopy.data.nodes.filter(node => node.deleted).map(node => node.id)
            dataCopy.data.edges = dataCopy.data.edges.filter(edge => !(leavers.includes(edge.source) || leavers.includes(edge.target)))
        }

        data.network.nodes = createNodeStructure(dataCopy);

        if (data.viewSettings.showSentiment)
            data.network.links = createSentimentLinkStructure(dataCopy);
        else
            data.network.links = createNeutralLinkStructure(dataCopy);

        if (data.viewSettings.directional) {
            chartConfig.directed = true;
            chartConfig.link.type = "CURVE_SMOOTH";
        }
        else {
            chartConfig.directed = false;
            chartConfig.link.type = "STRAIGHT";
        }

        data.chartConfig = chartConfig
        // if selected filters results into nothing to draw, let the user know of it:
        if (data.network && data.network.nodes && data.network.nodes.length === 0) {
            data.network.nodes.push({ id: 1, label: "Selected filters produce", size: 500, svg: "/images/ona_icons/topic_icon.svg" })
            data.network.nodes.push({ id: 2, label: "0 results", size: 100, })
            data.network.edges = []
            data.network.links.push({ source: 1, target: 2, strokeWidth: 3, color: "#575757" })
        }
        storedViewSettings = viewSettings
        delete data.reload
        setData(data);
    }
    const styles = { overlay: { position: 'absolute', top: '25px', left: '10px', color: 'black', } };

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({ width: window.innerWidth, height: window.innerHeight - 200 });

    if (targetRef.current && dimensions.height !== targetRef.current.offsetHeight) {
        setDimensions({
            width: targetRef.current.offsetWidth,
            //78px header, 70px footer (32 & 16 paddings)
            height: targetRef.current.offsetHeight // - 200 // - 70 - 78 - 32 - 16 // remove some padding related size from the graph
        });
    }

    const [data, setData] = useState(null);
    const [graphClickData, setGraphClickData] = useState({ node1: false, node2: false });

    const chartConfig = {
        nodeHighlightBehavior: true,
        highlightOpacity: 0.8,
        height: dimensions.height,
        width: dimensions.width,
        d3: {
            alphaTarget: 0.5,
            gravity: -1500,
            linkLength: 100,
        },
        directed: true,
        node: {
            color: "lightgreen",
            size: 240,
            fontSize: 16,
            highlightStrokeColor: "blue",
            highlightFontSize: 24,
            labelProperty: "label"
        },
        link: {
            highlightColor: "darkblue",
            semanticStrokeWidth: true,
            color: "#474747",
            type: "CURVE_SMOOTH",
            markerWidth: 2,
        },
    };

    if (props && props.data && props.data.reload) {
        delete props.data.reload // This is only done once per data load.
        if (storedViewSettings && Object.keys(storedViewSettings).length > 0)
            createNetworkDataStructure(storedViewSettings)
    }

    const changeViewSettings = name => event => {
        let viewSettings = data.viewSettings

        switch (name) {
            case "showBots":
                viewSettings.showBots = event.target.checked
                break;
            case "showCollaborators":
                viewSettings.showCollaborators = event.target.checked
                break;
            case "showSentiment":
                viewSettings.showSentiment = event.target.checked
                break;
            case "directional":
                viewSettings.directional = event.target.checked
                break;
            default:
                // code block
        }
        createNetworkDataStructure(viewSettings);
    };

    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickLinkDialog = (param1, param2) => {
        if (data && data.networkType && (data.networkType === "useronly" || data.networkType === "user"))
            return;
        setGraphClickData({ type: "link", node1: param1, node2: param2 })
        setOpenDialog(true);
    };
    const handleClickNodeDialog = (param1, param2) => {
        if (data && data.networkType && (data.networkType === "useronly" || data.networkType === "user"))
            return;
        setGraphClickData({ type: "node", node1: param1, node2: param2 })
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };
    useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            })
        }, 1000)

        window.addEventListener('resize', debouncedHandleResize)

        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    });
    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                //78px header, 70px footer (32 & 16 paddings)
                height: targetRef.current.offsetHeight - 70 - 78 - 32 - 16 // remove some padding related size from the graph
            });
        }

    }, []);


    if (!data) // First render with empty div
    {
        const defaultSettings = { directional: false, showSentiment: false, showBots: false, showCollaborators: false }
        createNetworkDataStructure(defaultSettings); // Default when coming to the page
        return (<div height="95%" width="100%" ref={targetRef}></div>);
    }
    // Height calculations
    // HeaderBox: 78 pixels
    // FooterBox: 70 pixels
    // SliderBox: 84 pixels
    // Container: 32 pixels, and 16 pixels (top and bottom)
    // Total: 280 pixels
    data.chartConfig.height = window.innerHeight - 280
    data.chartConfig.width = window.innerWidth


    const hideCheckboxWithTeamData = data.networkType === "team"
    return (

        <div ref={targetRef}>
            <Graph id="graph-id-networkData"
                data={data.network}
                config={data.chartConfig}
                onClickNode={handleClickNodeDialog}
                onClickLink={handleClickLinkDialog}
            />
            <div style={styles.overlay}>
              <FormControl component="fieldset" className="pr-4">
                  <FormGroup>
                    <FormControlLabel style={{'marginTop':'-10px'}} control={ <Checkbox checked={data.viewSettings.directional} onChange={changeViewSettings('directional')} value="directional" /> } label="Directional relationship" />
                    {data.networkType === "team"?
                        <FormControlLabel style={{'marginTop':'-25px'}} control={ <Checkbox checked={data.viewSettings.showSentiment} onChange={changeViewSettings('showSentiment')} value="showSentiment" /> } label="Collaboration sentiment" />
                    :
                        <Tooltip title="Only available for Team networks in paid plans." arrow>
                            <FormControlLabel style={{'marginTop':'-25px'}} control={ <Checkbox checked={false} disabled={true} value="showSentiment" /> } label="Collaboration sentiment" />
                        </Tooltip>
                    }
                    <FormControlLabel hidden={hideCheckboxWithTeamData} style={{'marginTop':'-25px'}} control={ <Checkbox checked={data.viewSettings.showCollaborators} onChange={changeViewSettings('showCollaborators')} value="showCollaborators" /> } label="Show past employees" />
                  </FormGroup>
              </FormControl>

              {data.viewSettings.showSentiment?sentimentExplanation:widthExplanation}
            </div>
            <DetailNetworkDialog open={openDialog} clickEvent={graphClickData} data={props.data} onClose={handleCloseDialog} />
        </div>
    );
};
export default BarChart;
