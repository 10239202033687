import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';


export default function TeamStatisticsChart(data) {

  //console.log(data.teamStats)



  const commsRawData = Object.entries(data.teamStats.productivity.totals).map(([key, val]) => { return { type: key, value: val.avg } })
  const commsCategories = ["Email", "Slack", "Internal Meeting", "External Meeting", "1:1 Meetings"]
  const dataOrder = ["email", "slack", "internalMeeting", "externalMeeting", "one2oneMeeting"]
  const commsData = []

  for (const cat of dataOrder) {
    const value = commsRawData.find(i => i.type === cat)
    commsData.push(value.value)
  }

  const commsSeries = [{
    name: "Average Minutes per Day",
    data: commsData
  }]

  const timeRawData = Object.entries(data.teamStats.productivity.totals.timeType).map(([key, val]) => { return { type: key, value: val.avg } });
  const timeCategories = ["Deep-work", "Collaborative", "Shallow-work"];
  const timeDataOrder = ["focus", "collaboration", "shallow"];
  const timeSeries = [];
  for (const cat of timeDataOrder) {
    const value = timeRawData.find(i => i.type === cat);
    timeSeries.push(value.value);
  }

  const timeChart = {
    chart: { type: 'pie', },
    labels: timeCategories,
    legend: { position: "bottom", show: true },
    yaxis: { labels: { formatter: function() { return "";} } },
    colors: ['#32a871', '#608bbd', '#60bd79', '#6071bd', '#e87694', '#7216b8', '#cf3a29']
  };

  // Communication Preference
  const RadarConfig = {
    chart: { animations: { enabled: false }, toolbar: { show: false }, type: "radar" },
    dataLabels: { enabled: false },
    xaxis: { labels: { show: true }, crosshairs: { width: 1 }, categories: commsCategories },
    yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return val ? parseInt(val) : 0 } } },
    theme: { mode: 'light', palette: 'palette2', },
    markers: { size: 4, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 2, }
  };

  return (
    <Card className="card-box mb-4" style={{width:"100%"}}>
      <CardContent className="p-0">
        <Grid key = "firstGrid"item xs={12} lg={12}>
          <Grid container spacing={1} className="mt-1">
            <Grid item xs={12} sm={12} lg={12}>
              <div className="text-left">
                <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                  <div className=" br-5" ><b className="font-size-lg " >Team: {data.teamStats.team}</b></div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div className="text-left">
                <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                Team members (current & past):
                  {data.teamStats.members.map(member => {
                    return <span key={member.id} className="text-black-50 d-block"> {member.name}  </span>
                  })}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={5} >
              <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">Collaboration method pattern
                <Chart options={RadarConfig} series={commsSeries} type="radar" height="300"/>

              </div>
            </Grid>
            <Grid item xs={12} sm={5} >
              <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">Time usage pattern
                <Chart className="mt-3" options={timeChart} series={timeSeries} type="pie" height="250"/>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


}
