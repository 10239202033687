import { Grid } from '@material-ui/core';
import React from 'react';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';

function getChangeBadge(currentValue, comparisonValue) {
  const changeValue = currentValue / comparisonValue * 100 - 100

  // Trending Flat, Up or Down?
  const trendIcon = changeValue > 0 ? <TrendingUpIcon/> : (changeValue < 0 ? <TrendingDownIcon/> : <TrendingFlatIcon/>)

  // Default to grey text and only change it to green/red in case the change is real
  let trendColor = "text-dark"
  if (changeValue < 0 || changeValue > 0) trendColor = changeValue > 0 ? "text-success" : "text-danger"

  // Parse the change to integer (to remove Infinity values) and test if the output is success === reasonable number to show to user
  const showDeltaNumber = Number.isInteger(parseInt(changeValue)) ? true : false
  if (isNaN(changeValue) || comparisonValue === null)
    return null

  return (
    <span className={" badge badge-neutral-info " + trendColor}>{trendIcon}
      <span className="font-size-l font-weight-bold"> { showDeltaNumber?changeValue.toFixed(0) +"%":"" }</span>
    </span>
  )
}

function getValidValue(value) {
  const parsedValue = parseFloat(value).toFixed(1);
  return (Number.isInteger(parseInt(parsedValue)) ? parsedValue : "");
}


function getNPSHeadLine(current, comparison, seriesName, minimize = false) {

  return (
    <Grid container spacing={0}>
      <Grid item xs={7} md={7}>
        {seriesName +":"}
      </Grid>
      <Grid item xs={5} md={5}>
        <div className={"align-items-right font-weight-bold text-right font-size-lg"}>{getValidValue(current)} {getChangeBadge(current, comparison)}</div>
      </Grid>
    </Grid>
  );

}


function getHeadLine(datapoints, movingAverage, series, minimize = false) {
  const seriesName = series.charAt(0).toUpperCase() + series.slice(1).replace(/_/g, ' ');
  const lastNotNullScore = datapoints.filter(dp => dp !== null)
  const latestScore = lastNotNullScore[lastNotNullScore.length - 1]
  const latestTrend = movingAverage[movingAverage.length - 1]

  return (
    <Grid container spacing={0}>
      <Grid item xs={7} md={7}>
        {seriesName +":"}
      </Grid>
      <Grid item xs={5} md={5}>
        <div className={"align-items-right font-weight-bold text-right font-size-lg"}>{getValidValue(latestScore)} {getChangeBadge(latestScore, latestTrend)}</div>
      </Grid>
    </Grid>
  );

}

const headliner = { getHeadLine, getNPSHeadLine, };
export default headliner;
//export default { getHeadLine, getNPSHeadLine }
