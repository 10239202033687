import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../../../../flowtrace-components/Loading';
import { List, ListItem, Switch, Button, Grid, Card, Divider, DialogActions } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import slackBot from '../../../../flowtrace-api/Slack/slackBotActions';
import commonAnalytics from "../../../../commonAnalytics";

const defaultInstallInfo = {slack_public:false, slack_private:false, slack_surveys:false, slack_commands:false};
class InstallSlack extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, installInfo: defaultInstallInfo, currentInstallation:defaultInstallInfo,permissionUpdated:false  };
  }

  // Get slack installation info
  async loadInstallInfo() {
    const flowtraceAPI = 'slackintegration';
    const oauth2InstallURLs = '/oauth2/install/current';
    const payload = { headers: {}, response: true };

    const installInformation = await API.get(flowtraceAPI, oauth2InstallURLs, payload).then((response, data, body) => {
      const permissions = response.data.permissions.reduce((acc, i) => {
        acc[Object.keys(i)[0]] = Object.values(i)[0];
        return acc;
      }, {});

      const installInfo = { permissions:{...defaultInstallInfo, ...permissions}, installSlackUrl: response.data.installSlackUrl };
      return installInfo;
    }).catch(error => { console.error(error, error.message); });
    return installInformation;
  }

  // Get slack installation info
  async redirectToSlack(permissionString) {
    const flowtraceAPI = 'slackintegration';
    const oauth2InstallURLs = '/oauth2/install/redirect/slack?permissions=' + permissionString;
    const payload = { headers: {}, response: true };
    commonAnalytics.track('install', {tool:"slack"});

    const redirectUrl = await API.get(flowtraceAPI, oauth2InstallURLs, payload).then((response, data, body) => {

      if (response.data && response.data.redirect) {
        console.debug("Redirect url", response.data);
        return response.data.redirect;
      }
    }).catch(error => {
      console.error(error, error.message);
      return "Something went wrong. Please try again later.";
    });
    return redirectUrl;
  }

  async uninstallFlowtraceBot() {
    this.setState({ loading: true });
    await slackBot.uninstall();
    commonAnalytics.track('uninstall', {tool:"slack"});

    // Force reload of the page as user might belongs to Onboarding flow after uninstall.
    window.location.reload();
    this.props.reload();
  }


  async componentDidMount() {
    // Put parent status to loading until data fetched
    this.setState({ loading: true });
    const installInfo = await this.loadInstallInfo();
    this.setState({ loading: false, installInfo, currentInstallation:JSON.parse(JSON.stringify(installInfo)) });
  }

  handleChange(event) {
    const updatedInstallInfo = this.state.installInfo;
    updatedInstallInfo.permissions[event.target.name] = event.target.checked;
    this.setState({ installInfo: updatedInstallInfo, permissionUpdated:true });
  }

  async installSlack() {
    let permissionString = "";
    if (this.state.installInfo.permissions.slack_public) permissionString += "slack_public,";
    if (this.state.installInfo.permissions.slack_private) permissionString += "slack_private,";
    if (this.state.installInfo.permissions.slack_surveys) permissionString += "slack_surveys,";
    if (this.state.installInfo.permissions.slack_commands) permissionString += "slack_commands,";

    if(!permissionString.length)
      return;
    const redirectToSlack = await this.redirectToSlack(permissionString);
    window.location = redirectToSlack;
  }

  render() {
    if(this.state.loading )
      return <Loading />;

    if (this.state.installInfo) {
      const installText = this.props.flowtraceUser.account.ws.SLACK?"Install Updated Permissions":"Install Slack With These Permissions";
      const installButton = <Button disabled={!this.state.permissionUpdated} onClick={this.installSlack.bind(this)} size="medium" variant="contained" color="primary" >{installText}</Button>;
      const uninstallButton = <Button onClick={this.uninstallFlowtraceBot.bind(this)} size="medium" variant="outlined" color="primary" >Uninstall Flowtrace from Slack workspace</Button>;
      const dialogActions =
        <DialogActions style={{ justifyContent: "space-between" }} className="mx-auto pt-5 pb-3">
          {installButton}
          {this.props.flowtraceUser.account.ws.SLACK?uninstallButton:null}
          <Button onClick={() => this.props.onClose()} color="primary" size="medium" variant="outlined" > Close </Button>
        </DialogActions>;

      const publicButton = <Switch checked={this.state.installInfo.permissions.slack_public } onChange={this.handleChange.bind(this)} color="primary" name="slack_public"/>;
      const surveyButton = <Switch checked={this.state.installInfo.permissions.slack_surveys} onChange={this.handleChange.bind(this)} color="primary" name="slack_surveys"/>;
      const privateButton = <Switch checked={this.state.installInfo.permissions.slack_private || false} onChange={this.handleChange.bind(this)} color="primary" name="slack_private"/>;
      const commandsButton = <Switch checked={this.state.installInfo.permissions.slack_commands||false} onChange={ this.handleChange.bind(this)} color="primary" name="slack_commands"/>;
      const checkMark = <div className="mr-0"> <div className="text-success text-center font-size-xl d-60"> <FontAwesomeIcon icon={['fas', 'check']} /> </div> </div>;

      return (
        <Fragment>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12}>
              <Card className="card-box mb-4">
                <List className="py-0">
                  <ListItem className="py-3 border-0">
                    <div className="align-box-row w-100">
                      <div className="mr-3">
                        <div className="bg-neutral-dark text-primary text-center font-size-xl d-60 rounded-sm">
                          <FontAwesomeIcon icon={['fas', 'comments']} />
                        </div>
                      </div>
                      {this.state.currentInstallation.permissions.slack_public? checkMark : publicButton }
                      <div>
                        <div className="font-weight-bold d-block opacity-8">Public Slack Channel Analytics (Recommended) </div>
                        <div className="text-dark "> Flowtrace will have access to conversations in public company domain. The access is granted from the moment installation took place. </div>
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                  <ListItem className="py-3 border-0">
                    <div className="align-box-row w-100">
                      <div className="mr-3">
                        <div className="bg-neutral-dark text-primary text-center font-size-xl d-60 rounded-sm">
                          <FontAwesomeIcon icon={['far', 'user-circle']} />
                        </div>
                      </div>
                      {this.state.currentInstallation.permissions.slack_surveys? checkMark : surveyButton }
                      <div>
                        <div className="font-weight-bold d-block opacity-8"> Slack Employee App: Personalized dashboard, Meeting Feedback & Employee Surveys (Recommended)</div>
                        <div className="text-dark "> Allows employee access to personalized dashboard, meeting reviews and feedback requests, and configuration of employee surveys. Flowtrace can message your employees to survey aspects of teamwork you define, on the schedule you define.  </div>
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                  <ListItem className="py-3 border-0">
                    <div className="align-box-row w-100">
                      <div className="mr-3">
                        <div className="bg-neutral-dark text-primary text-center font-size-xl d-60 rounded-sm">
                          <FontAwesomeIcon icon={['far', 'comments']} />
                        </div>
                      </div>
                      {this.state.currentInstallation.permissions.slack_private? checkMark : privateButton }
                      <div>
                        <div className="font-weight-bold d-block opacity-8"> Enable Private Slack Channel Analytics (Optional)</div>
                        <div className="text-dark "> Allows you to invite Flowtrace bot to a private channel. The conversation access is granted from the moment you invite the bot to a channel. </div>
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                  <ListItem className="py-3 border-0">
                    <div className="align-box-row w-100">
                      <div className="mr-3">
                        <div className="bg-neutral-dark text-primary text-center font-size-xl d-60 rounded-sm">
                          <FontAwesomeIcon icon={['fas', 'laptop-code']} />
                        </div>
                      </div>
                      {this.state.currentInstallation.permissions.slack_commands? checkMark : commandsButton }
                      <div>
                        <div className="font-weight-bold d-block opacity-8"> Enable Team Commands in Slack (Optional)</div>
                        <div className="text-dark "> Your employee can run commands like '/retrospective' in slack to generate team, and channel reports about collaboration. These reports require always multiple employees to maintain the privacy of an individual. </div>
                      </div>
                    </div>
                  </ListItem>
                </List>
              </Card>
            </Grid>
          </Grid>
          {dialogActions}
        </Fragment>
      );
    }

  }
}

export default InstallSlack;
