import { Grid, Card, CardContent, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import Chart from 'react-apexcharts';

export default function HourlyChart(props) {

  const chartOptions = {
    chart: { toolbar: { show: false }, animations: { enabled: false } },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '12px',
      },
      formatter: function(text, op) {
        return [text];
      },
      offsetY: 0.5,
      offsetX: -0.5
    },
    plotOptions: {
      heatmap: {
        //distributed: true,
        shadeIntensity: 0.1,
        inverse: true
      }
    },
    tooltip: {
      y: {
        formatter: function(value) {
          return Math.abs(value) + " messages";
        }
      },
      z: {
        formatter: function([positive, negative], op) {
          return positive + "% positive, " + negative + "% negative";
        },
        title: "Sentiment:"
      }
    },
  };


  // Change GMT to given users locale time
  const offset = moment().utcOffset();
  const timezoneDayHours = JSON.parse(JSON.stringify(props.dayHours));
  for(const day of props.dayHours){
    const weekday = moment().day(day.day);
    for(const hour of day.hours){
      const hourslot = moment(weekday).hour(hour.hour);
      hourslot.add(offset, "minutes");
      const daySlot = timezoneDayHours.find(dh => dh.day === hourslot.isoWeekday());
      const hourSlot = daySlot.hours.find(ds => ds.hour === hourslot.hour());
      hourSlot.count = hour.count;
      hourSlot.positive = hour.positive;
      hourSlot.negative = hour.negative;
    }
  }


  const series = timezoneDayHours.map(i => { return { name: i.id, data: i.hours.map(h => { return { x: h.id, y: h.count, z: [parseInt(h.positive / h.count * 100), parseInt(h.negative / h.count * 100)] } }) } });
  const localeText = "Based on timezone: " + Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <Fragment>
      <Card className="card-box ">
        <CardContent className="p-2 m-2">
          <Grid container spacing={2} >
            <Grid item xs={12}>
              <div className="d-flex pl-3">
                <FontAwesomeIcon icon={['far', 'clock']} className="font-size-xxl text-error"/>
                <Tooltip title={localeText} ><b className="pl-3 font-size-lg">Activity breakdown by day and hour</b></Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Chart options={chartOptions} series={series} type="heatmap" height="280"/>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
}
