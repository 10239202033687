import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StatsCard(passedData) {
    const component = passedData.id
    const data = passedData.data

    let title = ""
    let icon = ""

    if (component === "today") {
        title = "Today's Collaboration Activity (yesterday)"
        icon = <FontAwesomeIcon icon={['fa', 'hourglass-half']} className="font-size-xl" />
    }
    if (component === "thisWeek") {
        title = "Last 7 days of Collaboration Activity (7 days before)"
        icon = <FontAwesomeIcon icon={['far', 'chart-bar']} className="font-size-xl" />
    }
    if (component === "thisMonth") {
        title = "Last 30 days of Collaboration Activity (30 days before)"
        icon = <FontAwesomeIcon icon={['fa', 'calendar']} className="font-size-xl" />
    }

    const changeUp = <span key="changeUp" className="font-size-l mt-1 text-success"><FontAwesomeIcon icon={['fa', 'arrow-up']} className="font-size-l" /></span>
    const changeDown = <span key="changeDown" className="font-size-l mt-1 text-danger"><FontAwesomeIcon icon={['fa', 'arrow-down']} className="font-size-l" /></span>

    let change = []
    let changeNegative = []
    let changePositive = []

    if (data.change < 0) {
        change.push(changeDown)
        change.push(<span key="delta" className="font-size-l mt-1 text-danger"> { data.change }% </span>)
    }
    if (data.change > 0) {
        change.push(changeUp)
        change.push(<span key="delta" className="font-size-l mt-1 text-success"> { data.change }% </span>)
    }
    if (isNaN(data.change)) {
        change.push(<span key="delta" className="font-size-l mt-1"> </span>)
    }

    if (data.positive_change < 0) {
        changePositive.push(changeDown)
        changePositive.push(<span key="positiveDelta" className="font-size-l mt-1 text-danger"> { data.positive_change }% </span>)
    }
    if (data.positive_change > 0) {

        changePositive.push(changeUp)
        changePositive.push(<span key="positiveDelta" className="font-size-l mt-1 text-success"> { data.positive_change }% </span>)
    }
    if (isNaN(data.positive_change)) {
        changePositive.push(<span key="positiveDelta" className="font-size-l mt-1">  </span>)
    }

    if (data.negative_change < 0) {
        changeNegative.push(changeDown)
        changeNegative.push(<span key="negativeDelta" className="font-size-l mt-1 text-danger"> { data.negative_change }% </span>)
    }
    if (data.negative_change > 0) {

        changeNegative.push(changeUp)
        changeNegative.push(<span key="negativeDelta" className="font-size-l mt-1 text-success"> { data.negative_change }% </span>)
    }
    if (isNaN(data.negative_change)) {
        changeNegative.push(<span key="negativeDelta" className="font-size-l mt-1">  </span>)
    }

    const cardClasses = passedData.componentColor + " card-box border-0 text-light mb-4"

    return (
        <Fragment>
            <Card className={cardClasses}>
                <CardContent className="p-3">
                    <div className="d-flex align-items-start">
                        <div className="font-weight-bold">
                            <small className="text-white-50 d-block mb-1 text-uppercase">{title}</small>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={4}>
                                    <span className="font-size-xxl ">{ data ? data.value : "-" }</span>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                        <FontAwesomeIcon icon={['far', 'smile']} className="text-success font-size-m mt-3" />
                                        <span className="font-size-l mt-3">{ data.positive === "-" ? "" : " " + data.positive + "%" }</span>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <FontAwesomeIcon icon={['far', 'frown']} className="text-danger font-size-m mt-3" />
                                    <span className="font-size-l mt-3">{ data.negative === "-" ? "" : " " + data.negative + "%" }</span>
                                </Grid>
                            </Grid>
                            <Grid container spacing={0}>
                                <Grid item xs={12} md={4}>
                                    {change}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {changePositive}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {changeNegative}
                                </Grid>
                            </Grid>
                        </div>
                        <div className="ml-auto">
                            <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                                {icon}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Fragment>
    );
}
