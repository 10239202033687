import React, { Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import CompanySurveys from '../../../flowtrace-components/Surveys/Company';

const explanation = [];
explanation.push(<h4 key="header" className="heading-2 pb-1"> Survey Overview </h4>);
explanation.push(<p key="paragraph1"> Team effectiveness and eNPS (employee net promoter score) for your team</p>);
explanation.push(<p key="paragraph2"> All scores are weighted and given as a scale between -100 to 100. Responses of 3 or below are given negative weight, and respones of 5 a positive weight. Responses of 4 are considered neutral.</p>);

export default function NPSSurvey(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Company Survey Overview" titleDescription="All your survey trends and results. All trends and scores you see here are company wide." explanation={explanation}/>
      <CompanySurveys  flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
