import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import TeamSurveComponents from '../../../flowtrace-components/Surveys/Team';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1"> Survey Overview </h4>)
explanation.push(<p key="paragraph1"> Team effectiveness and eNPS (employee net promoter score) for all of you teams.</p>)
explanation.push(<p key="paragraph2"> All scores are weighted and given as a scale between -100 to 100. Responses of 3 or below are given negative weight, and respones of 5 a positive weight. Responses of 4 are considered neutral.</p>);
explanation.push(<p key="paragraph3"> Trend lines are averages for the past 4 scores.</p>);

export default function TeamSurveys(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Team Survey Overview" titleDescription="All your survey metrics and team breakdown" explanation={explanation}/>

      <TeamSurveComponents flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
