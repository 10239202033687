import { Grid, Card, CardContent, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default function StatsCard(props) {
    const icon = <FontAwesomeIcon icon={['fa', props.icon?props.icon:'calendar']} className="font-size-xl" />;
    const cardClasses = (props.color ? props.color : "bg-brand-slack") +" card-box border-0 text-light mb-4";

    return (
        <Fragment>
            <Card className={cardClasses}>
                <CardContent className="p-3">
                    <Tooltip title={props.tooltip ? props.tooltip:""}>
                        <div className="d-flex align-items-start">
                            <div className="font-weight-bold">
                                <small className="text-white-50 d-block mb-1 text-uppercase">{props.title}</small>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={12}>
                                        <span className="font-size-xxl ">{ props.totalCost ? props.totalCost : "-" }</span>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={12}>
                                        <span className="font-size-xl ">{ props.totalTime ? props.totalTime : "-" }</span>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className="ml-auto">
                                <div className="bg-white text-center text-primary d-50 rounded-circle d-flex align-items-center justify-content-center">
                                    {icon}
                                </div>
                            </div>
                        </div>
                    </Tooltip>
                </CardContent>
            </Card>
        </Fragment>
    );
}
