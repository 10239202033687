import React, { Fragment } from 'react';
import { Grid } from '@material-ui/core';
import GSuite from './GoogleConfiguration';
import SlackIntegration from './SlackConfiguration';
import WebhookIntegration from './webhookIntegration';


export default function IntegrationHandler(props) {

  return (
    <Fragment>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <SlackIntegration flowtraceUser={props.flowtraceUser}/>
        </Grid>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"google-oauth2"}/>
        <Grid item xs={12} md={6}>
          <GSuite flowtraceUser={props.flowtraceUser} />
        </Grid>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"microsoft"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"zoom"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"github"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"gitlab"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"atlassian"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"clickup"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"front"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"hubspot"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"salesforce"}/>
        <WebhookIntegration flowtraceUser={props.flowtraceUser} type={"asana"}/>
      </Grid>
    </Fragment>
  );
}
