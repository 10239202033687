import React, { Component } from 'react';
import { Box, Button, ButtonGroup, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import MuiAlert from '@material-ui/lab/Alert';


const noneSelected =
  <MuiAlert className="mb-4" severity="warning" style={{maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <strong className="d-block">Flowtrace freemium plan requires Slack connection:</strong>
        We do support many alternative collaboration tools in our advanced plans.<br/><br/>
        Click here to learn more about<b><a href="https://www.flowtrace.co/pricing" target="_blank" rel="noreferrer"> our plans</a></b>
      </span>
    </div>
  </MuiAlert>;


const bookDemo = (flowtraceUser) => {
  let hubspotMeetingLink = 'https://www.flowtrace.co/meetings/flowtrace/flowtrace-introduction?embed=true';
  try{
    const userName = flowtraceUser.name?flowtraceUser.name:flowtraceUser.full_name;
    const name = userName.split(" ");
    hubspotMeetingLink += name && name[0] && name[0].length ? "&firstName=" + name[0]:"";
    hubspotMeetingLink += name && name[1] && name[1].length ? "&lastName=" + name[1]:"";
    hubspotMeetingLink += "&email=" + flowtraceUser.email;
  } catch(e) {console.log(e)}


  return (
  <MuiAlert className="mb-4" severity="info" style={{maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <strong className="d-block">Looking for Calendar Analytics?</strong>
        You can get started with Calendar Analytics after completing the Slack onboarding. <br/><br/>Alternatively you can book your <b>personalized onboarding</b> below: <br/><br/>
      </span>
    </div>
    <iframe title={'hubspot'} style={{ display:"block", border: "0px", margin: "0px", padding: "0px", width: '100%', height: '620px' }} src={hubspotMeetingLink} />
  </MuiAlert>
  );
};

const privateSelected =
  <MuiAlert className="mb-4" severity="info" style={{maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <p><strong className="d-block">Your privacy, is our priority:</strong></p>
        <p>Flowtrace respects your teams’ privacy, which is why all “private channels” are excluded from analytics by default.</p>
        <p>You can invite Flowtrace to your private channels manually at any stage after the connection between Slack and Flowtrace is established.</p>
      </span>
    </div>
  </MuiAlert>;
const surveyEnabled =
  <MuiAlert className="mb-4" severity="info" style={{maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <strong className="d-block">You stay in control</strong> We will not send any surveys out before you have configured, and enabled them.
      </span>
    </div>
  </MuiAlert>;
const surveyDisabled =
  <MuiAlert className="mb-4" severity="warning" style={{maxWidth:"600px"}}>
    <div className="d-flex align-items-center align-content-center">
      <span>
        <p><strong >Did you know:</strong> </p>
        <p>Gathering and acting on employee feedback can quickly increase employee productivity and engagement by more than 30%? Weekly surveys enable a safe, anonymous, and non-intrusive channel for your employees to share how they feel about their team and the organisation.</p>
        <p>In a hybrid or remote environment it’s often the only way to get this feedback and Flowtrace gives you a solid yet privacy-centric overview into your team satisfaction.</p>
      </span>
    </div>
  </MuiAlert>;


class InstallationScope extends Component {

  channelType(type) {
    this.props.onUpdate({ channel: type });
  }
  surveyEnabled = (value) => {
    this.props.onUpdate({ survey: value });
  }

  render() {
    if(!this.props.isActive) return null;
    return (
      <StepNavigation stepTitle="What is your current collaboration style?" {...this.props} nextDisabled={this.props.settings.survey===undefined || this.props.settings.channel===undefined}>
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <h5>How do your teams prefer to communicate in Slack?</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box className="d-flex align-items-center">
              <ButtonGroup color="primary" size="large" variant="contained">
                <Button color={this.props.settings.channel==="public"?"primary":"default"} onClick={() => this.channelType("public")}>Public channels</Button>
                <Button color={this.props.settings.channel==="private"?"primary":"default"} onClick={() => this.channelType("private")}>Public and private channels</Button>
                <Button color={this.props.settings.channel==="none"?"primary":"default"} onClick={() => this.channelType("none")}>We don't use Slack</Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {this.props.settings.channel==="private"?privateSelected:null}
            {this.props.settings.channel==="none"?noneSelected:null}
            {this.props.settings.channel==="none"?bookDemo(this.props.flowtraceUser):null}
          </Grid>
          {this.props.settings.channel && this.props.settings.channel!=="none"?
            <Grid item xs={12} sm={12} md={12}>
              <h5>Do you plan to send weekly surveys to your teams?</h5>
            </Grid>
          :null}
          {this.props.settings.channel && this.props.settings.channel!=="none"?
            <Grid item xs={12} sm={12} md={12}>
              <Box className="d-flex align-items-center">
                <ButtonGroup color="primary" size="large" variant="contained">
                  <Button color={this.props.settings.survey===false?"primary":"default"} onClick={() => this.surveyEnabled(false)}>No</Button>
                  <Button color={this.props.settings.survey===true?"primary":"default"} onClick={() => this.surveyEnabled(true)}>Yes</Button>
                </ButtonGroup>
              </Box>
            </Grid>
          :null}
          {this.props.settings.channel && this.props.settings.channel!=="none"?
            <Grid item xs={12} sm={12} md={12}>
              {this.props.settings.survey?surveyEnabled:null}
              {this.props.settings.survey===false?surveyDisabled:null}
            </Grid>
          :null}

        </Grid>
      </StepNavigation>
    );

  }
}

export default withRouter(InstallationScope);
