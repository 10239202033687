import React, { Component } from 'react';
import { forwardRef } from 'react';
import MaterialTable from '@material-table/core';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import NoData from './noRecommendations';
import RecommendationDetail from './recommendationDetail'

const tableIcons = {
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const impactRender = (value) => {
    if(value===1)
        return <span className={"badge badge-dark-info"}>Low</span>;
    if(value===2)
        return <span className={"badge badge-dark-warning"}>Medium</span>;
    if(value===3)
        return <span className={"badge badge-dark-success"}>High</span>;
};

class RecommendationTable extends Component {
    render() {

        if(!this.props.recommendations.length)
            return <NoData />;
        return (
            <MaterialTable
                columns={[
                    { title: 'Recommendation', align:"left", width: 400, field: 'summary'},
                    { title: 'Benefit', align:"left", field: 'benefit' },
                    { title: 'Team', align:"left", width: 200, field: 'target', render: ((row) => row.target==="/"?"Company":row.target)},
                    { title: 'Potential Impact', align:"center", type:"string", width: 170, defaultSort:"desc", field: 'impact', render: ((row) => impactRender(row.impact))},
                ]}
                data={this.props.recommendations}
                icons={tableIcons}
                localization={{
                    body: {
                        emptyDataSourceMessage: "No recommendations available for your account."
                    }
                }}
                options={
                    {
                        search: false,
                        emptyRowsWhenPaging:false,
                        tableLayout:"auto",
                        toolbar:false,
                        padding:"dense",
                        pageSize:5,
                        pageSizeOptions:[5,10,25],
                    }
              }
                detailPanel={[
                    {render: ({rowData}) => { return ( <RecommendationDetail recommendation={rowData} /> ) }}
                ]}
                onRowClick={(event, rowData, togglePanel) => togglePanel()}

          />
        );

    } // end of render()

}

export default RecommendationTable;
