import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { API } from 'aws-amplify';

class CSVFileSelectAndUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { uploading: false, uploadResponse: null };
  }

  async uploadCSV(fileSelectedEvent) {
    this.setState({ uploading: true, uploadResponse: null });
    const flowtraceAPI = 'slackintegration';
    const URL = '/flowtrace/analytics/upload';

    // Details of the uploaded file
    const file = fileSelectedEvent.target.files[0];
    const options = { headers: { "Content-Type": "application/json" }, body: { csv: await file.text() }, response: true };
    const response = await API.post(flowtraceAPI, URL, options);

    this.setState({ uploading: false, uploadResponse: response.data.message });
    return;
  }

  handleUploadEvent = async(event) => { await this.uploadCSV(event); }

  render() {
    return (
      <div style={{float:'left', marginBottom:"5px",marginTop:'7px', marginLeft: '5px'}}>
        <input style={{display: 'none'}} accept=".csv" id="csvFile" type="file" onChange={this.handleUploadEvent}/>
        <label className="mr-3" htmlFor="csvFile">
          <Button disabled={this.state.uploading} variant="contained" color="secondary" component="span"> {this.state.uploading?"Please Wait":"Upload CSV (Dripify/PinPoint)"} </Button>
        </label>
        {this.state.uploadResponse ? this.state.uploadResponse:""}
      </div>

    );
  }
}
export default CSVFileSelectAndUpload;
