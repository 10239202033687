import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import ReportingSettings from '../../../flowtrace-components/Configuration/Reporting';
import Loading from '../../../flowtrace-components/Loading'
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from "react-router-dom";
import { Button } from '@material-ui/core';
import testSlackScope from '../slackSurveyScope';
import accountReportsAPI from '../../../flowtrace-api/Account/accountReportActions';

class ReportingSettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async load() {
    this.setState({ loading: true });
    const [accountReports, accountChannels, surveyScope] = await Promise.all([
      accountReportsAPI.getReports(),
      accountReportsAPI.getChannels(),
      testSlackScope()
    ]);
    const sortedAndFiltered = accountChannels.filter(c => c.is_channel && !c.is_archived).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    this.setState({ surveyScope, accountReports:accountReports.filter(r => r.type === "slackReport"), accountChannels: sortedAndFiltered, loading: false });

  }

  async componentDidMount() {
    await this.load();
  }

  render() {
    if (this.state.loading) {
      return <Loading/>;
    }

    return (
      <Fragment>

          <PageTitle titleHeading="Report Configuration" titleDescription="Slack based team reporting" />
          {
            this.props.flowtraceUser.account && this.state.surveyScope?
              <ReportingSettings flowtraceUser={this.props.flowtraceUser} reload={this.load.bind(this)} accountChannels={this.state.accountChannels} accountReports={this.state.accountReports} />
            :
              <div>
                <MuiAlert key="noScopeInstalled" className="mb-4" severity="warning">
                  <div className="d-flex align-items-center align-content-center">
                    <span>
                      <strong>Your current installation doesn't support reporting.</strong>
                      <p>You can install required survey scope to your account from Integrations page.</p>
                    </span>
                  </div>
                </MuiAlert>
                <Button onClick={() => {this.props.history.push("/Configuration");}  } rel="noopener" size="large" variant="contained" color="primary">Take me to Integrations</Button>
              </div>
            }
        </Fragment>
    );
  }
}

export default withRouter(ReportingSettingsPage);
