import React, { Component } from 'react';
import { Auth } from 'aws-amplify';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import commonAnalytics from "./commonAnalytics";

import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import Loading from './flowtrace-components/Loading'
import Amplify, { Hub, API } from 'aws-amplify';
import amplify from './aws-exports';
import { Redirect } from 'react-router-dom';
import { withAuth0 } from "@auth0/auth0-react";
import jwtDecode from 'jwt-decode';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub } from '@fortawesome/free-brands-svg-icons';
import { far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar } from '@fortawesome/free-regular-svg-icons';
import { fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight, faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager, faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink } from '@fortawesome/free-solid-svg-icons';

library.add(far, faSquare, faLifeRing, faCheckCircle, faTimesCircle, faDotCircle, faThumbsUp, faComments, faFolderOpen, faTrashAlt, faFileImage, faFileArchive, faCommentDots, faFolder, faKeyboard, faCalendarAlt, faEnvelope, faAddressCard, faMap, faObjectGroup, faImages, faUser, faLightbulb, faGem, faClock, faUserCircle, faQuestionCircle, faBuilding, faBell, faFileExcel, faFileAudio, faFileVideo, faFileWord, faFilePdf, faFileCode, faFileAlt, faEye, faChartBar);
library.add(fab, faFacebook, faTwitter, faVuejs, faReact, faHtml5, faGoogle, faInstagram, faPinterest, faYoutube, faDiscord, faSlack, faDribbble, faGithub);
library.add(fas, faAngleDoubleRight, faAngleDoubleLeft, faSmile, faHeart, faBatteryEmpty, faBatteryFull, faChevronRight, faSitemap, faPrint, faMapMarkedAlt, faTachometerAlt, faAlignCenter, faExternalLinkAlt, faShareSquare, faInfoCircle, faSync, faQuoteRight, faStarHalfAlt, faShapes, faCarBattery, faTable, faCubes, faPager, faCameraRetro, faBomb, faNetworkWired, faBusAlt, faBirthdayCake, faEyeDropper, faUnlockAlt, faDownload, faAward, faPlayCircle, faReply, faUpload, faBars, faEllipsisV, faSave, faSlidersH, faCaretRight, faChevronUp, faPlus, faLemon, faChevronLeft, faTimes, faChevronDown, faFilm, faSearch, faEllipsisH, faCog, faArrowsAltH, faPlusCircle, faAngleRight, faAngleUp, faAngleLeft, faAngleDown, faArrowUp, faArrowDown, faArrowRight, faArrowLeft, faStar, faSignOutAlt, faLink);

// Remove hosted UI settings which are not needed. (It cause issue with state/code parameters as auth0 uses them as well)
delete amplify.oauth;
Amplify.configure(amplify);
const store = configureStore(); // default redux store. We should use Hub from amplify with it in case this is the route forward...

// User session and authentication process on high level:
// 1. When user authenticates, /user/authenticated is called with auth event payload
// 2. Response from the end point contains the user object (without access tokens), or in case of failure, success=false
// 3. We set the session information about the login (and last activity) to the Cache
// 4. We set the user object into Cache
// 5. During onboarding, or integration installation process, user object in cache is updated (with workspace information)
// 6. In case user goes to onboarding, user object contains extra value "onboarding" to indicate they are as part of the onboarding flow
// 7. SignOut event triggers cache clearing

const authRoutes = ['/', '/Login', '/Register']; // No redirects to these urls


const userIdentity = (flowtraceUser) => {
    const user = { name: (flowtraceUser.name ? flowtraceUser.name : flowtraceUser.full_name), email: flowtraceUser.email, user_hash: flowtraceUser.hash };
    const acc = flowtraceUser && flowtraceUser.account ? flowtraceUser.account : null;
    if (acc) {
      const company = { company_id: acc.account_id, name: acc.account_name };
      if (acc.properties.find(p => p.created)) company.createdAt = acc.properties.find(p => p.created).created;
      if (acc.properties.find(p => p.type)) company.plan = acc.properties.find(p => p.type).type;
      if (acc.properties.find(p => p.stripeSubscriptionItemID)) company.plan = "paid";
      user.company = company;
    }
    return user;
};


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, authenticating: false, flowtraceUser: null };
  }

  // This is called internally to refresh the user and the account information
  async reloadUserAndAccount() {
    this.setState({ flowtraceUser: {...this.state.flowtraceUser, reloading: true }});
    const flowtraceUser = await this.createFlowtraceUser({auth0:this.props.auth0.user});
    return this.setState({ flowtraceUser});
  }

  // This function loads the Flowtrace user from userAPI based on the cognito ID and auth0 stuff.
  async createFlowtraceUser(userData) {
    try {
      const userAPI = 'slackintegration';
      const parameters = { headers: {}, body: userData, response: true };
      const { data } = await API.post(userAPI, '/user/authenticated', parameters);

      const flowtraceUser = data.user;
      flowtraceUser.reload = this.reloadUserAndAccount.bind(this);
      if (data.accounts && data.accounts.length){
        // Create easy accessor for account, props, and workspaces
        flowtraceUser.account = data.accounts[0];
        flowtraceUser.account.props = {};
        for(const p of flowtraceUser.account.properties){
          for(const k of Object.keys(p)){
            flowtraceUser.account.props[k] = p[k];
          }
        }
        flowtraceUser.account.ws = {};
        if(flowtraceUser.account.workspaces)
          flowtraceUser.account.workspaces.forEach(ws => { flowtraceUser.account.ws[ws.type + (ws.subtype?ws.subtype:"")] = ws; });
      }
      return flowtraceUser;

    }
    catch (e) {
      console.info("userAPI error:", e);
    }
    return null;
  }

  // Initial authentication sequence runs through here:
  async userAuthEvent(userData) {
    try {
      const flowtraceUser = await this.createFlowtraceUser(userData);
      // Test if we update the user's identity to the Analytics stack
      if (flowtraceUser){
        const u = userIdentity(flowtraceUser);
        commonAnalytics.identify(u.email, u);
        commonAnalytics.track("Login");
      }
      this.setState({ flowtraceUser});
    }
    catch (e) {
      console.info("Authentication Error:", e);
      this.setState({ flowtraceUser: null });
    }
  }


  async componentDidMount() {
    // Add authentication listener to execute flowtraceUser population and logout procedure
    await Hub.listen("auth", async({ payload: { event, data } }) => {
      console.log("App - Auth event fired:", event);
      // Send Google AdWords conversion event when user signed up (event name: "Cognito Sign-up")
      if (event === "signUp" && window.gtag)
        window.gtag('event', 'conversion', {'send_to': 'AW-625517572/d6soCJ3W-ccDEITIoqoC'});

      if (event === "signIn" || event === "tokenRefresh") {
        //console.debug("App - Auth event - populate user session:", event)
        // await this.userAuthEvent(data)
        // this.setState({ loading: false });
      }
      else if (event === "signOut") {
        this.props.auth0.logout({ returnTo: window.location.origin });
        await new Promise(r => setTimeout(r, 500)); // Wait for a 0.5 seconds to get the redirect happening
        this.setState({ flowtraceUser: null, loading: true, authenticating: false });
      }
      else if (event === "signIn_failure" || event === "cognitoHostedUI_failure" || event === "customState_failure") {
        //console.log("Hub - Auth0:", this.props.auth0)
      }
    });

    this.setState({ loading: false });
  }

  async redirectToLogin(){
    // Remove url parameters associated with login / auth0 from query params
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has("code")) urlParams.delete("code");
    if(urlParams.has("state")) urlParams.delete("state");
    const qs = urlParams.toString().length ? "?" + urlParams.toString() : "";
    await this.props.auth0.loginWithRedirect({appState: { returnTo: window.location.pathname + qs}});
    await new Promise(r => setTimeout(r, 200)); // Wait for a 0.2 seconds to get the redirect happening
  }
  async redirectToSignUp(){
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has("type") && urlParams.get("type") === "chrome")
      await this.props.auth0.loginWithRedirect({screen_hint: 'signup', type:"chrome", appState: { returnTo: "/Onboarding?type=chrome" }});
    else
      await this.props.auth0.loginWithRedirect({screen_hint: 'signup', appState: { returnTo: "/" }});
    await new Promise(r => setTimeout(r, 200)); // Wait for a 0.2 seconds to get the redirect happening
  }

  async currentUser() {
    try{
        return await Auth.currentAuthenticatedUser();
      }catch(e){
        return null;
    }
  }
  // Let's test the session is from valid login sources (and not from integration sources)
  async isUserHavingValidLoginMethod() {
    // Don't do this for localhost as we use different method to run cypress
    if(window.location.hostname === "localhost") return true;
    const sub = this.props.auth0.user.sub;
    const needsLogout = sub.startsWith("email|") || sub.startsWith("oauth2|microsoft|") || sub.startsWith("google-oauth2|") || sub.startsWith("oauth2|slack|")? false : true;
    if(needsLogout){
      console.log(sub, "needs logout:", needsLogout);
      await Auth.signOut({ global: true });
      await new Promise(r => setTimeout(r, 1000)); // Wait for a second to get the logout sorted
      return false;
    }
    return true;
  }
  async federatedSignin(){
    try {
      const token = await this.props.auth0.getAccessTokenSilently();
      const {user} = this.props.auth0;
      await Auth.federatedSignIn( this.props.auth0Config.domain, { token, expires_at: jwtDecode(token).exp * 1000 }, { name:user.name, email:user.email } );
    } catch (e) {
      console.log("Federation Error:", e);
    }
  }

  async componentDidUpdate() {
    if(this.props.auth0.isLoading)
      return console.debug("Authorization - Loading.");

    if(this.props.auth0.user && !(await this.isUserHavingValidLoginMethod()))
      return console.debug("Authorization - Unrecognized IdP");

    if(!this.props.auth0.isAuthenticated && !this.state.authenticating) {
      console.debug("Authorization - Redirect to login/signup page.");
      // Check if this is /Register page and redirect
      if(window.location.pathname === "/Register")
        return await this.redirectToSignUp();
      return await this.redirectToLogin();
    }
    else if(!this.state.authenticating && !this.state.flowtraceUser)
    {
        this.setState({ authenticating: true });
        console.debug("Authenticating.");
        const currentUser = await this.currentUser();
        if(!currentUser){
          console.debug("Authenticating - Federated login.");
          await this.federatedSignin();
        }
        if(!this.state.flowtraceUser){
          console.debug("Authenticating - Flowtrace user.");
          await this.userAuthEvent({auth0:this.props.auth0.user, ...currentUser});
        }
        console.debug("Authenticated.");
        return this.setState({ authenticating: false });
    }
  }

  render() {
    if (this.state.authenticating)
      return <Loading text="We are establishing secure connection..."/>;
    if (this.props.auth0.isLoading)
      return <Loading text="We are creating secure connection..."/>;
    if (!this.state.flowtraceUser)
      return <Loading text="We are processing secure connection..."/>;
    if (this.state.loading)
      return <Loading text="We are initializing secure connection..."/>;

    // 1st -> Go to onboarding if user doesn't have workspace installed
    // 2nd -> Go to route they asked for
    // 3rd -> Go to /Dashboard if its root path, and there is no redirect
    const appAndRedirects = [];

    if (this.state.flowtraceUser) {
      if (!this.state.flowtraceUser.account)
        appAndRedirects.push(<Redirect key="onboarding" to="/Onboarding" />);
      else if (authRoutes.includes(window.location.pathname))
        appAndRedirects.push(<Redirect key="redirecting" to="/Dashboard" />);
      // Add app to the mix as we have a session
      appAndRedirects.push(<Routes key="mainApp" flowtraceUser={this.state.flowtraceUser}/>);
    }


    //const googleAdsscript = "{ window.dataLayer = window.dataLayer || []; function gtag(){ dataLayer.push(arguments); } gtag('js', new Date()); gtag('config', '" + googleAdsId + "');};";
    // <Helmet>
    //   <script>{googleAdsscript}</script>
    // </Helmet>
    return (
        <Provider store={store}>
          <BrowserRouter basename="/">
            <CssBaseline />
            <ScrollToTop>
              { appAndRedirects }
            </ScrollToTop>
          </BrowserRouter>
        </Provider>
    );

  }
}

export default withAuth0(App);
