import React, { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import TeamDetails from './teamStats';

const DetailDialog = props => {

  if (props.open) {
    const dialogType = props.clickEvent.type // "link" or "node"
    const dialogNodes = []
    // Node1 is always either a team "/Executive" or user "U1234432"
    dialogNodes.push(props.clickEvent.node1)

    // Node2 is a team or user only if type is link, otherwise its details of the node which was clicked (which we discard)
    if (props.clickEvent.type === "link")
      dialogNodes.push(props.clickEvent.node2)

    return (
      <Fragment>
      <Dialog maxWidth="xl" open={props.open} onClose={ () => {props.onClose()}} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Collaboration Details</DialogTitle>
          <DialogContent>
              <TeamDetails dialogType={dialogType} dialogTeams={dialogNodes}/>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => {props.onClose()}} color="primary"> Close </Button>
          </DialogActions>
      </Dialog>
    </Fragment>
    );
  }
  else
    return <Fragment/>

};
export default DetailDialog;
