import React from 'react';
import { Launch } from '@material-ui/icons';
import { Link, Grid, Divider, Box, Card, Button, Dialog, DialogActions, DialogContent, DialogContentText,  TextField, } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { API } from 'aws-amplify';
import GoogleWorkspaceLogo from '../../../../assets/images/integration_logos/google_workspace.png';
import commonAnalytics from "../../../../commonAnalytics";

async function storeToDatabase(superAdmin, serviceAccountJSON, flowtraceUser) {
  try{
    const body = {superAdmin, serviceAccount: JSON.parse(serviceAccountJSON)};
    const flowtraceAPI = 'slackintegration';
    const endpoint = '/googleWorkspace/validate/' + flowtraceUser.account.account_id;
    const callHeaders = { body, headers: {}, response: true };
    const {data : {success, message, scopes, ...payload}} = await API.post(flowtraceAPI, endpoint, callHeaders);
    return { success, message, scopes, payload };
  }catch(e){
    return { success: false, message: "Failed to validate the token and admin email combination." };
  }
}

const getGSuiteInstallation = (user) => {
  // Check if we have app marketplace install or service account install in place
  if(user.account)
    return user.account.workspaces.find(ws => ws.type ==="GSUITE" && (ws.subtype === "SERVICE_ACCOUNT" || !ws.subtype))?true:false;
  return null;

};


export default function GSuiteConfiguration(props) {

  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [superAdmin, setSuperAdmin] = React.useState('');
  const [serviceAccountJSON, setServiceAccountJSON] = React.useState('');
  const [validating, setValidating] = React.useState(false);
  const [validationError, setValidationError] = React.useState('');
  const [validationSuccess, setValidationSuccess] = React.useState('');

  const handleClickOpen = () => {
    setValidationError("") // Reset the error
    setValidationSuccess(null) // Reset the success message
    setOpen(true);
  };
  const handleClose = () => { setOpen(false); };
  const handleSave = async() => {
    try {

      if (!superAdmin && superAdmin.split('@').length !== 2)
        throw new Error("Super Admin Email Adress Validation Failed")

      const key = JSON.parse(serviceAccountJSON)
      let valueTest = true
      valueTest = valueTest && key.type === "service_account"
      valueTest = valueTest && key.project_id.length > 1
      valueTest = valueTest && key.private_key_id.length > 10
      valueTest = valueTest && key.private_key.length > 100
      valueTest = valueTest && key.client_email.length > 5
      valueTest = valueTest && key.client_id.length > 5
      valueTest = valueTest && key.auth_uri.length > 5
      valueTest = valueTest && key.token_uri.length > 5
      valueTest = valueTest && key.auth_provider_x509_cert_url.length > 5
      valueTest = valueTest && key.client_x509_cert_url.length > 5
      if (!valueTest)
        throw new Error("JSON Key Validation Failed")

      // Init the values:
      setValidating(true);
      setValidationSuccess(null);
      setValidationError("");
      // Store to DB
      await storeToDatabase(superAdmin, serviceAccountJSON, props.flowtraceUser).then(response => {
        if (response && response.success) {
          const scopes = response.scopes.map((s, index) => index ? ", " + s : s );
          setValidationSuccess(<div><p>Google Workspace Installation Succeeded with following scopes:</p> <p>{scopes} </p><p> Background data processing queued and first complete insights are available in the coming 24 hours.</p></div>);
          setSuccess("Succesfully Installed");
          commonAnalytics.track('install', {tool:"google"});
        }
        else
          setValidationError("Google Workspace Installation Failed: " + response.message);
      });
    }
    catch (e) {
      console.error("Validation Failed:", e.message);
      setValidationError("Validation Failed: " + e.message);

    }
    setValidating(false);

  };

  const customerId = success ? success : getGSuiteInstallation(props.flowtraceUser);

  const currentPlan = props.flowtraceUser && props.flowtraceUser.account && props.flowtraceUser.account.props && props.flowtraceUser.account.props.type ? props.flowtraceUser.account.props.type:"free";

  const viewInstructions = <Link underline="hover" variant="body2" target={"_blank"} href={"https://help.flowtrace.co/en/articles/6286705-integrate-google-workspace-with-flowtrace"}>Instructions<Launch fontSize="small"/></Link>;

  return (
    <Card className="p-4 mb-4">
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <div style={{minHeight:"50px"}}>
            <img style={{maxHeight:"50px", maxWidth:"90px"}} alt="Paywall" src={GoogleWorkspaceLogo} />
          </div>
        </Grid>
        <Grid item xs={7}>
          <div className="font-size-lg font-weight-bold">Google Workspace Service Account</div>
        </Grid>
        <Grid item className="pt-2" xs={3}>
          {viewInstructions}
        </Grid>
      </Grid>


      <Divider className="my-4" />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box className="d-flex align-items-center">
            <Grid container spacing={0}>
              <Grid item xs={6}>
                {currentPlan==="free"?
                  "Please upgrade your plan to to integrate."
                :
                  <Button onClick={handleClickOpen} color="primary" variant={!customerId?"outlined":"contained"}>{customerId?"Re-upload service account":"Install Google Workspace"}</Button>

                }
              </Grid>
              <Grid item xs={6}>
                {customerId? <MuiAlert key="latestMessage" severity={"success"} className="pt-0 pb-0"> <strong className="d-block">Installed</strong> </MuiAlert> : null}
              </Grid>
            </Grid>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogContent>
                {customerId?
                  <h5 className="card-title font-weight-bold font-size-md">Currently Integrated Workspace ID: {customerId} </h5>
                :
                  <h5 className="card-title font-weight-bold font-size-md">Install Google Workspace</h5>
                }
                <DialogContentText>{viewInstructions}</DialogContentText>
                <DialogContentText> You will need the Google Workspace super admin email address and the json formatted service account key file.</DialogContentText>
                <DialogContentText> Super admin is a Google Workspace user, who has access to Directory Admin SDK. You can find super admin users using https://admin.google.com/ </DialogContentText>
                <DialogContentText> You can download JSON formatted key file for your service account using https://console.developers.google.com/apis/credentials </DialogContentText>
                <DialogContentText className="text-danger"> {validationError}</DialogContentText>
                <DialogContentText className="text-success"> {validationSuccess}</DialogContentText>
                {success? null :
                <div>
                  <TextField disabled={validating} onInput={e => setSuperAdmin(e.target.value)} autoFocus margin="dense" id="name" label="Super admin's email address" type="email" fullWidth variant="outlined"/>
                  <TextField disabled={validating} onInput={e => setServiceAccountJSON(e.target.value)} margin="dense"id="key" label="Service Account JSON Key File" multiline fullWidth rows={10} placeholder="Copy and paste the key file here." variant="outlined"/>
                </div>}
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave} disabled={validating || success} size="medium" variant="contained" color="primary"> Validate & Save </Button>
                <Button onClick={handleClose} disabled={validating} size="medium" variant="outlined" color="primary"> Close </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );

}
