import React, { Fragment } from 'react';

import { PageTitle } from '../../../layout-components';
import ToolStatistics from '../../../flowtrace-components/ProductivityStatistics/CompanyTool';

const explanation = []
explanation.push(<h4 key="header" className="heading-2 pb-1">Tool Usage Overview</h4>)
explanation.push(<p key="paragraph1"> Flowtrace breaks down every day to 15 minute periods and calculates your employee tool activity statistics.</p>)

export default function Dashboard(props) {
  return (
    <Fragment>
      <PageTitle key="title" titleHeading="Company's Tool Usage Overview" titleDescription="Identify the tools which don't get the use you want." explanation={explanation}/>

      <ToolStatistics flowtraceUser={ props.flowtraceUser }/>
    </Fragment>
  );
}
