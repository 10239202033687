import React, { Component, Fragment } from 'react';

import Loading from '../../../flowtrace-components/Loading'
import { Card, CardContent, Grid, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { API } from 'aws-amplify';
import MuiAlert from '@material-ui/lab/Alert';
import queryString from 'query-string';

const saved = <MuiAlert key="saveOK" severity="success"> <strong className="d-block">Notification Setting Saved!</strong> </MuiAlert>;
const warning = <MuiAlert key="saveWarn" severity="warning"> <div className="d-flex align-items-center align-content-center"> <span> <strong className="d-block">Nothing to unsubscribe!</strong><p>You are not subscribed to Weekly Insights email. Please send an email to contact@flowtrace.co if the problem persists.</p> </span> </div> </MuiAlert>;
const error = <MuiAlert key="saveNOK" severity="error"> <div className="d-flex align-items-center align-content-center"> <span> <strong className="d-block">Error when saving notification settings!</strong><p>Please try again later.</p> </span> </div> </MuiAlert>;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, alert: null };
  }

  async loadUser() {
    const flowtraceAPI = 'slackintegration';
    const callHeaders = { headers: {}, response: true };

    const response = await API.get(flowtraceAPI, '/user', callHeaders);
    const account = response.data && response.data.success ? response.data.account : null;
    const profile = response.data && response.data.success ? response.data.profile : null;
    this.setState({ account, profile, loading: false });
    return;
  }

  async saveSubscription(subscribed) {
    this.setState({ saving: true });
    const flowtraceAPI = 'slackintegration';
    const callHeaders = { headers: {}, response: true };

    try {
      const response = await API.patch(flowtraceAPI, '/user/notification/weekly/' + subscribed, callHeaders);
      await this.loadUser();

      if (response.data.success)
        this.setState({ saving: false, alert: saved }); // Update the profile information
      else if (response.data.message === "Nothing to update.")
        this.setState({ saving: false, alert: warning }); // Update the profile information
      setTimeout(() => { this.setState({ alert: null }) }, response.data.success ? 3000 : 10000);
    }
    catch (e) {
      this.setState({ saving: false, alert: error }); // Update the profile information
      setTimeout(() => { this.setState({ alert: null }) }, 20000);
    }
    return;
  }

  async subscriptionChange(event) {
    if (event.target.name === "weeklySubscription")
      this.saveSubscription(event.target.checked);

  }


  async componentDidMount() {
    await this.loadUser();
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.weekly && queryParams.weekly === "unsubscribe")
      this.saveSubscription(false);
  }


  render() {
    if (this.state.loading) {
      return <Loading/>;
    }
    if (!this.state.profile) {
      return <Loading error="Couldn't find your profile." />;
    }
    const weekly = this.state.profile.weekly_insights ? this.state.profile.weekly_insights : { subscribed: true };
    return (
      <Fragment>
        <Card className="card-box mb-4">
          <CardContent className="p-4">
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                <div className="font-size-lg font-weight-bold mb-3">Profile Details</div>
                <div className="p-3">
                  <TextField fullWidth disabled className="m-2" id="fullname" label="Name" value={this.state.profile.name?this.state.profile.name:this.state.profile.full_name}/>
                  <TextField fullWidth disabled className="m-2" id="email" label="Email" value={this.state.profile.email}/>
                </div>
              </Grid>
              <Grid item xs={12} lg={6}>
                <div className="font-size-lg font-weight-bold mb-3">Email Subscriptions</div>
                <div className="p-3">
                  <FormControlLabel color="secondary" disabled={this.state.saving} control={<Checkbox checked={weekly.subscribed } onClick={this.subscriptionChange.bind(this)} name="weeklySubscription" />} label="Weekly Insights Email" />
                  {this.state.alert?this.state.alert:null}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Fragment>
    );

  }
}

export default Profile;
