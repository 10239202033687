import React, { Fragment } from 'react';
import { MenuItem, Select, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, } from '@material-ui/core';

import { API } from 'aws-amplify';
import { Logger } from 'aws-amplify';

const logger = new Logger('admin', 'DEBUG');

async function storeToDatabase(accountId, command, value) {
  const flowtraceAPI = 'slackintegration';
  const url = '/members/clientAccounts/' + accountId;
  const callHeaders = {
    // TODO: This should not work... Figure out what is going on...
    body: { command: command, variable: "properties", cognitoIdentityIds: value },
    response: true
  };

  const stored = await API.patch(flowtraceAPI, url, callHeaders).then((response, data, body) => {
    if (response.status !== 200) {
      logger.debug("Error saving the property to the client account.")
      return false
    }
    return true;
  }).catch((error) => {
    logger.error(error, error.message);
    return false
  });
  return stored;
}


//Usage: <AddClientAccountDialog dialogState={this.state.dialogOpen} onClose={this.handleClose.bind(this)} />
export default function AddClientAccountProperty(data) {

  const [userCognitoId, setUserCognitoId] = React.useState('');
  const [validating, setValidating] = React.useState(false);
  const [validationError, setValidationError] = React.useState('');
  const [validationSuccess, setValidationSuccess] = React.useState('');
  //  if (!data.account) return
  logger.debug("AddProperty DialogLoad", data)

  const handleClose = () => { data.onClose(); };
  const handleSave = async() => {
    try {
      if (!userCognitoId || userCognitoId.length !== 46)
        throw new Error("CognitoID not valid")

      // Init the values:
      setValidating(true)
      setValidationSuccess("")
      setValidationError("")
      // Store to DB
      let userList = []

      if (data.command === "add")
        userList.push(userCognitoId)
      // if (data.command === "delete")
      //   accountProperty = propertyName

      await storeToDatabase(data.account.account_id, data.command, userList).then(response => {
        if (response) {
          setValidationSuccess("Account updated succesfully.")

          // Call close as success
          data.onClose();
        }
        else
          setValidationError("Failed to update the account.")
      })
    }
    catch (e) {
      logger.error("Validation Failed:", e.message)
      setValidationError("Validation Failed: " + e.message)

    }
    setValidating(false)

  };


  return (
    <Fragment>
      <Dialog open={data.dialogState} onClose={handleClose} aria-labelledby="form-dialog-title">
        {
          data.command==="add" ?
            <DialogTitle id="form-dialog-title">Add user to {data.account? data.account.accountName:""} account </DialogTitle>
          :
            <DialogTitle id="form-dialog-title">Remove user from {data.account? data.account.accountName:""} account</DialogTitle>
        }
        <DialogContent>
        {
          data.command==="add" ?
            <div>
              <DialogContentText>Use fromat "eu-west-1:db60c044-d104-4b1c-bdd6-7cf8e188d7fd"</DialogContentText>
              <DialogContentText className="text-danger"> {validationError}</DialogContentText>
              <DialogContentText className="text-success"> {validationSuccess}</DialogContentText>
            </div>
            :
            null
        }

          {data.command==="delete" ?
            <Select labelId="select-property-to-delete" id="remove-property-selector" value={undefined} onChange={e => setUserCognitoId(e.target.value)} fullWidth margin="dense">
              <MenuItem value={undefined}>Select Item</MenuItem>
              {[]}
            </Select>
          :null}
          {data.command==="add" ?
          <div>
            <TextField disabled={validating} onInput={e => setUserCognitoId(e.target.value)} autoFocus margin="dense" id="userCognitoID" label="Cognito ID of the user" type="text" fullWidth />
          </div>
          :null}
        </DialogContent>
        <DialogActions>
          {
            data.command==="add" ?
              <Button onClick={handleSave} disabled={validating} color="primary"> Add </Button>
            :
              <Button onClick={handleSave} disabled={validating} color="primary"> Remove </Button>
          }
          <Button onClick={handleClose} disabled={validating} color="primary"> Cancel </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
