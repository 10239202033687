import React, { Component } from 'react';
import { Button, ButtonGroup, Popover } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';
import LoadingButton from './loadingButton';


function getDefinedRanges(useFixedDates = true) {
    const today = moment().format("YYYY-MM-DD");
    const thisWeekStart = moment().subtract(moment().isoWeekday() - 1, "days").format("YYYY-MM-DD");
    const thisMonthStart = moment().format("YYYY-MM-01");
    const thisYearStart = moment().format("YYYY-01-01");
    const thisQuarterStart = moment(thisYearStart).quarter(moment().quarter()).format("YYYY-MM-01");
    const lastQuarterStart = moment(thisYearStart).quarter(moment().quarter() - 1).format("YYYY-MM-01");
    const lastQuarterEnd = moment(thisQuarterStart).subtract(1, 'days').format("YYYY-MM-DD");
    const lastWeekStart = moment().subtract(moment().isoWeekday() + 6, "days").format("YYYY-MM-DD");
    const lastWeekEnd = moment().subtract(moment().isoWeekday() , "days").format("YYYY-MM-DD");
    const lastMonthStart = moment().subtract(1, 'months').format("YYYY-MM-01");
    const lastMonthEnd = moment().day(1).subtract(1, 'days').format("YYYY-MM-01");
    const lastYearStart = moment().subtract(1, 'years').format("YYYY-01-01");
    const lastYearEnd = moment().subtract(1, 'years').format("YYYY-12-31");

    const last7Start = moment().subtract(7, 'days').format(useFixedDates ? "YYYY-MM-DD" : "YYYY-MM-01");
    const last30Start = moment().subtract(30, 'days').format(useFixedDates ? "YYYY-MM-DD" : "YYYY-MM-01");
    const last90Start = moment().subtract(90, 'days').format(useFixedDates ? "YYYY-MM-DD" : "YYYY-MM-01");
    const last180Start = moment().subtract(180, 'days').format(useFixedDates ? "YYYY-MM-DD" : "YYYY-MM-01");
    const last360Start = moment().subtract(360, 'days').format(useFixedDates ? "YYYY-MM-DD" : "YYYY-MM-01");

    //const thisYearStart = moment().format("YYYY-01-01");

    return [
        {label:"This week", startDate: thisWeekStart, endDate: today},
        {label:"Last week", startDate: lastWeekStart, endDate: lastWeekEnd},
        {label:"This month", startDate: thisMonthStart, endDate: today},
        {label:"Last month", startDate: lastMonthStart, endDate: lastMonthEnd},
        {label:"This quarter", startDate: thisQuarterStart, endDate: today},
        {label:"Last quarter", startDate: lastQuarterStart, endDate: lastQuarterEnd},
        {label:"This year", startDate: thisYearStart, endDate: today},
        {label:"Last year", startDate: lastYearStart, endDate: lastYearEnd},
        {label:"Last 7 days", startDate: last7Start, endDate: today},
        {label:"Last 30 days", startDate: last30Start, endDate: today},
        {label:"Last 90 days", startDate: last90Start, endDate: today},
        {label:"Last 180 days", startDate: last180Start, endDate: today},
        {label:"Last 360 days", startDate: last360Start, endDate: today},
    ];
}

class FilterBarCalendarOptions extends Component {
    constructor(props) {
        super(props);
        const initial = {};
        if(this.props.defaults && this.props.defaults.range_start)
            initial.startDate = this.props.defaults.range_start;
        if(this.props.defaults && this.props.defaults.range_end)
            initial.endDate = this.props.defaults.range_end;
        this.state = { loading: true, range:null, initial, anchor: null };
    }

    componentDidMount() {
        this.setState({ loading: false });
    }

    datePickerToggle() { this.setState({ anchor:null}) }
    datePickerRangeSelected(range) {
        this.setState({range, anchor:null});
        const range_start = moment(range.startDate).format("YYYY-MM-DD");
        const range_end = moment(range.endDate).format("YYYY-MM-DD");
        return this.props.onChange({ range_type: "calendar", range_start, range_end });
    }
    getRangeText() {

        if(!this.state.range || !this.state.initial)
            return "Select..."
        if(this.state.range && this.state.range.label)
            return this.state.range.label;

        if(!this.state.range && this.props.default)
            return this.state.default.range_start + " - " + this.state.default.range_end;
        return moment(this.state.range.startDate).format("YYYY-MM-DD") + " - " + moment(this.state.range.endDate).format("YYYY-MM-DD");
    }
    render() {
        if (this.props.loading) {
            return <LoadingButton />;
        }

        const buttonGroup =
            <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                <Button color="secondary" onClick={(e) => this.setState({anchor:e.currentTarget})}>{ this.getRangeText() }
                </Button>
                <Button color="secondary" size="small" aria-haspopup="true" onClick={(e) => this.setState({anchor:e.currentTarget})}>
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>;


        const max = moment().format("YYYY-MM-DD");
        const min = this.props.free ? moment().subtract(3, 'months').format("YYYY-MM-DD") : null;

        const initial = this.state.range ? this.state.range : this.state.initial;
        const rangeSelection =
            <Popover id="datepickerMenu" open={Boolean(this.state.anchor)} anchorEl={this.state.anchor}>
                <DateRangePicker maxDate={max} minDate={min} initialDateRange={initial} definedRanges={getDefinedRanges(this.props.fixedCalendarDates)} open={this.state.anchor} onClose={() => this.setState({anchor:null})} toggle={this.datePickerToggle.bind(this)} onChange={(range) => this.datePickerRangeSelected(range)} />
            </Popover>;

        return (
            <div style={{float:'left', marginBottom:"15px" }}>
                {buttonGroup}
                {rangeSelection}
            </div>
        );

    }
} // end of class

export default FilterBarCalendarOptions;
