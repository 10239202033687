import Chart from 'react-apexcharts';
import React, { Component, Fragment } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Card, CardContent } from '@material-ui/core';
import extract from '../dataPointExtractor';
import { HelpOutline } from '@material-ui/icons';
const info = <HelpOutline style={{ fontSize: 15 }}/>;

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const trendLinedSeries = {
      minutes: [],
      stats: [],
      types:[],
      video:[],
      total:[]
    };

    // Prepare the series data for trendlines:
    const categories = this.props.histogram.sort((a, b) => (a.date > b.date) ? 1 : -1).map(item => { return item.key_as_string });

    const seriesData = { totalMeetings:[], avgEvent: [], internalInvitesSum:[], externalInvitesSum:[], groupInvitesSum:[], lateSum: [], callSum: [], totalTime: [], totalLOTime: [], totalHITime: [],zoomSum: [], meetsSum: [], internalSum:[], externalSum:[], totalSum:[], one2oneSum:[], groupSum: [] };

    const getValue = (data, object, divider) => {
      const keys = object.split(".");
      let value = data;
      try{
        for(const key of keys)
          value = value[key];
        if(value > 0)
          return divider ? value/divider : value;
      } catch(e) {}
      return null;
    };

    for (const cat of categories) {
      // Loop every category through so the stats are aligned
      const stat = this.props.histogram.find(stat => stat.key_as_string === cat);
      seriesData.totalTime.push(getValue(stat, "duration.ALL.sum", 60) );
      seriesData.totalHITime.push(getValue(stat, "duration.HI.sum", 60) );
      seriesData.totalLOTime.push(getValue(stat, "duration.LO.sum", 60) );
      seriesData.totalMeetings.push(getValue(stat, "event_count"));

      seriesData.avgEvent.push(getValue(stat, "duration.event.avg", 60));
      seriesData.internalInvitesSum.push(getValue(stat, "internal.invites.avg"));
      seriesData.externalInvitesSum.push(getValue(stat, "external.invites.avg"));
      seriesData.groupInvitesSum.push(getValue(stat, "group.invites.avg"));

      const dataPoint = this.props.statistics.stats.find(stat => stat.date === cat);
      const data = extract.extractData(dataPoint);

      const internal = getValue(data, "internalMeeting");
      const external = getValue(data, "externalMeeting");
      const total = (internal + external) > 0 ? internal + external : null;

      seriesData.internalSum.push(internal);
      seriesData.externalSum.push(external);
      seriesData.totalSum.push(total);
      seriesData.one2oneSum.push(getValue(data, "one2oneMeeting"));
      seriesData.groupSum.push(getValue(data, "groupMeeting"));
      seriesData.meetsSum.push(getValue(data, "meets"));
      seriesData.zoomSum.push(getValue(data, "zoom"));
      seriesData.lateSum.push(getValue(stat, "video.late.sum", 60));
      seriesData.callSum.push(getValue(stat, "video.call.sum", 60));
    }

    const defaultFormatter = (val) => { return (val && val > 0 ? val.toFixed(0) : ""); };
    //const lateFormatter = (val) => { return (val && val > 0 ? val.toFixed(0) + "m" :""); };
    const totalFormatter = (val) => {return (val && val > 0 ? val.toFixed(1) + "h" : "") };

    trendLinedSeries.stats.push({ name: "Length (Hours)", type: "line", data: seriesData.avgEvent });
    trendLinedSeries.stats.push({ name: "Internal Invites", type: "line", data: seriesData.internalInvitesSum });
    trendLinedSeries.stats.push({ name: "External Invites", type: "line", data: seriesData.externalInvitesSum });
    trendLinedSeries.stats.push({ name: "Group Invites", type: "line", data: seriesData.groupInvitesSum });

    trendLinedSeries.total.push({ name: "Meeting Time (Invited)", type: "line", data: seriesData.totalTime, format:totalFormatter });
    trendLinedSeries.total.push({ name: "Meeting Time (HI)", type: "line", data: seriesData.totalHITime, format:totalFormatter });
    trendLinedSeries.total.push({ name: "Meeting Time (LO)", type: "line", data: seriesData.totalLOTime, format:totalFormatter });
    trendLinedSeries.total.push({ name: "# Meetings", type: "line", data: seriesData.totalMeetings, format:defaultFormatter });

    trendLinedSeries.types.push({ name: "External", type: "line", data: seriesData.externalSum });
    trendLinedSeries.types.push({ name: "Internal", type: "line", data: seriesData.internalSum });
    trendLinedSeries.types.push({ name: "1:1", type: "line", data: seriesData.one2oneSum });
    trendLinedSeries.types.push({ name: "Groups", type: "line", data: seriesData.groupSum });
    trendLinedSeries.types.push({ name: "Total", type: "line", data: seriesData.totalSum });

    //trendLinedSeries.video.push({ name: "Google Meets", type: "line", data: seriesData.meetsSum });
    //trendLinedSeries.video.push({ name: "Zoom", type: "line", data: seriesData.zoomSum });
    trendLinedSeries.video.push({ name: "Video Call Time", type: "line", data: seriesData.callSum });
    trendLinedSeries.video.push({ name: "Meeting Delay", type: "line", data: seriesData.lateSum });

    this.setState({ loading: false, trendLinedSeries, categories});
    //this.props.timestamp("meetingStats processing done")
  }


  render() {

    if (this.state.loading)
      return <div/>;

    const commonConfig = {
      chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
      dataLabels: { enabled: false },
      xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: this.state.categories ? this.state.categories : [] },
      legend: { position: "right", show: true },
      theme: { mode: 'light', palette: 'palette2', },
      markers: { size: 5, strokeColors: '#fff', strokeWidth: 1, shape: "circle", radius: 2, }
    };

    const meetingTrendLine = {
      ...commonConfig,
      yaxis: { forceNiceScale: true, labels: { formatter: function(val, index) {
        if(index && index.seriesIndex && index.w && index.w.config.series[index.seriesIndex].format)
          return index.w.config.series[index.seriesIndex].format(val);
        return (val ? (Math.abs(val) / 60).toFixed(1) + "h" : 0);
        }
      }
      },
    };
    const totalConfig = {
      ...commonConfig,
      yaxis: { forceNiceScale: true, labels: { formatter: function(val, index) {
        if(index && index.seriesIndex && index.w && index.w.config.series[index.seriesIndex].format)
          return index.w.config.series[index.seriesIndex].format(val);
        return (val ? val.toFixed(0) + "h" : 0);
        }
      }
      },
    };
    const scheduledMeetingConfig = {
      ...commonConfig,
      yaxis: { forceNiceScale: true, labels: { formatter: function(val) { return (val && val > 0 ? val.toFixed(1) : ""); } } },
    };

    // Checks to see if we need to hide time algorithm estimates or not:
    const filterWarning = <div className="m-3">This chart cannot be shown with current meeting filters.</div>;
    const qf = this.props.queryFilters;
    const af = qf.audienceFilter && qf.audienceFilter.length !== 3 ? true : false; // 3 seletions
    const lf = qf.lengthFilter && (qf.lengthFilter[0] !== 0 || qf.lengthFilter[1] !== 720) ? true : false; // 2 options (0 -> 720 minutes)
    const tf = qf.typeFilter && qf.typeFilter.length !== 2 ? true : false; // 2 types
    const filterApplied = lf || af || tf ? true : false;

    return (
      <Fragment >
        <Card className="card-box mb-4">
          <CardContent style={{width:"100%"}}>
            <div className=" font-weight-bold font-size-xl text-uppercase" >Scheduled Meetings Types and Times</div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="This is a sum of all meetings and how many hours of meetings are scheduled for the given time period. The times include group and direct invites. Invited time is all internal invite time aggregation, HI is all non-declined internal invite time aggregation, and LO is all accepted internal invite time aggregation.">
                    <div className="font-weight-bold mr-3"> Meeting Totals {info}</div>
                  </Tooltip>
                </div>
                <Chart options={totalConfig} series={this.state.trendLinedSeries.total} type="line" height="200"/>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="This is a 'average' of the meeting. (i.e. an average meeting has 0.5 internal participants, 0.7 external participants, and we schedule them for 25 minutes) ">
                    <div className="font-weight-bold mr-3"> Company Meeting Average {info}</div>
                  </Tooltip>
                </div>
                <Chart options={scheduledMeetingConfig} series={this.state.trendLinedSeries.stats} type="line" height="200"/>
              </Grid>

              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="These time estimates are calculated based on workday estimation algorithms. They are great at indicating meeting load per employee.">
                    <div className="font-weight-bold mr-3"> Meeting Time Estimate Trends {info}</div>
                  </Tooltip> (per employee, per {this.props.period})
                </div>
                { filterApplied ?
                  filterWarning
                :
                  <Chart options={meetingTrendLine} series={this.state.trendLinedSeries.types} type="line" height="200"/>
                }
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="d-flex align-items-start">
                  <Tooltip title="These time estimates are calculated based on video call start and end information algorithms. This estimate includes all video call records with unique ID against a scheduled calendar event. Meeting delay is an aggregate of all the participants who joined late to a given meeting we have a record of (i.e. 2 participants who join a meeting 10 minutes late would be shown as 0.3h late). Proportion of your online/hybrid/office based meeting can have big impact on this value. This is a premium feature.">
                    <div className="font-weight-bold mr-3"> Video Call Time Trends {info}</div>
                  </Tooltip>
                </div>
                  <Chart options={meetingTrendLine} series={this.state.trendLinedSeries.video} type="line" height="200"/>
              </Grid>
            </Grid>
          </CardContent>
         </Card>
        </Fragment>
    ); // end of return

  } // end of render()

} // end of class

export default MeetingStatisticsTable
