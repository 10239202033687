import React, { Component, Fragment } from 'react';
import { PageTitle } from '../../../layout-components';
import UserProfile from '../../../flowtrace-components/Configuration/Profile';

class Profile extends Component {

  render() {
    return (
      <Fragment>
          <PageTitle titleHeading="My Profile" titleDescription="Here you can change your profile and notification settings." />
          <UserProfile/>

        </Fragment>
    );
  }
}

export default Profile;
