import React, { Fragment } from 'react';

import { PageTitle } from '../../layout-components';
import Paywall from '../../flowtrace-components/Common/Paywall';

import MeetingStatistics from '../../assets/images/paywall/meeting_statistics_paywall.png';
import MeetingDetails from '../../assets/images/paywall/meeting_details_paywall.png';
import MeetingOverview from '../../assets/images/paywall/meeting_overview_paywall.png';
import MeetingAudit from '../../assets/images/paywall/meeting_audit_paywall.png';
import ProductivityOverview from '../../assets/images/paywall/producitivity_overview_paywall.png';
import TeamProductivity from '../../assets/images/paywall/team_producitivity_paywall.png';
import EmployeeOnboarding from '../../assets/images/paywall/employee_onboarding_paywall.png';


export default function PaywallPage(props) {

  const blurred = [];
  if (props.page === "Meeting Statistics")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={MeetingStatistics} />);
  if (props.page === "Meeting Details")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={MeetingDetails} />);
  if (props.page === "Meeting Overview")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={MeetingOverview} />);
  if (props.page === "Meeting Audit")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={MeetingAudit} />);
  if (props.page === "Productivity Overview")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={ProductivityOverview} />);
  if (props.page === "Team Productivity")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={TeamProductivity} />);
  if (props.page === "Employee Onboarding")
    blurred.push(<img key="paywalled" width="100%" alt="Paywall" src={EmployeeOnboarding} />);

  return (
    <Fragment>
      <PageTitle key="paywalltitle" titleHeading={props.page} titleDescription="This premium feature is available in Flowtrace paid plans when integrated with Google Workspace or Outlook." callToAction={<Paywall {...props} />}/>
      {blurred}
    </Fragment>
  );
}
