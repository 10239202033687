import { Grid, Card, CardContent } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import Loading from '../Loading';
import KPICard from './genericKPICard';
import ComparisonChart from './genericComparisonColumnChart';

function calculateEngagementKPIs(current, previous = null) {
  // Calculate the KPIs and deltas (attendance, average meeting length, agenda%):
  const engagementKPI = { engagement: 0, influence: 0, connectedness: 0, activity: 0, sentiment: 0, count: 0, dataFound: false };
  const stats = current.data && current.data.stats && current.data.stats.company ? current.data.stats.company : [];

  if (previous)
    engagementKPI.previous = { ...calculateEngagementKPIs(previous) };

  for (const stat of stats) {
    engagementKPI.engagement += stat.engagement * stat.activeDays | 0;
    engagementKPI.influence += stat.influence * stat.activeDays | 0;
    engagementKPI.connectedness += stat.connectedness * stat.activeDays | 0;
    engagementKPI.activity += stat.activity * stat.activeDays | 0;
    engagementKPI.sentiment += stat.sentiment * stat.activeDays | 0;
    engagementKPI.count += stat.activeDays;
    engagementKPI.dataFound = true;
  }

  engagementKPI.engagement = engagementKPI.engagement / engagementKPI.count;
  engagementKPI.influence = engagementKPI.influence / engagementKPI.count;
  engagementKPI.connectedness = engagementKPI.connectedness / engagementKPI.count;
  engagementKPI.activity = engagementKPI.activity / engagementKPI.count;
  engagementKPI.sentiment = engagementKPI.sentiment / engagementKPI.count;
  return engagementKPI;
}

class EngagementKPIComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }


  async loadStatistics() {
    const dataAPI = 'slackintegration';
    const statisticsPath = '/slack/installed/statistics';

    const currentAPI = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.current } };
    const previousAPI = { headers: {}, response: true, queryStringParameters: { ...this.props.queryFilters.previous } };

    try {
      const [currentData, previousData] = await Promise.all([
        API.get(dataAPI, statisticsPath, currentAPI),
        API.get(dataAPI, statisticsPath, previousAPI)
      ]);
      return this.setState({ loading: false, engagementKPI: calculateEngagementKPIs(currentData, previousData) });
    }
    catch (e) {}
    return this.setState({ loading: false, engagementKPI: null });
  }

  async componentDidMount() {
    this.loadStatistics();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => { return; };
  }

  render() {
    if (this.state.loading)
      return (
        <Grid item xs={12} sm={6} md={4} xl={2}>
          <Fragment >
            <Card>
              <CardContent >
                <div className="p-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Engagement</div>
                <Loading minimize="true" />
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
      );

    const leftHandColumn = [
      <ComparisonChart key="engagement_chart" title={"Organizational graph metrics:"} metrics={this.state.engagementKPI} exclude={["count", "dataFound"]}/>
    ];


    const rightHandColumn = [];
    if (this.state.engagementKPI && this.state.engagementKPI.engagement)
      rightHandColumn.push(<KPICard key="engagementScore" KPI="engagement_measured" benchmark={this.props.benchmark} value={this.state.engagementKPI.engagement} compareValue={this.state.engagementKPI.previous.engagement}/>);
    if (this.props.engagementSurveyScore && this.props.engagementSurveyScore.average)
      rightHandColumn.push(<KPICard key="engagementSurveyScore" KPI="engagement_survey" benchmark={this.props.benchmark} value={this.props.engagementSurveyScore.average} compareValue={this.props.engagementSurveyScore.previous?this.props.engagementSurveyScore.previous.average:null} />);
    if (this.props.npsSurveyScore && this.props.npsSurveyScore.average)
      rightHandColumn.push(<KPICard key="npsSurveyScore" KPI="engagement_enps" benchmark={this.props.benchmark} value={this.props.npsSurveyScore.average} compareValue={this.props.npsSurveyScore.previous?this.props.npsSurveyScore.previous.average:null} />);

    if (rightHandColumn.length === 0)
      return null // Return nothing for no cards


    const title = [<div key="title" className="pl-3 mt-3 font-weight-bold font-size-xl text-uppercase" >Engagement</div>];
    return (
      <Grid item xs={12} md={6} xl={4}>
        <Fragment>
          <Card className="card-box mb-4">
            <CardContent className="p-0">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6}>
                    {title}
                    {leftHandColumn.length >0 ? leftHandColumn : null}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {rightHandColumn.length >0 ? rightHandColumn : null}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Fragment>
        </Grid>
    );
  } // end of render()
} // end of class

export default EngagementKPIComponent;
