import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';

const Footer = props => {
  let { footerFixed } = props;
  return (
    <Fragment>
      <Paper square className={clsx('app-footer text-black-50', { 'app-footer--fixed': footerFixed })}>
        <div className="app-footer--inner">
          <div className="app-footer--first d-flex justify-content-center">
            <span>Flowtrace © {new Date().getFullYear()}</span>
          </div>
          <div className="app-footer--second d-flex justify-content-center">
            Created with <span className="text-danger px-1">❤</span>
            <span className="mr-1"> by</span>
            <a href="https://www.flowtrace.co" title="Flowtrace.co"> Flowtrace</a>
          </div>
        </div>
      </Paper>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  footerFixed: state.ThemeOptions.footerFixed
});

export default connect(mapStateToProps)(Footer);
