import React, { Fragment } from 'react';
import { MenuItem, Select, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, } from '@material-ui/core';

import { API } from 'aws-amplify';
import { Logger } from 'aws-amplify';

const logger = new Logger('admin', 'DEBUG');

async function storeToDatabase(accountId, command, value) {
  const flowtraceAPI = 'slackintegration';
  const url = '/members/clientAccounts/' + accountId;
  const callHeaders = {
    body: { command: command, variable: "properties", value: value },
    response: true
  };

  const stored = await API.put(flowtraceAPI, url, callHeaders).then((response, data, body) => {
    if (response.status !== 200) {
      logger.debug("Error saving the property to the client account.")
      return false
    }
    return true;
  }).catch((error) => {
    console.error(error, error.message);
    return false
  });
  return stored;
}


//Usage: <AddClientAccountDialog dialogState={this.state.dialogOpen} onClose={this.handleClose.bind(this)} />
export default function AddClientAccountProperty(data) {

  const [propertyName, setPropertyName] = React.useState('');
  const [propertyValue, setPropertyValue] = React.useState('');
  const [validating, setValidating] = React.useState(false);
  const [validationError, setValidationError] = React.useState('');
  const [validationSuccess, setValidationSuccess] = React.useState('');
  //  if (!data.account) return
  logger.debug("DialogLoad", data)

  const handleClose = () => { data.onClose(); };
  const handleSave = async() => {
    try {
      if (!propertyName || propertyName.length <= 1)
        throw new Error("Property name cannot be empty")
      if (data.command === "add" && (!propertyValue || propertyValue.length <= 1))
        throw new Error("Property value cannot be empty")

      // Init the values:
      setValidating(true)
      setValidationSuccess("")
      setValidationError("")
      // Store to DB
      let accountProperty = {}

      if (data.command === "add")
        accountProperty[propertyName] = propertyValue
      if (data.command === "delete")
        accountProperty = propertyName

      logger.debug(data.command, propertyValue, propertyName)
      await storeToDatabase(data.account.account_id, data.command, accountProperty).then(response => {
        if (response) {
          setValidationSuccess("Account updated succesfully.")
          // Call close as success
          data.onClose();
        }
        else
          setValidationError("Failed to update the account.")
      })
    }
    catch (e) {
      logger.error("Validation Failed:", e.message)
      setValidationError("Validation Failed: " + e.message)

    }
    setValidating(false)

  };

  const deletePropertyOptions = []
  if (data.account) {
    for (const propertyList of data.account.properties) {
      for (const [key, value] of Object.entries(propertyList)) {
        deletePropertyOptions.push(<MenuItem value={propertyList}>{key + ":" + value}</MenuItem>)
      }
    }
  }

  return (
    <Fragment>
      <Dialog open={data.dialogState} onClose={handleClose} aria-labelledby="form-dialog-title">
        {
          data.command==="add" ?
            <DialogTitle id="form-dialog-title">Add new property</DialogTitle>
          :
            <DialogTitle id="form-dialog-title">Remove selected property:</DialogTitle>
        }
        <DialogContent>
        {
          data.command==="add" ?
            <div>
              <DialogContentText>Property name nor value can be empty.</DialogContentText>
              <DialogContentText className="text-danger"> {validationError}</DialogContentText>
              <DialogContentText className="text-success"> {validationSuccess}</DialogContentText>
            </div>
            :
            null
        }

          {data.command==="delete" ?
            <Select labelId="select-property-to-delete" id="remove-property-selector" value={undefined} onChange={e => setPropertyName(e.target.value)} fullWidth margin="dense">
              <MenuItem value={undefined}>Select Item</MenuItem>
              {deletePropertyOptions}
            </Select>
          :null}
          {data.command==="add" ?
          <div>
            <TextField disabled={validating} onInput={e => setPropertyName(e.target.value)} autoFocus margin="dense" id="propertyName" label="Property name" type="text" fullWidth />
            <TextField disabled={validating} onInput={e => setPropertyValue(e.target.value)} margin="dense" id="propertyValue" label="Property value" type="text" fullWidth />
          </div>
          :null}
        </DialogContent>
        <DialogActions>
          {
            data.command==="add" ?
              <Button onClick={handleSave} disabled={validating} color="primary"> Save </Button>
            :
              <Button onClick={handleSave} disabled={validating} color="primary"> Remove </Button>
          }
          <Button onClick={handleClose} disabled={validating} color="primary"> Cancel </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
