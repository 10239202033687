import React, { Component, Fragment } from 'react';
import { Grid } from '@material-ui/core';
//import { API } from 'aws-amplify';
import Loading from '../Loading';
import FilterBar from '../Common/FilterBar';
//import moment from 'moment'
import FilterComponent from '../Common/FilterBar/injectedFilter';

//import MeetingKPIComponent from './meetingKPIComponent';
import ToolKPIComponent from './toolKPIComponent';


class MetricDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, benchmark:true, queryFilters: { range_type: "calendar", range_period: "month", range_limit: 12, range_window:30 } };
  }

  async componentDidMount() { this.setState({ loading: false }) }
  componentWillUnmount() {  this.setState = (state, callback) => { return; }; } // fix Warning: Can't perform a React state update on an unmounted component
  onFilterChange(data) { this.setState({ selectWindow: data.filter, queryFilters:{...this.state.queryFilters, range_window:data.filter} }); }
  async queryFiltersUpdated(queryFilters) { this.setState({ queryFilters}) }

  render() {
    const selectWindow = <FilterComponent options={["1", "7", "30", "90"]} default={this.state.queryFilters.range_window} name="Previous (x) days" showTitle onChange={this.onFilterChange.bind(this)} />;

    return (
      <Fragment>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12}>
              <FilterBar queryFilters={this.state.queryFilters} onChange={this.queryFiltersUpdated.bind(this)} injectedComponent={selectWindow}/>
            </Grid>
          </Grid>
          {!this.state.loading?
            <Grid container spacing={4}>
              <ToolKPIComponent
                tool="jira"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="gitlab"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="github"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="hubspot"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="front"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="clickup"
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="meeting"
                metric
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
              <ToolKPIComponent
                tool="slack"
                metric
                flowtraceUser={this.props.flowtraceUser}
                queryFilters={this.state.queryFilters}
              />
            </Grid>
          :
            <Loading/>
          }

        </Fragment>
    );
  }
}

export default MetricDashboard;
