import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import StepNavigation from './stepNavigation';
import ExtensionSettings from '../Configuration/AccountManagement/ChromeExtensionSettings';



class Complete extends Component {

  render() {
    if(!this.props.isActive) return null;

    const extensionPropsFound = this.props.flowtraceUser.account && this.props.flowtraceUser.account.props.chromeExtension ? true : false;

    return (
      <StepNavigation stepTitle="Appearance configuration" {...this.props} nextDisabled={!extensionPropsFound}>
        <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <ExtensionSettings onboarding={true} flowtraceUser={this.props.flowtraceUser}/>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
          </Grid>
        </Grid>
      </StepNavigation>
    );
  }
}

export default withRouter(Complete);
