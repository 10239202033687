import { Grid, Card, CardContent } from '@material-ui/core';
import React from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Box, Typography, Tabs, Tab } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, Button, ButtonGroup } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Moment from 'moment';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (<Typography component="div" role="tabpanel" hidden={value !== index} {...other}> {value === index && <Box p={3}>{children}</Box>} </Typography>);
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  padding: { padding: theme.spacing(3), },
  themeColorTabs: { backgroundColor: '#3d4977', },
}));
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': { maxWidth: 40, width: '100%', backgroundColor: '#f5f6f7', },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export default function UserStatisticsChart(data) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [timeSpan, setTimeSpan] = React.useState(data.timeSpan);
  const handleClick = event => { setAnchorEl(event.currentTarget); };
  const handleClose = timeSpan => {
    setTimeSpan(timeSpan)
    data.reload(timeSpan)
    setAnchorEl(null);
  };

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const userArray = data.user; // Array of all users
  const series = { engagement: [], activity: [], sentiment: [], connectedness: [], influence: [] };
  const categories = []; // Week number / date (fill this outside of user loop so whole data range gets populated)

  // Prepopulate the categories from every single user, so they can be used to fill empty values for missing data later on
  for (const userKey in userArray.filter(element => (element.activeDays !== undefined))) {
    const user = userArray[userKey]
    for (const key in user.timeStats) {
      const axisLabel = user.timeStats[key].timeSpan //data.timeSpan === "week" ? "Week " + (Number.parseInt(user.timeStats[key].timeSpan) + 1) : "Month " + (Number.parseInt(user.timeStats[key].timeSpan) + 1)
      //const axisTime = Moment(user.timeStats[key].timeSpan).format()
      //console.log(data.timeSpan, axisTime)
      if (!categories.includes(axisLabel)) categories.push(axisLabel); // X-Axis
    }
  }

  for (const userKey in userArray) {
    const user = userArray[userKey]
    const engagement = [];
    const activity = [];
    const sentiment = [];
    const connectedness = [];
    const influence = [];
    const engagementPrediction = [];
    const activityPrediction = [];
    const sentimentPrediction = [];
    const connectednessPrediction = [];
    const influencePrediction = [];
    const userName = user.name


    for (const key in user.timeStats.filter(element => (element.activeDays !== undefined))) {
      const stamp = Moment(user.timeStats[key].timeSpan).valueOf()
      engagement.push([stamp, user.timeStats[key].score.engagement]);
      activity.push([stamp, user.timeStats[key].score.activity.score]);
      sentiment.push([stamp, user.timeStats[key].score.sentiment.score]);
      connectedness.push([stamp, user.timeStats[key].score.connectedness.score]);
      influence.push([stamp, user.timeStats[key].score.influence.score]);
    }
    for (const key in user.timeStats.filter(element => (element.prediction !== undefined))) {
      const stamp = Moment(user.timeStats[key].timeSpan).valueOf()
      engagementPrediction.push([stamp, user.timeStats[key].prediction.engagement]);
      activityPrediction.push([stamp, user.timeStats[key].prediction.activity]);
      sentimentPrediction.push([stamp, user.timeStats[key].prediction.sentiment]);
      connectednessPrediction.push([stamp, user.timeStats[key].prediction.connectedness]);
      influencePrediction.push([stamp, user.timeStats[key].prediction.influence]);
    }

    series.engagement.push({ name: userName, data: engagement })
    series.engagement.push({ name: "(Prediction)", data: engagementPrediction })
    series.activity.push({ name: userName, data: activity })
    series.activity.push({ name: "(Prediction)", data: activityPrediction })
    series.sentiment.push({ name: userName, data: sentiment })
    series.sentiment.push({ name: "(Prediction)", data: sentimentPrediction })
    series.connectedness.push({ name: userName, data: connectedness })
    series.connectedness.push({ name: "(Prediction)", data: connectednessPrediction })
    series.influence.push({ name: userName, data: influence })
    series.influence.push({ name: "(Prediction)", data: influencePrediction })
  }
  // xaxis: { type: "date", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
  // yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : null } } },

  // Generate visualisation settings:
  const dashArray = []
  const curveArray = []
  const colors = ["#008FFB", "#3F51B5", "#33B2DF", "#4ECDC4", "#2B908F", "#449DD1", "#D7263D", "#662E9B", "#5C4742", "#A300D6", ]
  const colorArray = []
  for (let i = 0; i < series.engagement.length / 2; i++) {
    //   colors: ['#3786a1', '#F44336', '#37a141'],
    dashArray.push(0) // Add solid line
    dashArray.push(3) // Add dash line
    curveArray.push("straight") // Add straight line
    curveArray.push("smooth") // Add smooth line
    colorArray.push(colors[(i % 10)])
    colorArray.push(colors[(i % 10)])
    // opacity.push(0.05) // Add solid line
    // opacity.push(1)// Add dash line
    // stroke: { width: [3, 3, 3, 1, 1, 1], dashArray: [0, 0, 0, 3, 4, 5] },
    // fill: { type: 'solid', opacity: [0.05, 0.05, 0.05, 1, 1, 1], },

  }
  const graphChartOptionsCollaboration = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: categories },
    yaxis: { min: 0, max: 99, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : "" } } },
    legend: { position: "right", show: true },
    colors: colorArray,
    stroke: { width: 2, dashArray: dashArray, curve: curveArray },
    //states: { normal: { filter: { type: "darken", value: 0.04 } } }
    //states: { normal: { filter: { type: "darken", value: 0.15 } }, hover: { filter: { type: "darken", value: 0.15 } } }
  };


  return (
    <Card className="card-box mb-4" style={{width:"100%"}}>
      <CardContent className="p-0">
        <div className="ml-3 mt-3 line-height-sm align-items-center justify-content-left" >
          <div className={classes.root}>
            <div style={{float:'left'}}>
              <ButtonGroup style={{marginTop:'7px', marginLeft: '5px'}} variant="contained" color="secondary" aria-label="split button">
                <Button  color="secondary" onClick={handleClick}>{timeSpan==="month"?"Monthly":"Weekly"}</Button>
                <Button color="secondary" size="small" aria-haspopup="true" onClick={handleClick}>
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
                <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                  <MenuItem onClick={(e) => handleClose("month")}>Monthly</MenuItem>
                  <MenuItem onClick={ (e) => handleClose("week")}>Weekly</MenuItem>
                </Menu>
              </div>
            <div className={classes.themeColorTabs}>
              <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                <StyledTab label="Connectedness" />
                <StyledTab label="Influence" />
                <StyledTab label="Engagement" />
                <StyledTab label="Activity" />
                <StyledTab label="Sentiment" />
              </StyledTabs>
            </div>
            <TabPanel value={value} index={3}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <div className=" br-5" ><b className="font-size-lg " >Onboarding Connectedness Progress Compared to Company's Historical Onboardings</b></div>
                  <span className="text-black-50 d-block">(This score is based on connections employees has, information broker index and degree centrality scores)</span>
                  <Chart options={graphChartOptionsCollaboration} series={series.connectedness} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <div className=" br-5" ><b className="font-size-lg " >Onboarding Influence Progress Compared to Company's Historical Onboardings</b></div>
                  <span className="text-black-50 d-block">(This score in based on employees betweenness, authority and pagerank indexes)</span>
                  <Chart options={graphChartOptionsCollaboration} series={series.influence} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={0}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <div className=" br-5" ><b className="font-size-lg " >Onboarding Engagement Progress Compared to Company's Historical Onboardings</b></div>
                  <span className="text-black-50 d-block">(This score is weighted and calculated based on Activity, Connectedness and Influece scores)</span>
                  <Chart options={graphChartOptionsCollaboration} series={series.engagement} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <div className=" br-5" ><b className="font-size-lg " >Onboarding Activity Progress Compared to Company's Historical Onboardings</b></div>
                  <span className="text-black-50 d-block">(This score is based on activity volume, active hours per day and mention metrics)</span>
                  <Chart options={graphChartOptionsCollaboration} series={series.activity} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Grid item xs={12} sm={12} >
                <div className="text-left"><div className="ml-3 mt-1 line-height-sm">
                  <div className=" br-5" ><b className="font-size-lg " >Onboarding Sentiment Progress Compared to Company's Historical Onboarding</b></div>
                  <span className="text-black-50 d-block">(This score is calculated based on positive, negative and neutral collaboration data points)</span>
                  <Chart options={graphChartOptionsCollaboration} series={series.sentiment} type="line" height="300"/>
                </div></div>
              </Grid>
            </TabPanel>
          </div>
        </div>
      </CardContent>
    </Card>
  );


}
