import CommonAnalytics from 'analytics';
import hubSpotPlugin from '@analytics/hubspot';
import fullStoryPlugin from '@analytics/fullstory';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalyticsPlugin from '@analytics/google-analytics';
import awsPinpointPlugin from '@analytics/aws-pinpoint';
import intercomPlugin from '@analytics/intercom';
import amplify from './aws-exports';
import { Auth } from 'aws-amplify';

const config = require("./config.json");
const intercomAppId = config.INTERCOM_APP_ID; // Add intercome app id for connectivity

const production = window.location.hostname === "app.flowtrace.io";
if(!production)
    console.log(amplify)

const commonAnalytics = CommonAnalytics({
  app: 'flowtrace',
  //debug: !production,
  plugins: production?[
    fullStoryPlugin({ org: 'VR7V1' }),
    hubSpotPlugin({ portalId: '7761721' }),
    googleAnalyticsPlugin({ measurementIds: ['G-D6NGGJZBFP'] }),
    googleTagManager({ containerId: 'AW-625517572' }),
    intercomPlugin({ appId: intercomAppId }),
    awsPinpointPlugin({ pinpointAppId: "10c1a3a14c61419596eb80e1f795d261", getCredentials: () => Auth.currentCredentials(), pinpointRegion: "eu-west-1"})
  ]:[
    intercomPlugin({ appId: intercomAppId }),
    awsPinpointPlugin({ pinpointAppId: "5d14c820621e4f719ad03ba6c760d50a", getCredentials: () => Auth.currentCredentials(), pinpointRegion: "eu-west-1"})
  ]
});

export default commonAnalytics;