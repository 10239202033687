import { Grid, Card, CardContent, Tooltip } from '@material-ui/core';
import React, { Fragment } from 'react';
import { TableBody, Table, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ExpansionPanel from '@material-ui/core/Accordion';
import ExpansionPanelDetails from '@material-ui/core/AccordionDetails';
import ExpansionPanelSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function deltaIndicator(delta) {
  if (delta > 0)
    return <span key="changeUp" className="font-size-sm mt-1 ml-2 text-success"><FontAwesomeIcon icon={['fa', 'arrow-up']} className="font-size-sm" />{" " + Number.parseInt(delta) + " %"}</span>
  if (delta < 0)
    return <span key="changeDown" className="font-size-sm mt-1 ml-2 text-danger"><FontAwesomeIcon icon={['fa', 'arrow-down']} className="font-size-sm" />{" " + Number.parseInt(delta) + " %"}</span>
  return <span key="noChange" className="font-size-sm mt-1 ml-2">{" "}</span>
}

function createScoreBadgeContent(scoreValue, tooltipText) {
  if (scoreValue < 20)
    return [tooltipText + " This value indicates this team has BAD score compared to rest of the company", "badge badge-darkest-danger"]
  if (scoreValue >= 20 && scoreValue < 40)
    return [tooltipText + " This value indicates this team has POOR score compared to rest of the company!", "badge badge-dark-danger"]
  if (scoreValue >= 40 && scoreValue < 50)
    return [tooltipText + " This value indicates this team has AVERAGE score compared to rest of the company!", "badge badge-dark-warning"]
  if (scoreValue >= 50 && scoreValue < 60)
    return [tooltipText + " This value indicates this team has AVERAGE score compared to rest of the company!", "badge badge-darkest-warning"]
  if (scoreValue >= 60 && scoreValue < 80)
    return [tooltipText + " This value indicates this team has GOOD score compared to rest of the company!", "badge badge-dark-success"]
  if (scoreValue >= 80)
    return [tooltipText + " This value indicates this team has GREAT score compared to rest of the company!", "badge badge-darkest-success"]
}

const badgeTexts = {
  engagement: { title: "Engagement", explanation: "This is a weighted composite score of all other indexes. Engagement score rarely gets higher than 70." },
  sentiment: { title: "Sentiment", explanation: "This composite value indicates how this user's style of collaborating differs from rest of the company by its sentimental style." },
  activity: { title: "This composite index indicates collaboration activity volume. This is calculates from values like active hours, active communication channels and volume of communication." },
  connectedness: { title: "This composite value is calculates based on connectivity degrees of the individual within the company network. For more information, please search for degree centrality and other graph theory based connectedness metrics." },
  influence: { title: "This composite value indicates individuals degree of influence within the company network. This metric can be misleading if individual is part ot two or more peripheral communities within the company. For more information, please search for betweenness and other graph theory based weighted betweenness metrics." }
}


function createScoreBadge(latestScore, previousScore, badgeType) {
  const scoreValue = latestScore[badgeType]
  const previousScoreValue = previousScore ? previousScore[badgeType] : 0

  const scoreDelta = (scoreValue / previousScoreValue * 100 - 100).toFixed(0)

  const [badgeTooltipText, badgeClassNames] = createScoreBadgeContent(Number.parseFloat(scoreValue).toFixed(0), badgeTexts[badgeType].explanation)

  if (badgeType === "engagement" || badgeType === "sentiment") return (
    <Tooltip key={badgeType+"badge"} arrow title={badgeTooltipText} placement="bottom">
      <div style={{textAlign:"center", width:"100%"}} >
        <div className="font-weight-bold font-size-l mt-2 pt-1 mr-2 ">{badgeTexts[badgeType].title}:</div>
        <span className=" font-weight-bold font-size-xxl">
          <span className={badgeClassNames + " mb-5"}>
            {Number.parseInt(scoreValue)}
          </span>
        {deltaIndicator(scoreDelta)}</span>
      </div>
    </Tooltip>
  )
  else return (
    <Tooltip key={badgeType+"badge"} arrow title={badgeTooltipText} placement="bottom">
      <div className="font-weight-bold font-size-l">
        <span className={badgeClassNames}>
          {Number.parseInt(scoreValue)}
        </span>
      {deltaIndicator(scoreDelta)}
      </div>
    </Tooltip>
  )


}

export default function TeamStatisticsPanel(data) {
  const teamData = data.team
  const teamName = data.team.name

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const latestScore = teamData[teamData.length - 1]
  const previousScore = teamData[teamData.length - 2]
  const engagementScore = createScoreBadge(latestScore, previousScore, "engagement")
  const sentimentScore = createScoreBadge(latestScore, previousScore, "sentiment")

  const subScoreRow = []
  const badges = { activity: null, connectedness: null, influence: null }
  badges.activity = createScoreBadge(latestScore, previousScore, "activity")
  badges.connectedness = createScoreBadge(latestScore, previousScore, "connectedness")
  badges.influence = createScoreBadge(latestScore, previousScore, "influence")
  subScoreRow.push(badges)

  const graphChartOptionsMetric = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: teamData.map(stat => stat.date) },
    yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : "" } } },
    legend: { position: "bottom", show: true },
    title: { text: "Team Engagement Scores", style: { fontWeight: "bold" } },
    colors: ['#3786a1', '#F44336', '#37a141'],
    stroke: { width: 2 },
    markers: { size: 6, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 6, },

  };
  const graphChartOptionsCollaboration = {
    chart: { animations: { enabled: false }, stacked: false, toolbar: { show: false } },
    dataLabels: { enabled: false },
    xaxis: { type: "datetime", labels: { show: true }, crosshairs: { width: 1 }, categories: teamData.map(stat => stat.date) },
    yaxis: { min: 0, forceNiceScale: true, labels: { formatter: function(val) { return val ? val.toFixed(1) : "" } } },
    legend: { position: "bottom", show: true },
    title: { text: "Team Network Scores", style: { fontWeight: "bold" } },
    stroke: { width: 2 },
    markers: { size: 6, strokeColors: '#fff', strokeWidth: 2, shape: "circle", radius: 6, },

  };

  const scoreSeries = [
    { name: 'Engagement', data: teamData.map(stat => stat.engagement) },
    { name: 'Activity', data: teamData.map(stat => stat.activity) },
    { name: 'Sentiment', data: teamData.map(stat => stat.sentiment) },
  ];
  const scoreNetworkSeries = [
    { name: 'Connectedness', data: teamData.map(stat => stat.connectedness) },
    { name: 'Influence', data: teamData.map(stat => stat.influence) }
  ];

  return (
    <Fragment>
      <ExpansionPanel expanded={expanded === 'userEngagementPanel'} onChange={handleChange('userEngagementPanel')}>
        <ExpansionPanelSummary expandIcon={expanded?<ExpandMoreIcon />:<div><div><b className="font-size-sm">Details</b></div><ExpandMoreIcon /></div>} aria-controls="panel1bh-content" id="panel1bh-header">
          <Grid key = "firstGrid"item xs={12} lg={12}>
            <Grid container spacing={1} className="mt-1">
              <Grid item xs={12} sm={4} lg={2}>
                <div className="text-left">
                  <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                    <div className=" br-5" ><b className="font-size-lg " >{teamName}</b></div>
                    <span className="text-black-50 d-block">Scores based on {teamData.reduce((acc, item) => { return acc + item.activeDays }, 0)} days of team member activity </span>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className="text-left">
                  <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                    <div className=" justify-content-left br-5" > {engagementScore} </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={2}>
                <div className="text-left">
                  <div className="ml-2 mt-3 line-height-sm align-items-center justify-content-left">
                    <div className=" justify-content-left br-5" > {sentimentScore} </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} lg={5}>
                <div className="text-left">
                  <div className="ml-2 line-height-sm ">
                    <TableContainer className=" line-height-sm" >
                      <Table  size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell >Activity</TableCell>
                            <TableCell align="center">Connectedness</TableCell>
                            <TableCell align="center">Influence</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subScoreRow.map(row => (
                            <TableRow key={"tableRow"+row.name}>
                              <TableCell component="th" scope="row">
                                               {row.activity}
                              </TableCell>
                              <TableCell align="center">{row.connectedness}</TableCell>
                              <TableCell align="center">{row.influence}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
          <Grid item xs={12} lg={12}>
            <Card className="card-box mb-4">
              <CardContent className="p-0">
                <Grid container spacing={1} >
                  <Grid item xs={12} sm={6} >
                    <div className="text-left">
                      <div className="ml-3 mt-1 line-height-sm">
                       <Chart key="chartMetrics" options={graphChartOptionsMetric} series={scoreSeries} type="line" height="200"/>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <div className="text-left">
                      <div className="ml-3 mt-1 line-height-sm">
                       <Chart key="chartGraphs" options={graphChartOptionsCollaboration} series={scoreNetworkSeries} type="line" height="200"/>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );


}
