import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { Component, Fragment, forwardRef } from 'react';
import Loading from '../../Loading';
import meetingAPI from '../../../flowtrace-api/meetingAPI';
import MeetingDetail from './meetingDetail';
import FilterBar from '../../Common/FilterBar';
import Rating from '@material-ui/lab/Rating';
import { Tooltip } from '@material-ui/core';

import MaterialTable from '@material-table/core';
import { ExportCsv } from "@material-table/exporters";
import RateReview from '@material-ui/icons/RateReview';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const agenda = {
  noAgenda: 10,
  staticVariance: 2
}

function convertMinutesToTimeString(minutes) {
  let timeString = ""
  if (!minutes) return "-"
  if (minutes >= 60)
    timeString = Math.floor(minutes / 60).toFixed(0) + "h " + (minutes % 60).toFixed(0) + "m"
  else
    timeString = (minutes % 60).toFixed(0) + " min" || "-"
  return timeString
}

function convertMinutesToCostString(costProps, minutes) {
  if (!minutes) return "-"
  // Average hours worked by employee in UK / year is 1730 https://clockify.me/working-hours
  // Average salary for tech company in UK is 74k https://www.computerweekly.com/news/252478407/Average-UK-tech-salary-grows-to-74000-a-year-in-2019
  // Average employe costs for employer in UK for 74k Gross are £84,216.92 using https://www.pcpayroll.co.uk/uk-salary-calculator/
  const yearlyCost = costProps ? costProps.hours / (costProps.salary * 1000) : 1730 / 84217;
  const cost = minutes / 60 / yearlyCost;
  const currency = costProps ? costProps.currency : "GBP";
  const formatter = new Intl.NumberFormat(Intl.NumberFormat().resolvedOptions().locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits:0
  });
  return formatter.format(cost) + " (" + convertMinutesToTimeString(minutes) + ")" // "£1,000.00 (X hours)"
}

const renderMeetingType = (rowData) => {
  // There's a typo in Google meeting type (hence 2 ways)
  const recur = rowData.type==="recuring_meeting" || rowData.type==="recurring_meeting" ? true : false;
  const icon = recur ? ['fas', 'redo']:['fas', 'calendar-day'];
  const type = recur ? "Recurring":"One off";
  const count = "("+rowData.events.length +" meetings)";
  return (
    <div className="d-flex">
      <span className="mr-3 mr-3 pt-2"> <FontAwesomeIcon icon={icon} className="font-size-xl opacity-5" /> </span>
      <div>
        <a href="#/" onClick={e => e.preventDefault()} className="font-weight-bold text-black" title="..."> {rowData.name} </a> <span className="text-black-50 d-block"> {type} {count} </span>
      </div>
    </div>
)};
const title = (title, tooltip) => {return {titleAsText:title, explanation:tooltip, title:<Tooltip title={tooltip} ><span>{title}</span></Tooltip>}};
//const totalDurationRender = (duration) => { return <span className="font-weight-bold"> { convertMinutesToCostString(duration) } </span> };
const durationRender = (scheduled_duration) => { return <div className="font-weight-bold "> {convertMinutesToTimeString(scheduled_duration)} </div> };
const attendanceRender = (data) => {
  return <span className="font-weight-bold"> {(data).toFixed(0) } </span>;
};

const ratingRender = (review) => {
  if(review.avg)
    return <span><Tooltip title={"Rating is based on " + review.ratings.length + " ratings."} ><div><Rating value={review.avg?review.avg:0} readOnly disabled={review.avg?false:true} precision={0.5} size="small"/></div></Tooltip></span>;
  else
    return <span><Tooltip title="No ratings found for this meeting." ><div><Rating value={0} readOnly disabled size="small"/></div></Tooltip></span>;
};

const reviewRender = (review) => {
  if(review.reviews.length)
    return <Tooltip title={"This meeting has " + review.reviews.length + " written review(s)."} ><RateReview size="small" /></Tooltip>;
  else
    return <Tooltip title="No reviews found for this meeting." ><RateReview color="disabled" size="small" /></Tooltip>;
};


const acceptedRender = (acceptance) => {
  const acceptedRatio = acceptance.accepted / acceptance.invites;
  const declinedRatio = acceptance.declined / acceptance.invites;
  return <span className={((acceptedRatio < declinedRatio) ? "text-danger ":"") + " font-weight-bold" }> {(acceptedRatio * 100).toFixed(0)+"%" } </span>;

};
const agendaRender = (rowData) => {
  const rowAgenda = rowData.agenda;
  let agendaBadge = "undefined";
  if (rowAgenda.average <= agenda.noAgenda) agendaBadge = "no";
  if (rowAgenda.average > agenda.noAgenda && rowAgenda.variance <= agenda.staticVariance) agendaBadge = "static";
  if (rowAgenda.average > agenda.noAgenda && rowAgenda.variance > agenda.staticVariance) agendaBadge = "dynamic";
  // If this is not recurring, then just the fact we have a agenda makes this green
  if (rowAgenda.average > agenda.noAgenda && rowData.type==="meeting") agendaBadge = "dynamic";
  return (
    <div className="text-center">
      <span className="font-weight-bold">
        {(agendaBadge==="no") ? <span className="badge badge-danger">No Agenda</span> : ""}
        {(agendaBadge==="undefined") ? <span className="badge badge-first">Not available</span> : ""}
        {(agendaBadge==="static") ? <span className="badge badge-warning">Static Agenda</span> : ""}
        {(agendaBadge==="dynamic") ? <span className="badge badge-success">Agenda OK</span> : ""}
      </span>
    </div>);
};
const typeRender = (rowData) => {
  const internal = rowData.internal.invites.sum + rowData.group.invites.sum;
  const invites = rowData.invite.invites.sum;
  const external = rowData.external.invites.sum;
  return <div> <b>{(external > 0)?"Mixed":"Internal" }</b>{(external>0)?" (" + (internal / invites * 100).toFixed(0) +"% internal)":""} </div>;
};

const agendaExportTransform = (row) => {
  if (row.agenda.average <= agenda.noAgenda) return "no";
  if (row.agenda.average > agenda.noAgenda && row.agenda.variance <= agenda.staticVariance) return "static";
  return "dynamic";
};


const tooManyResults = {text:"Maximum meeting count reached.", tooltip:"Please refine your search for complete results."};

class MeetingStatisticsTable extends Component {
  constructor(props) {
    super(props);
    //const [range_start, range_end] = [moment().subtract((moment().month % 3) - 1, "months").format("YYYY-MM-01"), moment().format("YYYY-MM-DD")]
    this.state = { loading: true, searchWarning:null, queryFilters: { range_type: "calendar", range_period:"day", range_limit: 28} };
  }


  async loadProductivityStatistics(queryFilters) {
    const data = await meetingAPI.audit(queryFilters, this.props.flowtraceUser);
    const searchWarning = data.meetings.length >= 5000 ? tooManyResults : null;
    data.meetings = data.meetings.map(m => {
      return {
        ...m,
        id:m.meeting_id,
        //calc // accepted gropu + direct
        latest_location: m.events[0] ? m.events[0].location : null,
        latest_resources: m.events[0] ? m.events[0].resources : null,
        calc: {
          acceptance:{
            invites: m.internal.invites.sum + m.group.invites.sum,
            accepted: m.internal.accepted.sum + m.group.accepted.sum,
            declined: m.internal.declined.sum + m.group.declined.sum,
            ratio: (m.internal.accepted.sum + m.group.accepted.sum) / (m.internal.invites.sum + m.group.invites.sum),
          }
        }
        };
    });
    return this.setState({ loading: false, ...data, searchWarning });
  }

  onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    this.loadProductivityStatistics(queryFilters);
  }

  async componentDidMount() {
    this.loadProductivityStatistics(this.state.queryFilters);
  }

  componentWillUnmount() { this.setState = () => { return; }; }

  render() {
    if(this.state.loading)
    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter hidePeriod searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} />
        <Loading text={"This feature requires Google or Outlook integration. Please inquire your account manager for more details."}/>
      </Fragment>);

    const account = this.props.flowtraceUser && this.props.flowtraceUser.account ? this.props.flowtraceUser.account : null;
    const costProps = account && account.props.costConfiguration ? account.props.costConfiguration : null;
    const config = costProps ? `(${costProps.currency}, yearly hours: ${costProps.hours}, and average salary: ${costProps.salary}k)` : "";
    const costEstimateTooltipText = costProps ? " Cost estimate is based on your account default configuration: " + config : " Cost estimate is based on £74k salary and 1,730 hour work year.";

    return (
      <Fragment>
        <FilterBar teamFilter meetingFilter hidePeriod searchWarning={this.state.searchWarning} loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={{...this.state.queryFilters }} onChange={this.onChange.bind(this)} />
          <div >
            <div style={{zIndex:1,position:"absolute",float:'left', marginBottom:"5px"}}>
              <span style={{float:'left', marginLeft:"20px", marginRight:"25px", paddingTop:"10px"}} className="font-weight-bold font-size-xl" >Meeting Insights</span>
            </div>
            <MaterialTable
              columns={[
                { title: "Meeting - Type and Frequency", align:"left", width:250, field: 'name', render: rowData => renderMeetingType(rowData)  },
                { title: 'Organizer', field: 'organizer_name' },
                { ...title("Cost Estimate (HI)", "Formula: (total invites - declined invites) * duration of events * hourly cost estimate." + costEstimateTooltipText), width: 180, defaultSort: "desc", field:"duration.HI.sum", render: rowData => convertMinutesToCostString(costProps, rowData.duration.HI.sum) },
                { ...title("Cost Estimate (LO)", "Formula: accepted invites * duration of events * hourly cost estimate." + costEstimateTooltipText), width: 180, field:"duration.LO.sum", render: rowData => convertMinutesToCostString(costProps, rowData.duration.LO.sum) },
                { ...title("Invited (D)", "Internal meeting participants invited directly to the meeting events."), width: 120, field:"internal.invites.sum", render: rowData => attendanceRender(rowData.internal.invites.sum) },
                { ...title("Invited (G)", "Internal meeting participants invited through group invitation to the meeting events."), width: 120, field:"group.invites.sum", render: rowData => attendanceRender(rowData.group.invites.sum) },
                { ...title("Invited (E)", "External meeting participants invited directly within the meeting events."), width: 120, field:"external.invites.sum", render: rowData => attendanceRender(rowData.external.invites.sum) },
                { ...title("Accepted", "What % of internal (direct & group) invites are accepted."), width: 120, field:"calc.acceptance.ratio", render: rowData => acceptedRender(rowData.calc.acceptance) },
                { ...title("Rating", "Average rating from meeting participants who have accepted the meeting."), width: 120, field:"review.avg", render: rowData => ratingRender(rowData.review) },
                { ...title("Reviews", "How many written feedbacks are available for this meeting."), width: 60, field:"review.review", render: rowData => reviewRender(rowData.review) },
                { ...title("Agenda", "Does agenda exists? Static means agenda stays the same between meeting events."), align:"center", width: 120, sorting: false, field:"agenda", render: rowData => agendaRender(rowData), exportTransformer: row => agendaExportTransform(row)},
                { ...title("Type", "Is internal meeting or does invitees contain external participants."), field:"type", sorting: false, render: rowData => typeRender(rowData) },
                { title: 'Duration', width:150, field:"duration.event.avg", render: rowData => durationRender(rowData.duration.event.avg) },
                { title: 'Location',  hidden:true, export:true, field:"latest_location" },
                { title: 'Resources', hidden:true, export:true, field:"latest_resources" },
              ]}
              data={this.state.meetings}
              icons={tableIcons}
              title=""
              options={
                {
                headerStyle: { padding: '1px' },
                pageSize:10,
                pageSizeOptions:[5,10,25],
                emptyRowsWhenPaging:false,
                exportAllData:true,
                exportMenu: [
                      {
                        label: "Export CSV",
                        exportFunc: (columns, data) => {
                          columns.forEach(c => {
                            if(typeof c.title !== "string")
                              if(c.titleAsText)
                                c.title = c.titleAsText;// + (c.explanation?" - " + c.explanation :"");
                              else
                                c.title = c.field + "-translation-missing";
                          });
                          ExportCsv(columns, data, "meeting_insights_" + (new Date()).toISOString().split(".")[0]);
                        },
                      },
                    ],
                }
              }
              detailPanel={[
                {render: ({rowData}) => { return ( <MeetingDetail flowtraceUser={this.props.flowtraceUser} queryFilters={this.state.queryFilters} members={this.state.members} meetingId={rowData.meeting_id} /> ) }}
              ]}
              onRowClick={(event, rowData, togglePanel) => togglePanel()}
            />
          </div>
        </Fragment>
    );
  } // end of render()
} // end of class

export default MeetingStatisticsTable
