import React, { Component, Fragment } from 'react';
import Loading from '../../Loading'
import { Grid } from '@material-ui/core';
import { API } from 'aws-amplify';
import moment from 'moment';
import FilterBar from '../../Common/FilterBar';
//import HourlyChart from '../../Common/SlackChartComponents/hourlyChart';
import DailyChart from '../../Common/SlackChartComponents/dailyChart';
import TreeComponent from '../../Common/SlackChartComponents/treeComponent';
import ActivityComponent from '../../Common/SlackChartComponents/activityComponent';
import StatsCard from './statsCard';

function getPercentageChange(previousNumber, latestNumber) {
  var decreaseValue = latestNumber - previousNumber;
  return Math.floor((decreaseValue / previousNumber) * 100);
}

class AccountOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, overview: null, queryFilters: { range_type: "calendar", range_period: "day", range_limit:30 } };
  }

  async loadSlackOverview(queryFilters) {
    const dataAPI = 'slackintegration';
    const slackDataPath = '/slack/installed/overview';
    const initAPI = { headers: {}, response: false, queryStringParameters: { ...queryFilters } };

    try {
      const overview = await API.get(dataAPI, slackDataPath, initAPI);
      this.setState({ overview });
    }
    catch (e) { console.error(e) }
  }

  async loadSlackUserData() {
    const dataAPI = 'slackintegration';
    const slackDataPath = '/slack/installed/dashboard';
    const initAPI = { headers: {}, response: true, };

    let slackData = await API.get(dataAPI, slackDataPath, initAPI).then((response, data, body) => {
      if (!response.data.summary)
        return { total: { today: 0, thisWeek: 0, thisMonth: 0 }, users: [], channels: [], topics: [], days: [], weeks: [], months: [] };
      return response.data.summary;
    }).catch(error => {
      console.error(error);
      return { total: { today: 0, thisWeek: 0, thisMonth: 0 }, users: [], channels: [], topics: [], days: [], weeks: [], months: [] };
    });

    // Create total data structure
    slackData.total = {};
    slackData.total.user = Object.keys(slackData.users).length;
    slackData.total.channel = Object.keys(slackData.channels).length;
    slackData.total.topic = Object.keys(slackData.topics).length;

    // Get values for today and yesterday...
    const today = slackData.days[moment().format("YYYY-MM-DD")];
    const yesterday = slackData.days[moment().subtract(1, "days").format("YYYY-MM-DD")];
    const thisWeek = Object.values(slackData.weeks).find(value => value.key < moment().valueOf() && value.key > moment().subtract(7, "days").valueOf());
    const lastWeek = Object.values(slackData.weeks).find(value => value.key < moment().subtract(7, "days").valueOf() && value.key > moment().subtract(15, "days").valueOf());
    const thisMonth = Object.values(slackData.months).find(value => value.key < moment().valueOf() && value.key > moment().subtract(30, "days").valueOf());
    const lastMonth = Object.values(slackData.months).find(value => value.key < moment().subtract(30, "days").valueOf() && value.key > moment().subtract(60, "days").valueOf());

    slackData.total.today = {};
    slackData.total.thisMonth = {};
    slackData.total.thisWeek = {};

    // Populate current values and sentiment ratios
    if (today) slackData.total.today.value = today.doc_count;
    if (thisWeek) slackData.total.thisWeek.value = thisWeek.doc_count;
    if (thisMonth) slackData.total.thisMonth.value = thisMonth.doc_count;
    if (today) slackData.total.today.positive = Math.floor(100 * today.sentiment.POSITIVE / today.doc_count) || "-";
    if (today) slackData.total.today.negative = Math.floor(100 * today.sentiment.NEGATIVE / today.doc_count) || "-";
    if (thisWeek) slackData.total.thisWeek.positive = Math.floor(100 * thisWeek.sentiment.POSITIVE / thisWeek.doc_count) || "-";
    if (thisWeek) slackData.total.thisWeek.negative = Math.floor(100 * thisWeek.sentiment.NEGATIVE / thisWeek.doc_count) || "-";
    if (thisMonth) slackData.total.thisMonth.positive = Math.floor(100 * thisMonth.sentiment.POSITIVE / thisMonth.doc_count) || "-";
    if (thisMonth) slackData.total.thisMonth.negative = Math.floor(100 * thisMonth.sentiment.NEGATIVE / thisMonth.doc_count) || "-";

    // Calculate deltas for doc counts, positive and negative values
    //console.log("Totals:", today, thisWeek, thisMonth)
    if (today && yesterday) slackData.total.today.change = getPercentageChange(yesterday.doc_count, today.doc_count);
    if (thisWeek && lastWeek) slackData.total.thisWeek.change = getPercentageChange(lastWeek.doc_count, thisWeek.doc_count);
    if (thisMonth && lastMonth) slackData.total.thisMonth.change = getPercentageChange(lastMonth.doc_count, thisMonth.doc_count);
    if (today && yesterday) slackData.total.today.positive_change = getPercentageChange(yesterday.sentiment.POSITIVE, today.sentiment.POSITIVE) || "-";
    if (thisWeek && lastWeek) slackData.total.thisWeek.positive_change = getPercentageChange(lastWeek.sentiment.POSITIVE, thisWeek.sentiment.POSITIVE) || "-";
    if (thisMonth && lastMonth) slackData.total.thisMonth.positive_change = getPercentageChange(lastMonth.sentiment.POSITIVE, thisMonth.sentiment.POSITIVE) || "-";
    if (today && yesterday) slackData.total.today.negative_change = getPercentageChange(yesterday.sentiment.NEGATIVE, today.sentiment.NEGATIVE) || "-";
    if (thisWeek && lastWeek) slackData.total.thisWeek.negative_change = getPercentageChange(lastWeek.sentiment.NEGATIVE, thisWeek.sentiment.NEGATIVE) || "-";
    if (thisMonth && lastMonth) slackData.total.thisMonth.negative_change = getPercentageChange(lastMonth.sentiment.NEGATIVE, thisMonth.sentiment.NEGATIVE) || "-";

    return this.setState({ today: slackData.total.today, thisWeek: slackData.total.thisWeek, thisMonth: slackData.total.thisMonth, slackData });
  }


  async componentDidMount() {
    this.setState({ loading: true });
    await Promise.all([
      this.loadSlackUserData(),
      this.loadSlackOverview()
    ]);
    this.setState({ loading: false });
  }

  async onChange(queryFilters) {
    this.setState({ loading: true, queryFilters });
    await this.loadSlackOverview(queryFilters);
    this.setState({ loading: false });
  }


  render() {
    if (this.state.loading)
      return (
        <Fragment>
          <FilterBar hidePeriod teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />
          <Loading/>
        </Fragment>
      );

    return (
      <Fragment>
        <FilterBar hidePeriod teamFilter loading={this.state.loading} flowtraceUser={ this.props.flowtraceUser } queryFilters={this.state.queryFilters} onChange={this.onChange.bind(this)} />

          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="today" componentColor="bg-premium-dark" data={this.state.today} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <StatsCard id="thisWeek" componentColor="bg-midnight-bloom" data={this.state.thisWeek} />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatsCard id="thisMonth" componentColor="bg-plum-plate" data={this.state.thisMonth} />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <DailyChart data={this.state.slackData} />
            </Grid>
            {this.state.overview?
              <Grid item xs={12} lg={6}>
                <ActivityComponent dayHours={this.state.overview.dayHours} />
              </Grid>
            :null}
            {this.state.overview?
              <Grid item xs={12} lg={6}>
                <TreeComponent type="Channel" channel={this.state.overview.channels} />
              </Grid>
            :null}
            {this.state.overview?
              <Grid item xs={12} lg={6}>
                <TreeComponent type="Topic" topic={this.state.overview.topics} />
              </Grid>
            :null}
          </Grid>
        </Fragment>
    ); // end of return
    // <Grid item xs={12} lg={6}>
    //   <TopicTree data={this.state.slackData} />
    // </Grid>


  }

} // end of class

export default AccountOverview;
